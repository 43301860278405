import { Switch, Route, useHistory } from 'react-router-dom';

import ItemsStep from './steps/ItemsSteps';
import ContactStep from './steps/ContactStep';
import ReviewStep from './steps/ReviewStep';
import NoMerchant from './NoMerchant';
import Loader from '../components/Loader';
import { useAppContext } from '../providers/useAppContext';

import { Container } from '../styles/CreateOrder';
import { E_PAGE_PATHS } from '../interfaces/dataTypes';

const CreateOrder = () => {
  const { state } = useAppContext();
  const { isLoading, language } = state;
  const history = useHistory();

  return (
    <Container>
      <Switch>
        <Route path={`/:shopLink/noMerchantFound`} component={NoMerchant} />
        <Route
          path={`/:shopLink/rating/:ratingHash`}
          component={ItemsStep}
        />
        <Route
          path={`/:shopLink/${E_PAGE_PATHS.ITEMS}`}
          component={ItemsStep}
        />
        <Route
          path={`/:shopLink/${E_PAGE_PATHS.CONTACT}`}
          component={ContactStep}
        />
        <Route
          path={`/:shopLink/${E_PAGE_PATHS.REVIEW}`}
          component={ReviewStep}
        />
        <Route path={`/:shopLink/`} component={ItemsStep} />
      </Switch>
    </Container>
  );
};

export default CreateOrder;

import React, { useLayoutEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { E_COLOR, E_DELIVERY_OPTION, E_FONT_WEIGHT } from '../interfaces/dataTypes';
import { IBuyer, ICategory, IOutlet } from '../interfaces';
import CloseAsset from '../assets/close.png';
import ArrowAsset from '../assets/arrow.png';
import { StoreName } from '../styles/CreateOrder';
import { findDistanceByCoordinates } from '../utils';
import { URL_PARAM_FOODCOURT_MERCHANT, URL_PARAM_HIDE_OTHER_OUTLET, URL_PARAM_IS_REDIRECT, URL_PARAM_RESERVATION, URL_PARAM_SOURCE, URL_PARAM_TABLE_NUMBER, URL_VALUE_SOURCE_PICK_UP, URL_VALUE_SOURCE_QR_TABLE } from '../constants';
import { WORDING } from '../constants/translate';

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? '100vh !important' : '100%')};
    overflow-y: ${({ isShow }) => (isShow ? 'hidden !important' : 'scroll')};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
  z-index: 6;
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  position: fixed;
  width: 100vh;
  max-width: 600px;
  background-color: rgba(26, 26, 26, 0.5);
  height: 100vh;
  bottom: 0;
  top: 0;
`;

const Content = styled.div`
  width: 100%;
  padding-bottom: 50px;
  max-width: 600px;
  min-height: 100px;
  max-height: 80%;
  position: fixed;
  background-color: ${E_COLOR.WHITE};
  overflow: hidden;
  bottom: 0;
  border-radius: 16px 16px 0 0;
`;

const ScrollAbleContent = styled.div`
	overflow-y: scroll;
	max-width: 600px;
	max-height: 75vh;
	padding-bottom: 20px;
`;

const ImgClose = styled.img`
  float: right;
  width: 16px;
  height: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin: 31px 20px 20px 18px;
`;

const Option = styled.div<{ isActive: boolean }>`
  background-color: ${E_COLOR.WHITE};
  padding: 16px 12px;
  margin: 0 16px 20px;
  border-radius: 12px;
  border: 1px solid ${({ isActive }) => isActive ? E_COLOR.BLUE_BORDER : E_COLOR.NEW_LIGHT_GREY};
  width: calc(100% - 64px);
`

const WrapperStoreName = styled.div`
  display: flex;
  justify-content: space-between;
`

const ShopName = styled.div`
  color: ${E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 17px;
  line-height: 21px;
  padding-bottom: 8px;
`

const ShopAddress = styled.div`
  color: ${E_COLOR.GREY_DISABLED};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 12px;
  line-height: 19px;
`

const HeaderLabel = styled.div`
	width: 70%;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 27px;
`;

const ActiveStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 25px;
  background-color: ${E_COLOR.LIGHT_BLUE};
  color: ${E_COLOR.DARK_BLUE};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 10px;
  line-height: 21px;
  border-radius: 20px;
`

interface IProps {
  isShow: boolean;
  setShow: (isShow: boolean) => void;
  outlets: IOutlet[];
  currLat: number;
  currLng: number;
  language: "id" | "en";
}

const BottomSheetOutlets = ({
  isShow,
  setShow,
  outlets,
  currLng,
  currLat,
  language,
}: IProps) => {

  const handleClickedShop = (shopUniqueName: string) => {
    // @ts-ignore
    window.location.href = `${window.location.origin}/${shopUniqueName}?${URL_PARAM_IS_REDIRECT}=true`
  }

  const compareDistance = (a: IOutlet, b: IOutlet) => {
    const { lat: latA, lng: lngA } = a;
    const { lat: latB, lng: lngB } = b;
    const distanceA = findDistanceByCoordinates(currLat, currLng, parseFloat(latA), parseFloat(lngA));
    const distanceB = findDistanceByCoordinates(currLat, currLng, parseFloat(latB), parseFloat(lngB));

    return parseFloat(distanceA) - parseFloat(distanceB);
    // if (distanceA < distanceB) {
    //   return -1;
    // }
    // if (distanceA > distanceB) {
    //   return 1;
    // }
    // return 0;
  }

  const sortedOutlets = currLng !== 0 ? outlets.sort(compareDistance) : outlets;

  useLayoutEffect(() => {
    let timer: any;

    // do not open bottom sheet closest outlet suggestion if its already being redirected
		// @ts-ignore
		const url = new URL(window.location.href);
    const sourceValue = url.searchParams.get(URL_PARAM_IS_REDIRECT);
		const reservationValue = url.searchParams.get(URL_PARAM_RESERVATION);
    const tableValue = url.searchParams.get(URL_PARAM_TABLE_NUMBER);
    const sourceQRValue = url.searchParams.get(URL_PARAM_SOURCE);
    const singleOutletValue = url.searchParams.get(URL_PARAM_HIDE_OTHER_OUTLET);
    
    // check if currently opened outlet is already the nearest outlet in sorted outlets list
    if(sortedOutlets[0] && !sortedOutlets[0].is_active && currLat !== 0 && !sourceValue && !reservationValue) {
      // check if its not the nearest outlet in the list, but the active store distance is 0.0km
      const activeShop = sortedOutlets.filter(sortedOutlets => sortedOutlets.is_active === true);
      const activeShopDistance = findDistanceByCoordinates(currLat, currLng, parseFloat(activeShop[0]?.lat), parseFloat(activeShop[0]?.lng));
      
      if(activeShopDistance !== "0.0" && !tableValue && sourceQRValue !== URL_VALUE_SOURCE_QR_TABLE && sourceQRValue !== URL_VALUE_SOURCE_PICK_UP && singleOutletValue !== "true") {
        timer = setTimeout(() => setShow(true), 300);
      }
    }

    return () => {
			clearTimeout(timer);
		};
  },[sortedOutlets, currLat]);

  return (
    <Container isShow={isShow}>
      <GlobalStyle isShow={isShow} />
      <Content>
        <Header>
          <HeaderLabel>
            {WORDING.item_page.MULTI_OUTLETS[language].replace("{length}", language === 'id' ? 'Lihat' : '')}
          </HeaderLabel>
          <ImgClose onClick={() => setShow(false)} src={CloseAsset} id="btn-close-bs-outlets"/>
        </Header>
        <ScrollAbleContent>
        {sortedOutlets.map((outlet, index) => {
          const { address, is_active, shop_name, shop_url, lat, lng } = outlet;
          return (
            <Option key={index} isActive={is_active} onClick={() => handleClickedShop(shop_url)}>
              <WrapperStoreName>
                <ShopName>{shop_name.substring(0,30)}</ShopName>
                {is_active && <ActiveStatus>{WORDING.general.ACTIVE[language]}</ActiveStatus>}
              </WrapperStoreName>
              <ShopAddress>
                {address}
                {currLng !== 0 && " • "}
                <b>{currLng !== 0 && findDistanceByCoordinates(currLat, currLng, parseFloat(lat), parseFloat(lng))}</b>
                <b>{currLng !== 0 && "km"}</b>
              </ShopAddress>
            </Option>
          )
        })}
        </ScrollAbleContent>
      </Content>
    </Container>
  );
};

export default BottomSheetOutlets;

import styled, { createGlobalStyle, keyframes } from "styled-components";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";
import CloseAsset from "../assets/close.png";
import ClockAsset from "../assets/clock.png";
import CalendarAsset from "../assets/calendar.png";
import UpArrowAsset from "../assets/upArrow.png";
import DownArrowAsset from "../assets/downArrow.png";
import ButtonFull from "./ButtonFull";
import React, { useEffect, useState } from "react";
import { WORDING } from "../constants/translate";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh !important" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden !important" : "scroll")};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
	z-index: 6;
	display: ${(props) => (props.isShow ? "block" : "none")};
	position: fixed;
	width: 100vh;
	max-width: 600px;
	background-color: rgba(26, 26, 26, 0.5);
	height: 100vh;
	bottom: 0;
	top: 0;
`;

const Content = styled.div`
	width: 100%;
	padding-bottom: 50px;
	max-width: 600px;
	min-height: 100px;
	position: fixed;
	background-color: ${E_COLOR.WHITE};
	overflow: hidden;
	bottom: 0;
	border-radius: 16px 16px 0 0;
`;

const ImgClose = styled.img`
	float: right;
	width: 16px;
	height: 16px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	border-radius: 5px;
	margin: 31px 20px 20px 18px;
`;

const HeaderLabel = styled.div`
	width: 70%;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 27px;
`;

const FulfillmentTypeContainer = styled.div``;

const RadioWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 0 16px 14px;
`;

const RadioContainer = styled.div`
	display: inline-block;
	vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const Icon = styled.svg`
	fill: none;
	stroke: white;
	stroke-width: 3px;
`;

const StyledRadio = styled.div<{ checked: boolean, color?: string }>`
	display: inline-block;
	width: 23px;
	height: 23px;
	background: ${({ checked, color }) => (checked ? color ? color : E_COLOR.DARK_BLUE : E_COLOR.WHITE)};
	border-radius: 50%;
	margin: 0 9px 2px 0;
	transition: all 150ms;
	border: 1px solid
		${({ checked, color }) => (checked ? color ? color : E_COLOR.DARK_BLUE : E_COLOR.GREY_DISABLED)};

	${Icon} {
		visibility: ${(props) => (props.checked ? "visible" : "hidden")};
	}
`;

const RadioLabel = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	width: 60%;
`;

const SelectedTime = styled.div`
	display: flex;
	border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
	border-radius: 8px;
	padding: 15px;
	margin: 0 16px 8px;
`;

const SelectedTimeIcon = styled.img`
	width: 18px;
	height: 18px;
	margin-right: 12px;
`;

const SelectedTimeValue = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.MEDIUM};
	font-size: 14px;
	line-height: 21px;
`;

const TimePickerContainer = styled.div`
	width: 220px;
	margin: 0 auto;
	display: flex;
	justify-content: space-around;
	align-items: center;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
`;

const TimePickerButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const TimePickerSingle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${E_COLOR.LIGHT_BLUE};
	width: 70px;
	height: 70px;
	border-radius: 8px;
	margin: 10px 0;
`;

const SlideDown = keyframes`
  from {top: 0px;}
  to {top: 5px;}
`;

const TimePickerValue = styled.div`
	color: ${E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 36px;
	line-height: 37px;
	animation: ${SlideDown} 5s;
	animation-timing-function: ease;
`;

const TimePickerButton = styled.img`
	width: 18px;
`;

interface IProps {
	isShow: boolean;
	setShow: (isShow: boolean) => void;
	setShowDate: (isShow: boolean) => void;
	fulfillmentTime: null | number;
	handleSaveDateTime: (isImmediately: boolean) => void;
	time: string;
	date: string;
	setTime: (time: string) => void;
	operationHour: any;
	language: "id" | "en";
	hexPrimary: string;
}

const BottomSheetDateTime = ({
	isShow,
	setShow,
	setShowDate,
	fulfillmentTime,
	setTime,
	time,
	handleSaveDateTime,
	date,
	operationHour,
	language,
	hexPrimary,
}: IProps) => {
	const [isTimePicker, setIsTimePicker] = useState(false);
	const [isImmediately, setIsImmediately] = useState(true);
	const [hour, setHour] = useState(0);
	const [minute, setMinute] = useState(0);
	
	useEffect(() => {
		const hourMinute = time.split(":");
		setHour(parseInt(hourMinute[0]));
		setMinute(parseInt(hourMinute[1]));
	}, [time]);

	useEffect(() => {
		setIsImmediately(fulfillmentTime ? false : true);
	},[fulfillmentTime])

	const handleClickDate = () => {
		setShowDate(true);
		setShow(false);
	};

	const handleChangeMins = (isUp: boolean) => {
		const endHourMin = operationHour.end;
		const endMin = parseInt(endHourMin.substring(3, 5));
		if (isUp) {
			const open24H =
				operationHour.start.slice(0, 5) === "00:00" &&
				operationHour.end.slice(0, 5) === "00:00";
			if (open24H && minute < 50) {
				if(minute % 10 !== 0) {
					setMinute(minute + (10 - minute % 10))
				} else {
					setMinute(minute + 10);
				}
			} else {
				if (minute < 50) {
					const endHourMin = operationHour.end;
					const endHour = parseInt(endHourMin.substring(0, 2));
					if(hour < endHour) {
						if(minute % 10 !== 0) {
							setMinute(minute + (10 - minute % 10))
						} else {
							setMinute(minute + 10);
						}
					} else {
						if(minute < endMin) {
							if(minute % 10 !== 0) {
								setMinute(minute + (10 - minute % 10))
							} else {
								setMinute(minute + 10);
							}
						}
					}
				}
			}
		} else {
			if (minute >= 10) {
				const startHourMin = operationHour.start;
				const startHour = parseInt(startHourMin.substring(0, 2));
				const startMin = parseInt(startHourMin.substring(3, 5));
				if(hour === startHour) {
					if(minute > startMin) {
						if(minute % 10 !== 0) {
							setMinute(minute - (minute % 10))
						} else {
							setMinute(minute - 10);
						}
					}
				} 
				// todo: need to handle user cant select minute less than current minute if its in the same hour
				// else if(hour === new Date().getHours()) {
				// 	if(minute > new Date().getMinutes()) {
				// 		if(minute % 10 !== 0) {
				// 			setMinute(minute - (minute % 10))
				// 		} else {
				// 			setMinute(minute - 10);
				// 		}
				// 	}
				// } 
				else {
						if(minute % 10 !== 0) {
							setMinute(minute - (minute % 10))
						} else {
							setMinute(minute - 10);
						}
				}
			}
		}
	};

	const handleChangeHours = (isUp: boolean) => {
		const open24H =
			operationHour.start.slice(0, 5) === "00:00" &&
			operationHour.end.slice(0, 5) === "00:00";
		if (isUp) {
			if (open24H && hour !== 23) {
				setHour(hour + 1);
			} else {
				const endHourMin = operationHour.end;
				const endHour = parseInt(endHourMin.substring(0, 2));
				const endMin = parseInt(endHourMin.substring(3, 5));
				if (hour < endHour) {
					if (hour === endHour - 1) {
						if (minute <= endMin) {
							setHour(hour + 1);
						}
					} else {
						setHour(hour + 1);
					}
				}
			}
		} else {
			if (open24H && hour !== 0) {
				setHour(hour - 1);
			} else {
				const startHour = operationHour.start;
				if (hour > parseInt(startHour.substring(0, 2))) {
					setHour(hour - 1);
				}
			}
		}
	};

	const handleSaveTime = () => {
		setTime(
			`${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`
		);
		setIsTimePicker(false);
	};

	const handleSave = () => {
		handleSaveDateTime(isImmediately ? true : false);
		setShow(false);
	};

	return (
		<Container isShow={isShow}>
			<GlobalStyle isShow={isShow} />
			<Content>
				<Header>
					<HeaderLabel>{WORDING.general.DELIVERY_TIME[language]}</HeaderLabel>
					{!isTimePicker && (
						<ImgClose onClick={() => setShow(false)} src={CloseAsset} />
					)}
				</Header>
				{!isTimePicker ? (
					<React.Fragment>
						<FulfillmentTypeContainer>
							<RadioWrapper>
								<RadioContainer
									id={`id`}
									onClick={() => setIsImmediately(true)}
								>
									<HiddenCheckbox name={`name`} checked={isImmediately} id="cb-immediately" />
									<StyledRadio checked={isImmediately} color={hexPrimary}>
										<Icon viewBox="0 0 24 24">
											<polyline points="20 6 9 17 3 12" />
										</Icon>
									</StyledRadio>
								</RadioContainer>
								<RadioLabel>{WORDING.general.IMMEDIATELY[language]}</RadioLabel>
							</RadioWrapper>
							<RadioWrapper>
								<RadioContainer
									id={`id`}
									onClick={() => setIsImmediately(false)}
								>
									<HiddenCheckbox name={`name`} checked={!isImmediately} id="cb-choose-time"/>
									<StyledRadio checked={!isImmediately} color={hexPrimary}>
										<Icon viewBox="0 0 24 24">
											<polyline points="20 6 9 17 3 12" />
										</Icon>
									</StyledRadio>
								</RadioContainer>
								<RadioLabel>{WORDING.general.PICK_TIME[language]}</RadioLabel>
							</RadioWrapper>
						</FulfillmentTypeContainer>
						{!isImmediately && (
							<React.Fragment>
								<SelectedTime onClick={handleClickDate}>
									<SelectedTimeIcon src={CalendarAsset} />
									<SelectedTimeValue>{date}</SelectedTimeValue>
								</SelectedTime>
								<SelectedTime onClick={() => setIsTimePicker(true)}>
									<SelectedTimeIcon src={ClockAsset} />
									<SelectedTimeValue>
										{hour < 10 ? `0${hour}` : hour}:
										{minute < 10 ? `0${minute}` : minute}
									</SelectedTimeValue>
								</SelectedTime>
							</React.Fragment>
						)}
					</React.Fragment>
				) : (
					<TimePickerContainer>
						<TimePickerButtonsWrapper>
							<TimePickerButton
								src={UpArrowAsset}
								onClick={() => handleChangeHours(true)}
							/>
							<TimePickerSingle>
								<TimePickerValue>
									{hour < 10 ? `0${hour}` : hour}
								</TimePickerValue>
							</TimePickerSingle>
							<TimePickerButton
								src={DownArrowAsset}
								onClick={() => handleChangeHours(false)}
							/>
						</TimePickerButtonsWrapper>
						:
						<TimePickerButtonsWrapper>
							<TimePickerButton
								src={UpArrowAsset}
								onClick={() => handleChangeMins(true)}
							/>
							<TimePickerSingle>
								<TimePickerValue>
									{minute < 10 ? `0${minute}` : minute}
								</TimePickerValue>
							</TimePickerSingle>
							<TimePickerButton
								src={DownArrowAsset}
								onClick={() => handleChangeMins(false)}
							/>
						</TimePickerButtonsWrapper>
					</TimePickerContainer>
				)}
				<ButtonFull
					hexPrimary={hexPrimary}
					isDisabled={false}
					isPrimary={true}
					onClick={isTimePicker ? handleSaveTime : handleSave}
					customMargin="30px 16px 12px"
					customWidth="calc(100% - 32px)"
					id="bt-apply-date-time"
				>
					{WORDING.general.APPLY[language]}
				</ButtonFull>
				{!isTimePicker && (
					<ButtonFull
						hexPrimary={hexPrimary}
						isDisabled={false}
						isPrimary={false}
						onClick={() => setShow(false)}
						customMargin="0 16px 12px"
						customWidth="calc(100% - 32px)"
						id="bt-cancel-apply-date-time"
					>
						{WORDING.general.CANCEL[language]}
					</ButtonFull>
				)}
			</Content>
		</Container>
	);
};

export default BottomSheetDateTime;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { E_FONT_WEIGHT, E_COLOR, E_ACTION } from "../interfaces/dataTypes";
import { ICategory, IItem, IOpenedItem, ISelectedItems } from "../interfaces";
import { calcDiscountPercentage, parseIDR } from "../utils";

import defaultAsset from "../assets/defaultItem.png";
import HeartOnAsset from "../assets/heartOn.png";
import HeartOffAsset from "../assets/heartOff.png";
import { ReactComponent as Pencil } from "../assets/pencil.svg";
import { useAppContext } from "../providers/useAppContext";
import BottomSheetCategory from "./BottomSheetCategory";
import ItemPreview from "./ItemPreview";
import MultipleImageAsset from "../assets/multipleImage.png";
import { WORDING } from "../constants/translate";

const ItemsContainer = styled.div`
	position: relative;
`;

const ItemBox = styled.div<{ isLast: boolean }>`
	padding-bottom: ${({ isLast }) => (isLast ? "5px" : "22px")};
	border-bottom: ${({ isLast }) =>
		isLast ? "none" : `1px solid ${E_COLOR.BLACK_LINE}}`};
	position: relative;
	margin: 16px;
`;

const ItemImg = styled.img`
	margin: 0 14px 0 0;
	width: 60px;
	height: 60px;
	border-radius: 7px;
	object-fit: cover;
`;

const ItemWrapper = styled.div<{ isSelected: boolean; color?: string }>`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	svg:first-of-type {
		position: absolute;
		top: 18px;
		left: 44px;
	}
	padding-left: 16px;
	margin-left: -16px;
	border-left: 7px solid
		${({ isSelected, color }) =>
			isSelected ? (color ? color : E_COLOR.DARK_BLUE) : "none"};
	text-align: left;
`;

const ItemDetails = styled.div`
	display: flex;
	flex-direction: column;
	width: 60%;
`;

const ItemLabel = styled.h4<{ isDisabled: boolean }>`
	margin: 0 3px 5px 0;
	color: ${({ isDisabled }) =>
		isDisabled ? E_COLOR.GREY_DISABLED : E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

const ItemPrice = styled.div<{ isDisabled: boolean; isDiscount: boolean }>`
	color: ${({ isDisabled }) =>
		isDisabled ? E_COLOR.GREY_DISABLED : E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 16px;
`;

const ItemDiscountWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;

const ItemDiscountPrice = styled.div`
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 10px;
	line-height: 10px;
	padding-right: 4px;
	text-decoration: line-through;
`;

const ItemDiscountPercentage = styled.div<{ isDisabled: boolean }>`
	background-color: ${({ isDisabled }) =>
		isDisabled ? E_COLOR.GREY_DISABLED : E_COLOR.RED};
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 8px;
	line-height: 8px;
	padding: 2px;
	text-align: center;
	border-radius: 4px;
`;

const ItemNotes = styled.div`
	display: flex;
	align-items: center;
	margin-top: 14px;
`;

const Notes = styled.div<{ isHaveNotes: boolean }>`
	margin-left: ${({ isHaveNotes }) => (isHaveNotes ? "10px" : "0")};
	word-break: break-word;
	color: ${({ isHaveNotes }) =>
		isHaveNotes ? E_COLOR.DARK_ORANGE : E_COLOR.DARK_BLUE};
	font-weight: ${({ isHaveNotes }) =>
		isHaveNotes ? E_FONT_WEIGHT.REGULAR : E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

const ItemButtonWrapper = styled.div`
	width: 75px;
	display: flex;
	justify-content: space-between;
	align-self: flex-end;
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
`;

const ItemButtonStockWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 9px;
`;

const ItemStock = styled.div`
	margin-top: 10px;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 19px;
`;

const Button = styled.div<{ isClickable: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	color: ${E_COLOR.WHITE};
	background-color: ${({ isClickable }) =>
		isClickable ? E_COLOR.DARK_BLUE : E_COLOR.NEW_LIGHT_GREY};
	border-radius: 50%;
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 16px;
	line-height: 20px;
`;

const CategoryLabel = styled.h3`
  margin: 25px 16px 0;
  color: ${E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 16px;
  line-height: 24px;

  &:nth-child(3) {
    margin-top: 0;
`;

const FloatingButton = styled.div<{ isShow: boolean }>`
	bottom: 0;
	display: ${({ isShow }) => (isShow ? "block" : "none")};
	position: fixed;
	left: 50%;
	transform: translate(-50%, 0);
	margin: 0 auto 120px;
	padding: 7px 25px;
	background-color: ${E_COLOR.BLACK};
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
	border-radius: 24px;
	z-index: 2;
`;

const GreyLine = styled.div<{ isLast: boolean }>`
	display: ${({ isLast }) => (isLast ? "block" : "none")};
	width: 100vw;
	max-width: 600px;
	height: 8px;
	background-color: ${E_COLOR.NEW_GREY_LINE};
`;

const MultiImageIcon = styled.img`
	position: absolute;
	width: 11px;
	height: 11px;
	margin: 5px 5px 0;
`;

const AddItemButton = styled.div<{
	isPrimary: boolean;
	isDisabled: boolean;
	color?: string;
}>`
	width: 30%;
	padding: 7px 0;
	background-color: ${({ isPrimary, color }) =>
		isPrimary ? (color ? color : E_COLOR.DARK_BLUE) : E_COLOR.WHITE};
	border-radius: 5px;
	color: ${({ isPrimary, isDisabled, color }) =>
		isPrimary
			? E_COLOR.WHITE
			: isDisabled
			? E_COLOR.GREY_DISABLED
			: color
			? color
			: E_COLOR.DARK_BLUE};
	border: 1px solid
		${({ isDisabled, color }) =>
			isDisabled ? E_COLOR.GREY_DISABLED : color ? color : E_COLOR.DARK_BLUE};
	font-size: 12px;
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	line-height: 19px;
	text-align: center;
`;

const GreyLayer = styled.div`
	position: absolute;
	width: 60px;
	height: 60px;
	background-color: ${E_COLOR.GREY_LAYER};
	border-radius: 7px;
`;

const OutOfStockLabelWrapper = styled.div`
	width: 60px;
	height: 60px;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const OutOfStockLabel = styled.div`
	background-color: ${E_COLOR.GREY_DISABLED};
	border-radius: 4px;
	padding: 4px 8px;
	color: ${E_COLOR.WHITE};
	font-size: 10px;
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	line-height: 15px;
`;

const Likes = styled.div`
	display: flex;
	align-items: center;
	margin: 4px 0 0;
`;

const LikesNum = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-size: 10px;
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	line-height: 10px;
`;

const LikesIcon = styled.img`
	width: 11px;
	margin: 0 5px 3px;
`;

interface IProps {
	categories?: ICategory[];
	items?: IItem[];
	selectedItems: ISelectedItems;
	isShopClose: boolean;
	handleOpenItemDetail: (item: IItem) => void;
	filterSelectedItemBySameId: (id: string) => ISelectedItems;
	countQuantitySelectedItems: (selectedItems: ISelectedItems) => number;
	language: "id" | "en";
	hexPrimary: string;
}

const ItemList = ({
	categories,
	items,
	selectedItems,
	isShopClose,
	handleOpenItemDetail,
	filterSelectedItemBySameId,
	countQuantitySelectedItems,
	language,
	hexPrimary,
}: IProps) => {
	const [timeoutToogle, setTimeoutToogle] = useState(1000);
	const [isShowCategoryBottomSheet, setIsShowCategoryBottomSheet] =
		useState(false);
	const [isOpenItemPreview, setIsOpenItemPreview] = useState(false);
	const [openedItem, setOpenedItem] = useState({
		description: "",
		images: [] as string[],
		name: "",
		price: 0,
		stock: 0,
	} as IOpenedItem);

	const renderProduct = (
		productsLength: number,
		item: IItem,
		index: number
	) => {
		const {
			name,
			price,
			stock,
			image_urls,
			id,
			description,
			variant,
			is_out_of_stock,
			like_count,
			discount_price,
		} = item;
		const quantity = variant
			? countQuantitySelectedItems(filterSelectedItemBySameId(`${id}`))
			: selectedItems[id]?.quantity;
		return (
			<ItemBox key={index} isLast={index === productsLength - 1}>
				<ItemWrapper
					isSelected={quantity > 0}
					onClick={
						is_out_of_stock || stock === 0 || isShopClose
							? () => {}
							: () => handleOpenItemDetail(item)
					}
					color={hexPrimary}
				>
					{image_urls?.length > 1 && (
						<MultiImageIcon src={MultipleImageAsset} />
					)}
					<ItemImg
						src={
							image_urls && image_urls[0] !== "" ? image_urls[0] : defaultAsset
						}
						// isClose={isShopClose}
					/>
					{(is_out_of_stock || stock === 0 || isShopClose) && (
						<React.Fragment>
							<GreyLayer />
							{(is_out_of_stock || stock === 0) && (
								<OutOfStockLabelWrapper>
									<OutOfStockLabel>
										{WORDING.general.OUT_OF_STOCK[language]}
									</OutOfStockLabel>
								</OutOfStockLabelWrapper>
							)}
						</React.Fragment>
					)}
					<ItemDetails>
						<ItemLabel
							isDisabled={is_out_of_stock || stock === 0 || isShopClose}
						>
							{name}
						</ItemLabel>
						{discount_price ? (
							<React.Fragment>
								<ItemPrice
									isDiscount={true}
									isDisabled={is_out_of_stock || stock === 0 || isShopClose}
								>
									Rp{parseIDR(parseInt(discount_price))}
								</ItemPrice>
								<ItemDiscountWrapper>
									<ItemDiscountPrice>
										Rp{parseIDR(parseInt(price))}
									</ItemDiscountPrice>
									<ItemDiscountPercentage
										isDisabled={is_out_of_stock || stock === 0 || isShopClose}
									>
										-
										{calcDiscountPercentage(
											parseInt(price),
											parseInt(discount_price)
										)}
										%
									</ItemDiscountPercentage>
								</ItemDiscountWrapper>
							</React.Fragment>
						) : (
							<ItemPrice
								isDiscount={false}
								isDisabled={is_out_of_stock || stock === 0 || isShopClose}
							>
								Rp{parseIDR(parseInt(price))}
							</ItemPrice>
						)}
						{like_count > 0 && (
							<Likes>
								<LikesNum>
									{like_count > 1000
										? `${(like_count / 1000).toFixed(1)}K`
										: like_count}
								</LikesNum>
								<LikesIcon
									src={
										is_out_of_stock || stock === 0 || isShopClose
											? HeartOffAsset
											: HeartOnAsset
									}
								/>
							</Likes>
						)}
					</ItemDetails>
					<AddItemButton
						isDisabled={is_out_of_stock || stock === 0 || isShopClose}
						isPrimary={quantity > 0}
						color={hexPrimary}
					>
						{quantity > 0
							? `${quantity}x - ${WORDING.general.ADD[language]}`
							: WORDING.general.ADD[language]}
					</AddItemButton>
				</ItemWrapper>
			</ItemBox>
		);
	};

	useEffect(() => {
		let timer = setTimeout(() => {
			setTimeoutToogle(3000);
		}, timeoutToogle);
		return () => {
			clearTimeout(timer);
		};
	}, [timeoutToogle]);

	return (
		<React.Fragment>
			<ItemsContainer>
				{categories
					? categories.map(({ name, products }, index) => {
							return (
								<div key={index} id={name.replace(" ", "_")}>
									<CategoryLabel>{name}</CategoryLabel>
									{products.map((item, index) => {
										return renderProduct(products.length, item, index);
									})}
									{/* <GreyLine isLast={index === products.length - 1} /> */}
								</div>
							);
					  })
					: items &&
					  items.map((item, index) => {
							return renderProduct(items.length, item, index);
					  })}
			</ItemsContainer>
			<ItemPreview
				description={openedItem.description}
				images={openedItem.images}
				isShow={isOpenItemPreview}
				setShow={setIsOpenItemPreview}
				name={openedItem.name}
				price={openedItem.price}
				stock={openedItem.stock}
				language={language}
			/>
		</React.Fragment>
	);
};

export default ItemList;

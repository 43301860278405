import React from "react";
import styled from "styled-components";
import {
	E_COLOR,
	E_DELIVERY_OPTION,
	E_FONT_WEIGHT,
} from "../interfaces/dataTypes";
import CloseAsset from "../assets/close.png";
import PickupAsset from "../assets/pickup.svg";
import DineInSVG from "../assets/dinein";
import { WORDING } from "../constants/translate";
import DeliverySVG from "../assets/delivery";
import PickupSVG from "../assets/pickup";
import { ISelectedItems } from "../interfaces";

const Container = styled.div<{ isShow: boolean }>`
	z-index: 6;
	display: ${(props) => (props.isShow ? "block" : "none")};
	position: fixed;
	width: 100vh;
	max-width: 600px;
	background-color: rgba(26, 26, 26, 0.5);
	height: 100vh;
	bottom: 0;
	top: 0;
`;

const Content = styled.div`
	width: 100%;
	padding-bottom: 20px;
	max-width: 600px;
	position: fixed;
	background-color: ${E_COLOR.WHITE};
	overflow: hidden;
	bottom: 0;
	border-radius: 16px 16px 0 0;
`;

const ScrollAbleContent = styled.div`
	overflow-y: scroll;
	max-width: 600px;
	max-height: 75vh;
	padding-bottom: 20px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px;
`;

const Title = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 18px;
	line-height: 27px;
`;

const CloseIcon = styled.img`
	width: 16px;
	height: 16px;
`;

const OrderList = styled.div`
	padding: 0 16px;
`;

const Order = styled.div`
	display: flex;
	padding-bottom: 8px;
`;

const ItemQty = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	padding-right: 4px;
`;

const ItemName = styled.div<{ isBold?: boolean; isItalic?: boolean }>`
	color: ${({ isItalic }) =>
		isItalic ? E_COLOR.GREY_DISABLED : E_COLOR.NEW_BLACK};
	font-weight: ${({ isBold }) =>
		isBold ? E_FONT_WEIGHT.EXTRA_BOLD : E_FONT_WEIGHT.REGULAR};
	font-style: ${({ isItalic }) => (isItalic ? "italic" : "none")};
	font-size: 14px;
	line-height: 21px;
	padding-bottom: 8px;
`;

const ItemDetail = styled.div``;

interface IProps {
	isShow: boolean;
	setShow: (isShow: boolean) => void;
	currentItems: ISelectedItems;
  language: "id" | "en";
}

const BottomSheetOrderDetail = ({ isShow, setShow, currentItems, language }: IProps) => {
	const handleCloseBackground = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (e.target === e.currentTarget) {
			setShow(false);
		}
	};

	return (
		<Container isShow={isShow} onClick={() => setShow(false)}>
			<Content>
				<Header>
					<Title>{WORDING.item_page.ORDER_DETAILS[language]}</Title>
					<CloseIcon
						onClick={(e) => handleCloseBackground(e)}
						src={CloseAsset}
					/>
				</Header>
				<ScrollAbleContent>
					<OrderList>
						{Object.keys(currentItems).map((key) => {
							return (
								<Order>
									<ItemQty>{currentItems[key].quantity}x</ItemQty>
									<ItemDetail>
										<ItemName>{currentItems[key].data.name}</ItemName>
										{Object.keys(currentItems[key].variants).map(
											(varianKey) => {
												return Object.keys(
													currentItems[key].variants[varianKey]
												).map((varDetailKey) => {
													return (
														<ItemName>
															{
																currentItems[key].variants[varianKey][
																	varDetailKey
																].category
															}
															:{" "}
															{
																currentItems[key].variants[varianKey][
																	varDetailKey
																].name
															}
														</ItemName>
													);
												});
											}
										)}
										{currentItems[key].notes && (
											<ItemName isItalic={true}>
												{currentItems[key].notes}
											</ItemName>
										)}
										{currentItems[key].is_takeaway && (
											<ItemName isBold={true}>{WORDING.general.PRODUCT_TAKE_AWAY[language]}</ItemName>
										)}
									</ItemDetail>
								</Order>
							);
						})}
					</OrderList>
				</ScrollAbleContent>
			</Content>
		</Container>
	);
};

export default BottomSheetOrderDetail;

import {
	calcDiscountPercentage,
	getFullDateLabel,
	getMobileOperatingSystem,
	getTimeHourMinutes,
	parseIDR,
} from "../utils";
import {
	E_COLOR,
	E_DELIVERY_OPTION,
	E_USER_AGENT,
} from "../interfaces/dataTypes";
import ButtonFull from "../components/ButtonFull";
import BottomSheetSummaryOption from "../components/BottomSheetSummaryOption";
import BottomSheetPaymentOption from "../components/BottomSheetPaymentOption";
import PopupErrorSuccess from "../components/PopupErrorSuccess";
import BottomSheetPaymentInstruction from "../components/BottomSheetPaymentInstruction";
import { useOpenBillHook } from "../hooks/OpenBill";
import {
	ButtonAction,
	ButtonActionIcon,
	ButtonActionLabel,
	ButtonActionWrapper,
	ButtonChangeBuyerInfo,
	ButtonNext,
	BuyerInfo,
	BuyerInfoBackgroundImage,
	BuyerInfoDetail,
	BuyerInfoWrapper,
	BuyerName,
	BuyerPhone,
	Container,
	DashedLine,
	DimNotification,
	DotSeparator,
	GreyLine,
	ItemCard,
	ItemDetail,
	ItemDiscount,
	ItemDiscountWrapper,
	ItemNamePrice,
	ItemNamePriceWrapper,
	ItemNotes,
	ItemPriceWrapper,
	ItemQuantity,
	OrderHeader,
	OrderLabel,
	OrderProcessedLabel,
	OrderTime,
	Product,
	ProductNamePrice,
	PropValueWrapper,
	SuccessImg,
	TableNumber,
	Title,
	Total,
	TotalLabel,
	TotalPrice,
	VariantWrapper,
	WrapperVoucher,
} from "../styles/OpenBill";
import CartSVG from "../assets/cart";
import BuyerInfoBackgroundAsset from "../assets/buyerInfoBackground.png";
import GoogleAsset from "../assets/google.png";
import AppStoreAsset from "../assets/appstore.jpeg";
import PitikCryAsset from "../assets/pitikCry.png";
import ShareMenuAsset from "../assets/shareMenu.png";
import AddCartAsset from "../assets/addCart.png";
import ShopAsset from "../assets/shop.png";
import { WORDING } from "../constants/translate";
import Voucher from "../components/Voucher";
import React from "react";
import LoyaltyPoint from "../components/LoyaltyPoint";
import BottomSheetOTP from "../components/BottomSheetOTP";
import {
	AppStoreIcon,
	ContainerErrPage,
	ErrPageButton,
	ErrPageDescription,
	ErrPageFooter,
	ErrPageTitle,
	GoogleIcon,
	IconErrPage,
	OpaperTitle,
} from "../styles/SummaryOrder";

const CASHIER_NAME = "KASIR ****";
const CASHIER_PHONE = "+620";

const OpenBill = () => {
	const {
		isPostError,
		setIsPostError,
		popupErrorMsg,
		invoiceData,
		orders,
		isShowBottomSheetPaymentOption,
		setShowBottomSheetPaymentOption,
		selectedPaymentMethod,
		setSelectedPaymentMethod,
		handleCloseBill,
		amountItemOnly,
		fetchOrder,
		fetchPromotionVoucher,
		handleSelectVoucher,
		isSearchingVoucherByCode,
		isShowVoucherBottomSheet,
		searchedCode,
		setIsShowVoucherBottomSheet,
		currentEligibleVouchers,
		currentNotEligibleVouchers,
		fetchPromotionVoucherByCode,
		setSearchedCode,
		voucherErrorMessage,
		selectedProductVoucher,
		selectedShippingVoucher,
		handleAddMoreProduct,
		buyerCoin,
		coinToExchange,
		handleTextInput,
		isBuyerHaveCoin,
		otpCode,
		setOtpCode,
		isShowExchangeCoinBottomSheet,
		setIsShowExchangeCoinBottomSheet,
		setIsShowOtpBottomSheet,
		sendOTPtoBuyer,
		otpLS,
		isShowOtpBottomSheet,
		checkOTPValid,
		totalAmount,
		discountVoucherAmount,
		shopInfo,
		roundedValue,
		serviceFee,
		tax,
		statusPage,
		handleShareMenu,
		handleClickChangeBuyerInfo,
    language,
	} = useOpenBillHook();
	const {
		subtotal,
		dine_in_service_fee_amount,
		dine_in_tax,
		dine_in_tax_amount,
		rounding_adj,
		platform_fee,
		is_buyer_pay_platform,
		shop_eligible_payment_method,
		phone_number,
		name,
		table_no,
	} = invoiceData;

	const render404 = () => {
		return (
			<ContainerErrPage>
				<IconErrPage src={PitikCryAsset} />
				<ErrPageTitle>{WORDING.errors.NOT_FOUND_TITLE[language]}</ErrPageTitle>
				<ErrPageDescription>
					{WORDING.errors.NOT_FOUND_CONTENT[language]}
				</ErrPageDescription>
				<ErrPageFooter>
					{getMobileOperatingSystem() === E_USER_AGENT.IOS ? (
						<AppStoreIcon src={AppStoreAsset} />
					) : (
						<GoogleIcon src={GoogleAsset} />
					)}
					<OpaperTitle isCenter={false}>
						PT Opaper Internasional Indonesia
					</OpaperTitle>
				</ErrPageFooter>
			</ContainerErrPage>
		);
	};

	const render500 = () => {
		return (
			<ContainerErrPage>
				<IconErrPage src={PitikCryAsset} />
				<ErrPageTitle>{WORDING.errors.SERVER_TITLE[language]}</ErrPageTitle>
				<ErrPageDescription>
					{WORDING.errors.SERVER_CONTENT[language]}
				</ErrPageDescription>
				{/* @ts-ignore */}
				<ErrPageButton onClick={() => window.location.reload()}>
					{WORDING.general.RELOAD[language]}
				</ErrPageButton>
				<ErrPageFooter>
					{getMobileOperatingSystem() === E_USER_AGENT.IOS ? (
						<AppStoreIcon src={AppStoreAsset} />
					) : (
						<GoogleIcon src={GoogleAsset} />
					)}
					<OpaperTitle isCenter={false}>
						PT Opaper Internasional Indonesia
					</OpaperTitle>
				</ErrPageFooter>
			</ContainerErrPage>
		);
	};

	const renderErrConnection = () => {
		return (
			<ContainerErrPage>
				<ErrPageTitle>{WORDING.errors.CONNECTION_TITLE[language]}</ErrPageTitle>
				<ErrPageDescription>
					{WORDING.errors.CONNECTION_CONTENT[language]}
				</ErrPageDescription>
				{/* @ts-ignore */}
				<ErrPageButton onClick={() => window.location.reload()}>
					{WORDING.general.RELOAD[language]}
				</ErrPageButton>
				<ErrPageFooter>
					<OpaperTitle isCenter={true}>
						PT Opaper Internasional Indonesia
					</OpaperTitle>
				</ErrPageFooter>
			</ContainerErrPage>
		);
	};

	const renderBuyerInfo = (phone, name, tableNumber) => {
		if (
			!tableNumber &&
			(!phone || phone === CASHIER_PHONE) &&
			(!name || name.startsWith(CASHIER_NAME))
		) {
			return (
				<BuyerInfo>
					<BuyerInfoDetail>
						<BuyerPhone>{WORDING.open_bill.BUYER_INFO_NOT_COMPLETE[language]}</BuyerPhone>
					</BuyerInfoDetail>
					<ButtonChangeBuyerInfo onClick={handleClickChangeBuyerInfo}>
						{WORDING.general.CHANGE[language]}
					</ButtonChangeBuyerInfo>
				</BuyerInfo>
			);
		} else if (
			tableNumber &&
			(!phone || phone === CASHIER_PHONE) &&
			(!name || name.startsWith(CASHIER_NAME))
		) {
			return (
				<BuyerInfo>
					<BuyerInfoDetail>
						<TableNumber>{table_no}</TableNumber>
						<DotSeparator />
						<BuyerPhone>{WORDING.open_bill.BUYER_INFO_NOT_COMPLETE[language]}</BuyerPhone>
					</BuyerInfoDetail>
					<ButtonChangeBuyerInfo onClick={handleClickChangeBuyerInfo}>
						{WORDING.general.CHANGE[language]}
					</ButtonChangeBuyerInfo>
				</BuyerInfo>
			);
		} else if (tableNumber && name && (!phone || phone === CASHIER_PHONE)) {
			return (
				<BuyerInfo>
					<BuyerInfoDetail>
						<TableNumber>{table_no}</TableNumber>
						<DotSeparator />
						<BuyerName>{name}</BuyerName>
						<BuyerPhone isOrange={true}>(??)</BuyerPhone>
					</BuyerInfoDetail>
					<ButtonChangeBuyerInfo onClick={handleClickChangeBuyerInfo}>
						{WORDING.general.CHANGE[language]}
					</ButtonChangeBuyerInfo>
				</BuyerInfo>
			);
		} else if (
			tableNumber &&
			phone &&
			(!name || name.startsWith(CASHIER_NAME))
		) {
			return (
				<BuyerInfo>
					<BuyerInfoDetail>
						<TableNumber>{table_no}</TableNumber>
						<DotSeparator />
						<BuyerName isOrange={true}>??</BuyerName>
						<BuyerPhone>({phone_number})</BuyerPhone>
					</BuyerInfoDetail>
					<ButtonChangeBuyerInfo onClick={handleClickChangeBuyerInfo}>
						{WORDING.general.CHANGE[language]}
					</ButtonChangeBuyerInfo>
				</BuyerInfo>
			);
		} else {
			return (
				<BuyerInfo>
					<BuyerInfoDetail>
						<TableNumber>{table_no ? table_no : "??"}</TableNumber>
						<DotSeparator />
						<BuyerName isOrange={name.startsWith(CASHIER_NAME)}>
							{name.startsWith(CASHIER_NAME) ? "??" : name}
						</BuyerName>
						<BuyerPhone isOrange={phone === CASHIER_PHONE}>
							({phone === CASHIER_PHONE ? "??" : phone_number})
						</BuyerPhone>
					</BuyerInfoDetail>
					<ButtonChangeBuyerInfo onClick={handleClickChangeBuyerInfo}>
						{WORDING.general.CHANGE[language]}
					</ButtonChangeBuyerInfo>
				</BuyerInfo>
			);
		}
	};

	const downpaymentData = invoiceData.additional_payments?.filter(
		(payment) => payment.payment_method === "downpayment"
	)[0];

	return (
		<React.Fragment>
			{statusPage === 200 ? (
				<Container>
					<PopupErrorSuccess
						isOpen={isPostError}
						handleClose={() => setIsPostError(false)}
						isSuccess={false}
						language={language}
						desc={popupErrorMsg}
						isCancelButtonOnly={true}
					/>
					<SuccessImg src={shopInfo.logo_url ? shopInfo.logo_url : ShopAsset} />
					<OrderProcessedLabel>
						{name && !name.startsWith(CASHIER_NAME)
							? name.includes(" ")
								? WORDING.open_bill.THANK_YOU_WITH_NAME[language].replace('{name}', name.substring(0, name.indexOf(" ")))
								: WORDING.open_bill.THANK_YOU_WITH_NAME[language].replace('{name}', name)
							: WORDING.open_bill.THANK_YOU_WITHOUT_NAME[language]}
					</OrderProcessedLabel>
					<BuyerInfoWrapper>
						<BuyerInfoBackgroundImage src={BuyerInfoBackgroundAsset} />
						{renderBuyerInfo(
							phone_number,
							name.includes(" ") ? name.substring(0, name.indexOf(" ")) : name,
							table_no
						)}
					</BuyerInfoWrapper>

					{/* <ButtonNext isDisabled={false} isPrimary={false} onClick={handleAddMoreProduct}>
					Tambah Pesanan
				</ButtonNext> */}
					<ButtonActionWrapper>
						<ButtonAction onClick={handleShareMenu}>
							<ButtonActionIcon src={ShareMenuAsset} />
							<ButtonActionLabel>{WORDING.open_bill.SHARE_OPEN_BILL_LINK[language]}</ButtonActionLabel>
						</ButtonAction>
						<ButtonAction onClick={handleAddMoreProduct}>
							<ButtonActionIcon src={AddCartAsset} />
							<ButtonActionLabel>{WORDING.open_bill.ADD_MORE_PRODUCT[language]}</ButtonActionLabel>
						</ButtonAction>
					</ButtonActionWrapper>
					<GreyLine />

					<Title>{WORDING.review_page.DETAIL_ORDER[language]}</Title>
					{orders.map(({ time, products }, index) => {
						return (
							<React.Fragment>
								<OrderHeader>
									<OrderLabel>
										Order #{index + 1}{" "}(
										{getFullDateLabel(new Date(time), true)},{" "}
										{getTimeHourMinutes(new Date(time))} WIB)
									</OrderLabel>
									{/* <OrderTime>
									{getFullDateLabel(new Date(time), true)},{" "}
									{getTimeHourMinutes(new Date(time))} WIB
								</OrderTime> */}
								</OrderHeader>
								{products.map((product, index) => {
									const {
										name,
										quantity,
										discount_price,
										price,
										description,
										variants,
									} = product;
									return (
										<ItemCard isLastItem={index === products.length - 1}>
											<ItemQuantity>{quantity}x</ItemQuantity>
											<ItemDetail>
												<ItemNamePriceWrapper>
													<ItemNamePrice isBold={false} width="60%">
														{name}
													</ItemNamePrice>
													{discount_price ? (
														<ItemPriceWrapper>
															<ItemNamePrice isBold={false}>
																Rp
																{parseIDR(parseInt(discount_price) * quantity)}
															</ItemNamePrice>
															<ItemDiscountWrapper>
																<ItemDiscount isPercentage={true}>
																	-
																	{calcDiscountPercentage(
																		parseInt(price),
																		parseInt(discount_price)
																	)}
																	%
																</ItemDiscount>
																<ItemDiscount isPercentage={false}>
																	{parseIDR(parseInt(price) * quantity)}
																</ItemDiscount>
															</ItemDiscountWrapper>
														</ItemPriceWrapper>
													) : (
														<ItemNamePrice isBold={false}>
															Rp{parseIDR(price * quantity)}
														</ItemNamePrice>
													)}
												</ItemNamePriceWrapper>
												{variants.map((variant) => {
													const { id, name, price, variant_name } = variant;
													if (id === 0 && name.toUpperCase() === "TAKEAWAY") {
														return (
															<VariantWrapper>
																<ItemNamePrice width="65%" isBold>
																	{WORDING.general.PRODUCT_TAKE_AWAY[language]}
																</ItemNamePrice>
																<ItemNamePrice
																	textAlign="right"
																	width="35%"
																	isBold={false}
																>
																	Rp{parseIDR(parseInt(price) * quantity)}
																</ItemNamePrice>
															</VariantWrapper>
														);
													}

													return (
														<VariantWrapper>
															<ItemNamePrice width="65%" isBold={false}>
																{variant_name}: {name}
															</ItemNamePrice>
															<ItemNamePrice
																textAlign="right"
																width="35%"
																isBold={false}
															>
																Rp{parseIDR(parseInt(price) * quantity)}
															</ItemNamePrice>
														</VariantWrapper>
													);
												})}
												{description !== "" && (
													<ItemNotes>{description}</ItemNotes>
												)}
											</ItemDetail>
										</ItemCard>
									);
								})}
							</React.Fragment>
						);
					})}
					{/* {discountVoucherAmount > 0 && ( */}
					{(selectedProductVoucher || selectedShippingVoucher) && (
						<Product>
							<PropValueWrapper>
								<ProductNamePrice>
									{WORDING.review_page.DISCOUNT_CODE[language]}
									<TotalLabel inline={true}>
										{selectedProductVoucher
											? selectedShippingVoucher
												? `${selectedProductVoucher?.code}, ${selectedShippingVoucher?.code}`
												: selectedProductVoucher?.code
											: selectedShippingVoucher?.code}
									</TotalLabel>
								</ProductNamePrice>
								<ProductNamePrice>
									- Rp{parseIDR(discountVoucherAmount)}
								</ProductNamePrice>
							</PropValueWrapper>
						</Product>
					)}
					{/* )} */}
					{coinToExchange !== "" && (
						<Product>
							<PropValueWrapper>
								<ProductNamePrice>
									{WORDING.review_page.COIN[language]}
								</ProductNamePrice>
								<ProductNamePrice>
									-Rp
									{parseIDR(
										parseInt(coinToExchange) *
											parseInt(shopInfo.loyalty_data.value_per_coin)
									)}
								</ProductNamePrice>
							</PropValueWrapper>
						</Product>
					)}
					{dine_in_service_fee_amount && (
						<Product>
							<PropValueWrapper>
								<ProductNamePrice>
									{WORDING.general.SERVICE_FEE[language]}
								</ProductNamePrice>
								<ProductNamePrice>
									Rp
									{parseIDR(serviceFee)}
								</ProductNamePrice>
							</PropValueWrapper>
						</Product>
					)}
					{dine_in_tax_amount && (
						<Product>
							<PropValueWrapper>
								<ProductNamePrice>
									{WORDING.general.TAX[language]} {shopInfo.tax}%
								</ProductNamePrice>
								<ProductNamePrice>Rp{parseIDR(tax)}</ProductNamePrice>
							</PropValueWrapper>
						</Product>
					)}
					{shopInfo.is_buyer_pay_platform_fee && (
						<Product>
							<PropValueWrapper>
								<ProductNamePrice>
									{WORDING.general.PLATFORM_FEE[language]}
								</ProductNamePrice>
								<ProductNamePrice>
									Rp{parseIDR(shopInfo.platform_fee)}
								</ProductNamePrice>
							</PropValueWrapper>
						</Product>
					)}
					{roundedValue !== 0 && (
						<Product>
							<PropValueWrapper>
								<ProductNamePrice>
									{WORDING.general.ROUNDING[language]}
								</ProductNamePrice>
								<ProductNamePrice>
									{roundedValue < 0 && "-"} Rp
									{roundedValue &&
										parseIDR(
											roundedValue < 0 ? roundedValue * -1 : roundedValue
										)}
								</ProductNamePrice>
							</PropValueWrapper>
						</Product>
					)}
					{downpaymentData && (
						<Product>
							<PropValueWrapper>
								<ProductNamePrice>{WORDING.open_bill.DOWN_PAYMENT_REIMBURSE[language]}</ProductNamePrice>
								<ProductNamePrice>
									-Rp{parseIDR(downpaymentData.value)}
								</ProductNamePrice>
							</PropValueWrapper>
						</Product>
					)}
					<WrapperVoucher>
						<Voucher
							code={searchedCode}
							fetchPromotionVoucher={fetchPromotionVoucher}
							fetchPromotionVoucherByCode={fetchPromotionVoucherByCode}
							isShowBottomSheetVoucher={isShowVoucherBottomSheet}
							selectedProductVoucher={selectedProductVoucher}
							selectedShippingVoucher={selectedShippingVoucher}
							setCode={setSearchedCode}
							setIsShowBottomSheetVoucher={setIsShowVoucherBottomSheet}
							handleSelectVoucher={handleSelectVoucher}
							eligibleVouchers={currentEligibleVouchers}
							notEligibleVouchers={currentNotEligibleVouchers}
							errorMessage={voucherErrorMessage}
							amountItemOnly={parseInt(invoiceData.subtotal)}
							isSearchingVoucherByCode={isSearchingVoucherByCode}
							language={language}
							hexPrimary={E_COLOR.DARK_BLUE}
						/>
					</WrapperVoucher>
					{shopInfo.loyalty_data && (
						<LoyaltyPoint
							isHaveCoin={isBuyerHaveCoin}
							sendOTPTrigger={sendOTPtoBuyer}
							coins={buyerCoin}
							coinToExchange={coinToExchange}
							handleTextInput={handleTextInput}
							hexColor={E_COLOR.DARK_BLUE}
							language={language}
							setOtpCode={setOtpCode}
							loyaltyData={{
								maxCoinUsed: invoiceData.max_coin_used,
								minPurchase: parseInt(invoiceData.coin_min_order),
								valuePerCoin: parseInt(invoiceData.value_per_coin),
							}}
							totalAmountItemOnly={parseInt(invoiceData.subtotal_before_additional_payments)}
							otpCode={otpCode || otpLS}
							// otpCode=""
							discountVoucherAmount={0}
							isShowExchangeCoinBottomSheet={isShowExchangeCoinBottomSheet}
							setIsShowExchangeCoinBottomSheet={
								setIsShowExchangeCoinBottomSheet
							}
							setShowOTPBottomSheet={setIsShowOtpBottomSheet}
							checkOtpValid={checkOTPValid}
						/>
					)}
					<DashedLine />
					<Total>
						<TotalLabel>Total</TotalLabel>
						<TotalPrice>
							{/* Rp {totalAmount <= 0 ? "0" : parseIDR(totalAmount)}  */}
							{totalAmount < 0 ? '-' : ''}Rp{parseIDR(Math.abs(+totalAmount))}
						</TotalPrice>
					</Total>

					<ButtonNext
						color={E_COLOR.DARK_BLUE}
						isPrimary={true}
						onClick={totalAmount < 0 ? () => {} : () => setShowBottomSheetPaymentOption(true)}
						isDisabled={totalAmount < 0}
					>
            {
              WORDING.open_bill.PAY_FINISH_BTN[language]
                .replace('{price}', `${totalAmount < 0 ? '-' : ''}Rp${parseIDR(Math.abs(+totalAmount))}`)
            }
						{/* {shop.openBillSetting === "open" && selectedShip.name === E_DELIVERY_OPTION.DINE_IN ? WORDING.review_page.PAY_NOW[language] : WORDING.review_page.CONFIRM_ORDER[language]} */}
					</ButtonNext>
					<BottomSheetPaymentOption
						isShow={isShowBottomSheetPaymentOption}
						setShow={setShowBottomSheetPaymentOption}
						selectedPaymentMethod={selectedPaymentMethod}
						setSelectedPaymentMethod={setSelectedPaymentMethod}
						handleSubmitPaymentMethod={handleCloseBill}
						isCashEligible={
							selectedProductVoucher
								? selectedProductVoucher?.payment_type?.includes("cash")
								: true
						}
						isXenditEligible={
							selectedProductVoucher
								? selectedProductVoucher?.payment_type?.includes("xendit")
								: true
						}
						isEDCEligible={
							selectedProductVoucher
								? selectedProductVoucher?.payment_type?.includes("manual-edc")
								: true
						}
						getEligibilityForCustomPayment={(payment) =>
							selectedProductVoucher
								? selectedProductVoucher?.payment_type?.includes(payment)
								: true
						}
						eligiblePaymentMethods={shop_eligible_payment_method}
						language={language}
					/>
					<DimNotification isShow={false}>
						{WORDING.message.VOUCHER_OUT_OF_STOCK[language]}
					</DimNotification>
					<BottomSheetOTP
						isShow={isShowOtpBottomSheet}
						setShow={setIsShowOtpBottomSheet}
						phone={phone_number}
						resendOTP={sendOTPtoBuyer}
						setShowExchangeCoinBottomSheet={setIsShowExchangeCoinBottomSheet}
						setOtpCode={setOtpCode}
						checkOtpValid={checkOTPValid}
						otpCode={otpCode}
						language={language}
					/>
				</Container>
			) : statusPage === 404 ? (
				render404()
			) : statusPage === 500 ? (
				render500()
			) : statusPage === -1 ? (
				renderErrConnection()
			) : null}
		</React.Fragment>
	);
};

export default OpenBill;

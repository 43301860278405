import { useState, useEffect, useLayoutEffect } from "react";
import { useAppContext } from "../providers/useAppContext";
import {
	E_ACTION,
	E_DELIVERY_OPTION,
	E_VOUCHER_TYPE,
} from "../interfaces/dataTypes";
import { useHistory, useParams } from "react-router-dom";
import {
	IBuyer,
	ICategory,
	IContextState,
	IFoodcourtCart,
	IItem,
	IItemRating,
	IMerchant,
	IPathParams,
	IProduct,
	IReqCoinBalance,
	IReqOpenBill,
	IReqOtp,
	IReqRating,
	IReqReservation,
	IReqValidateOrder,
	IReservation,
	IResFoodEventInfo,
	ISelectedItem,
	ISelectedItems,
	ISelectedVariant,
	IShop,
	IStockByCategories,
	IVoucher,
	T_PAYMENT_TYPE,
	T_SHOP_TYPE,
} from "../interfaces";
import {
	getFoodcourtMerchantList,
	getFoodEventInfo,
	getOTP,
	getRatingForm,
	getShopInfo,
	getStockCategorizedList,
	postRatingForm,
	postReservation,
	postValidateOTP,
} from "../services/Shop";
import {
	convertLocalTimeToOtherByOffset,
	convertOtherTimeToLocal,
	findIfDateInclude,
	findIfPassedTime,
	findIfTimeInclude,
	getDateDay7,
	getDayBackendName,
	getFormattedDate,
	getHourMinuteFromTime,
	getMondayDate,
	getTimeHourMinutes,
	getUnformattedDate,
} from "../utils";
import {
	ASK_MERCHANT_WORDING,
	AUTO_FILL_CONTACT,
	ERR_VALIDATION_TYPE,
	MONAS_LAT,
	MONAS_LNG,
	REPORT_ISSUE_RESERVATION,
	SHOP_ID_FOR_EXCLUDE_TAKEAWAY,
	SHOP_ID_FOR_POPUP,
	SHOP_URI_FOR_POPUP,
	URL_PARAM_EVENT_TYPE,
	URL_PARAM_FOODCOURT_MERCHANT,
	URL_PARAM_FOOD_COURT,
	URL_PARAM_HIDE_OTHER_OUTLET,
	URL_PARAM_RESERVATION,
	URL_PARAM_SOURCE,
	URL_PARAM_TABLE_NUMBER,
} from "../constants";
import { WORDING } from "../constants/translate";
import { postOpenBill, postValidateOrder } from "../services/Order";

export const useItemsStepHook = () => {
	const INITIAL_RESERVATION: IReservation = {
		countryCode: "",
		date: new Date(),
		name: "",
		notes: "",
		persons: "",
		phone: "",
		time: "00:00",
		reservationFee: "",
	};

	const [currentItems, setCurrentItems] = useState({} as ISelectedItems);
	const [currentItem, setCurrentItem] = useState({} as IItem);
	const [editedItem, setEditedItem] = useState({} as ISelectedItem | null);
	const [activeItemId, setActiveItemId] = useState(-1);
	const [temporaryTotalAmount, setTemporaryTotalAmount] = useState(0);
	const [currentDate, setCurrentDate] = useState(getUnformattedDate());
	const [currentTime, setCurrentTime] = useState("00:00");
	const [fulfillmentTime, setFulfillmentTime] = useState(null);
	const [isValidDateSelected, setIsValidDateSelected] = useState(false);
	const [currentCategories, setCurrentCategories] = useState([] as ICategory[]);
	const [currentOneWeekCategories, setCurrentOneWeekCategories] = useState(
		[] as IStockByCategories[]
	);
	const [isShopCloseForSelectedTime, setIsShopCloseForSelectedTime] =
		useState(false);
	const [isShopCloseNow, setIsShopCloseNow] = useState(false);
	const [currentOperationalHour, setCurrentOperationalHour] = useState(
		null as { start: string; end: string }
	);
	const [isShowBottomSheetItemDetail, setIsShowBottomSheetItemDetail] =
		useState(false);
	const [isShowBottomSheetExistingItem, setIsShowBottomSheeExistingtItem] =
		useState(false);
	const [isShowBottomSheetOrderMethod, setIsShowBottomSheetOrderMethod] =
		useState(false);
	const [isShowBottomSheetDineInInfo, setIsShowBottomSheetDineInInfo] =
		useState(false);
	const [isShowBottomSheetSuccess, setIsShowBottomSheetSuccess] =
		useState(false);
	const [isShowBottomSheetRating, setIsShowBottomSheetRating] = useState(false);
	const [
		isShowBottomSheetReservationPayment,
		setIsShowBottomSheetReservationPayment,
	] = useState(false);
	const [totalItem, setTotalItem] = useState(0);
	const [itemsRating, setItemsRating] = useState(([] as IItemRating[]) || null);
	const [orderSNRating, setOrderSNRating] = useState(null);
	const [successSubmitRating, setSuccessSubmitRating] = useState(false);
	const [listItem, setListItem] = useState([]); // list of items without category
	const [searchedItems, setSearchedItems] = useState([]); // list of items found by searching
	const [keyword, setKeyword] = useState(""); // keyword that used for searching
	const [isSearchComponentSticky, setIsSearchComponentSticky] = useState(false);
	const [shopType, setShopType] = useState("MERCHANT" as T_SHOP_TYPE);
	const [foodEventName, setFoodEventName] = useState("");
	const [foodEventData, setfoodEventData] = useState({} as IResFoodEventInfo);
	const [activeCategory, setActiveCategory] = useState("");
	const [reservation, setReservation] = useState(
		INITIAL_RESERVATION as IReservation
	);
	const [currRoute, setCurrRoute] = useState("");
	const [isTreatAsMerchantFoodcourt, setIsTreatAsMerchantFoodcourt] =
		useState(false);
	const [isDropdownLangActive, setDropdownLangActive] = useState(false);
	const [isReservationError, setIsReservationError] = useState(false);
	const { dispatch, state } = useAppContext();
	const history = useHistory();
	const params = useParams() as IPathParams;
	const { shopLink, ratingHash } = params as IPathParams;
	const {
		selectedDate,
		categories,
		vouchers,
		selectedItems,
		shop,
		oneWeekCategory,
		source: sourceState,
		buyer,
		selectedShip,
		fulfillment,
		language,
		tableNumber,
		isHideOtherOutlet,
		isPopupCommonOpen,
		foodcourtCart,
		merchant,
	} = state as IContextState;
	const [isShowDimNotification, setIsShowDimNotification] = useState(false);
	const [merchants, setMerchants] = useState([]);
	let timerDim: any;

	// BEGIN CASE: OPEN BILL
	// @ts-ignore
	const isOpenBill = sessionStorage.getItem("ordersn") !== null;
	const [showOTPBottomSheet, setShowOTPBottomSheet] = useState<
		"y" | "n" | "exchangeCoin" | "submitOpenBill"
	>("n");
	const [phoneNumberOTP, setPhoneNumberOTP] = useState("");
	// END CASE: OPEN BILL

	const initiateFromPage = () => {
		// get current active page
		const locationPath = history.location.pathname.split("/");
		setActiveCategory(locationPath[2]);

		// Find url params for source qr code
		// @ts-ignore
		const url = new URL(window.location.href);
		const sourceValue = url.searchParams.get(URL_PARAM_SOURCE);
		const foodCourtValue = url.searchParams.get(URL_PARAM_FOOD_COURT);
		const eventTypeValue = url.searchParams.get(URL_PARAM_EVENT_TYPE);
		const reservationValue = url.searchParams.get(URL_PARAM_RESERVATION);
		const tableNumberValue = url.searchParams.get(URL_PARAM_TABLE_NUMBER);
		const hideOtherOutletValue = url.searchParams.get(
			URL_PARAM_HIDE_OTHER_OUTLET
		);
		const merchantFoodcourtValue = url.searchParams.get(
			URL_PARAM_FOODCOURT_MERCHANT
		);

		if (sourceValue && !sourceState) {
			dispatch({
				type: E_ACTION.SAVE_SOURCE,
				payload: sourceValue,
			});
			if (tableNumberValue) {
				dispatch({
					type: E_ACTION.SAVE_TABLE_NUMBER,
					payload: tableNumberValue,
				});
			}
		} else if (foodCourtValue) {
			// want to show back button to foodcourt if its from food court list
			setFoodEventName(foodCourtValue);
			dispatch({
				type: E_ACTION.SAVE_SHOP,
				payload: {
					...shop,
					foodEventLink: foodCourtValue,
				} as IShop,
			});
			dispatch({
				type: E_ACTION.SAVE_TABLE_NUMBER,
				payload: tableNumberValue ? tableNumberValue : "",
			});
		} else if (eventTypeValue) {
			dispatch({
				type: E_ACTION.SAVE_EVENT_TYPE,
				payload: eventTypeValue,
			});
		} else if (reservationValue === "true") {
			setIsShowBottomSheetSuccess(true);
		} else if (tableNumberValue) {
			dispatch({
				type: E_ACTION.SAVE_TABLE_NUMBER,
				payload: tableNumberValue,
			});
		} else if (hideOtherOutletValue) {
			dispatch({
				type: E_ACTION.SAVE_HIDE_OTHER_OUTLETS,
				payload: hideOtherOutletValue === "true",
			});
		} else if (merchantFoodcourtValue) {
			setIsTreatAsMerchantFoodcourt(true);
			if (tableNumberValue) {
				dispatch({
					type: E_ACTION.SAVE_TABLE_NUMBER,
					payload: tableNumberValue,
				});
			}
		}

		// Check if store is currently close by the date and operational hours
		if (currentOperationalHour) {
			const open24H =
				currentOperationalHour.start.slice(0, 5) === "00:00" &&
				currentOperationalHour.end.slice(0, 5) === "00:00";
			const dateIncludedInCloseStoreRange = findIfDateInclude(
				currentDate,
				shop.close.start,
				shop.close.end
			);
			if (open24H && !dateIncludedInCloseStoreRange && shop.preOrder === 0) {
				setIsShopCloseForSelectedTime(false);
			} else {
				if (fulfillmentTime) {
					// if user choose another time (not immediately)
					const selectedHourIncluded = findIfTimeInclude(
						`${currentTime.substring(0, 2)}:${currentTime.substring(3, 5)}`,
						currentOperationalHour?.start,
						currentOperationalHour?.end
					);
					const nowHourIncludedInOpenStoreTime = findIfTimeInclude(
						getTimeHourMinutes(currentDate),
						currentOperationalHour?.start,
						currentOperationalHour?.end
					);

					const today = new Date();
					today.setHours(0, 0, 0, 0);
					const preOrderTime = today.getTime() + shop.preOrder * 86400000;

					const isValidPreOrder = fulfillmentTime >= preOrderTime;

					setIsShopCloseForSelectedTime(
						!isValidPreOrder
							? true
							: dateIncludedInCloseStoreRange
							? true
							: selectedHourIncluded
							? false
							: true
					);
					setIsShopCloseNow(
						dateIncludedInCloseStoreRange
							? true
							: nowHourIncludedInOpenStoreTime
							? false
							: true
					);
				} else {
					// handle if store have pre order day and selected date is today
					if (
						(convertLocalTimeToOtherByOffset(
							new Date().getTimezoneOffset(),
							shop.tzOffset
						).toDateString(),
						selectedDate.toDateString())
					) {
						if (shop.preOrder > 0) {
							setIsShopCloseForSelectedTime(true);
						}
					}
				}
				// else {
				// if user choose immediately and selected date is not today
				// if(currentDate.setHours(0, 0, 0, 0) !== convertLocalTimeToOtherByOffset(new Date(new Date().setHours(0,0,0,0)).getTimezoneOffset(),shop.tzOffset).getTime()) {
				// 	const nowHourIncludedInOpenStoreTime = findIfTimeInclude(
				// 		getTimeHourMinutes(selectedDate),
				// 		currentOperationalHour?.start,
				// 		currentOperationalHour?.end
				// 	);

				// 	// setIsShopCloseForSelectedTime(
				// 	// 	dateIncludedInCloseStoreRange
				// 	// 		? true
				// 	// 		: nowHourIncludedInOpenStoreTime
				// 	// 		? false
				// 	// 		: true
				// 	// );
				// 	// setIsShopCloseForSelectedTime(
				// 	// 	dateIncludedInCloseStoreRange
				// 	// 		? true
				// 	// 		: false
				// 	// );
				// }
				// }

				// const hourIncluded = findIfTimeInclude(
				// 	fulfillmentTime
				// 		? `${currentTime.substring(0, 2)}:${currentTime.substring(3, 5)}`
				// 		: `${new Date().getHours()}:${new Date().getMinutes()}`,
				// 	convertOtherTimeToLocal(currentOperationalHour?.start),
				// 	convertOtherTimeToLocal(currentOperationalHour?.end)
				// );
				// setIsShopCloseForSelectedTime(dateIncluded ? true : hourIncluded ? false : true);
			}
		} else if (selectedDate) {
			// means that user back from other page, ex back from shop profile (and we want to find current opt hour)
			const day = getDayBackendName(selectedDate);
			const optHour = shop.operationHours[day];
			setCurrentOperationalHour(optHour);

			if (fulfillment) {
				setFulfillmentTime(fulfillment.timestamp);
				if (fulfillment.time === WORDING.general.IMMEDIATELY[language]) {
					// check if store is open for the current day
					if (optHour) {
						// TODO: FIX LOGIC BELOW ITS REDUNDANT WITH HANDLESELECTDATE
						// check if selected date is today
						const startOptHour = optHour.start;
						const endOptHour = optHour.end;
						if (
							(convertLocalTimeToOtherByOffset(
								new Date().getTimezoneOffset(),
								shop.tzOffset
							).toDateString(),
							selectedDate.toDateString())
						) {
							// find initiate time by the current time or start open shop time

							// check if the store is already in opening hours
							const timeNowMoreThanOpenTime = findIfPassedTime(
								`${selectedDate.getHours()}:${selectedDate.getMinutes()}`,
								startOptHour
							);

							// check if the store is already passed opening hours
							const timeNowMoreThanCloseTime = findIfPassedTime(
								`${selectedDate.getHours()}:${selectedDate.getMinutes()}`,
								endOptHour
							);

							// check if the store currently on holiday status
							const dateIncludedInCloseStoreRange = findIfDateInclude(
								selectedDate,
								shop.close.start,
								shop.close.end
							);

							// close the store if its not open yet today or the opening hours is already passed
							if (
								!timeNowMoreThanOpenTime ||
								timeNowMoreThanCloseTime ||
								dateIncludedInCloseStoreRange
							) {
								setIsShopCloseForSelectedTime(true);
							}
							setCurrentTime(
								timeNowMoreThanOpenTime
									? `${selectedDate.getHours()}:${selectedDate.getMinutes()}`
									: startOptHour
							);
							handleInputReservation(
								"time",
								timeNowMoreThanOpenTime
									? `${selectedDate.getHours()}:${selectedDate.getMinutes()}`
									: startOptHour
							);
						} else {
							setCurrentTime(startOptHour);
							handleInputReservation("time", startOptHour);
						}
					} else {
						setIsShopCloseForSelectedTime(true);
					}
				} else {
					setCurrentTime(fulfillment.time);
				}
			}
		}

		// IF: User back from other page.
		if (selectedDate) {
			setCurrentDate(new Date(selectedDate.getTime()));
			setCurrentCategories(categories);
			setCurrentOneWeekCategories(oneWeekCategory);

			// if (fulfillment?.timestamp) {
			// 	setFulfillmentTime(fulfillment.timestamp);
			// }

			if (selectedItems && Object.keys(selectedItems).length > 0) {
				setCurrentItems(selectedItems);
			}
			countTotalItems(selectedItems || {});

			countTemporaryTotalAmount();
		}
	};

	const initiateFirst = () => {
		if (!isValidDateSelected && !selectedDate) {
			fetchShopInfo();
		}

		// fetch rating form if it has rating hash code on the url
		if (
			ratingHash &&
			typeof itemsRating === "object" &&
			itemsRating?.length === 0
		) {
			fetchRatingForm();
		}

		// means try to get user location if 'we havent get the location' and make sure its not coming from userEffect on review page
		// which will have buyer.name if its already passed the contact page
		if (!buyer.currLat && !buyer.name) {
			getUserCurrentLocation();
		}

		// check if its self kiosk webview and remove autofill data
		// @ts-ignore
		if (navigator.userAgent.indexOf("OpaperKiosk") !== -1) {
			// @ts-ignore
			localStorage.removeItem(AUTO_FILL_CONTACT);
		}
	};

	const handleSaveNotes = (notes: string) => {
		const updatedSelectedItems = { ...currentItems };
		updatedSelectedItems[`${activeItemId}`].notes = notes;

		setCurrentItems(updatedSelectedItems);
		dispatch({
			type: E_ACTION.SAVE_SELECTED_ITEMS,
			payload: updatedSelectedItems,
		});
	};

	const handleSubmit = (isConfirmDineIn?: boolean, isNotDineIn?: boolean) => {
		// remove shop info from local storage
		// @ts-ignore
		localStorage.removeItem("shop");

		//	@ts-ignore
		const orderSN = sessionStorage.getItem("ordersn");

		const copiedCurrentItems = JSON.parse(JSON.stringify(currentItems));
		if (isNotDineIn) {
			Object.keys(copiedCurrentItems).forEach((item) => {
				copiedCurrentItems[item].is_takeaway = true;
			});
		}

		// check if buyer currently add more product for open bill order
		if (orderSN) {
			handleOpenBill(orderSN);
		} else {
			dispatch({
				type: E_ACTION.SAVE_SELECTED_ITEMS,
				payload: copiedCurrentItems,
			});
			dispatch({
				type: E_ACTION.SAVE_FULFILLMENT,
				payload: {
					timestamp: isConfirmDineIn ? null : fulfillmentTime,
					time: isConfirmDineIn
						? WORDING.general.IMMEDIATELY[language]
						: fulfillmentTime
						? currentTime
						: WORDING.general.IMMEDIATELY[language],
				},
			});

			// check if its merchant of a foodcourt. if its a merchant foodcourt, then we want to add current cart changes to foodcourt cart
			// @ts-ignore
			const url = new URL(window.location.href);
			const merchantFoodcourtValue = url.searchParams.get(
				URL_PARAM_FOODCOURT_MERCHANT
			);

			// handle next from tenant that part of foodcourt
			if (merchantFoodcourtValue) {
				handleAddToCartFoodcourt("");
			} else {
				history.push(`/${shopLink}/contact`);
			}
		}
	};

	const countTotalItems = (items: ISelectedItems) => {
		let total = 0;
		Object.keys(items).forEach((id) => {
			total = total + items[id].quantity;
		});
		setTotalItem(total);
	};

	const countTemporaryTotalAmount = () => {
		let amount = 0;
		let discount = 0;
		// const voucherAmount = currentVoucher?.amount
		// 	? parseInt(currentVoucher.amount)
		// 	: 0;
		// const voucherMaxAmount = currentVoucher?.max_amount
		// 	? parseInt(currentVoucher.max_amount)
		// 	: null;

		Object.keys(currentItems).forEach((id: string) => {
			const actualPrice = currentItems[id].data.discount_price
				? currentItems[id].data.discount_price
				: currentItems[id].data.price;
			let itemPrice = parseInt(actualPrice) * currentItems[id].quantity;
			let variantPrice = 0;
			Object.keys(currentItems[id].variants).forEach((variantId: string) => {
				Object.keys(currentItems[id].variants[variantId]).forEach(
					(variantDetailId: string) => {
						variantPrice =
							variantPrice +
							parseInt(
								currentItems[id].variants[variantId][variantDetailId].price
							);
					}
				);
			});

			itemPrice = itemPrice + variantPrice * currentItems[id].quantity;

			// if (currentVoucher?.type === "order") {
			// 	// @ts-ignore
			// 	if (currentVoucher?.product_ids?.includes(parseInt(id))) {
			// 		discount =
			// 			discount +
			// 			countDiscount(itemPrice, voucherAmount, voucherMaxAmount);
			// 	}
			// }

			amount = amount + itemPrice;
			itemPrice = 0;
		});

		// discount = voucherMaxAmount
		// 	? discount > voucherMaxAmount
		// 		? voucherMaxAmount
		// 		: discount
		// 	: discount;
		amount = amount - discount;
		setTemporaryTotalAmount(amount);
		dispatch({
			type: E_ACTION.SAVE_TOTAL,
			payload: amount,
		});
	};

	const handleClickAction = (isShopProfile: boolean) => {
		dispatch({
			type: E_ACTION.SAVE_SELECTED_ITEMS,
			payload: selectedItems,
		});
		dispatch({
			type: E_ACTION.SAVE_FULFILLMENT,
			payload: {
				timestamp: fulfillmentTime,
				time: fulfillmentTime
					? currentTime
					: WORDING.general.IMMEDIATELY[language],
			},
		});

		if (isShopProfile) {
			history.push(`/${shopLink}/profile`);
		} else {
			history.push(`/${shopLink}/reservation`);
		}
	};

	const fetchItems = async (
		shopId,
		operationalDays,
		preOrder,
		date?: Date,
		operationHours?: any,
		closeDate?: any,
		tzOffset?: number
	) => {
		// convert buyer local time to seller time
		const dateNowSeller = convertLocalTimeToOtherByOffset(
			new Date().getTimezoneOffset(),
			tzOffset
		);
		const dateForFetch = date ? date : dateNowSeller;
		const dateStart = getFormattedDate(
			date ? date : getMondayDate(dateNowSeller)
		);
		const dateDay7 = getFormattedDate(
			getDateDay7(true, getUnformattedDate(dateStart))
		);
		const parameter = `start_date=${dateStart}&end_date=${dateDay7}`;
		const responseOneWeekCategories = await getStockCategorizedList(
			shopId,
			parameter
		);

		// save the response to current state and context
		setCurrentOneWeekCategories(responseOneWeekCategories);
		dispatch({
			type: E_ACTION.SAVE_ONE_WEEK_CATEGORY,
			payload: responseOneWeekCategories,
		});

		// set selected date to seller's today's date
		dispatch({
			type: E_ACTION.SAVE_SELECTED_DATE,
			payload: dateForFetch,
		});
		setCurrentDate(new Date(dateForFetch.getTime()));

		// find today's date (by seller local time)
		// TODO: use filter or every instead of loop with exit?
		for (let i = 0; i < responseOneWeekCategories.length; i++) {
			if (
				responseOneWeekCategories[i].date === getFormattedDate(dateForFetch)
			) {
				setCurrentCategories(responseOneWeekCategories[i].categories);

				dispatch({
					type: E_ACTION.SAVE_CATEGORIES,
					payload: responseOneWeekCategories[i].categories,
				});

				// set the list for item without category (to support search)
				const tempListItem = [...listItem];
				responseOneWeekCategories[i].categories.forEach(({ products }) => {
					products.forEach((product) => {
						if (listItem.indexOf(product.id) === -1) {
							tempListItem.push(product);
						}
					});
				});
				setListItem(tempListItem);

				// set i variable to max length so it will quit the loop
				i = responseOneWeekCategories.length;

				setIsValidDateSelected(true);

				// find if today the store is close (by seller time)
				const day = getDayBackendName(dateForFetch);
				if (!operationalDays.includes(day)) {
					setIsShopCloseForSelectedTime(true);
				}

				// find if the store open for the current hour
				const optHour = operationHours[day];
				setCurrentOperationalHour(optHour);
				const open24H = optHour
					? optHour.start.slice(0, 5) === "00:00" &&
					  optHour.end.slice(0, 5) === "00:00"
					: false;
				const dateIncludedInCloseStoreRange = closeDate.start
					? findIfDateInclude(
							dateForFetch,
							getUnformattedDate(closeDate.start),
							getUnformattedDate(closeDate.end)
					  )
					: false;

				// find initiate time by the current time or start open shop time
				if (optHour) {
					const startOptHour = optHour?.start;
					const timeNowMoreThanOpenTime = findIfPassedTime(
						`${dateForFetch.getHours()}:${dateForFetch.getMinutes()}`,
						startOptHour
					);
					setCurrentTime(
						timeNowMoreThanOpenTime
							? `${dateForFetch.getHours()}:${dateForFetch.getMinutes()}`
							: startOptHour
					);
				}

				// const currTime = `${dateNowSeller.getHours()}:${dateNowSeller.getMinutes()}`;
				const currTime = getTimeHourMinutes(dateForFetch);
				const currentTimeIncludedInOptHours = optHour
					? findIfTimeInclude(currTime, optHour.start, optHour.end)
					: false;

				if (dateIncludedInCloseStoreRange) {
					setIsShopCloseForSelectedTime(true);
				} else {
					if (!open24H) {
						if (!currentTimeIncludedInOptHours) {
							setIsShopCloseForSelectedTime(true);
						}
					}
				}
			}
		}
		// find closest active day to set selected date
		// for (let i = 0; i < responseOneWeekCategories.length; i++) {
		// 	if (responseOneWeekCategories[i].categories.length > 0) {
		// 		const date = responseOneWeekCategories[i].date;
		// 		const unformattedDate = getUnformattedDate(date);
		// 		if (operationalDays.includes(getDayBackendName(unformattedDate))) {
		// 			const today = new Date();
		// 			today.setHours(0, 0, 0, 0);
		// 			if (
		// 				// unformattedDate.getDate() >= today.getDate() + preorder_day ||
		// 				unformattedDate.getTime() >=
		// 				today.getTime() + preOrder * 86400000
		// 			) {
		// 				setCurrentCategories(responseOneWeekCategories[i].categories);

		// 				// set the list for item without category (to support search)
		// 				const tempListItem = [...listItem];
		// 				responseOneWeekCategories[i].categories.forEach(({ products }) => {
		// 					products.forEach(product => {
		// 						if(listItem.indexOf(product.id) === -1) {
		// 							tempListItem.push(product);
		// 						}
		// 					});
		// 				})
		// 				setListItem(tempListItem);

		// 				dispatch({
		// 					type: E_ACTION.SAVE_CATEGORIES,
		// 					payload: responseOneWeekCategories[i].categories,
		// 				});

		// 				// set i variable to max length so it will quit the loop
		// 				i = responseOneWeekCategories.length;
		// 				setCurrentDate(unformattedDate);
		// 				dispatch({
		// 					type: E_ACTION.SAVE_SELECTED_DATE,
		// 					payload: unformattedDate,
		// 				});
		// 				setIsValidDateSelected(true);

		// 				// find operational hours for specific selected date
		// 				const day = getDayBackendName(unformattedDate);
		// 				const optHour = operationHours[day];
		// 				setCurrentOperationalHour(optHour);

		// 				// find initiate time by the current time or start open shop time
		// 				// const startOptHour = convertOtherTimeToLocal(optHour.start);
		// 				// const timeNowMoreThanOpenTime = findIfPassedTime(`${new Date().getHours()}:${new Date().getMinutes()}`, startOptHour);
		// 				// setCurrentTime(timeNowMoreThanOpenTime ? `${new Date().getHours()}:${new Date().getMinutes()}` : startOptHour);

		// 				// TODO: FIX LOGIC BELOW ITS REDUNDANT WITH HANDLESELECTDATE
		// 				// check if selected date is today
		// 				const startOptHour = convertOtherTimeToLocal(optHour.start);
		// 				if (new Date().toDateString() === unformattedDate.toDateString()) {
		// 					// find initiate time by the current time or start open shop time
		// 					const timeNowMoreThanOpenTime = findIfPassedTime(
		// 						`${new Date().getHours() < 10 ? `0${new Date().getHours()}` : new Date().getHours()}:${new Date().getMinutes()}`,
		// 						startOptHour
		// 					);
		// 					setCurrentTime(
		// 						timeNowMoreThanOpenTime
		// 							? `${new Date().getHours()}:${new Date().getMinutes()}`
		// 							: startOptHour
		// 					);
		// 					handleInputReservation("time", timeNowMoreThanOpenTime
		// 					? `${new Date().getHours()}:${new Date().getMinutes()}`
		// 					: startOptHour)
		// 				} else {
		// 					setCurrentTime(startOptHour);
		// 					handleInputReservation("time", startOptHour);
		// 				}
		// 			}
		// 		}
		// 	}
		// }
	};

	const fetchMerchantList = async (shopId: string) => {
		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: true,
		});

		const response = await getFoodcourtMerchantList(shopId);

		if (response) {
			setMerchants(response);
		}

		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: false,
		});
	};

	const fetchShopInfo = async (secondShopLink?: string) => {
		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: true,
		});

		// Find url params
		// @ts-ignore
		const url = new URL(window.location.href);
		const merchantFoodcourtValue = url.searchParams.get(
			URL_PARAM_FOODCOURT_MERCHANT
		);

		const shopLink = secondShopLink
			? secondShopLink
			: merchantFoodcourtValue
			? merchantFoodcourtValue
			: params.shopLink;

		// set foodcourt name so user can back to the foodcourt page
		setFoodEventName(params.shopLink);

		const responseShopInfo = await getShopInfo(shopLink);

		// set local storage for SEO purposes
		// @ts-ignore
		localStorage.setItem("shop", JSON.stringify(responseShopInfo));

		if (responseShopInfo.shop_id) {
			const {
				day_of_operation,
				name,
				preorder_day,
				shipping_preference,
				shop_id,
				shop_address,
				self_shipping_note,
				self_shipping_price,
				shop_phone_number,
				close_end_date,
				close_start_date,
				payment_method,
				display_config,
				outlets,
				service_fee,
				tax,
				tax_delivery_method,
				operation_hours,
				is_buyer_pay_platform_fee,
				platform_fee,
				is_reservation_enabled,
				logo_url,
				hex,
				loyalty_data,
				rounding_info,
				open_bill_setting,
				tz_offset,
				is_foodcourt,
				is_tenant,
				is_open,
				is_reservation_fee_enabled,
				reservation_config,
        is_service_fee_free_tax,
			} = responseShopInfo;

			if (is_foodcourt) {
				if (!secondShopLink) {
					setShopType("FOODCOURT");
				}
				fetchMerchantList(shop_id);
				dispatch({
					type: E_ACTION.SAVE_SHOP,
					payload: {
						address: shop_address,
						deliveryOptions: shipping_preference,
						id: shop_id,
						link: shopLink,
						name,
						phone: shop_phone_number,
						close: {
							end: close_end_date ? getUnformattedDate(close_end_date) : null,
							start: close_start_date
								? getUnformattedDate(close_start_date)
								: null,
						},
						operationalDays: day_of_operation,
						preOrder: preorder_day,
						shipping: {
							note: self_shipping_note,
							price: self_shipping_price,
						},
						paymentOptions: payment_method,
						banners: display_config.banners,
						outlets,
						serviceFeePercentage: service_fee,
						taxPercentage: tax,
						operationHours: operation_hours,
						taxDeliveryOption: tax_delivery_method,
						platformFeeByMerchant: !is_buyer_pay_platform_fee,
						platformFee: platform_fee,
						reservation: is_reservation_enabled,
						logoUrl: logo_url,
						hexPrimary: hex,
						loyaltyData: loyalty_data
							? {
									maxCoinUsed: loyalty_data.max_coin_used,
									minPurchase: parseInt(loyalty_data.min_order),
									valuePerCoin: parseInt(loyalty_data.value_per_coin),
							  }
							: null,
						rounding: rounding_info,
						openBillSetting: open_bill_setting,
						tzOffset: (parseInt(tz_offset) / 60) * -1,
						isFoodcourt: is_foodcourt,
						foodEventLink: "",
						foodcourtLink: params.shopLink,
						isTenant: is_tenant,
						isOpen: is_open,
						isReservationFee: is_reservation_fee_enabled,
						reservationFeeConfig: reservation_config,
            isServiceFeeFreeTax: is_service_fee_free_tax,
					} as IShop,
				});
			} else {
				setShopType("MERCHANT");
				// SEO things
				// @ts-ignore
				document
					.querySelector('meta[name="description"]')
					.setAttribute("content", shop_address);
				// @ts-ignore
				document.querySelector('link[rel="canonical"]').setAttribute("href", window.location.href);

				fetchItems(
					shop_id,
					day_of_operation,
					preorder_day,
					null,
					operation_hours,
					{
						start: close_start_date,
						end: close_end_date,
					},
					(parseInt(tz_offset) / 60) * -1
				);

				// means the merchant is part of foodcourt
				// foodcourt will be treated as 'shop' and merchant that part of foodcourt will be treated as 'merchant'
				if (merchantFoodcourtValue) {
					dispatch({
						type: E_ACTION.SAVE_MERCHANT,
						payload: {
							address: shop_address,
							deliveryOptions: shipping_preference,
							id: shop_id,
							link: shopLink,
							name,
							phone: shop_phone_number,
							close: {
								end: close_end_date ? getUnformattedDate(close_end_date) : null,
								start: close_start_date
									? getUnformattedDate(close_start_date)
									: null,
							},
							operationalDays: day_of_operation,
							preOrder: preorder_day,
							shipping: {
								note: self_shipping_note,
								price: self_shipping_price,
							},
							banners: display_config.banners,
							operationHours: operation_hours,
							logoUrl: logo_url,
							hexPrimary: hex,
							isFoodcourt: is_foodcourt,
							isTenant: is_tenant,
							paymentOptions: payment_method,
						} as IMerchant,
					});

					dispatch({
						type: E_ACTION.SET_LOADING,
						payload: true,
					});

					// means user directly open tenant page, not from foodcourt list entry point
					if (merchants.length === 0) {
						fetchShopInfo(params.shopLink);
					}
				} else {
					// else save the shop as a regular shop

					dispatch({
						type: E_ACTION.SAVE_SHOP,
						payload: {
							address: shop_address,
							deliveryOptions: shipping_preference,
							id: shop_id,
							link: shopLink,
							name,
							phone: shop_phone_number,
							close: {
								end: close_end_date ? getUnformattedDate(close_end_date) : null,
								start: close_start_date
									? getUnformattedDate(close_start_date)
									: null,
							},
							operationalDays: day_of_operation,
							preOrder: preorder_day,
							shipping: {
								note: self_shipping_note,
								price: self_shipping_price,
							},
							paymentOptions: payment_method,
							banners: display_config.banners,
							outlets,
							serviceFeePercentage: service_fee,
							taxPercentage: tax,
							operationHours: operation_hours,
							taxDeliveryOption: tax_delivery_method,
							platformFeeByMerchant: !is_buyer_pay_platform_fee,
							platformFee: platform_fee,
							reservation: is_reservation_enabled,
							logoUrl: logo_url,
							hexPrimary: hex,
							loyaltyData: loyalty_data
								? {
										maxCoinUsed: loyalty_data.max_coin_used,
										minPurchase: parseInt(loyalty_data.min_order),
										valuePerCoin: parseInt(loyalty_data.value_per_coin),
								  }
								: null,
							rounding: rounding_info,
							openBillSetting: open_bill_setting,
							tzOffset: (parseInt(tz_offset) / 60) * -1,
							isFoodcourt: is_foodcourt,
							foodEventLink: "",
							isReservationFee: is_reservation_fee_enabled,
							reservationFeeConfig: reservation_config,
              isServiceFeeFreeTax: is_service_fee_free_tax
						} as IShop,
					});
				}

				dispatch({
					type: E_ACTION.SET_LOADING,
					payload: false,
				});
			}
		} else {
			// check if the shop link belongs to food court
			const responseFoodEventInfo = await getFoodEventInfo(shopLink);
			if (responseFoodEventInfo.id) {
				// @ts-ignore
				document.title = responseFoodEventInfo.name;
				setfoodEventData(responseFoodEventInfo);
				setShopType("FOOD_EVENT");

				dispatch({
					type: E_ACTION.SET_LOADING,
					payload: false,
				});
			} else {
				// means the shop link wasnt a shop or a food court
				dispatch({
					type: E_ACTION.SET_LOADING,
					payload: false,
				});
				history.push(`${shopLink}/noMerchantFound`);
			}
		}
	};

	const handleSelectDate = (date: Date) => {
		setCurrentDate(date);

		dispatch({
			type: E_ACTION.SAVE_SELECTED_DATE,
			payload: date,
		});
		dispatch({
			type: E_ACTION.SAVE_SELECTED_ITEMS,
			payload: [],
		});

		const formattedDate = getFormattedDate(date);
		const selectedCategory = currentOneWeekCategories.filter(
			(cat) => cat.date === formattedDate
		);

		setCurrentCategories(selectedCategory[0].categories);
		dispatch({
			type: E_ACTION.SAVE_CATEGORIES,
			payload: selectedCategory[0].categories,
		});

		// update current operational hours as the date changed
		const day = getDayBackendName(date);
		const optHour = shop.operationHours[day];
		setCurrentOperationalHour(optHour);

		// check if selected date is today
		const startOptHour = optHour.start;
		if (new Date().toDateString() === date.toDateString()) {
			// find initiate time by the current time or start open shop time
			const timeNowMoreThanOpenTime = findIfPassedTime(
				`${new Date().getHours()}:${new Date().getMinutes()}`,
				startOptHour
			);
			setCurrentTime(
				timeNowMoreThanOpenTime
					? `${new Date().getHours()}:${new Date().getMinutes()}`
					: startOptHour
			);
			handleInputReservation(
				"time",
				timeNowMoreThanOpenTime
					? `${new Date().getHours()}:${new Date().getMinutes()}`
					: startOptHour
			);
		} else {
			setCurrentTime(startOptHour);
			handleInputReservation("time", startOptHour);
		}
	};

	const handleNextAndPrevDateInterval = (isNext: boolean) => {
		const { id, operationalDays, preOrder } = shop;
		if (isNext) {
			fetchItems(
				id,
				operationalDays,
				preOrder,
				getDateDay7(true, getUnformattedDate(currentOneWeekCategories[0].date)),
				shop.operationHours,
				shop.close,
				shop.tzOffset
			);
		} else {
			fetchItems(
				id,
				operationalDays,
				preOrder,
				getDateDay7(
					false,
					getUnformattedDate(currentOneWeekCategories[0].date)
				),
				shop.operationHours,
				shop.close,
				shop.tzOffset
			);
		}
	};

	const handleOpenItemDetail = (item: IItem) => {
		// IF: Means user directly open item detail by clicking item on item page
		if (countQuantityItemSameId(`${item.id}`) > 0 && item.variant) {
			setCurrentItem(item);
			setIsShowBottomSheeExistingtItem(true);
		} else {
			// ELSE: Means user open item detail from bottom sheet existing items
			setCurrentItem(item);
			setIsShowBottomSheetItemDetail(true);
			if (currentItems[item.id] && !item.variant) {
				setEditedItem(currentItems[item.id]);
			}
		}
	};

	const handleAddSelectedItem = (item: ISelectedItem) => {
		let updatedSelectedItems: ISelectedItems = { ...currentItems };

		// IF: Means its user first time adding this item or user currently editing existing item
		if (!currentItems[item.data.id] || editedItem?.quantity) {
			updatedSelectedItems = { ...updatedSelectedItems, [item.data.id]: item };
		} else {
			// IF: Means user is adding same item with different variant so we need to adjust the id
			const realId = `${item.data.id}`.split("v");
			let maxVId = "";
			Object.keys(currentItems).forEach((id: string) => {
				const currentRealId = id.split("v");

				if (currentRealId[0] === realId[0]) {
					maxVId = currentRealId[1];
				}
			});

			let newId = "";
			if (parseInt(maxVId) >= 1) {
				newId = `${realId[0]}v${parseInt(maxVId) + 1}`;
				updatedSelectedItems = {
					...updatedSelectedItems,
					[newId]: item,
				};
			} else {
				newId = `${realId[0]}v1`;
				updatedSelectedItems = {
					...updatedSelectedItems,
					[newId]: item,
				};
			}

			// update the id inside the data.id with newId
			updatedSelectedItems[newId].data.id = newId;
		}

		setCurrentItems(updatedSelectedItems);
		countTotalItems(updatedSelectedItems);
		setEditedItem({} as ISelectedItem);
		dispatch({
			type: E_ACTION.SAVE_SELECTED_ITEMS,
			payload: updatedSelectedItems,
		});
	};

	const handleChangeDetailExistingItem = (id: string) => {
		setCurrentItem(currentItems[id].data);
		setEditedItem(currentItems[id]);
	};

	const handleDeleteExistingItem = (id: string) => {
		const updatedCurrentItems = { ...currentItems };
		delete updatedCurrentItems[id];

		if (state.shop?.isFoodcourt) {
			const clonedFoodcourtCart = JSON.parse(
				JSON.stringify(foodcourtCart)
			) as IFoodcourtCart;
			if (clonedFoodcourtCart?.allItems?.[id]) {
				delete clonedFoodcourtCart.allItems[id];
			}

			Object.entries(clonedFoodcourtCart?.cart).forEach(([shopId, cart]) => {
				if (cart?.items?.[id]) {
					delete clonedFoodcourtCart.cart[shopId].items[id];
				}
			});

			dispatch({
				type: E_ACTION.SAVE_FOODCOURT_CART,
				payload: clonedFoodcourtCart,
			});
		}

		setCurrentItems(updatedCurrentItems);

		dispatch({
			type: E_ACTION.SAVE_SELECTED_ITEMS,
			payload: updatedCurrentItems,
		});

		// Close the bottom sheet if user remove all current id item
		if (Object.keys(updatedCurrentItems).length === 0) {
			setIsShowBottomSheeExistingtItem(false);
		}
	};

	const filterSelectedItemBySameId = (id: string) => {
		const realId = id.split("v");

		const filteredItem = Object.keys(currentItems)
			.filter((id: string) => {
				const currentId = id.split("v");
				return realId[0] === currentId[0];
			})
			.reduce((res, key) => ((res[key] = currentItems[key]), res), {});
		return filteredItem as ISelectedItems;
	};

	const countQuantitySelectedItems = (selectedItems: ISelectedItems) => {
		let quantity = 0;

		Object.keys(selectedItems).forEach((id: string) => {
			quantity = quantity + selectedItems[id].quantity;
		});

		return quantity;
	};

	const countQuantityItemSameId = (id: string) => {
		return countQuantitySelectedItems(filterSelectedItemBySameId(id));
	};

	const handleChooseOrderMethod = (method: E_DELIVERY_OPTION) => {
		if (method === E_DELIVERY_OPTION.PICK_UP) {
			dispatch({
				type: E_ACTION.SAVE_SELECTED_SHIP,
				payload: {
					name: E_DELIVERY_OPTION.PICK_UP,
					price: 0,
					rateId: 0,
					type: "",
					insuranceRate: 0,
					withInsurance: false,
					isUserUseInsurance: false,
				},
			});
			dispatch({
				type: E_ACTION.SAVE_SELECTED_SHIPPING_VOUCHER,
				payload: null,
			});
		} else if (method === E_DELIVERY_OPTION.DINE_IN) {
			dispatch({
				type: E_ACTION.SAVE_SELECTED_SHIP,
				payload: {
					name: E_DELIVERY_OPTION.DINE_IN,
					price: 0,
					rateId: E_DELIVERY_OPTION.DINE_IN,
					type: "",
					insuranceRate: 0,
					withInsurance: false,
					isUserUseInsurance: false,
				},
			});
			dispatch({
				type: E_ACTION.SAVE_SELECTED_SHIPPING_VOUCHER,
				payload: null,
			});
		} else {
			dispatch({
				type: E_ACTION.SAVE_SELECTED_SHIP,
				payload: {
					name: "",
					price: 0,
					rateId: 0,
					type: "",
					insuranceRate: 0,
					withInsurance: false,
					isUserUseInsurance: false,
				},
			});
		}

		dispatch({
			type: E_ACTION.SAVE_SELECTED_PRODUCT_VOUCHER,
			payload: null,
		});

		handleSubmit(undefined, method !== E_DELIVERY_OPTION.DINE_IN);
	};

	const sendWaMessageToMerchant = () => {
		const { phone, name } = shop;
		// @ts-ignore
		const waLink = ASK_MERCHANT_WORDING.replace("{shopPhone}", phone).replace(
			"{shopName}",
			name
		);

		// @ts-ignore
		window.open(waLink, "_blank");
	};

	const redirectToJoinOpaper = () => {
		// @ts-ignore
		window.open("https://www.opaper.app", "_blank");
	};

	const handleSaveDateTime = (isImmediately: boolean) => {
		if (isImmediately) {
			// Resetting the Select Date to Today
			handleSelectDate(getUnformattedDate());
			setFulfillmentTime(null);
		} else {
			const dateTime = new Date(
				currentDate.getFullYear(),
				currentDate.getMonth(),
				currentDate.getDate(),
				parseInt(currentTime.substring(0, 2)),
				parseInt(currentTime.substring(3, 5))
			);
			setFulfillmentTime(dateTime.getTime());
		}
	};

	const handleConfirmDineInOrder = () => {
		handleChooseOrderMethod(E_DELIVERY_OPTION.DINE_IN);
		handleSubmit(true, false);
	};

	const handleSubmitRating = async (payload: IReqRating) => {
		const payloadReq = {
			...payload,
			hash: ratingHash,
			order_sn: orderSNRating,
		};
		const response = await postRatingForm(payloadReq);

		setItemsRating(null);
		setSuccessSubmitRating(true);
		history.push(`/${shopLink}`);
	};

	const fetchRatingForm = async () => {
		const response = await getRatingForm(ratingHash);

		// means the rating hash is not expired
		if (response.order_items) {
			setItemsRating(response.order_items);
			setOrderSNRating(response.order_sn);
		} else {
			// means the rating hash is expired
			setItemsRating(null);
		}

		setIsShowBottomSheetRating(true);
	};

	const getUserCurrentLocation = () => {
		const logPosition = (position: any) => {
			// @ts-ignore
			const { latitude, longitude } = position.coords;

			dispatch({
				type: E_ACTION.SAVE_BUYER,
				payload: {
					currLat: latitude,
					currLng: longitude,
				} as IBuyer,
			});
		};

		const logError = (error: any) => {
			dispatch({
				type: E_ACTION.SAVE_BUYER,
				payload: {
					currLat: MONAS_LAT,
					currLng: MONAS_LNG,
				} as IBuyer,
			});
			// init with monas coordinate if needed
		};

		// @ts-ignore
		if (navigator.geolocation) {
			// @ts-ignore
			navigator.geolocation.getCurrentPosition(logPosition, logError);
		}
	};

	const findSearchedItems = () => {
		if (keyword) {
			const list = listItem.filter(
				(o) => o.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
			);
			setSearchedItems(list);
		} else {
			setSearchedItems([]);
		}
	};

	const handleScroll = () => {
		const currentId = currentCategories.filter((category) => {
			// @ts-ignore
			const categoryHeader = document.getElementById(
				category.name.replace(" ", "_")
			);
			return (
				parseInt(categoryHeader?.getBoundingClientRect?.().top) > 50 &&
				parseInt(categoryHeader?.getBoundingClientRect?.().top) < 100
			);
		});
		if (currentId[0]) {
			setActiveCategory(currentId[0].name);
			// @ts-ignore
			document
				.getElementById(`NAV_${currentId[0].name.replace(" ", "_")}`)
				?.scrollIntoView({ inline: "center" });
		}

		// @ts-ignore
		const scrollYPosition = window.scrollY;

		if (scrollYPosition >= 310) {
			setIsSearchComponentSticky(true);
		} else {
			setIsSearchComponentSticky(false);
		}
	};

	const handleRedirectOtherShop = (shopLink: string) => {
		// @ts-ignore
		const url = `${window.location.host}/${shopLink}`;

		if (shopType === "FOOD_EVENT") {
			// @ts-ignore
			window.location.href = `http://${url}?foodCourt=${foodEventData.link}${
				tableNumber ? `&table=${tableNumber}` : ""
			}`;
		} else {
			// @ts-ignore
			window.location.href = `http://${url}`;
		}
	};

	const handleInputReservation = (
		name: string,
		value: string | number | Date,
		data?: any
	) => {
		let updatedReservation = {
			...reservation,
			[name]: value,
		};

		if (name === "phone") {
			const phoneValue = value as string;
			if (phoneValue.charAt(0) === "0") {
				updatedReservation[name] = data.dialCode + phoneValue.replace(/^0/, "");
			} else if (
				!phoneValue.startsWith(data.dialCode) &&
				phoneValue.length > 2
			) {
				updatedReservation[name] = data.dialCode + phoneValue;
			}

			updatedReservation = {
				...updatedReservation,
				countryCode: data.countryCode,
			};
		}

		setReservation(updatedReservation);
	};

	const handleSubmitReservationWithPayment = async (
		payment: T_PAYMENT_TYPE
	) => {
		setIsShowBottomSheetReservationPayment(false);
		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: true,
		});

		const { isReservationFee, reservationFeeConfig } = shop;
		const { countryCode, name, date, notes, persons, phone, time } =
			reservation;
		date.setHours(0, 0, 0, 0);
		const dateTime =
			new Date(date).getTime() +
			getHourMinuteFromTime(time).hour * 60000 * 60 +
			getHourMinuteFromTime(time).minute * 60000;
		const amount =
			isReservationFee && reservationFeeConfig
				? reservationFeeConfig.fee_type === "flat"
					? reservationFeeConfig.amount
					: reservationFeeConfig.fee_type === "per_person"
					? (+persons * +reservationFeeConfig.amount).toString()
					: null
				: null;

		const req: IReqReservation = {
			name,
			note: notes,
			party_number: parseInt(persons),
			phone_number: phone,
			reservation_time: dateTime,
			amount,
			payment_method: payment,
		};

		const response = await postReservation(req, `${shop.id}`);

		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: false,
		});

		setCurrentDate(getUnformattedDate());
		dispatch({
			type: E_ACTION.SAVE_SELECTED_DATE,
			payload: getUnformattedDate(),
		});

		if (response?.id) {
			if (response?.payment) {
				// @ts-ignore
				window.open(response.payment.payment_url, "_self");
				// history.push(`/${shopLink}?reserve=true`);
				return;
			}

			history.push(`/${shopLink}?reserve=true`);
			fetchItems(
				shop.id,
				shop.operationalDays,
				shop.preOrder,
				selectedDate,
				shop.operationHours,
				shop.close,
				shop.tzOffset
			);
		} else {
			setIsReservationError(true);
		}
	};

	const handleSubmitReservation = async () => {
		const { isReservationFee, reservationFeeConfig } = shop;

		if (isReservationFee && reservationFeeConfig) {
			// setIsShowBottomSheetReservationPayment(true);
			// TODO: the payment is hardcoded QRIS, but it would be all payment!
			handleSubmitReservationWithPayment("xendit/qris");
			return;
		}

		handleSubmitReservationWithPayment(null);
	};

	const handleBackToStore = () => {
		history.push(`/${shopLink}?reserve=false`);
	};

	const handleClosePopupSuccessReservation = () => {
		history.push(`/${shopLink}`);
	};

	const handleChangeLanguage = () => {
		dispatch({
			type: E_ACTION.CHANGE_LANGUAGE,
			payload: language === "id" ? "en" : "id",
		});
		setDropdownLangActive(false);
	};

	const reportIssueReservation = () => {
		// @ts-ignore
		const waLink = REPORT_ISSUE_RESERVATION;
		// @ts-ignore
		window.open(waLink, "_blank");
	};

	const handleValidationAddOpenBillOrder = async () => {
		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: true,
		});

		const utcFulfillment = new Date(fulfillmentTime).toUTCString();
		const products: IProduct[] = [];

		Object.keys(selectedItems!).forEach((id: string) => {
			const { notes, quantity, variants, data, is_takeaway } =
				selectedItems![id];
			let variant_details = [];

			if (variants) {
				Object.keys(variants).forEach((variantId: string) => {
					Object.keys(variants[variantId]).forEach(
						(variantDetailId: string) => {
							variant_details.push({
								id: parseInt(variantDetailId),
								variant_id: parseInt(variantId),
								price: variants[variantId][variantDetailId].price,
							});
						}
					);
				});
			}

			if (quantity > 0) {
				products.push({
					description: notes,
					id: parseInt(id),
					quantity,
					variant_details,
					price: data.discount_price
						? parseInt(data.discount_price)
						: parseInt(data.price),
					is_takeaway,
				});
			}
		});

		const payload: IReqValidateOrder = {
			products: products,
			shipping_date_millis: fulfillmentTime
				? new Date(utcFulfillment).getTime()
				: new Date().getTime(),
			shop_id: shop.id,
		};

		const response = await postValidateOrder(payload);

		// setValidationErrors(response.errors);

		if (response.errors) {
			const { id, operationalDays, preOrder, operationHours, close, tzOffset } =
				shop;

			// refetch the stock in case the user back the page to items page
			fetchItems(
				id,
				operationalDays,
				preOrder,
				null,
				operationHours,
				close,
				tzOffset
			);

			const errInvalidPrice = response.errors.filter(
				(err) => err.type === ERR_VALIDATION_TYPE.INVALID_PRICE
			);
			const errMissingVariant = response.errors.filter(
				(err) => err.type === ERR_VALIDATION_TYPE.MISSING_VARIANT
			);
			const errMissingProduct = response.errors.filter(
				(err) => err.type === ERR_VALIDATION_TYPE.MISSING_PRODUCT
			);
			const errOutOfStock = response.errors.filter(
				(err) => err.type === ERR_VALIDATION_TYPE.OUT_OF_STOCK
			);
			if (errInvalidPrice.length > 0) {
				const updatedSelectedItems = { ...currentItems };
				errInvalidPrice.forEach((err) => {
					if (err.variant_id !== 0 && err.variant_detail_id !== 0) {
						updatedSelectedItems[err.product_id].variants[err.variant_id][
							err.variant_detail_id
						].price = err.expected_value;
					} else {
						if (updatedSelectedItems[err.product_id].data.discount_price) {
							updatedSelectedItems[err.product_id].data.discount_price =
								err.expected_value;
						}
						updatedSelectedItems[err.product_id].data.price =
							err.expected_value;
					}
				});

				dispatch({
					type: E_ACTION.SAVE_SELECTED_ITEMS,
					payload: updatedSelectedItems,
				});

				setCurrentItems(updatedSelectedItems);
			}
			if (errMissingVariant.length > 0) {
				const updatedSelectedItems = { ...currentItems };

				// remove oos variant from the data that used to display the product detail in review page
				const indexMissingVariant = updatedSelectedItems[
					errMissingVariant[0].product_id
				].data.variant.findIndex(
					(item) => item.id === errMissingVariant[0].variant_id
				);
				delete updatedSelectedItems[errMissingVariant[0].product_id].data
					.variant[indexMissingVariant];

				// remove oos variant from the selected item in the cart
				delete updatedSelectedItems[errMissingVariant[0].product_id].variants[
					errMissingVariant[0].variant_id
				][errMissingVariant[0].variant_detail_id];

				dispatch({
					type: E_ACTION.SAVE_SELECTED_ITEMS,
					payload: updatedSelectedItems,
				});

				setCurrentItems(updatedSelectedItems);
			}
			if (errMissingProduct.length > 0) {
				const updatedSelectedItems = { ...currentItems };

				delete updatedSelectedItems[errMissingProduct[0].product_id];

				dispatch({
					type: E_ACTION.SAVE_SELECTED_ITEMS,
					payload: updatedSelectedItems,
				});

				setCurrentItems(updatedSelectedItems);
			}
			if (errOutOfStock.length > 0) {
				const updatedSelectedItems = { ...currentItems };

				delete updatedSelectedItems[errOutOfStock[0].product_id];

				dispatch({
					type: E_ACTION.SAVE_SELECTED_ITEMS,
					payload: updatedSelectedItems,
				});

				setCurrentItems(updatedSelectedItems);
			}
		}

		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: false,
		});

		if (!response.success) {
			setIsShowDimNotification(true);
			timerDim = setTimeout((e) => {
				setIsShowDimNotification(false);
			}, 2000);
		}

		return response.success;
	};

	const handleOpenBill = async (orderSN: string) => {
		const isPassedValidation = await handleValidationAddOpenBillOrder();

		if (isPassedValidation) {
			dispatch({
				type: E_ACTION.SET_LOADING,
				payload: true,
			});

			const products: IProduct[] = [];

			Object.keys(currentItems!).forEach((id: string) => {
				const { notes, quantity, variants, is_takeaway } = currentItems![id];
				let variant_details = [];

				if (variants) {
					Object.keys(variants).forEach((variantId: string) => {
						Object.keys(variants[variantId]).forEach(
							(variantDetailId: string) => {
								variant_details.push({
									id: parseInt(variantDetailId),
									variant_id: parseInt(variantId),
								});
							}
						);
					});
				}

				if (quantity > 0) {
					products.push({
						description: notes,
						id: parseInt(id),
						quantity,
						variant_details,
						is_takeaway,
					});
				}
			});

			// @ts-ignore
			const otpLS = localStorage.getItem("otp");
			// @ts-ignore
			const contactLS = localStorage.getItem(AUTO_FILL_CONTACT) ?? "{}";
			const { name, phone, country, table } = JSON.parse(contactLS);

			// if (!otpLS) {
			//   setShowOTPBottomSheet("submitOpenBill");
			//   setPhoneNumberOTP(phone);
			//   await sendOTPtoBuyer("whatsapp", phone);

			//   dispatch({
			//     type: E_ACTION.SET_LOADING,
			//     payload: false,
			//   });

			//   return;
			// }

			const payload: IReqOpenBill = {
				name,
				order_sn: orderSN,
				otp: null,
				phone_country: country,
				phone_number: `+${phone}`,
				products,
				shop_id: shop.id,
				table_no: table,
			};

			const response = await postOpenBill(payload);

			if (response.code === 500 && response.err_msg === "otp invalid") {
				setShowOTPBottomSheet("submitOpenBill");
				setPhoneNumberOTP(phone);
				await sendOTPtoBuyer("whatsapp", phone);

				dispatch({
					type: E_ACTION.SET_LOADING,
					payload: false,
				});

				return;
			}

			dispatch({
				type: E_ACTION.SET_LOADING,
				payload: false,
			});

			if (response.data) {
				if (response.data.order_sn) {
					history.push(`/open/${response.data.order_sn}`);

					// @ts-ignore
					sessionStorage.removeItem("ordersn");

					// remove selected items from context
					dispatch({
						type: E_ACTION.SAVE_SELECTED_ITEMS,
						payload: null,
					});
				}
			}
		}
	};

	const handleClosePopupCommon = () => {
		dispatch({ type: E_ACTION.CLOSE_POPUP_COMMON });
	};

	const handleAddToCartFoodcourt = (
		foodEventName: string,
		isVisitMerchant?: boolean
	) => {
		// to handle foodcourt v1 (ex: FJB)
		if (foodEventData.id) {
			handleRedirectOtherShop(foodEventName);
		} else {
			if (selectedItems) {
				let diff = {};
				if (foodcourtCart.allItems) {
					Object.keys(selectedItems).forEach((key) => {
						if (!foodcourtCart.allItems[key]) {
							diff[key] = selectedItems[key];
						}
					});
				} else {
					diff = selectedItems;
				}

				const cart = { ...foodcourtCart.cart };
				cart[merchant.id] = {
					items: cart[merchant.id]
						? { ...cart[merchant.id].items, ...diff }
						: diff,
					shopName: merchant.name,
				};

				if (Object.keys(diff).length > 0) {
					dispatch({
						type: E_ACTION.SAVE_FOODCOURT_CART,
						payload: {
							cart,
							total: temporaryTotalAmount,
							allItems: selectedItems,
						} as IFoodcourtCart,
					});
				}
			}

			// if means user currently in merchant page and wanted to back to foodcourt list page
			if (foodEventName) {
				if (isVisitMerchant) {
					history.push(`/${shop.link}?merchant=${foodEventName}`);
				} else {
					setIsTreatAsMerchantFoodcourt(false);
					history.push(shop.id ? `/${shop.link}` : `/${foodEventName}`);
				}

				fetchShopInfo();
			} else {
				history.push(`/${shopLink}/contact`);
			}
		}
	};

	const handleClickFoodcourtMerchant = (merchantLink: string) => {
		history.push(`/${shopLink}?merchant=${merchantLink}`);

		fetchShopInfo();
	};

	// BEGIN CASE: OPEN BILL
	const sendOTPtoBuyer = async (
		method: "sms" | "whatsapp",
		phoneNumber?: string
	) => {
		const payload: IReqOtp = {
			phone_number: phoneNumber ?? phoneNumberOTP,
			send_method: method,
		};

		await getOTP(payload);
	};

	const checkOTPValid = async (otp: string, isFetchCoinBalance: boolean) => {
		const payload: IReqCoinBalance = {
			phone_number: phoneNumberOTP,
			otp: parseInt(otp),
		};

		const response = await postValidateOTP(payload);
		if (response.code === 200) {
			// @ts-ignore
			localStorage.setItem("otp", otp);
		}

		return response.code === 200;
	};

	const handleSubmitOTPOpenBill = (otp: string) => {
		// @ts-ignore
		localStorage.setItem("otp", otp);
		handleChooseOrderMethod(E_DELIVERY_OPTION.DINE_IN);
	};
	// END CASE: OPEN BILL

	useEffect(() => {
		initiateFirst();
	}, [isValidDateSelected]);

	useEffect(() => {
		initiateFromPage();
	}, [
		currentItems,
		currentItem,
		currentOperationalHour,
		currentTime,
		fulfillmentTime,
	]);

	// use effect for delay typing searched keyword
	useEffect(() => {
		const delaySearch = setTimeout(() => {
			findSearchedItems();
		}, 800);

		return () => clearTimeout(delaySearch);
	}, [keyword]);

	// use effect to stick the search component when its scrolled
	useLayoutEffect(() => {
		// @ts-ignore
		const currRoute = /[^/]*$/.exec(window.location.pathname)[0];
		if (currRoute !== "review" && currRoute !== "reservation") {
			// @ts-ignore
			window.addEventListener("scroll", handleScroll);

			// @ts-ignore
			return () => window.removeEventListener("scroll", handleScroll);
		}
		setCurrRoute(currRoute);
	}, [currentCategories, merchants]);

	useLayoutEffect(() => {
		return () => {
			clearTimeout(timerDim);
		};
	}, []);

	return {
		currentItems,
		setCurrentItems,
		currentCategories,
		handleSaveNotes,
		handleSubmit,
		totalItem,
		countTotalItems,
		activeItemId,
		setActiveItemId,
		temporaryTotalAmount,
		handleClickAction,
		currentOneWeekCategories,
		handleSelectDate,
		handleNextAndPrevDateInterval,
		isValidDateSelected,
		sendWaMessageToMerchant,
		shop,
		redirectToJoinOpaper,
		currentDate,
		handleAddSelectedItem,
		handleOpenItemDetail,
		currentItem,
		setIsShowBottomSheetItemDetail,
		isShowBottomSheetItemDetail,
		isShowBottomSheetExistingItem,
		setIsShowBottomSheeExistingtItem,
		filterSelectedItemBySameId,
		handleChangeDetailExistingItem,
		handleDeleteExistingItem,
		editedItem,
		setEditedItem,
		isShowBottomSheetOrderMethod,
		setIsShowBottomSheetOrderMethod,
		handleChooseOrderMethod,
		countQuantitySelectedItems,
		countQuantityItemSameId,
		selectedDate,
		setCurrentTime,
		currentTime,
		fulfillmentTime,
		handleSaveDateTime,
		currentOperationalHour,
		isShopCloseForSelectedTime,
		setIsShowBottomSheetDineInInfo,
		isShowBottomSheetDineInInfo,
		handleConfirmDineInOrder,
		isShopCloseNow,
		isShowBottomSheetRating,
		setIsShowBottomSheetRating,
		itemsRating,
		handleSubmitRating,
		buyer,
		successSubmitRating,
		listItem,
		keyword,
		searchedItems,
		setKeyword,
		findSearchedItems,
		isSearchComponentSticky,
		shopType,
		foodEventData,
		handleRedirectOtherShop,
		foodEventName,
		activeCategory,
		handleInputReservation,
		reservation,
		currRoute,
		handleSubmitReservation,
		isShowBottomSheetSuccess,
		setIsShowBottomSheetSuccess,
		language,
		handleChangeLanguage,
		isDropdownLangActive,
		setDropdownLangActive,
		handleBackToStore,
		reportIssueReservation,
		setIsReservationError,
		isReservationError,
		handleClosePopupSuccessReservation,
		tableNumber,
		sourceState,
		fetchItems,
		isShowDimNotification,
		isHideOtherOutlet,
		isPopupCommonSelected:
			SHOP_URI_FOR_POPUP.includes(shopLink) ||
			SHOP_ID_FOR_POPUP.includes(shop.id),
		isPopupCommonOpen,
		isHideTakeaway: SHOP_ID_FOR_EXCLUDE_TAKEAWAY.includes(shop.id),
		handleClosePopupCommon,
		merchants,
		handleClickFoodcourtMerchant,
		handleAddToCartFoodcourt,
		foodcourtCart,
		merchant,
		isTreatAsMerchantFoodcourt,
		// OPEN BILL
		isOpenBill,
		phoneNumberOTP,
		showOTPBottomSheet,
		setShowOTPBottomSheet,
		sendOTPtoBuyer,
		checkOTPValid,
		handleSubmitOTPOpenBill,
		isShowBottomSheetReservationPayment,
		setIsShowBottomSheetReservationPayment,
		handleSubmitReservationWithPayment,
	};
};

import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
	IPathParams,
	IProductOpenBill,
	IReqCloseBill,
	IReqCoinBalance,
	IReqLoyaltyStatus,
	IReqOtp,
	IResOpenBillOrder,
	IShop,
	IVoucher,
	T_PAYMENT_TYPE,
} from "../interfaces";
import {
	E_ACTION,
	E_DELIVERY_OPTION,
	E_VOUCHER_TYPE,
} from "../interfaces/dataTypes";
import { useAppContext } from "../providers/useAppContext";
import { getOrder, postCloseBill } from "../services/Order";
import {
	getCoinBalance,
	getLoyaltyStatus,
	getOTP,
	getPromotionVoucher,
	getPromotionVoucherByCode,
	getShopInfo,
	postValidateOTP,
} from "../services/Shop";
import { calcRoundedValue, countDiscount } from "../utils";
import { AUTO_FILL_CONTACT } from "../constants";

export const useOpenBillHook = () => {
	const INITIAL_SHOP_INFO = {
		tax: "",
		service_fee: "",
		rounding_info: null as { type: "round" | "round_up"; value: number } | null,
		platform_fee: 0,
		open_bill_setting: "",
		is_buyer_pay_platform_fee: false,
		loyalty_data: null as {
			max_coin_used: number;
			min_order: string;
			value_per_coin: string;
		},
		tax_delivery_method: [],
		logo_url: "",
		shop_id: 0,
	};

	const [isPostError, setIsPostError] = useState(false);
	const [popupErrorMsg, setPopupErrorMsg] = useState("");
	const [orders, setOrders] = useState([]);
	const [invoiceData, setInvoiceData] = useState({} as IResOpenBillOrder);
	const [amountItemOnly, setAmountItemOnly] = useState(0);
	const [searchedCode, setSearchedCode] = useState("");
	const [currentEligibleVouchers, setCurrentEligibleVouchers] = useState(
		[] as IVoucher[]
	);
	const [currentNotEligibleVouchers, setCurrentNotEligibleVouchers] = useState(
		[] as IVoucher[]
	);
	const [isSearchingVoucherByCode, setIsSearchingVoucherByCode] =
		useState(false);
	const [isShowBottomSheetPaymentOption, setShowBottomSheetPaymentOption] =
		useState(false);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
	const [coinToExchange, setCoinToExchange] = useState("");
	const [otpCode, setOtpCode] = useState("");
	const [voucherErrorMessage, setVoucherErrorMessage] = useState("");
	const [isShowVoucherBottomSheet, setIsShowVoucherBottomSheet] =
		useState(false);
	const [selectedProductVoucher, setSelectedProductVoucher] = useState(null);
	const [selectedShippingVoucher, setSelectedShippingVoucher] = useState(null);
	const [isBuyerHaveCoin, setIsBuyerHaveCoin] = useState(false);
	const [buyerCoin, setBuyerCoin] = useState(0);
	const [isShowExchangeCoinBottomSheet, setIsShowExchangeCoinBottomSheet] =
		useState(false);
	const [isShowOtpBottomSheet, setIsShowOtpBottomSheet] = useState(
		"n" as "y" | "n" | "exchangeCoin" | "submitOpenBill"
	); // "n" | "y" | "exchangeCoin"
	const [totalAmount, setTotalAmount] = useState(0);
	const [discountVoucherAmount, setDiscountVoucherAmount] = useState(0);
	const [tax, setTax] = useState(0);
	const [serviceFee, setServiceFee] = useState(0);
	const [roundedValue, setRoundedValue] = useState(0);
	const [shopInfo, setShopInfo] = useState(INITIAL_SHOP_INFO);
	const [statusPage, setStatusPage] = useState((null as number) || null);
	const params: IPathParams = useParams();
	const history = useHistory();
	const { state, dispatch } = useAppContext();
	const { orderSN } = params;

	const { vouchers, language } = state;

	// @ts-ignore
	const otpLS = localStorage.getItem("otp");

	const fetchOrder = async () => {
		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: true,
		});

		const response: { data: IResOpenBillOrder; code: number; err_msg: string } =
			await getOrder(orderSN);

		if (
			response.data ||
			(response.code === 500 && response.err_msg === "otp invalid")
		) {
			const { open_bill_data, products, shop_id, shop_link } = response.data;

			if (open_bill_data.length > 0 && open_bill_data[open_bill_data.length - 1].end_date !== null) {
				history.push(`/order_sn/${orderSN}`);
			} else {
				// fetchLoyaltyStatus(response.data.phone_number, response.data.shop_id);

				// generate orders array
				let tempOrders = [];
				open_bill_data.forEach(({ id, start_date }) => {
					const orderByOpenBillId = products.filter(
						(product) => product.open_bill_id === id
					);
					tempOrders.push({ time: start_date, products: orderByOpenBillId });
				});

				// count amount item only
				let amount = 0;
				products.map(({ discount_price, price }) => {
					amount =
						amount +
						(discount_price ? parseInt(discount_price) : parseInt(price));
				});

				await fetchPromotionVoucher(shop_id, amount);
				setOrders(tempOrders);
				setInvoiceData(response.data);

				// Set auto fill contact data
				const phoneNumber = response.data.phone_number.startsWith("+")
					? response.data.phone_number.slice(1)
					: response.data.phone_number;

				// @ts-ignore
				localStorage.setItem(
					AUTO_FILL_CONTACT,
					JSON.stringify({
						name: response.data.name,
						phone: phoneNumber,
						country: response.data.phone_country,
						table: response.data.table_no,
					})
				);
			}

			fetchShopInfo(shop_link);

			setStatusPage(200);
		} else if (response?.code === 404) {
			setStatusPage(404);
		} else if (response?.code === 500) {
			setStatusPage(500);
		} else {
			setStatusPage(-1);
		}

		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: false,
		});
	};

	const handleCloseBill = async (paymentMethod: T_PAYMENT_TYPE) => {
		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: true,
		});

		// @ts-ignore
		const otpLS = localStorage.getItem("otp");

		const coinValue =
			coinToExchange && invoiceData.value_per_coin
				? (
						parseInt(coinToExchange) * parseInt(invoiceData.value_per_coin)
				  ).toString()
				: null;

		const payload: IReqCloseBill = {
			coin_used: parseInt(coinToExchange),
			coin_value: coinValue,
			order_sn: orderSN,
			otp: coinToExchange ? otpLS ? parseInt(otpLS) : otpCode ? parseInt(otpCode) : null : null,
			payment_method: paymentMethod,
			phone_number: invoiceData.phone_number,
			phone_country: "id",
			promotions: selectedProductVoucher
				? [
						{
							promotion_code: selectedProductVoucher.code,
							promotion_id: selectedProductVoucher.id,
						},
				  ]
				: [],
			shop_id: invoiceData.shop_id,
			subtotal: `${totalAmount}`,
		};

		const response = await postCloseBill(payload);

		if (response?.data?.order_sn) {
			history.push(`/order_sn/${response.data.order_sn}`);
			// @ts-ignore
			sessionStorage.removeItem("ordersn");
		} else {
			if (response?.err_msg === "otp invalid") {
				sendOTPtoBuyer("whatsapp", invoiceData.phone_number);
				setIsShowOtpBottomSheet("y");
			}
			setIsPostError(true);
		}

		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: false,
		});
	};

	const fetchPromotionVoucherByCode = async () => {
		setIsSearchingVoucherByCode(true);
		if (searchedCode === "") {
			await fetchPromotionVoucher(null, amountItemOnly);
		} else {
			dispatch({
				type: E_ACTION.SET_LOADING,
				payload: true,
			});

			const response: IVoucher = await getPromotionVoucherByCode(
				invoiceData.shop_id,
				searchedCode,
				{
					phone_number: invoiceData.phone_number,
					phone_country: invoiceData.phone_country,
				}
			);

			dispatch({
				type: E_ACTION.SET_LOADING,
				payload: false,
			});

			if (response && typeof response === "object") {
				filterVouchers([response], amountItemOnly);
				dispatch({
					type: E_ACTION.SAVE_VOUCHERS,
					payload: [response],
				});
			} else {
				setCurrentEligibleVouchers([]);
				setCurrentNotEligibleVouchers([]);
				if (typeof response === "string") {
					if (response === "phone number already used for this promotion")
						setVoucherErrorMessage("Melebihi batas penggunaan voucher");
				} else {
					setVoucherErrorMessage("Voucher tidak ditemukan");
				}
			}
		}
	};

	const fetchPromotionVoucher = async (
		shopIdInitial?: number,
		amount?: number
	) => {
		setIsSearchingVoucherByCode(false);
		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: true,
		});

		const response = await getPromotionVoucher(
			shopIdInitial ? shopIdInitial : invoiceData.shop_id,
			{
				phone_number: invoiceData.phone_number,
				phone_country: invoiceData.phone_country,
			}
		);

		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: false,
		});
		dispatch({
			type: E_ACTION.SAVE_VOUCHERS,
			payload: response,
		});

		filterVouchers(response, amount);
	};

	const filterVouchers = (vouchers: IVoucher[], amount: number) => {
		const eligibleVoucher = [];
		const notEligibleVoucher = [];
		vouchers.forEach((vou) => {
			const {
				min_order,
				payment_type,
				shipping_type,
				type,
				product_ids,
				is_all_product,
			} = vou;
			const passedMinOrder = amount >= parseInt(min_order);
			let passedShippingType = false;
			if (shipping_type.includes("dine_in")) {
				passedShippingType = true;
			}

			if (passedMinOrder && passedShippingType) {
				// filter if product ids is included
				if (type === E_VOUCHER_TYPE.ORDER) {
					const exist =
						invoiceData?.products?.filter((product) => {
							return product_ids.includes(product?.product_id);
						}).length > 0;

					if (exist || is_all_product) {
						eligibleVoucher.push(vou);
					} else {
						notEligibleVoucher.push(vou);
					}
				} else {
					eligibleVoucher.push(vou);
				}
			} else {
				notEligibleVoucher.push(vou);
			}
		});

		setCurrentEligibleVouchers(eligibleVoucher);
		setCurrentNotEligibleVouchers(notEligibleVoucher);
	};

	const handleSelectVoucher = (
		voucher: IVoucher,
		isProductVoucher: boolean
	) => {
		if (voucher) {
			if (isProductVoucher) {
				setSelectedProductVoucher(voucher);
				dispatch({
					type: E_ACTION.SAVE_SELECTED_PRODUCT_VOUCHER,
					payload: voucher,
				});
			} else {
				setSelectedShippingVoucher(voucher);
				dispatch({
					type: E_ACTION.SAVE_SELECTED_SHIPPING_VOUCHER,
					payload: voucher,
				});
			}
		} else {
			if (isProductVoucher) {
				setSelectedProductVoucher(null);
				dispatch({
					type: E_ACTION.SAVE_SELECTED_PRODUCT_VOUCHER,
					payload: null,
				});
			} else {
				setSelectedShippingVoucher(null);
				dispatch({
					type: E_ACTION.SAVE_SELECTED_SHIPPING_VOUCHER,
					payload: null,
				});
			}
		}
	};

	const handleAddMoreProduct = () => {
		// @ts-ignore
		sessionStorage.setItem("ordersn", orderSN);

		history.push(`/${invoiceData.shop_link}`);
	};

	const fetchLoyaltyStatus = async (phoneNumber: string, shopId: number) => {
		const payload: IReqLoyaltyStatus = {
			phone_number: phoneNumber,
			shop_id: shopId,
		};

		const response = await getLoyaltyStatus(payload);

		setIsBuyerHaveCoin(response);

		// @ts-ignore
		const otpLS = localStorage.getItem("otp");

		if (response) {
			fetchCoinBalance(otpCode || otpLS, phoneNumber, shopId);
		}
	};

	const handleShareMenu = () => {
		const data = {
			// @ts-ignore
			url: window.location.href,
		};

		// @ts-ignore
		navigator.share?.(data);
	};

	const fetchCoinBalance = async (
		otp: string,
		phoneNumber: string,
		shopId: number
	) => {
		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: true,
		});

		const payload: IReqCoinBalance = {
			phone_number: phoneNumber,
			otp: parseInt(otp),
		};

		const response = await getCoinBalance(shopId, payload);

		if (response.data) {
			setBuyerCoin(response.data.balance);
		} else {
			if (response.err_msg === "otp invalid") {
				sendOTPtoBuyer("whatsapp", phoneNumber);
				setIsShowOtpBottomSheet("y");
			}
		}

		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: false,
		});
	};

	const handleTextInput = (name: string, value: string) => {
		if (name === "coinToExchange") {
			setCoinToExchange(value);
		}
	};

	const sendOTPtoBuyer = async (
		method: "sms" | "whatsapp",
		phoneNumber?: string
	) => {
		const payload: IReqOtp = {
			phone_number: phoneNumber || invoiceData.phone_number,
			send_method: method,
		};

		const response = await getOTP(payload);
	};

	const checkOTPValid = async (otp: string, isFetchCoinBalance: boolean) => {
		const payload: IReqCoinBalance = {
			phone_number: invoiceData.phone_number,
			otp: parseInt(otp),
		};

		const response = await postValidateOTP(payload);

		if (response.code === 200) {
			setOtpCode(otp);

			// @ts-ignore
			localStorage.setItem("otp", otp);

			if (isFetchCoinBalance) {
				fetchCoinBalance(otp, invoiceData.phone_number, invoiceData.shop_id);
			}
		}

		return response.code === 200;
	};

	const fetchShopInfo = async (shopLink: string) => {
		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: true,
		});

		const responseShopInfo = await getShopInfo(shopLink);

		setShopInfo(responseShopInfo);

		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: false,
		});
	};

	const countTotalAmount = () => {
		const {
			subtotal,
			is_buyer_pay_platform,
      is_service_fee_free_tax: isServiceFeeFreeTax,
			dine_in_service_fee,
			dine_in_service_fee_amount,
			dine_in_tax,
			dine_in_tax_amount,
			rounding,
			platform_fee: platformFeeString,
		} = invoiceData;

		let tax = dine_in_tax;
		let service_fee = dine_in_service_fee;
		let rounding_info = rounding;
		let is_buyer_pay_platform_fee = is_buyer_pay_platform;
		let platform_fee = parseInt(platformFeeString);
		const downpayment = invoiceData.additional_payments?.filter(
			(payment) => payment.payment_method === "downpayment"
		)[0];

		// revert codes to use shop info as the shop setting config: search for "todo shop info"
		// todo shop info: uncomment all below this line
		// const {
		//   tax,
		//   service_fee,
		//   rounding_info,
		//   platform_fee,
		//   open_bill_setting,
		//   is_buyer_pay_platform_fee,
		// 	tax_delivery_method,
		// } = shopInfo;

		let totalGross = 0;
		let totalNett = 0;
		let discountAmount = 0;

		let amountWithServiceFee = 0;
		let amountWithTax = 0;
		let promoAmountWithServiceFee = 0;
		let promoAmountWithTax = 0;

		// iterate items in buyer cart
		invoiceData?.products?.forEach(
			({
				product_id: productId,
				discount_price,
				price,
				quantity,
				variants,
				is_bypass_service_fee,
				is_bypass_tax,
			}) => {
				// const actualPrice = discount_price ? discount_price : price;
				// let itemPrice =
				// 	parseInt(actualPrice) * quantity;
				let variantPrice = 0;
				let itemGrossPrice = parseInt(price) * quantity;
				let itemNettPrice = discount_price
					? parseInt(discount_price) * quantity
					: itemGrossPrice;

				// iterate variant for each item in buyer cart
				variants.forEach((variant) => {
					variantPrice = variantPrice + parseInt(variant.price);
				});
				// itemPrice = itemPrice + variantPrice * quantity;
				itemGrossPrice = itemGrossPrice + variantPrice * quantity;
				itemNettPrice = itemNettPrice + variantPrice * quantity;

				// if (selectedProductVoucher) {
				// 	if (
				// 		selectedProductVoucher?.product_ids?.includes(parseInt(id)) ||
				// 		selectedProductVoucher.is_all_product
				// 	) {
				// 		discountProduct =
				// 			discountProduct +
				// 			countDiscount(
				// 				itemPrice,
				// 				voucherProductAmount,
				// 				voucherProductMaxAmount
				// 			);
				// 	}
				// }

				if (selectedProductVoucher) {
					if (
						selectedProductVoucher?.product_ids?.includes(
							parseInt(productId as any)
						) ||
						selectedProductVoucher.is_all_product
					) {
						const discountItem = countDiscount(
							itemNettPrice,
							selectedProductVoucher.amount ? selectedProductVoucher.amount : 0,
							selectedProductVoucher.max_amount
								? parseInt(selectedProductVoucher.max_amount)
								: 0
						);

						discountAmount += discountItem;

						if (!is_bypass_service_fee) {
							promoAmountWithServiceFee += discountItem;
						}

						if (!is_bypass_tax) {
							promoAmountWithTax += discountItem;
						}
					}
				}

				if (!is_bypass_service_fee) {
					amountWithServiceFee += itemNettPrice;
				}

				if (!is_bypass_tax) {
					amountWithTax += itemNettPrice;
				}

				totalGross = totalGross + itemGrossPrice;
				totalNett = totalNett + itemNettPrice;
			}
		);

		filterVouchers(vouchers, totalNett);

		if (selectedProductVoucher) {
			const voucherProductMaxAmount = selectedProductVoucher.max_amount
				? parseInt(selectedProductVoucher.max_amount)
				: 0;

			discountAmount = Math.round(discountAmount);
			discountAmount =
				voucherProductMaxAmount && discountAmount >= voucherProductMaxAmount
					? voucherProductMaxAmount
					: discountAmount;

			promoAmountWithServiceFee = Math.round(promoAmountWithServiceFee);
			promoAmountWithServiceFee =
				voucherProductMaxAmount &&
				promoAmountWithServiceFee >= voucherProductMaxAmount
					? voucherProductMaxAmount
					: promoAmountWithServiceFee;

			promoAmountWithTax = Math.round(promoAmountWithTax);
			promoAmountWithTax =
				voucherProductMaxAmount && promoAmountWithTax >= voucherProductMaxAmount
					? voucherProductMaxAmount
					: promoAmountWithTax;
		}

		let discountCoin = coinToExchange
			? parseInt(coinToExchange) * parseInt(invoiceData?.value_per_coin)
			: 0;

		let serviceFeeTemp = 0;
		let taxTemp = 0;
		let platformFee =
			is_buyer_pay_platform_fee && platform_fee ? platform_fee : 0;

		if (service_fee !== "") {
			// service fee = total amount before discount
			// serviceFeeTemp = Math.round((totalGross * parseInt(service_fee)) / 100);

			// service fee =  total amount after discount
			serviceFeeTemp = Math.round(
				((amountWithServiceFee - promoAmountWithServiceFee - discountCoin) *
					parseInt(service_fee)) /
					100
			);

			if (serviceFeeTemp < 0) {
				serviceFeeTemp = 0;
			}
		}
		// todo shop info: uncomment the condition below
		// if (tax !== "" && tax_delivery_method.includes("dine_in")) {
		if (tax !== "") {
      const taxAmount = amountWithTax - promoAmountWithTax - (discountCoin >= amountWithTax - promoAmountWithTax
        ? amountWithTax - promoAmountWithTax
        : discountCoin);
			// tax = total amount after discount and after service fee
			taxTemp = Math.round(
				((taxAmount + (isServiceFeeFreeTax ? 0 : serviceFeeTemp)) *
					parseFloat(tax)) /
					100
			);

			if (taxTemp < 0) {
				taxTemp = 0;
			}
		}

		// round discount amount
		discountAmount = Math.round(discountAmount);

		totalNett =
			totalNett +
			serviceFeeTemp +
			taxTemp +
			platformFee -
			discountAmount -
			discountCoin;

		if (rounding_info) {
			const roundedAmount = calcRoundedValue(
				rounding_info.type,
				rounding_info.value,
				totalNett
			);
			setRoundedValue(roundedAmount - totalNett);

			totalNett = roundedAmount;
		}

		// check if total is negative value (because of disc coin or etc)
		if(totalNett < 0) {
			totalNett = 0;
		}

		// check if this bill have downpayment from reservation
		if(downpayment) {
			totalNett = totalNett - downpayment.value;
		}

		setTax(taxTemp);
		setServiceFee(serviceFeeTemp);
		setDiscountVoucherAmount(discountAmount);
		setTotalAmount(totalNett);
	};

	const handleClickChangeBuyerInfo = () => {
		dispatch({
			type: E_ACTION.SAVE_SHOP,
			payload: {
				id: shopInfo.shop_id,
				deliveryOptions: [E_DELIVERY_OPTION.DINE_IN],
			},
		});

		dispatch({
			type: E_ACTION.SAVE_SELECTED_SHIP,
			payload: {
				name: E_DELIVERY_OPTION.OPEN_BILL,
				price: 0,
				rateId: E_DELIVERY_OPTION.OPEN_BILL,
				type: "",
				insuranceRate: 0,
				withInsurance: false,
				isUserUseInsurance: false,
			},
		});

		dispatch({
			type: E_ACTION.SAVE_TABLE_NUMBER,
			payload: invoiceData.table_no,
		});

		dispatch({
			type: E_ACTION.SAVE_ORDER_SN,
			payload: orderSN,
		});

		history.push(`/${invoiceData.shop_link}/contact`);
	};

	useEffect(() => {
		fetchOrder();
	}, []);

	useEffect(() => {
		countTotalAmount();
	}, [invoiceData, selectedProductVoucher, shopInfo, coinToExchange, vouchers]);

	return {
		fetchOrder,
		isPostError,
		setIsPostError,
		popupErrorMsg,
		orders,
		invoiceData,
		setShowBottomSheetPaymentOption,
		isShowBottomSheetPaymentOption,
		selectedPaymentMethod,
		setSelectedPaymentMethod,
		handleCloseBill,
		searchedCode,
		fetchPromotionVoucher,
		isShowVoucherBottomSheet,
		setIsShowVoucherBottomSheet,
		handleSelectVoucher,
		amountItemOnly,
		isSearchingVoucherByCode,
		fetchPromotionVoucherByCode,
		setSearchedCode,
		currentEligibleVouchers,
		currentNotEligibleVouchers,
		voucherErrorMessage,
		selectedProductVoucher,
		selectedShippingVoucher,
		handleAddMoreProduct,
		isBuyerHaveCoin,
		buyerCoin,
		coinToExchange,
		handleTextInput,
		setOtpCode,
		otpCode,
		setIsShowExchangeCoinBottomSheet,
		isShowExchangeCoinBottomSheet,
		setIsShowOtpBottomSheet,
		sendOTPtoBuyer,
		otpLS,
		isShowOtpBottomSheet,
		checkOTPValid,
		totalAmount,
		discountVoucherAmount,
		serviceFee,
		roundedValue,
		tax,
		shopInfo,
		statusPage,
		handleShareMenu,
		handleClickChangeBuyerInfo,
    language,
	};
};

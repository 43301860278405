import styled, { createGlobalStyle } from "styled-components";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";
import CloseAsset from "../assets/close.png";
import FoodcourtDefaultAsset from "../assets/foodcourtDefault.png";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh !important" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden !important" : "scroll")};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
  z-index: 6;
  display: ${(props) => (props.isShow ? "block" : "none")};
  position: fixed;
  width: 100vh;
  max-width: 600px;
  background-color: rgba(26, 26, 26, 0.5);
  height: 100vh;
  bottom: 0;
  top: 0;
`;

const Content = styled.div`
  width: 100%;
  padding-bottom: 50px;
  max-width: 600px;
  min-height: 100px;
  position: fixed;
  background-color: ${E_COLOR.WHITE};
  overflow: hidden;
  bottom: 0;
  border-radius: 16px 16px 0 0;
`;

const ImgClose = styled.img`
  float: right;
  width: 16px;
  height: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin: 31px 20px 20px 18px;
`;

const HeaderLabel = styled.div`
  width: 70%;
  color: ${E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 18px;
  line-height: 27px;
`;

const Message = styled.div`
  color: ${E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 16px;
  line-height: 24px;
  margin: 0 16px 11px;
`;

const MerchantContainer = styled.div`
  display: flex;
  justify-content: center;
  place-items: flex-start;
`;

const Merchant = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(90vw / 3);
  max-width: calc(540px / 3);
`;

const MerchantIcon = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`;

const MerchantName = styled.div`
  color: ${E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 14px;
  line-height: 21px;
  margin-top: 12px;
  text-align: center;
`;

const MerchantButton = styled.div`
  color: ${E_COLOR.DARK_BLUE};
  font-weight: ${E_FONT_WEIGHT.BOLD};
  font-size: 12px;
  line-height: 18px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 16px 0;
`;

const Button = styled.div<{ isPrimary: boolean }>`
  width: 38%;
  background-color: ${({ isPrimary }) =>
    isPrimary ? E_COLOR.DARK_BLUE : E_COLOR.WHITE};
  color: ${({ isPrimary }) => (isPrimary ? E_COLOR.WHITE : E_COLOR.DARK_BLUE)};
  border: 1px solid ${E_COLOR.DARK_BLUE};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 14px;
  line-height: 21px;
  padding: 10px 15px;
  text-align: center;
  border-radius: 12px;
`;

interface IProps {
  isShow: boolean;
  setShow: (isShow: boolean) => void;
  currentMerchant: any;
  merchants: any[];
  handleBackToFoodcourt: () => void;
  handleNext: () => void;
  handleClickVisitMerchant: (link: string) => void;
	foodcourtName: string;
}

const BottomSheetFoodcourtSuggestion = ({
  isShow,
  setShow,
  currentMerchant,
  merchants,
  handleBackToFoodcourt,
  handleNext,
  handleClickVisitMerchant,
	foodcourtName,
}: IProps) => {
  const excludedSelfMerchants = merchants.filter(
    (merchant) => merchant.shop_id !== currentMerchant?.id
  );

  const randomizedMerchants = excludedSelfMerchants.map((merchant) => {
    merchant.suggestionRandomRank = Math.random();
    return merchant;
  });

  const selectedMerchants = randomizedMerchants
    .sort(
      (merchantA, merchantB) =>
        merchantA.suggestionRandomRank - merchantB.suggestionRandomRank
    )
    .slice(0, 3);

  return (
    <Container isShow={isShow}>
      <GlobalStyle isShow={isShow} />
      <Content>
        <Header>
          <HeaderLabel>Tambah Lagi Pesanan</HeaderLabel>
          <ImgClose onClick={() => setShow(false)} src={CloseAsset} />
        </Header>
        <Message>
          Yuk, pilih produk dari tenant lain yang mungkin kamu suka di {foodcourtName}.
        </Message>
        {selectedMerchants.length > 0 && (
          <MerchantContainer>
            {selectedMerchants.map((merchant) => (
              <Merchant onClick={() => handleClickVisitMerchant(merchant.link)}>
                <MerchantIcon
                  src={
                    merchant?.logo_url
                      ? merchant.logo_url
                      : FoodcourtDefaultAsset
                  }
                />
                <MerchantName>
                  {merchant?.name.length > 20
                    ? `${merchant.name.substring(0, 20)}...`
                    : merchant.name}
                </MerchantName>
                <MerchantButton>Kunjungi</MerchantButton>
              </Merchant>
            ))}
          </MerchantContainer>
        )}
        <ButtonWrapper>
					<Button isPrimary={false} onClick={handleBackToFoodcourt}>Lihat Tenant Lain</Button>
					<Button isPrimary={true} onClick={handleNext}>Lanjut Bayar</Button>
        </ButtonWrapper>
      </Content>
    </Container>
  );
};

export default BottomSheetFoodcourtSuggestion;

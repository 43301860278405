import styled from "styled-components";
import {
	E_COLOR,
	E_FONT_WEIGHT,
	E_ORDER_STATUS,
} from "../interfaces/dataTypes";

export const Container = styled.div`
	padding: 0 0 16px;
`;

export const GreyText = styled.div`
	max-width: 200px;
	word-break: break-word;
	color: ${E_COLOR.NEW_GREY_TEXT};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 12px;
	line-height: 18px;
`;

export const ItemCard = styled.div<{ isLastItem: boolean }>`
	display: flex;
	margin: 0 16px ${({ isLastItem }) => (isLastItem ? "14px" : "0")};
	padding: 12px 0 12px;
	border-bottom: dashed 1px ${E_COLOR.NEW_LIGHT_GREY};
`;

export const ItemQuantity = styled.div`
	width: 7%;
	color: ${E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

export const ItemDetail = styled.div`
	width: 93%;
`;

export const ItemNamePriceWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const VariantWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 8px;
`;

export const ItemPriceWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

export const ItemDiscountWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const ItemDiscount = styled.div<{ isPercentage: boolean }>`
	color: ${({ isPercentage }) =>
		isPercentage ? E_COLOR.RED : E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	text-decoration: ${({ isPercentage }) =>
		isPercentage ? "none" : "line-through"};
	padding-left: 4px;
`;

export const ItemNamePrice = styled.div<{
	isBold: boolean;
	width?: string;
	textAlign?: string;
}>`
	width: ${({ width }) => width};
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${({ isBold }) =>
		isBold ? E_FONT_WEIGHT.EXTRA_BOLD : E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	text-align: ${({ textAlign }) => textAlign};
`;

export const ItemNotes = styled.div`
	background-color: ${E_COLOR.NEW_GREY_LINE};
	border-radius: 4px;
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	padding: 10px;
	margin: 8px 0 -2px;
`;

export const Product = styled.div`
	padding: 6px 0;
	margin: 0 16px;
`;

export const ProductNamePrice = styled.div`
	display: flex;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
`;

export const PropValueWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const GreyLine = styled.div`
	margin: 15px 0 20px;
	width: 100%;
	height: 8px;
	background-color: ${E_COLOR.NEW_GREY_LINE};
`;

export const Title = styled.div`
	margin: 0 16px 14px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 16px;
	line-height: 16px;
`;

export const WrapperVoucher = styled.div`
	padding: 14px 0;
`;

export const DashedLine = styled.div`
	margin: 0 16px;
	border-bottom: dashed 1px ${E_COLOR.NEW_LIGHT_GREY};
`;

export const Total = styled.div`
	padding: 18px 16px 11px;
	display: flex;
	justify-content: space-between;
`;

export const TotalLabel = styled.div<{ inline?: boolean }>`
	padding-left: ${({ inline }) => (inline ? "5px" : "0")};
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

export const TotalPrice = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 21px;
`;

export const ButtonNext = styled.div<{
	isDisabled: boolean;
	isPrimary: boolean;
	color?: string;
}>`
	width: calc(100% - 32px);
	margin: 16px;
	padding: 14px 0;
	background-color: ${({ isDisabled, color, isPrimary }) =>
		isDisabled
			? E_COLOR.NEW_LIGHT_GREY
			: isPrimary
			? color
				? color
				: E_COLOR.DARK_BLUE
			: E_COLOR.WHITE};
	border-radius: 10px;
	border: ${({ isDisabled, color, isPrimary }) =>
		isDisabled || isPrimary
			? "none"
			: color
			? `1px solid ${color}`
			: `1px solid ${E_COLOR.DARK_BLUE}`};
	color: ${({ isDisabled, isPrimary, color }) =>
		isDisabled
			? E_COLOR.GREY_DISABLED
			: isPrimary
			? E_COLOR.WHITE
			: color
			? color
			: E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 25px;
	text-align: center;
`;

export const DimNotification = styled.div<{ isShow: boolean }>`
	bottom: 0;
	display: ${({ isShow }) => (isShow ? "block" : "none")};
	position: fixed;
	left: 50%;
	transform: translate(-50%, 0);
	margin: 0 auto 100px;
	padding: 10px 25px 5px;
	background-color: ${E_COLOR.NEW_LIGHT_GREY};
	box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.113762);
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
	border-radius: 24px;
	z-index: 7;
	text-align: center;
	width: calc(100vw - 150px);
	max-width: 300px;
`;

export const OrderProcessedLabel = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	padding-bottom: 4px;
`;

export const SuccessImg = styled.img`
	display: flex;
	margin: 30px auto 0;
	height: 60px;
	padding: 16px;
`;

export const OrderHeader = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 6px 16px 0;
`;

export const OrderLabel = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
`;

export const OrderTime = styled.div`
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
`;

export const BuyerInfoWrapper = styled.div`
	position: relative;
	margin: 16px 0;
`;

export const BuyerInfo = styled.div`
	width: -webkit-fill-available;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: space-between;
	top: 0;
	padding: 12px;
	margin: 0 16px;
`;

export const BuyerInfoBackgroundImage = styled.img`
	position: relative;
	width: calc(100% - 32px);
	height: 42px;
	max-width: calc(600px - 32px);
	left: 50%;
	margin-left: calc((100% - 32px) / 2 * -1);
`;

export const BuyerInfoDetail = styled.div`
	display: flex;
	align-items: center;
`;

export const TableNumber = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 12px;
	line-height: 18px;
`;

export const DotSeparator = styled.div`
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background-color: ${E_COLOR.DARK_GREY};
	margin: 0 6px;
`;

export const BuyerName = styled.div<{ isOrange?: boolean }>`
	color: ${({ isOrange }) =>
		isOrange ? E_COLOR.DARK_ORANGE : E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 18px;
	margin-right: 6px;
`;

export const BuyerPhone = styled.div<{ isOrange?: boolean }>`
	color: ${({ isOrange }) =>
		isOrange ? E_COLOR.DARK_ORANGE : E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 18px;
	font-style: italic;
`;

export const ButtonChangeBuyerInfo = styled.div`
	color: ${E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 12px;
	line-height: 18px;
`;

export const ButtonActionWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 11px 4px;
`;

export const ButtonAction = styled.div`
	min-width: 37%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${E_COLOR.WHITE};
	border: 1px solid ${E_COLOR.DARK_BLUE};
	border-radius: 12px;
	padding: 10px 14px;
	margin: 0 4px;
`;

export const ButtonActionIcon = styled.img`
	width: 24px;
	height: 24px;
	margin-right: 8px;
`;

export const ButtonActionLabel = styled.div`
	color: ${E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 18px;
`;

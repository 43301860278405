import styled from 'styled-components';
import { E_COLOR, E_FONT_WEIGHT } from '../interfaces/dataTypes';
import CloseAsset from '../assets/close.png';

const Background = styled.div<{ isOpen: boolean }>`
  position: fixed;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  max-width: 600px;
  background-color: ${E_COLOR.GREY_BACKGROUND};
  z-index: 3;
`;

const Container = styled.div`
  width: calc(100% - 90px);
  padding: 24px 16px;
  background-color: ${E_COLOR.WHITE};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  text-align: center;
`;

const Image = styled.img`
  margin: 24px 0;
`;

const CloseButton = styled.button`
  outline: none;
  background: transparent;
  border: 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Title = styled.h3`
  color: ${E_COLOR.BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  flex: 1 1 0%;
  text-align: left;
`;

const Description = styled.p`
  color: ${E_COLOR.BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 16px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 24px;
`;

const Button = styled.button<{ isClose: boolean }>`
  outline: none;
  width: 100%;
  height: 48px;
  background-color: ${({ isClose }) =>
    isClose ? E_COLOR.WHITE : E_COLOR.DARK_BLUE};
  border-radius: 12px;
  border: 1px solid
    ${({ isClose }) => (isClose ? E_COLOR.GREY_BORDER : E_COLOR.BLUE)};
  text-align: center;
  color: ${({ isClose }) => (isClose ? E_COLOR.BLACK : E_COLOR.WHITE)};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 14px;
  line-height: 21px;
  font-family: inherit;
`;

interface IProps {
  isOpen: boolean;
  imageUrl: string;
  title: string;
  description: string;
  buttonLabel: string;
  onClose: () => void;
}

const PopupCommon = ({
  isOpen,
  imageUrl,
  title,
  description,
  buttonLabel,
  onClose,
}: IProps) => {
  return (
    <Background isOpen={isOpen}>
      <Container>
        <HeaderContainer>
          <Title>{title}</Title>
          <CloseButton onClick={onClose}>
            <img src={CloseAsset} alt="close" width={16} height={16} />
          </CloseButton>
        </HeaderContainer>
        <Image src={imageUrl} alt={title} width={160} height={160} />
        <Description>{description}</Description>
        <Button isClose={false} onClick={onClose}>
          {buttonLabel}
        </Button>
      </Container>
    </Background>
  );
};

export default PopupCommon;

import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { ISelectedItems } from "../interfaces";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";

import CloseAsset from "../assets/close.png";
import { calcDiscountPercentage, parseIDR } from "../utils";
import { WORDING } from "../constants/translate";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden" : "scroll")};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
	z-index: 6;
	display: ${(props) => (props.isShow ? "block" : "none")};
	position: fixed;
	width: 100vh;
	max-width: 600px;
	background-color: rgba(26, 26, 26, 0.5);
	height: 100vh;
	bottom: 0;
	top: 0;
	overflow: hidden;
`;

const Content = styled.div`
	width: 100%;
	max-width: 600px;
	position: fixed;
	background-color: ${E_COLOR.WHITE};
	bottom: 0;
	border-radius: 16px 16px 0 0;
`;

const ScrollAbleContent = styled.div`
	overflow-y: scroll;
	max-width: 600px;
	max-height: 75vh;
	padding-bottom: 20px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 16px;
	max-width: calc(600px - 32px);
`;

const Title = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 18px;
	line-height: 27px;
`;

const CloseIcon = styled.img`
	width: 16px;
	height: 16px;
`;

const ItemCard = styled.div<{ isLast: boolean }>`
	display: flex;
	margin: 0 16px 0;
	padding: 16px 0 20px;
	border-bottom: ${({ isLast }) =>
		!isLast ? `1px solid ${E_COLOR.NEW_LIGHT_GREY}` : "none"};
`;

const ItemQuantity = styled.div`
	width: 7%;
	color: ${E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

const ItemDetail = styled.div`
	width: 93%;
`;

const ItemNamePriceWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 8px;
`;

const ItemPriceWrapper = styled.div`
	display: flex;
	align-items: end;
	flex-direction: column;
`;

const ItemDiscountWrapper = styled.div`
	display: flex;
`;

export const ItemDiscount = styled.div<{ isPercentage: boolean }>`
	color: ${({ isPercentage }) =>
		isPercentage ? E_COLOR.RED : E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 21px;
	text-decoration: ${({ isPercentage }) =>
		isPercentage ? "none" : "line-through"};
	padding-left: 4px;
`;

const ItemNamePrice = styled.div<{ isBold: boolean }>`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${({ isBold }) =>
		isBold ? E_FONT_WEIGHT.EXTRA_BOLD : E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
`;

const ItemNotes = styled.div`
	background-color: ${E_COLOR.NEW_LIGHT_GREY};
	border-radius: 4px;
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	padding: 10px;
`;

const ItemButtonWrapper = styled.div`
	display: flex;
`;

const ItemButton = styled.div`
	color: ${E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	padding: 8px 12px 0 0;
`;

const ButtonNext = styled.div<{ isDisabled: boolean }>`
	padding: 10px 0;
	background-color: ${({ isDisabled }) =>
		isDisabled ? E_COLOR.NEW_LIGHT_GREY : E_COLOR.DARK_BLUE};
	border-radius: 10px;
	color: ${({ isDisabled }) =>
		isDisabled ? E_COLOR.GREY_DISABLED : E_COLOR.WHITE};
	border: 1px solid
		${({ isDisabled }) =>
			isDisabled ? E_COLOR.NEW_LIGHT_GREY : E_COLOR.DARK_BLUE};
	text-align: center;
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
	margin: 0 16px 16px;
`;

interface IProps {
	isShow: boolean;
	setShow: (isShow: boolean) => void;
	selectedItems: ISelectedItems;
	setIsShowBottomSheetItemDetail: (isShow: boolean) => void;
	handleDeleteExistingItem: (id: string) => void;
	handleChangeDetailExistingItem: (id: string) => void;
	language: "id" | "en";
}

const BottomSheetExistingItem = ({
	isShow,
	setShow,
	selectedItems,
	setIsShowBottomSheetItemDetail,
	handleChangeDetailExistingItem,
	handleDeleteExistingItem,
	language,
}: IProps) => {
	const handleCloseBackground = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (e.target === e.currentTarget) {
			setShow(false);
		}
	};

	const handleAddOtherVariant = () => {
		setShow(false);
		setIsShowBottomSheetItemDetail(true);
	};

	const handleClickedChangeItem = (id: string) => {
		handleChangeDetailExistingItem(id);
		handleAddOtherVariant();
	};

	const validateReachTotalQty = () => {
		// check if the product in the cart is already max stock
		let totalQtyInCart = 0;
		let stock = 0;
		Object.keys(selectedItems).forEach((itemId) => {
			const { quantity, data } = selectedItems[itemId];
			totalQtyInCart = totalQtyInCart + quantity;
			stock = data.stock;
		});
		
		return stock === totalQtyInCart;
	};

	return (
		<Container isShow={isShow} onClick={(e) => handleCloseBackground(e)}>
			<GlobalStyle isShow={isShow} />
			<Content>
				<Header>
					<Title>{WORDING.item_page.CHOOSE_VARIANT[language]}</Title>
					<CloseIcon
						onClick={(e) => handleCloseBackground(e)}
						src={CloseAsset}
					/>
				</Header>
				<ScrollAbleContent>
					{Object.keys(selectedItems).map((idItem: string, index) => {
						const { data, notes, quantity, variants, is_takeaway } = selectedItems[idItem];
						const { name, price, discount_price, takeaway_cost } = data;
						let total = parseInt(discount_price ? discount_price : price) + (is_takeaway ? +takeaway_cost : 0);

						return (
							<ItemCard
								isLast={index === Object.keys(selectedItems).length - 1}
								key={index}
							>
								<ItemQuantity>{quantity}x</ItemQuantity>
								<ItemDetail>
									<ItemNamePriceWrapper>
										<ItemNamePrice isBold={false}>{name}</ItemNamePrice>
										<ItemPriceWrapper>
											<ItemNamePrice isBold={false}>
												Rp
												{parseIDR(
													parseInt(discount_price ? discount_price : price)
												)}
											</ItemNamePrice>
											{discount_price && (
												<ItemDiscountWrapper>
													<ItemDiscount isPercentage={true}>
														-
														{calcDiscountPercentage(
															parseInt(price),
															parseInt(discount_price)
														)}
														%
													</ItemDiscount>
													<ItemDiscount isPercentage={false}>
														{parseIDR(parseInt(price))}
													</ItemDiscount>
												</ItemDiscountWrapper>
											)}
										</ItemPriceWrapper>
									</ItemNamePriceWrapper>
									{Object.keys(variants) &&
										Object.keys(variants).map((id: string) => {
											return Object.keys(variants[id]).map(
												(variantOptionId: string) => {
													const { name, price } = variants[id][variantOptionId];
													total = total + parseInt(price);
													return (
														<ItemNamePriceWrapper>
															<ItemNamePrice isBold={false}>
																{name}
															</ItemNamePrice>
															<ItemNamePrice isBold={false}>
																Rp{parseIDR(parseInt(price))}
															</ItemNamePrice>
														</ItemNamePriceWrapper>
													);
												}
											);
										})}
                  {is_takeaway && (
                    <ItemNamePriceWrapper>
                      <ItemNamePrice isBold>
                        {WORDING.general.PRODUCT_TAKE_AWAY[language]}
                      </ItemNamePrice>
                      <ItemNamePrice isBold={false}>
                        Rp{parseIDR(+takeaway_cost)}
                      </ItemNamePrice>
                    </ItemNamePriceWrapper>
                  )}
									<ItemNamePriceWrapper>
										<ItemNamePrice isBold={false}>Total</ItemNamePrice>
										<ItemNamePrice isBold={true}>
											Rp{parseIDR(total * quantity)}
										</ItemNamePrice>
									</ItemNamePriceWrapper>
									{notes !== "" && <ItemNotes>{notes}</ItemNotes>}
									<ItemButtonWrapper>
										<ItemButton onClick={() => handleClickedChangeItem(idItem)}>
											{WORDING.general.CHANGE[language]}
										</ItemButton>
										<ItemButton
											onClick={() => handleDeleteExistingItem(idItem)}
										>
											{WORDING.general.REMOVE[language]}
										</ItemButton>
									</ItemButtonWrapper>
								</ItemDetail>
							</ItemCard>
						);
					})}
					<ButtonNext isDisabled={validateReachTotalQty()} onClick={validateReachTotalQty() ? () => {} : handleAddOtherVariant}>
						{WORDING.item_page.ADD_OTHER_VARIANT[language]}
					</ButtonNext>
				</ScrollAbleContent>
			</Content>
		</Container>
	);
};

export default BottomSheetExistingItem;

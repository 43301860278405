import { useEffect, useState } from "react";
import {
	IProduct,
	IProductResOrder,
	IReqPayment,
	IResOrder,
	T_PAYMENT_TYPE,
} from "./../interfaces/index";
import { useParams, useHistory } from "react-router-dom";
import {
	getOrder,
	postWaInvoice,
	putChangePaymentMethod,
	putOrderStatus,
} from "./../services/Order";
import { IPathParams } from "../interfaces";
import {
	ASK_MERCHANT_ORDER_WORDING,
	INVOICE_TO_BUYER,
	SHOP_ID_FOR_POPUP,
	SHOP_URI_FOR_POPUP,
	URL_PARAM_CHANGE_PAYMENT_METHOD,
} from "../constants";
import { useAppContext } from "../providers/useAppContext";
import { E_ACTION, E_DELIVERY_OPTION } from "../interfaces/dataTypes";
import { parseIDR } from "../utils";

export const useSummaryOrderHook = () => {
	const INITIAL_WA_OBJECT = {
		shopName: "",
		shopPhone: "",
		buyerName: "",
		buyerPhone: "",
		shippingType: "",
		shippingDate: "",
		total: "",
	};

	const [waMessageObject, setWaMessageObject] = useState(INITIAL_WA_OBJECT);

	const INITIAL_STATE_DATA: IResOrder = {
		shipping_price: "",
		name: "",
		recipient_name: "",
		recipient_address: "",
		recipient_address_note: "",
		phone_number: "",
		shipping_type: "",
		shipping_date: "",
		products: [
			{
				name: "",
				description: "",
				quantity: 0,
				price: "",
				discount_price: "",
				variants: [],
			},
		],
		subtotal: "",
		shop_name: "",
		shop_phone_number: "",
		status: "created",
		payment_information: "",
		transaction_time: "",
		shop_address: "",
		payment_url: "",
		promotion: {
			code: "",
			value: "",
		},
		buyer_phone_number: "",
		payment: {
			shop_id: 0,
			amount: 0,
			expired_at: "",
			payment_method: "",
		},
		payment_method: "",
		shop_eligible_payment_method: [],
		order_shipping: {
			with_insurance: false,
			insurance_rate: 0,
		},
		promotion_payment_type: [],
		dine_in_service_fee_amount: "",
		dine_in_tax: "",
		dine_in_tax_amount: "",
		is_buyer_pay_platform: false,
		platform_fee: "",
		promotions: [],
		coin_min_order: "",
		coin_used: 0,
		coin_value: "",
		rounding_adj: 0,
		shop_link: "",
		shop_id: 0,
		is_foodcourt: false,
    additional_payments: [],
	};

	const [data, setData] = useState(INITIAL_STATE_DATA);
	const [totalQty, setTotalQty] = useState(0);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
	const [shopId, setShopId] = useState(0);
	const [isBottomSheetPaymentOptionOpen, setIsBottomSheetPaymentOptionOpen] =
		useState(false);
	const [isPopupSuccessOpen, setIsPopupSuccessOpen] = useState(false);
	const [isChangePaymentSuccess, setIsChangePaymentSuccess] = useState(false);
	const [isShowDimNotification, setIsShowDimNotification] = useState(false);
	const [statusPage, setStatusPage] = useState((null as number) || null);
	const [productsPerShop, setProductsPerShop] = useState(
		[] as IProductResOrder[][]
	); // for foodcourt products
	const { state, dispatch } = useAppContext();
	const history = useHistory();
	const params: IPathParams = useParams();
	let timer: any;
	const { orderSN } = params;

  const { language } = state;

	const fetchOrder = async () => {
		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: true,
		});

		const response: { data: IResOrder; code: number } = await getOrder(orderSN);

		if (response?.data) {
			setData(response.data);
			generateFoodcourtProducts(response.data.products);

			const {
				shop_phone_number,
				name,
				shipping_type,
				shipping_date,
				payment,
				buyer_phone_number,
				shop_name,
				subtotal,
				shop_id,
				status,
				payment_method,
			} = response.data;

			const waObj = {
				shopPhone: shop_phone_number,
				buyerName: name,
				shippingType: shipping_type,
				shippingDate: shipping_date,
				buyerPhone: buyer_phone_number,
				shopName: shop_name,
				total: subtotal,
			};

			setWaMessageObject(waObj);

			// count total items
			const products = response.data.products;
			let totalItems = 0;
			for (let i = 0; i < products.length; i++) {
				totalItems = totalItems + products[i].quantity;
			}
			setTotalQty(totalItems);

			setShopId(shop_id);

			if (
				status === "created" &&
				payment_method !== "cash" &&
				payment_method !== "manual-edc" &&
        !payment_method.startsWith("custom/")
			) {
				setIsBottomSheetPaymentOptionOpen(true);
			}

			setStatusPage(200);

			dispatch({
				type: E_ACTION.SET_LOADING,
				payload: false,
			});

			return response;
		} else if (response?.code === 404) {
			setStatusPage(404);
		} else if (response?.code === 500) {
			setStatusPage(500);
		} else {
      setStatusPage(-1);
    }

		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: false,
		});
	};

	const generateFoodcourtProducts = (products: IProductResOrder[]) => {
		let tempProductPerShop = [];
		let currShopName = "";
		products.forEach((product) => {
			if (currShopName !== product.shop_name) {
				currShopName = product.shop_name;
				tempProductPerShop.push([product]);
			} else {
				tempProductPerShop[tempProductPerShop.length - 1]?.push(product);
			}
		});

		setProductsPerShop(tempProductPerShop);
	};

	const cancelOrder = () => {
		putOrderStatus({
			shop_id: 2,
			order_sn: orderSN,
			new_status: "gagal",
		});
	};

	const sendWaMessageToMerchant = () => {
		const { shopPhone, buyerName, shippingType, shippingDate } =
			waMessageObject;
		// @ts-ignore
		const link = window.location.href;
		const waLink = ASK_MERCHANT_ORDER_WORDING.replace("{shopPhone}", shopPhone)
			.replace("{buyerName}", buyerName)
			.replace(
				"{shippingType}",
				shippingType === E_DELIVERY_OPTION.PICK_UP
					? "penjemputan"
					: "pengiriman"
			)
			.replace("{shippingDate}", shippingDate)
			.replace("{orderSN}", orderSN)
			.replace("{link}", link);

		// @ts-ignore
		window.open(waLink, "_blank");
	};

	const handleSubmitPaymentMethod = async (paymentMethod?: string) => {
		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: true,
		});

		const payload: IReqPayment = {
			new_payment_method: paymentMethod,
			order_sn: orderSN,
			shop_id: shopId,
		};

		const response = await putChangePaymentMethod(payload);

		if (response.code === 200) {
			if (paymentMethod === "cash" || paymentMethod === "manual-edc" || paymentMethod.startsWith("custom/")) {
				setIsChangePaymentSuccess(true);
				setIsBottomSheetPaymentOptionOpen(false);
				setIsPopupSuccessOpen(true);

				history.push(`/order_sn/${orderSN}`);
			} else {
				const newRes = await fetchOrder();
				setIsBottomSheetPaymentOptionOpen(false);
				history.push(`/order_sn/${orderSN}`);

				openXenditPage(newRes.data.payment_url);
			}
		} else {
			setIsChangePaymentSuccess(false);
			setIsBottomSheetPaymentOptionOpen(false);
			setIsPopupSuccessOpen(true);
		}

		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: false,
		});
	};

	const openXenditPage = (url: string) => {
		//  @ts-ignore
		let a = document.createElement("a");
		//  @ts-ignore
		document.body.appendChild(a);
		a.style = "display: none";
		a.href = url;
		// a.download = <your_fileName>;
		a.click();
		//  @ts-ignore
		document.body.removeChild(a);

		// @ts-ignore
		// window.open(url);
	};

	const openTrackingPage = () => {
		history.push(`/order_sn/${orderSN}/tracking`);
	};

	const handleClosePopupSuccessChangeToCashMethod = () => {
		fetchOrder();
		setIsPopupSuccessOpen(false);
	};

	const triggerWaInvoiceToBuyer = async () => {
		setIsShowDimNotification(true);
		timer = setTimeout(() => setIsShowDimNotification(false), 2000);

		await postWaInvoice(orderSN);
	};

	const sendWaInvoiceToBuyer = async () => {
		const {
			buyerName,
			// shippingDate,
			// shippingType,
			shopPhone,
			buyerPhone,
			shopName,
			total,
		} = waMessageObject;

		// const shipping = shippingType === "pickup" ? "pengambilan" : "pengiriman";
		// const formattedDate = getUnformattedDate(shippingDate);
		// const date = `${formattedDate.getDate()} ${getMonthName(
		// 	formattedDate,
		// 	true
		// )} ${formattedDate.getFullYear()}`;
		const totalRp = `${parseIDR(parseInt(total))},-`;

		// @ts-ignore
		const linkInvoice = `${window.location.origin}/order_sn/${orderSN}`;

		// @ts-ignore
		const waLink = INVOICE_TO_BUYER.replace("{buyerName}", buyerName)
			.replace("{buyerPhone}", buyerPhone)
			.replace("{shopName}", shopName)
			.replace("{orderSN}", orderSN)
			// .replace("{shipping}", shipping)
			// .replace("{date}", date)
			.replace("{linkInvoice}", linkInvoice)
			.replace("{shopPhone}", shopPhone)
			.replace("{shopName}", shopName)
			.replace("{total}", totalRp);

		// @ts-ignore
		window.open(waLink, "_blank");
	};

	useEffect(() => {
		// Prevent robot tracking
		// @ts-ignore
		document
			.querySelector('meta[name="robots"]')
			.setAttribute("content", "noindex");

		fetchOrder();

		return () => {
			clearTimeout(timer);
		};
	}, []);

	useEffect(() => {
		history.listen((newLocation, action) => {
			if (action === "POP") {
				// history.push(`/${data.shop_link}`);
				if (data.shop_link) {
					// @ts-ignore
					const url = `${window.location.origin}/${data.shop_link}`;
					// @ts-ignore
					window.open(url, "_self");
				}
			}
		});
	}, [data]);

	return {
		data,
		orderSN: params.orderSN,
		totalQty,
		sendWaMessageToMerchant,
		sendWaInvoiceToBuyer,
		cancelOrder,
		setSelectedPaymentMethod,
		selectedPaymentMethod,
		handleSubmitPaymentMethod,
		isBottomSheetPaymentOptionOpen,
		setIsBottomSheetPaymentOptionOpen,
		isPopupSuccessOpen,
		setIsPopupSuccessOpen,
		handleClosePopupSuccessChangeToCashMethod,
		isChangePaymentSuccess,
		openXenditPage,
		isShowDimNotification,
		openTrackingPage,
		statusPage,
		isPopupCommonSelected:
			SHOP_URI_FOR_POPUP.includes(data.shop_link) ||
			SHOP_ID_FOR_POPUP.includes(data.shop_id),
		productsPerShop,
    language,
	};
};

import styled from 'styled-components';
import { E_COLOR, E_FONT_WEIGHT } from '../interfaces/dataTypes';

import errorAsset from '../assets/error.png';
import successAsset from "../assets/success.png";
import { useEffect } from 'react';
import { WORDING } from '../constants/translate';

const Background = styled.div<{ isOpen: boolean }>`
  position: fixed;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  max-width: 600px;
  background-color: ${E_COLOR.GREY_BACKGROUND};
  z-index: 3;
`;

const Container = styled.div`
  width: calc(100% - 90px);
  padding: 20px 18px;
  background-color: ${E_COLOR.WHITE};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
`;

const Title = styled.div`
  color: ${E_COLOR.BLACK};
  font-weight: ${E_FONT_WEIGHT.BOLD};
  font-size: 15px;
  line-height: 18.27px;
  margin: 15px 0 5px;
`;
const Description = styled.div`
  color: ${E_COLOR.BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 14px;
  line-height: 17px;
`;

const Button = styled.div<{ isClose: boolean }>`
  width: 200px;
  margin: 20px auto;
  padding: 13px 0;
  background-color: ${({ isClose }) =>
    isClose ? E_COLOR.WHITE : E_COLOR.DARK_BLUE};
  border-radius: 100px;
  border: 1px solid
    ${({ isClose }) => (isClose ? E_COLOR.GREY_BORDER : E_COLOR.BLUE)};
  text-align: center;
  color: ${({ isClose }) => (isClose ? E_COLOR.BLACK : E_COLOR.WHITE)};
  font-weight: ${E_FONT_WEIGHT.BOLD};
`;

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  isSuccess: boolean;
  language: "id" | "en";
  isRetriedPreviously?: boolean;
  setIsRetried?: (isRetried: boolean) => void;
  title?: string;
  desc?: string;
  reportIssue?: () => void;
  handleRetry?: () => void;
  isCancelButtonOnly?: boolean;
}

const PopupErrorSuccess = ({
  isOpen,
  setIsRetried,
  handleRetry,
  handleClose,
  isRetriedPreviously,
  title,
  desc,
  reportIssue,
  isSuccess,
  isCancelButtonOnly,
  language,
}: IProps) => {
  const retry = () => {
    if(setIsRetried && handleRetry) {
      setIsRetried(true);
      handleRetry();
    }
  }
  
  return (
    <Background isOpen={isOpen}>
      <Container>
        <Image src={isSuccess ? successAsset : errorAsset} />
        <Title>{title ? title : WORDING.popup.TITLE_SERVER_ERROR[language]}</Title>
        <Description>
          {desc ? desc : WORDING.popup.DESC_SERVER_ERROR[language]}
        </Description>
        {isRetriedPreviously ? (
          <Button isClose={false} onClick={reportIssue ? reportIssue : () => {}}>
            {WORDING.popup.ACTION_REPORT_SERVER_ERROR[language]}
          </Button>
        ) : (
          <>
            {!isCancelButtonOnly && <Button isClose={false} onClick={isSuccess ? handleClose : retry}>
              {isSuccess ? WORDING.popup.ACTION_BACK_TO_ORDER[language] : WORDING.popup.ACTION_TRY_AGAIN[language]}
            </Button>}
            {!isSuccess && <Button isClose={true} onClick={handleClose}>
              {WORDING.popup.ACTION_CANCEL[language]}
            </Button>}
          </>
        )}
      </Container>
    </Background>
  );
};

export default PopupErrorSuccess;

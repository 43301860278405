import styled from "styled-components";
import {
	E_COLOR,
	E_FONT_WEIGHT,
	E_ORDER_STATUS,
} from "../interfaces/dataTypes";

export const Background = styled.div`
	padding: 16px;
	background-color: ${E_COLOR.DARK_YELLOW};
`;

export const Container = styled.div`
	padding: 29px 0 18px;
	background-color: ${E_COLOR.WHITE};
	border-radius: 24px;
`;

export const Title = styled.div<{ marginTop?: string }>`
	margin: ${({ marginTop }) =>
		marginTop ? `${marginTop} 9px 19px` : "0 9px 19px"};
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 16px;
	line-height: 16px;
`;

export const PageDetail = styled.div`
	margin: 10px 17px 19px 17px;
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
`;

export const StatusCard = styled.div<{ status: E_ORDER_STATUS }>`
	display: flex;
	align-items: center;
	padding: 10px 15px;
	margin: 0 12px;
	background-color: ${({ status }) =>
		status === E_ORDER_STATUS.created
			? E_COLOR.LIGHT_BLUE
			: status === E_ORDER_STATUS.ready_to_ship
			? E_COLOR.LIGHT_GREEN
			: status === E_ORDER_STATUS.completed
			? E_COLOR.LIGHT_BLUE
			: E_COLOR.LIGHT_PINK};
	color: ${({ status }) =>
		status === E_ORDER_STATUS.created
			? E_COLOR.DARK_BLUE
			: status === E_ORDER_STATUS.ready_to_ship
			? E_COLOR.DARK_GREEN
			: status === E_ORDER_STATUS.completed
			? E_COLOR.DARK_BLUE
			: E_COLOR.DARK_PINK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 14px;
	line-height: 20px;
	border-radius: 12px;
	min-height: 60px;
`;

export const TransactionStatus = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 19px 12px 24px;
`;

export const GreyText = styled.div`
	max-width: 200px;
	word-break: break-word;
	color: ${E_COLOR.NEW_GREY_TEXT};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 12px;
	line-height: 18px;
`;

export const Status = styled.div<{ status: E_ORDER_STATUS }>`
	color: ${({ status }) =>
		status === E_ORDER_STATUS.created
			? E_COLOR.DARK_ORANGE
			: status === E_ORDER_STATUS.ready_to_ship
			? E_COLOR.DARK_GREEN
			: status === E_ORDER_STATUS.completed
			? E_COLOR.DARK_BLUE
			: E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 19px;
	text-align: right;
`;

export const PropAndValue = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 12px 9px;
`;

export const Prop = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 17.6px;
`;

export const Value = styled.div`
	text-align: right;
	max-width: 200px;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 14px;
	line-height: 17.6px;
	word-break: break-word;
`;

export const GreyLine = styled.div`
	margin: 15px 0 23px;
	width: 100%;
	height: 8px;
	background-color: ${E_COLOR.NEW_GREY_LINE};
`;

export const GreyDashLineWrapper = styled.div`
	width: calc(100% + 32px);
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const GreyDashLine = styled.div`
	margin: 30px 0 17px;
	position: absolute;
	width: 100%;
	max-width: 600px;
	display: flex;
	align-items: center;
`;

export const Dash = styled.div`
	width: 100%;
	border-bottom: dashed 1px ${E_COLOR.NEW_LIGHT_GREY};
`;

export const Dot = styled.div`
	width: 28px;
	height: 23px;
	border-radius: 50%;
	background-color: ${E_COLOR.DARK_YELLOW};
`;

export const ProductShop = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	padding: 12px 9px 0;
`;

export const Product = styled.div<{ isVariant: boolean, isSolidLine?: boolean }>`
	padding: ${({ isVariant }) =>
		isVariant ? "13px 0 6px 16px" : "13px 0 6px"};
	margin: 0 9px;
	display: flex;
	justify-content: space-between;
	border-bottom: ${({ isSolidLine }) => isSolidLine ? "solid" : "dashed"} 1px ${E_COLOR.NEW_LIGHT_GREY};
`;

export const AlignRight = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ProductNamePrice = styled.div<{
	isAlignRight?: boolean;
	isHaveNotes?: boolean;
}>`
	width: ${({ isAlignRight, isHaveNotes }) =>
		isAlignRight ? (isHaveNotes ? "inherit" : "35%") : "65%"};
	display: flex;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	justify-content: ${({ isAlignRight }) =>
		isAlignRight ? "flex-end" : "flex-start"};
`;

export const ItemPriceWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

export const ItemDiscountWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
`;

export const ItemDiscount = styled.div<{ isPercentage: boolean }>`
	color: ${({ isPercentage }) =>
		isPercentage ? E_COLOR.RED : E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 21px;
	text-decoration: ${({ isPercentage }) =>
		isPercentage ? "none" : "line-through"};
	padding-left: 4px;
`;

export const ProductNotes = styled.div`
	margin-top: 5px;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 21px;
	text-align: right;
`;

export const Total = styled.div`
	padding: 18px 9px 11px;
	display: flex;
	justify-content: space-between;
`;

export const TotalLabel = styled.div<{ inline?: boolean }>`
	padding-left: ${({ inline }) => (inline ? "5px" : "0")};
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

export const TotalPrice = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 21px;
`;

export const Footer = styled.div`
	margin: 23px 0 14px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const GoogleIcon = styled.img`
	height: 26.5px;
`;

export const AppStoreIcon = styled.img`
	height: 26.5px;
`;

export const FooterText = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
`;

export const Desc = styled.div`
	margin-bottom: 30px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 13px;
	line-height: 16px;
`;

export const OrderInfo = styled.div`
	margin-bottom: 13px;
	padding: 23px 16px 21px;
	border: 1px solid ${E_COLOR.BLACK_LINE};
	background-color: ${E_COLOR.LIGHT_GREY};
	border-radius: 8px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 16px;
`;

export const SellerInfo = styled.div`
	margin: 13px 0;
	padding: 23px 16px 21px;
	border: 1px solid ${E_COLOR.BLACK_LINE};
	background-color: ${E_COLOR.LIGHT_GREY};
	border-radius: 8px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 16px;
	line-height: 20px;
`;

export const Watermark = styled.div`
	margin: 30px 0 10px;
	font-style: italic;
	font-size: 16px;
`;

export const ThankYouImg = styled.img`
	display: block;
	width: 60%;
	margin: 50px auto;
`;

export const UserInfoBox = styled.div`
	margin: 30px 0 21px;
`;

export const BoldText = styled.div`
	margin-bottom: 11px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 17px;
`;

export const OrderDate = styled.div`
	margin-bottom: 11px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 17px;
`;

export const UserInfoBoxDropship = styled.div`
	margin-top: 30px;
`;

export const RegularText = styled.div`
	margin-bottom: 11px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 17px;
`;

export const Address = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 17px;
`;

export const SubTitle = styled.div`
	margin: 12px 0;
	color: ${E_COLOR.BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 17px;
`;

export const ItemBox = styled.div`
    display: flex;
    padding: 15px 0 12px;
    border-bottom: 1px solid ${E_COLOR.BLACK_LINE};

    &:nth-child(3) {
      border-top: 1px solid ${E_COLOR.BLACK_LINE};
`;

export const OrderQty = styled.div`
	flex: 1;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 17px;
`;

export const WrapperLabelDetail = styled.div`
	flex: 6;
	display: flex;
	flex-direction: column;
`;

export const ItemLabel = styled.div`
	margin-bottom: 2px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 17px;
`;

export const ItemDetail = styled.div`
	color: ${E_COLOR.GREY_MEDIUM};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 13px;
	line-height: 15px;
	font-style: italic;
`;

export const ItemPrice = styled.div`
	flex: 2;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 17px;
	text-align: right;
`;

export const ItemTotalBox = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 15px 0 12px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 17px;
`;

export const ItemTotalQty = styled.div``;

export const ItemTotalPrice = styled.div``;

export const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-top: 13px;
`;

export const MoreButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 63px;
	height: 53px;
	margin-right: 14px;
	background-color: ${E_COLOR.WHITE};
	border-radius: 10px;
	border: 1px solid ${E_COLOR.DARK_BLUE};
`;

export const MoreIcon = styled.img`
	width: 27.5px;
`;

export const DimNotification = styled.div<{ isShow: boolean }>`
	bottom: 0;
	display: ${({ isShow }) => (isShow ? "block" : "none")};
	position: fixed;
	left: 50%;
	transform: translate(-50%, 0);
	margin: 0 auto 100px;
	padding: 7px 25px;
	background-color: ${E_COLOR.NEW_LIGHT_GREY};
	box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.113762);
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
	border-radius: 24px;
	z-index: 7;
	text-align: center;
	width: 200px;
`;

export const WAButton = styled.div`
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: 0;
	right: 0;
	z-index: 2;
	width: 25px;
	height: 25px;
	background-color: ${E_COLOR.DARK_GREEN};
	box-shadow: 0px 4px 24px rgba(51, 51, 51, 0.15);
	border-radius: 50%;
	margin: 0 17px 33px 0;
	padding: 12px;

	@media (min-width: 600px) {
		right: calc((100% - 600px) / 2);
	}
`;

export const WALogo = styled.img`
	width: 20px;
	height: 20px;
`;

export const WALabel = styled.div`
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 12px;
	line-height: 19px;
`;

export const ContainerErrPage = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20vh 0 0;
	text-align: center;
`;

export const IconErrPage = styled.img`
	width: 120px;
	height: 120px;
	margin: 0 auto;
`;

export const ErrPageTitle = styled.div<{ padding?: string }>`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
	padding: ${({padding}) => padding ? padding  : "0 40px"};
	margin: 33px auto 12px;
`;

export const ErrPageDescription = styled.div`
	color: ${E_COLOR.DARK_GREY};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	padding: 0 40px;
`;

export const ErrPageFooter = styled.div`
	display: flex;
	justify-content: space-between;
	position: fixed;
	width: calc(100% - 32px);
	max-width: calc(600px - 32px);
	bottom: 0;
	padding: 16px;
`;

export const StoreIcon = styled.img`
	width: 84px;
	height: 25px;
`;

export const OpaperTitle = styled.div<{ isCenter: boolean }>`
	color: ${E_COLOR.DARK_GREY};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	text-align: ${({ isCenter }) => isCenter ? "center" : "right"};
	width: ${({ isCenter }) => isCenter ? "100%" : "inherit"};
`;

export const ErrPageButton = styled.div`
	width: 250px;
	margin: 30px auto 0;
	padding: 10px 0;
	border-radius: 12px;
	border: 1px solid ${E_COLOR.DARK_BLUE};
	color: ${E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	text-align: center;
`
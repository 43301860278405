import styled from "styled-components";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";
import CloseAsset from "../assets/close.png";
import WalletAsset from "../assets/wallet.png";
import CardAsset from "../assets/card.png";
import ShieldAsset from "../assets/shield.png";
import XenditAsset from "../assets/xendit.png";
import QRISAsset from "../assets/qris.png";
import QRISDisabledAsset from "../assets/qrisDisabled.png";
import CashAsset from "../assets/cash.png";
import CashDisabledAsset from "../assets/cashDisabled.png";
import EdcAsset from "../assets/edc.png";
import EdcDisabledAsset from "../assets/edcDisabled.png";
import CustomPaymentAsset from "../assets/customPayment.png";
import CustomPaymentDisabledAsset from "../assets/customPaymentDisabled.png";
import ArrowRightAsset from "../assets/next.png";
import ArrowRightDisabledAsset from "../assets/nextDisabled.png";
import ShopeeAsset from "../assets/shopee.png";
import DanaAsset from "../assets/dana.png";
import OvoAsset from "../assets/ovo.png";
import LinkajaAsset from "../assets/linkaja.png";
import ShopeeDisabledAsset from "../assets/shopeeDisabled.png";
import DanaDisabledAsset from "../assets/danaDisabled.png";
import OvoDisabledAsset from "../assets/ovoDisabled.png";
import LinkajaDisabledAsset from "../assets/linkajaDisabled.png";
import { T_PAYMENT_TYPE } from "../interfaces";
import ButtonFull from "./ButtonFull";
import { WORDING } from "../constants/translate";
import { URL_VALUE_EVENT_FOOD_FESTIVAL } from "../constants";

const Container = styled.div<{ isShow: boolean; customMargin: string }>`
	z-index: 2;
	display: ${(props) => (props.isShow ? "block" : "none")};
	position: fixed;
	width: 100%;
	max-width: 600px;
	background-color: rgba(26, 26, 26, 0.5);
	height: 100vh;
	bottom: 0;
	top: 0;
	margin: ${({ customMargin }) => (customMargin ? customMargin : "0")};
`;

const Content = styled.div`
	width: 100%;
	max-width: 600px;
	position: fixed;
	background-color: ${E_COLOR.WHITE};
	overflow-y: hidden;
	bottom: 0;
	border-radius: 16px 16px 0 0;
	padding-bottom: 30px;
`;

const ScrollAbleContent = styled.div`
	overflow-y: scroll;
	max-width: 600px;
	max-height: 75vh;
	padding-bottom: 20px;
`;

const CloseIcon = styled.img`
	width: 16px;
	height: 16px;
`;

const Options = styled.div``;

const OptionCategory = styled.div<{ customMargin?: string }>`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 22px;
	margin: ${({ customMargin }) => customMargin ? customMargin : "16px 16px 0"};
`;

const Option = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 15px 12px;
	border: 1px solid ${E_COLOR.GREY_LIGHT_BORDER};
	border-radius: 12px;
	margin: 10px 16px 0;
`;

const OptionIcon = styled.img<{ height: string }>`
	height: ${({ height }) => height};
`

const OptionIconWrapper = styled.div`
	flex: 2;
`

const OptionLabel = styled.div<{ disabled: boolean }>`
	flex: 6;
	width: 70%;
	color: ${({ disabled }) => disabled ? E_COLOR.GREY_DISABLED : E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 18px;
`

const OptionArrowIcon = styled.img`
	height: 12px;
`

// const Option = styled.div`
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// 	margin: 19px 16px;
// 	padding-bottom: 20px;
// 	border-bottom: 1px dashed ${E_COLOR.BLACK_LINE};
// `;

const ImgWrapper = styled.div`
	margin: 4px 13px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${E_COLOR.NEW_GREY_BACKGROUND};
	width: 52px;
	height: 52px;
	border-radius: 50%;
`;

const ImgOption = styled.img<{ width?: string }>`
	width: ${({ width }) => (width ? width : "27px")};
	padding-left: 16px;
`;

const LabelOptionWrapper = styled.div`
	width: 70%;
	display: flex;
	flex-direction: column;
`;

const LabelOption = styled.div<{ disabled: boolean }>`
	color: ${({ disabled }) =>
		disabled ? E_COLOR.GREY_DISABLED : E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 19px;
`;

const Header = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 0 16px;
	border-radius: 5px;
	margin: 16px auto;
`;

const OptionRadio = styled.input`
	-webkit-appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: ${E_COLOR.GREY_DISABLED};

	&:checked {
		background: ${E_COLOR.DARK_BLUE};
		border: solid 2px ${E_COLOR.DARK_BLUE};
	}
`;

const HighlightLabel =  styled.div<{ disabled: boolean }>`
	display: inline;
	color: ${({ disabled }) =>
		disabled ? E_COLOR.GREY_DISABLED : E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 14px;
	padding: 0 0 3px 2px;
	vertical-align: middle;
`

const OptionDetail = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-top: 5px;
	word-break: break-all;
	place-items: flex-start;
`;

const ImgOptionDetail = styled.img`
	height: 12px;
	padding: 3px 5px 3px 0;
`;

const OptionDetailText = styled.div`
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 10px;
	line-height: 19px;
	word-break: break-word;
`;

const HeaderLabel = styled.div`
	width: 100%;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 27px;
`;

const getCustomPaymentName = (payment: T_PAYMENT_TYPE) => {
  return payment.replace(/^custom\//, '');
};

interface IProps {
	isShow: boolean;
	setShow: (isShow: boolean) => void;
	selectedPaymentMethod: T_PAYMENT_TYPE;
	setSelectedPaymentMethod: (paymentType: T_PAYMENT_TYPE) => void;
	handleSubmitPaymentMethod: (paymentMethid: T_PAYMENT_TYPE) => void;
	isCashEligible: boolean; // by fulfillment method and voucher
	isXenditEligible: boolean; // by fulfillment method and voucher
	isEDCEligible: boolean; // by fulfillment method and voucher
  getEligibilityForCustomPayment: (paymentType: T_PAYMENT_TYPE) => boolean;
	eligiblePaymentMethods: T_PAYMENT_TYPE[];
	language: "id" | "en";
	eventType?: string;
	customMargin?: string;
}

const BottomSheetPaymentOption = ({
	isShow,
	setShow,
	selectedPaymentMethod,
	setSelectedPaymentMethod,
	handleSubmitPaymentMethod,
	isCashEligible,
	isXenditEligible,
	isEDCEligible,
  getEligibilityForCustomPayment,
	eligiblePaymentMethods,
	customMargin,
	language,
	eventType,
}: IProps) => {
	const xendits = [
		"xendit",
		"xendit/va",
		"xendit/card",
	] as T_PAYMENT_TYPE[];
	const shopAllowCashless = eligiblePaymentMethods?.some(
		(method) => xendits.indexOf(method) >= 0
	);
	const shopAllowQris = eligiblePaymentMethods?.includes("xendit/qris");
	const shopAllowEDC = eligiblePaymentMethods?.includes("manual-edc");
	const shopAllowCash = eligiblePaymentMethods?.includes("cash");
	const shopAllowShopee = eligiblePaymentMethods?.includes("xendit/shopeepay");
	const shopAllowDana = eligiblePaymentMethods?.includes("xendit/dana");
	const shopAllowLinkaja = eligiblePaymentMethods?.includes("xendit/linkaja");
	const shopAllowOvo = eligiblePaymentMethods?.includes("xendit/ovo");

  const customPayments = eligiblePaymentMethods?.filter((payment) => payment.startsWith("custom/")) ?? [];

	const cashDisabled =
		!eligiblePaymentMethods?.includes("cash") || !isCashEligible;
	const xenditDisabled = !shopAllowCashless || !isXenditEligible;
	const cardDisabled =
		!eligiblePaymentMethods?.includes("manual-edc") || !isCashEligible;


	const handleClosePaymentOption = () => {
		setSelectedPaymentMethod(null);
		setShow(false);
	};

	const handleSelectPayment = (paymentMethod: T_PAYMENT_TYPE) => {
    setSelectedPaymentMethod(paymentMethod);
		handleSubmitPaymentMethod(paymentMethod);
	}

	return (
		<Container isShow={isShow} customMargin={customMargin}>
			<Content>
				<Header>
					<HeaderLabel>{WORDING.general.PAYMENT[language]}</HeaderLabel>
					<CloseIcon src={CloseAsset} onClick={handleClosePaymentOption} />
				</Header>
				<ScrollAbleContent>
					<Options>
						{(shopAllowCashless || shopAllowQris) && <OptionCategory customMargin="0 16px">{WORDING.general.CASHLESS[language]}</OptionCategory>}
						{shopAllowQris && <Option onClick={isXenditEligible ? () => handleSelectPayment("xendit/qris") : () => {}}>
							<OptionIconWrapper>
								<OptionIcon src={isXenditEligible ? QRISAsset : QRISDisabledAsset} height="14px" />
							</OptionIconWrapper>
							<OptionLabel disabled={!isXenditEligible}>QRIS</OptionLabel>
							<OptionArrowIcon src={isXenditEligible ? ArrowRightAsset : ArrowRightDisabledAsset} />
						</Option>}
						{eventType !== URL_VALUE_EVENT_FOOD_FESTIVAL && shopAllowShopee && <Option onClick={isXenditEligible ? () => handleSelectPayment("xendit/shopeepay") : () => {}}>
							<OptionIconWrapper>
								<OptionIcon src={isXenditEligible ? ShopeeAsset : ShopeeDisabledAsset} height="24px" />
							</OptionIconWrapper>
							<OptionLabel disabled={!isXenditEligible}>ShopeePay</OptionLabel>
							<OptionArrowIcon src={isXenditEligible ? ArrowRightAsset : ArrowRightDisabledAsset} />
						</Option>}
						{eventType !== URL_VALUE_EVENT_FOOD_FESTIVAL && shopAllowOvo && <Option onClick={isXenditEligible ? () => handleSelectPayment("xendit/ovo") : () => {}}>
							<OptionIconWrapper>
								<OptionIcon src={isXenditEligible ? OvoAsset : OvoDisabledAsset} height="13px" />
							</OptionIconWrapper>
							<OptionLabel disabled={!isXenditEligible}>OVO</OptionLabel>
							<OptionArrowIcon src={isXenditEligible ? ArrowRightAsset : ArrowRightDisabledAsset} />
						</Option>}
						{eventType !== URL_VALUE_EVENT_FOOD_FESTIVAL && shopAllowDana && <Option onClick={isXenditEligible ? () => handleSelectPayment("xendit/dana") : () => {}}>
							<OptionIconWrapper>
								<OptionIcon src={isXenditEligible ? DanaAsset : DanaDisabledAsset} height="16px" />
							</OptionIconWrapper>
							<OptionLabel disabled={!isXenditEligible}>DANA</OptionLabel>
							<OptionArrowIcon src={isXenditEligible ? ArrowRightAsset : ArrowRightDisabledAsset} />
						</Option>}
						{eventType !== URL_VALUE_EVENT_FOOD_FESTIVAL && shopAllowLinkaja && <Option onClick={isXenditEligible ? () => handleSelectPayment("xendit/linkaja") : () => {}}>
							<OptionIconWrapper>
								<OptionIcon src={isXenditEligible ? LinkajaAsset : LinkajaDisabledAsset} height="22px" />
							</OptionIconWrapper>
							<OptionLabel disabled={!isXenditEligible}>LinkAja</OptionLabel>
							<OptionArrowIcon src={isXenditEligible ? ArrowRightAsset : ArrowRightDisabledAsset} />
						</Option>}
						{eventType !== URL_VALUE_EVENT_FOOD_FESTIVAL && shopAllowCashless && <Option onClick={isXenditEligible ? () => handleSelectPayment("xendit") :  () => {}}>
							<OptionLabel disabled={!isXenditEligible}>{WORDING.review_page.OTHER[language]}</OptionLabel>
							<OptionArrowIcon src={isXenditEligible ? ArrowRightAsset : ArrowRightDisabledAsset} />
						</Option>}
						{(shopAllowCash || shopAllowEDC) && <OptionCategory>{WORDING.review_page.CASHIER[language]}</OptionCategory>}
						{shopAllowEDC && <Option onClick={isEDCEligible ? () => handleSelectPayment("manual-edc") :  () => {}}>
							<OptionIconWrapper>
								<OptionIcon src={isEDCEligible ? EdcAsset : EdcDisabledAsset} height="15px" />
							</OptionIconWrapper>
							<OptionLabel disabled={!isEDCEligible}>EDC</OptionLabel>
							<OptionArrowIcon src={isEDCEligible ? ArrowRightAsset : ArrowRightDisabledAsset} />
						</Option>}
						{shopAllowCash && <Option onClick={isCashEligible ? () => handleSelectPayment("cash") :  () => {}}>
							<OptionIconWrapper>
								<OptionIcon src={isCashEligible ? CashAsset : CashDisabledAsset} height="15px" />
							</OptionIconWrapper>
							<OptionLabel disabled={!isCashEligible}>{WORDING.general.CASH[language]}</OptionLabel>
							<OptionArrowIcon src={isCashEligible ? ArrowRightAsset : ArrowRightDisabledAsset} />
						</Option>}
            {customPayments.map((payment, index) => (
              <Option key={`${payment}_${index}`} onClick={getEligibilityForCustomPayment(payment) ? () => handleSelectPayment(payment) :  () => {}}>
                <OptionIconWrapper>
                  <OptionIcon src={getEligibilityForCustomPayment(payment) ? CustomPaymentAsset : CustomPaymentDisabledAsset} height="15px" />
                </OptionIconWrapper>
                <OptionLabel disabled={!getEligibilityForCustomPayment(payment)}>{getCustomPaymentName(payment)}</OptionLabel>
                <OptionArrowIcon src={getEligibilityForCustomPayment(payment) ? ArrowRightAsset : ArrowRightDisabledAsset} />
              </Option>
            ))}
					</Options>
				</ScrollAbleContent>
				{/* <Options>
					{shopAllowXendit && (
						<Option
							onClick={
								xenditDisabled
									? () => {}
									: () => setSelectedPaymentMethod("xendit")
							}
						>
							<ImgOption src={WalletAsset} />
							<LabelOptionWrapper>
								<LabelOption disabled={xenditDisabled}>
									{WORDING.general.CASHLESS[language]} <HighlightLabel disabled={xenditDisabled}>({WORDING.general.RECOMMENDED[language]})</HighlightLabel>
									<OptionDetail>
										<ImgOptionDetail src={ShieldAsset} />
										<OptionDetailText>
											{WORDING.review_page.SECURE_PAYMENT[language]}
										</OptionDetailText>
										<ImgOptionDetail src={XenditAsset} />
									</OptionDetail>
									{xenditDisabled && (
										<OptionDetailText>
											{WORDING.review_page.NOT_ELIGIBLE_PAYMENT_METHOD[language]}
										</OptionDetailText>
									)}
								</LabelOption>
							</LabelOptionWrapper>
							<OptionRadio
								type="radio"
								name="paymentOption"
								checked={selectedPaymentMethod === "xendit"}
							/>
						</Option>
					)}
					{eligiblePaymentMethods.indexOf("cash") !== -1 && 
						<Option
							onClick={
								cashDisabled ? () => {} : () => setSelectedPaymentMethod("cash")
							}
						>
							<ImgOption src={CashAsset} />
							<LabelOptionWrapper>
								<LabelOption disabled={cashDisabled}>
									{WORDING.general.CASH[language]}
									<OptionDetail>
										<OptionDetailText>
											{cashDisabled
												? WORDING.review_page.NOT_ELIGIBLE_PAYMENT_METHOD[language]
												: WORDING.review_page.PAY_AT_CASHIER[language]}
										</OptionDetailText>
									</OptionDetail>
								</LabelOption>
							</LabelOptionWrapper>
							<OptionRadio
								type="radio"
								name="paymentOption"
								checked={selectedPaymentMethod === "cash"}
							/>
						</Option>
					}
					{eligiblePaymentMethods.indexOf("manual-edc") !== -1 && <Option
						onClick={
							cardDisabled
								? () => {}
								: () => setSelectedPaymentMethod("manual-edc")
						}
					>
						<ImgOption src={CardAsset} />
						<LabelOptionWrapper>
							<LabelOption disabled={cardDisabled}>
								{WORDING.review_page.DEBIT_CREDIT_CARD[language]}
								<OptionDetail>
									<OptionDetailText>
										{cardDisabled
											? WORDING.review_page.NOT_ELIGIBLE_PAYMENT_METHOD[language]
											: WORDING.review_page.PAY_AT_CASHIER[language]}
									</OptionDetailText>
								</OptionDetail>
							</LabelOption>
						</LabelOptionWrapper>
						<OptionRadio
							type="radio"
							name="paymentOption"
							checked={selectedPaymentMethod === "manual-edc"}
						/>
					</Option>
}
				</Options> */}
				{/* <ButtonFull
					isPrimary={true}
					isDisabled={
						(cashDisabled && xenditDisabled && cardDisabled) || !selectedPaymentMethod
					}
					onClick={handleSubmitPaymentMethod}
				>
					{WORDING.general.NEXT2[language]}
				</ButtonFull> */}
			</Content>
		</Container>
	);
};

export default BottomSheetPaymentOption;

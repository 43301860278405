import styled, { createGlobalStyle, keyframes } from "styled-components";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";
import CloseAsset from "../assets/close.png";
// import DineInAsset from "../assets/dinein.svg";
import ButtonFull from "./ButtonFull";
import { WORDING } from "../constants/translate";
import DineInSVG from "../assets/dinein";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh !important" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden !important" : "scroll")};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
	z-index: 6;
	display: ${(props) => (props.isShow ? "block" : "none")};
	position: fixed;
	width: 100vh;
	max-width: 600px;
	background-color: rgba(26, 26, 26, 0.5);
	height: 100vh;
	bottom: 0;
	top: 0;
`;

const Content = styled.div`
	width: 100%;
	padding-bottom: 50px;
	max-width: 600px;
	min-height: 100px;
	position: fixed;
	background-color: ${E_COLOR.WHITE};
	overflow: hidden;
	bottom: 0;
	border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ImgClose = styled.img`
	float: right;
	width: 16px;
	height: 16px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	border-radius: 5px;
	margin: 31px 20px 20px 18px;
`;

const HeaderLabel = styled.div`
	width: 70%;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 27px;
`;

const DineInLogo = styled.img`
	width: 148px;
	height: 148px;
  margin-top: 47px;
`;

const Headline = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 24px;
	line-height: 36px;
	margin: 16px 100px;
  text-align: center;
`;

const Description = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	margin: 0 16px 16px;
  text-align: center;
`;

const CloseButton = styled.div`
  color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
  margin-top: 8px;
`

interface IProps {
	isShow: boolean;
	setShow: (isShow: boolean) => void;
  handleConfirm: () => void;
	language: "id" | "en";
	hexPrimary: string;
}

const BottomSheetDineInInfo = ({ isShow, setShow, handleConfirm, language, hexPrimary }: IProps) => {
	return (
		<Container isShow={isShow}>
			<GlobalStyle isShow={isShow} />
			<Content>
				{/* <Header>
					<HeaderLabel>Waktu Pengiriman</HeaderLabel>
					<ImgClose onClick={() => setShow(false)} src={CloseAsset} />
				</Header> */}
				<DineInSVG
					color={hexPrimary}
					margin="47px 0 0"
					widthHeight="148px"
				/>
				<Headline>{WORDING.popup.TITLE_DINE_IN_INFO[language]}</Headline>
				<Description>
					{WORDING.popup.DESC_DINE_IN_INFO[language]}
				</Description>
        <ButtonFull hexPrimary={hexPrimary} isDisabled={false} isPrimary={true} onClick={handleConfirm}>Lanjutkan</ButtonFull>
        <CloseButton onClick={() => setShow(false)}>Batalkan</CloseButton>
			</Content>
		</Container>
	);
};

export default BottomSheetDineInInfo;

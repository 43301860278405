import React, { useState } from "react";
import InputText from "../../components/InputText";
import ButtonSmall from "../../components/ButtonSmall";
import ButtonFull from "../../components/ButtonFull";
import AgreeCheckbox from "../../components/AgreeCheckbox";
import BottomSheetOTP from "../../components/BottomSheetOTP";
import BottomSheetAddress from "../../components/BottomSheetAddress";
import ShipOptions from "../../components/contactStep/ShipOptions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import {
	Container,
	FieldLabel,
	SectionLabel,
	AddressHeader,
	ShippingOption,
	SelectedShippingOption,
	DetailNotes,
	ImportantLabel,
	AddressSection,
	AddressLabel,
	ChooseAddressButton,
	AddressDetailWrapper,
	AddressDetail,
	AddressSeparator,
	FieldInstruction,
	TotalContainer,
	CartIcon,
	TotalLabel,
	TotalPrice,
	PinPointButton,
	PinIcon,
	TextPin,
	ArrowIcon,
	Label,
	Checkbox,
	DropshipperWrapper,
	DropshipperInfo,
	ThinSeparator,
	SelfShippingConcent,
	SelfShippingConcentText,
	SelfShippingConcentIcon,
} from "../../styles/ContactStep";
import Button from "../../components/Button";
import {
	E_PAGE_PATHS,
	E_COLOR,
	E_DELIVERY_OPTION,
} from "../../interfaces/dataTypes";
import { useContactStepHook } from "../../hooks/ContactStep";
import BorderSection from "../../components/BorderSection";
import { parseIDR } from "../../utils";

import CartSVG from "../../assets/cart";
import PinAsset from "../../assets/pin.png";
import PinBlueAsset from "../../assets/pinBlue.png";
import ArrowAsset from "../../assets/greyArrow.png";
import InfoAsset from "../../assets/infoBlue.png";
import { useHistory } from "react-router";
import { URL_VALUE_EVENT_FOOD_FESTIVAL } from "../../constants";
import { WORDING } from "../../constants/translate";
import PopupMaps from "../../components/PopupMaps";

const ContactStep = () => {
	const {
		handleSubmit,
		handleSelectShip,
		currentShip,
		isMapPickerOpen,
		setIsMapPickerOpen,
		phoneOTP,
		setPhoneOTP,
		handleInputAddress,
		handleSaveAddress,
		handleClickChooseOtherAddress,
		fetchLoginInfo,
		isBottomSheeOTPOpen,
		setIsBottomSheetOTPOpen,
		isWithInsurance,
		setIsWithInsurance,
		sendWaMessageToSupportNoShipAvailable,
		activeAddressesIndex,
		currentAddresses,
		contact,
		currentFillAddress,
		currentShips,
		handleInputContact,
		handleSelectAutoCompleteAddress,
		inputSearchAddress,
		setInputSearchAddress,
		handleClickAddNewAddress,
		isShowBottomSheetAddress,
		setIsShowBottomSheetAddress,
		setCurrentFillAddress,
		total,
		selectedShip,
		tableNumber,
		shop,
		history,
		eventType,
		language,
    setBuyerToContext,
		merchant,
		orderSN,
	} = useContactStepHook();
	const [isDropshipper, setIsDropshipper] = useState(false);
	const isOnlyDelivery =
		!shop.deliveryOptions?.includes(E_DELIVERY_OPTION.DINE_IN) &&
		!shop.deliveryOptions?.includes(E_DELIVERY_OPTION.PICK_UP) &&
		shop.deliveryOptions?.includes(E_DELIVERY_OPTION.DELIVERY);

	const { name, phone } = contact;
	let isDisabled = true;
	if (name?.replace(/\s/g, "").length > 0 && phone?.length >= 11) {
		// open bill from cashier can have no table number
		if (selectedShip.name === E_DELIVERY_OPTION.PICK_UP || selectedShip.name === E_DELIVERY_OPTION.OPEN_BILL) {
			isDisabled = false;
		} else if (selectedShip.name === E_DELIVERY_OPTION.DINE_IN) {
			if (tableNumber?.replace(/\s/g, "").length > 0) {
				isDisabled = false;
			}
		} else {
			if (
				currentAddresses.length > 0 &&
				currentAddresses[activeAddressesIndex].detail !== "" &&
				currentShip.name &&
				currentShip.name !== "" &&
				currentShip.name !== E_DELIVERY_OPTION.PICK_UP &&
				currentAddresses[activeAddressesIndex].lat !== 0
			) {
				if (
					isDropshipper &&
					currentFillAddress.name &&
					currentFillAddress.phone
				) {
					isDisabled = false;
				} else if (!isDropshipper) {
					isDisabled = false;
				}
			}
		}
	} else {
		// to support current company event (FJB)
		if (
			selectedShip.name === E_DELIVERY_OPTION.PICK_UP &&
			eventType === URL_VALUE_EVENT_FOOD_FESTIVAL
		) {
			isDisabled = false;
		}
	}

	const handleBackButton = () => {
		if(selectedShip.name === E_DELIVERY_OPTION.OPEN_BILL) {
			history.push(`/open/${orderSN}`);
		} else {
			if (merchant) {
				history.push(`/${shop.link}?merchant=${merchant.link}`);
			} else {
				history.push(
					`/${shop.link}${
						shop.foodEventLink ? `?foodCourt=${shop.foodEventLink}` : ""
					}`
				);
			}
			handleSubmit();
		}
	};
	
	return (
		<Container>
			{/* {!isLoggedIn && (
				<ButtonFull
					isDisabled={false}
					isPrimary
					onClick={() => setIsBottomSheetOTPOpen(true)}
				>
					Masuk ke Opaper
				</ButtonFull>
			)} */}
			{/* <BorderSection isThick={true} /> */}
			{selectedShip.name !== E_DELIVERY_OPTION.OPEN_BILL && <TotalContainer color={shop.hexPrimary}>
				{/* <CartIcon src={CartAsset} /> */}
				<CartSVG
					color={shop.hexPrimary ? shop.hexPrimary : E_COLOR.DARK_BLUE}
				/>
				<TotalLabel>{WORDING.general.TOTAL_PAYMENT[language]}</TotalLabel>
				<TotalPrice>Rp{parseIDR(total)}</TotalPrice>
			</TotalContainer>}
			<FieldLabel>
				{selectedShip.name !== E_DELIVERY_OPTION.PICK_UP &&
					selectedShip.name !== E_DELIVERY_OPTION.DINE_IN && selectedShip.name !== E_DELIVERY_OPTION.OPEN_BILL}
				{WORDING.contact_page.BUYER_INFO[language]}
			</FieldLabel>
			<FieldInstruction isError={false}>
				{WORDING.message.FILL_PHONE_NUMBER[language]}
			</FieldInstruction>
			<PhoneInput
				country={"id"}
				value={phone}
				placeholder="Nomor Telepon"
				onChange={(input, data) => handleInputContact("phone", input, data)}
				inputStyle={{
					backgroundColor: E_COLOR.NEW_GREY,
					height: "50px",
					width: "100%",
					paddingLeft: "60px",
					border: `1px solid ${E_COLOR.NEW_GREY}`,
					fontSize: "16px",
					fontWeight: "bold",
				}}
				buttonStyle={{
					backgroundColor: E_COLOR.WHITE,
					marginRight: "10px",
					padding: "0 4px",
					border: `1px solid ${E_COLOR.NEW_GREY}`,
				}}
				containerStyle={{ margin: "20px 16px 6px", width: "inherit" }}
				countryCodeEditable={true}
        disableCountryGuess={true}
        inputProps={{ name: 'phone', autoComplete: 'tel', autoFocus: true }}
			/>
			<FieldInstruction
				isError={phone?.charAt(2) === "0" && phone?.substring(0, 2) === "62"}
			>
				{phone?.charAt(2) === "0" && phone?.substring(0, 2) === "62"
					? WORDING.message.ERROR_ZERO_RESTRICT_PHONE_NUMBER[language]
					: WORDING.message.ZERO_RESTRICT_PHONE_NUMBER[language]}
			</FieldInstruction>

			<InputText
				placeholder={WORDING.general.NAME[language]}
				name="name"
				onChange={handleInputContact}
				type="text"
				value={name}
				maxLength="30"
        autoComplete="name"
			/>

			{(selectedShip.name === E_DELIVERY_OPTION.DINE_IN || selectedShip.name === E_DELIVERY_OPTION.OPEN_BILL) && (
				<BorderSection isThick={true} />
			)}

			{(selectedShip.name === E_DELIVERY_OPTION.DINE_IN || selectedShip.name === E_DELIVERY_OPTION.OPEN_BILL) && (
				<React.Fragment>
					<FieldLabel>{WORDING.general.TABLE_NUMBER[language]}</FieldLabel>
					<InputText
						placeholder={WORDING.general.TABLE_NUMBER[language]}
						name="tableNumber"
						onChange={handleInputContact}
						type="text"
						value={tableNumber}
						maxLength="4"
						disabled={selectedShip.name === E_DELIVERY_OPTION.OPEN_BILL}
					/>
				</React.Fragment>
			)}

			{selectedShip.name !== E_DELIVERY_OPTION.PICK_UP &&
				selectedShip.name !== E_DELIVERY_OPTION.DINE_IN && selectedShip.name !== E_DELIVERY_OPTION.OPEN_BILL && (
					<React.Fragment>
						{/* <FieldLabel>2. {WORDING.general.SHIPMENT[language]}</FieldLabel>
						<DetailNotes isError={false}>
							{WORDING.message.ADDRESS_UTILIZATION[language]}
						</DetailNotes> */}
						{/* <PinPointButton onClick={() => setIsMapPickerOpen(true)}>
							<PinIcon src={PinAsset} />
							<TextPin small={false}>{WORDING.contact_page.MARK_PIN_POINT[language]}</TextPin>
							<ArrowIcon src={ArrowAsset} />
						</PinPointButton> */}
						{!currentFillAddress.detail ? (
							<PinPointButton onClick={() => setIsMapPickerOpen(true)}>
								<PinIcon src={PinAsset} />
								<TextPin small={false}>
									{WORDING.contact_page.MARK_PIN_POINT[language]}
								</TextPin>
								<ArrowIcon src={ArrowAsset} />
							</PinPointButton>
						) : (
							<React.Fragment>
								<PinPointButton onClick={() => setIsMapPickerOpen(true)}>
									<PinIcon src={PinBlueAsset} />
									<TextPin small={true}>{currentFillAddress.detail}</TextPin>
									<ArrowIcon src={ArrowAsset} />
								</PinPointButton>
								<Label>
									{WORDING.general.ADD_INSTRUCTION[language]} (
									{WORDING.general.OPTIONAL[language]})
								</Label>
								<InputText
									placeholder={
										WORDING.contact_page.INSTRUCTION_PLACEHOLDER[language]
									}
									name="instruction"
									onChange={handleInputAddress}
									type="text"
									value={currentFillAddress.instruction}
									customMargin="8px 16px"
								/>
								<DropshipperWrapper>
									<Checkbox
										type="checkbox"
										value="save"
										name="save-info"
										id="check-save"
										checked={isDropshipper}
										onChange={() => setIsDropshipper(!isDropshipper)}
									/>
									<Label>
										{WORDING.contact_page.DROPSHIPPER_CHECKBOX[language]}
									</Label>
								</DropshipperWrapper>
								{isDropshipper && (
									<DropshipperInfo>
										<ThinSeparator />
										<FieldLabel customMargin="0 16px">
											{selectedShip.name !== E_DELIVERY_OPTION.PICK_UP &&
												selectedShip.name !== E_DELIVERY_OPTION.DINE_IN &&
												selectedShip.name !== E_DELIVERY_OPTION.OPEN_BILL}
											{WORDING.contact_page.RECIPIENT_INFO[language]}
										</FieldLabel>
										<PhoneInput
											country={"id"}
											value={currentFillAddress.phone}
											placeholder={WORDING.general.PHONE_NUMBER[language]}
											onChange={(input) => handleInputAddress("phone", input)}
											inputStyle={{
												backgroundColor: E_COLOR.NEW_GREY,
												height: "50px",
												width: "100%",
												paddingLeft: "60px",
												border: `1px solid ${E_COLOR.NEW_GREY}`,
												fontSize: "16px",
												fontWeight: "bold",
											}}
											buttonStyle={{
												backgroundColor: E_COLOR.WHITE,
												marginRight: "10px",
												padding: "0 4px",
												border: `1px solid ${E_COLOR.NEW_GREY}`,
											}}
											containerStyle={{ margin: "16px 16px 6px", width: "90%" }}
											countryCodeEditable={false}
										/>
										<FieldInstruction
											isError={
												currentFillAddress.phone?.charAt(2) === "0" &&
												currentFillAddress.phone?.substring(0, 2) === "62"
											}
										>
											{currentFillAddress.phone?.charAt(2) === "0" &&
											currentFillAddress.phone?.substring(0, 2) === "62"
												? WORDING.message.ERROR_ZERO_RESTRICT_PHONE_NUMBER[
														language
												  ]
												: WORDING.message.ZERO_RESTRICT_PHONE_NUMBER[language]}
										</FieldInstruction>
										<InputText
											placeholder={WORDING.general.RECIPIENT_NAME[language]}
											name="name"
											onChange={handleInputAddress}
											type="text"
											value={currentFillAddress.name}
											customMargin="8px 16px"
										/>
									</DropshipperInfo>
								)}
							</React.Fragment>
						)}
					</React.Fragment>
				)}

			{/* {currentAddresses.length > 0 &&
				((selectedShip.name !== E_DELIVERY_OPTION.PICK_UP &&
					selectedShip.name !== E_DELIVERY_OPTION.DINE_IN) ||
					isOnlyDelivery) && (
					<>
						<BorderSection isThick={false} isDashed={true} />
						<AddressSection>
							<AddressHeader>
								<AddressDetail>
									{currentAddresses[activeAddressesIndex].name} (
									{currentAddresses[activeAddressesIndex].phone})
								</AddressDetail>
								<ChooseAddressButton
									onClick={() => setIsShowBottomSheetAddress(true)}
								>
									{WORDING.contact_page.USE_OTHER_ADDRESS[language]}
								</ChooseAddressButton>
							</AddressHeader>
							<AddressDetailWrapper>
							</AddressDetailWrapper>
							<AddressDetail>
								{currentAddresses[activeAddressesIndex].detail}
							</AddressDetail>
						</AddressSection>
					</>
				)} */}

			{/* {selectedShip.name !== E_DELIVERY_OPTION.PICK_UP &&
				selectedShip.name !== E_DELIVERY_OPTION.DINE_IN &&
				!currentAddresses.length && (
					<ButtonFull
						hexPrimary={shop.hexPrimary}
						isDisabled={false}
						isPrimary={false}
						onClick={handleClickAddNewAddress}
					>
						{WORDING.contact_page.ADD_ADDRESS[language]}
					</ButtonFull>
				)} */}

			{selectedShip.name !== E_DELIVERY_OPTION.PICK_UP &&
				selectedShip.name !== E_DELIVERY_OPTION.DINE_IN &&
				selectedShip.name !== E_DELIVERY_OPTION.OPEN_BILL && (
					<BorderSection isThick={true} customMargin="12px 0 20px" />
				)}

			{selectedShip.name !== E_DELIVERY_OPTION.PICK_UP &&
				selectedShip.name !== E_DELIVERY_OPTION.DINE_IN &&
				selectedShip.name !== E_DELIVERY_OPTION.OPEN_BILL && (
					<React.Fragment>
						<FieldLabel>{WORDING.general.SHIPPING_FEE[language]}</FieldLabel>
						<ShipOptions
							handleSelectShip={handleSelectShip}
							options={currentShips}
							selectedShip={currentShip}
							isAddressFilled={currentAddresses.length > 0}
							deliveryOptions={shop.deliveryOptions}
							merchantShip={shop.shipping}
							isWithInsurance={isWithInsurance}
							setIsWithInsurance={setIsWithInsurance}
							handleReportShipping={sendWaMessageToSupportNoShipAvailable}
							language={language}
						/>
					</React.Fragment>
				)}

			{currentShip.rateId === E_DELIVERY_OPTION.SELF_SHIPPING && (
				<SelfShippingConcent>
					<SelfShippingConcentIcon src={InfoAsset} />
					<SelfShippingConcentText>
						Pesanan ini akan dikirim oleh <b>kurir pribadi penjual</b>. Dengan
						klik Lanjut, saya menyetujui bahwa tanggung jawab transaksi berada
						di pihak pembeli dan penjual.
					</SelfShippingConcentText>
				</SelfShippingConcent>
			)}

			{/* <BorderSection isThick={true} /> */}

			{/* <AgreeCheckbox
				label={"Simpan informasi ini"}
				isChecked={isAgreeSaveInfo}
				onChange={setIsAgreeSaveInfo}
			/> */}
			{/* <BottomSheetOTP
				isShow={isBottomSheeOTPOpen}
				setShow={setIsBottomSheetOTPOpen}
				phone={phoneOTP}
				setPhone={setPhoneOTP}
				fetchLoginInfo={fetchLoginInfo}
			/> */}
			<BottomSheetAddress
				isShow={isShowBottomSheetAddress}
				setShow={setIsShowBottomSheetAddress}
				setIsMapPickerOpen={setIsMapPickerOpen}
				handleInputAddress={handleInputAddress}
				handleSelectedAddress={handleSelectAutoCompleteAddress}
				// handleSaveAddress={handleSaveAddress}
				isMapPickerOpen={isMapPickerOpen}
				inputSearchBar={inputSearchAddress}
				setInputSearchBar={setInputSearchAddress}
				currentFillAddress={currentFillAddress}
				setCurrentFillAddress={setCurrentFillAddress}
				language={language}
			/>
			{selectedShip.name !== E_DELIVERY_OPTION.PICK_UP &&
				selectedShip.name !== E_DELIVERY_OPTION.DINE_IN &&
				selectedShip.name !== E_DELIVERY_OPTION.OPEN_BILL && (
					<PopupMaps
						handleClose={() => {
							setIsMapPickerOpen(false);
						}}
						isOpen={isMapPickerOpen}
						// initialCoordinates={
						// 	currentFillAddress.lat === undefined
						// 		? userCoordinates
						// 		: { lat: currentFillAddress.lat, lng: currentFillAddress.lng }
						// }
						inputSearchBar={inputSearchAddress}
						setInputSearchBar={setInputSearchAddress}
						currentFillAddress={currentFillAddress}
						setCurrentFillAddress={setCurrentFillAddress}
						language={language}
						handleSaveAddress={handleSaveAddress}
					/>
				)}
			<Button
				isCancelAble={true}
				isDisabled={isDisabled}
				isFinalButton={false}
				pathPrev={E_PAGE_PATHS.ITEMS}
				pathNext={E_PAGE_PATHS.REVIEW}
        setBuyer={setBuyerToContext}
				handleNext={handleSubmit}
				handleBack={handleBackButton}
				isFixed={true}
				language={language}
				hexPrimary={shop.hexPrimary}
				nextButtonLabel={selectedShip.name === E_DELIVERY_OPTION.OPEN_BILL ? "Simpan" : ""}
				customPathNext={selectedShip.name === E_DELIVERY_OPTION.OPEN_BILL ? `/open/${orderSN}` : ""}
			/>
		</Container>
	);
};

export default ContactStep;

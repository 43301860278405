export const WORDING = {
  general: {
    ROUNDING: {
      id: "Pembulatan",
      en: "Rounding",
    },
    SECONDS: {
      id: "detik",
      en: "seconds",
    },
    IMMEDIATELY: {
      id: "Secepatnya",
      en: "Soon"
    },
    PRODUCT: {
      id: "Produk",
      en: "Products"
    },
    ADD: {
      id: "Tambah",
      en: "Add"
    },
    OUT_OF_STOCK: {
      id: "Habis",
      en: "Empty"
    },
    NEXT: {
      id: "Lanjut",
      en: "Next"
    },
    NEXT2: {
      id: "Lanjutkan",
      en: "Next",
    },
    PICK_DATE: {
      id: "Pilih Tanggal",
      en: "Choose Date"
    },
    PICK_TIME: {
      id: "Pilih Waktu",
      en: "Choose Time"
    },
    NOTES: {
      id: "Notes",
      en: "Notes"
    },
    CHANGE: {
      id: "Ubah",
      en: "Edit",
    },
    REMOVE: {
      id: "Hapus",
      en: "Delete",
    },
    DINE_IN: {
      id: "Makan Di Tempat",
      en: "Dine-In",
    },
    PICK_UP: {
      id: "Diambil",
      en: "Pickup",
    },
    DELIVERY: {
      id: "Dikirim",
      en: "Delivery",
    },
    STORE_COURIER: {
      id: "Kurir Toko",
      en: "Stores' Owned Courier",
    },
    LOGISTIC_COURIER: {
      id: "Kurir Logistik",
      en: "Delivery Services",
    },
    PICK_UP_DATE: {
      id: "Tanggal Penjemputan",
      en: "Pick-up Date",
    },
    DELIVERY_DATE: {
      id: "Tanggal Pengiriman",
      en: "Delivery Date",
    },
    NAME: {
      id: "Nama",
      en: "Name",
    },
    SHIPMENT: {
      id: "Pengiriman",
      en: "Shipping",
    },
    INSURANCE: {
      id: "Asuransi",
      en: "Insurance",
    },
    BACK: {
      id: "Kembali",
      en: "Back",
    },
    TOTAL_PAYMENT: {
      id: "Total Bayar",
      en: "Total Order",
    },
    TABLE_NUMBER: {
      id: "Nomor Meja",
      en: "Table Number",
    },
    OPTIONAL: {
      id: "Opsional",
      en: "Optional",
    },
    BUYER_NAME: {
      id: "Nama Pembeli",
      en: "Buyer Name",
    },
    PHONE_NUMBER: {
      id: "No Telepon",
      en: "Phone Number",
    },
    RECIPIENT_NAME: {
      id: "Nama Penerima",
      en: "Recipient's Name",
    },
    COURIER: {
      id: "Kurir",
      en: "Courier",
    },
    ADDRESS: {
      id: "Alamat",
      en: "Address",
    },
    INSTRUCTION: {
      id: "Instruksi",
      en: "Instruction",
    },
    NOW: {
      id: "Sekarang",
      en: "Now",
    },
    SHIPPING_FEE: {
      id: "Ongkos Kirim",
      en: "Delivery Fee"
    },
    SERVICE_FEE: {
      id: "Service Fee",
      en: "Service Fee"
    },
    TAX: {
      id: "PPN",
      en: "Tax"
    },
    PLATFORM_FEE: {
      id: "Biaya Layanan",
      en: "Platform Fee"
    },
    THANK_YOU: {
      id: "Terima Kasih",
      en: "Thank You"
    },
    YES: {
      id: "Iya",
      en: "Yes"
    },
    NO: {
      id: "Tidak",
      en: "No"
    },
    ADD_INSTRUCTION: {
      id: "Tambah instruksi",
      en: "Add instruction"
    },
    APPLY: {
      id: "Terapkan",
      en: "Apply"
    },
    DELIVERY_TIME: {
      id: "Waktu Pengiriman",
      en: "Delivery Time",
    },
    CANCEL: {
      id: "Batalkan",
      en: "Cancel"
    },
    AND: {
      id: "dan",
      en: "and"
    },
    COPIED: {
      id: "tersalin",
      en: "copied"
    },
    SEE_DETAIL: {
      id: "Lihat Detail",
      en: "See details"
    },
    HIDE_DETAIL: {
      id: "Sembunyikan Detail",
      en: "Hide details"
    },
    MANDATORY: {
      id: "Wajib",
      en: "Required"
    },
    FULFILLMENT_METHOD: {
      id: "Tipe Pengantaran",
      en: "Fulfillment Method"
    },
    ACTIVE: {
      id: "Aktif",
      en: "Active",
    },
    UNDERSTOOD: {
      id: "Mengerti",
      en: "OK",
    },
    PAYMENT: {
      id: "Pembayaran",
      en: "Payment"
    },
    CASHLESS: {
      id: "Non-Tunai",
      en: "Cashless"
    },
    CASH: {
      id: "Tunai",
      en: "Cash"
    },
    RECOMMENDED: {
      id: "Direkomendasikan",
      en: "Recommended"
    },
    CONFIRM: {
      id: "Konfirmasi",
      en: "Confirm"
    },
    LEFT: {
      id: "sisa",
      en: "left"
    },
    WAIT: {
      id: "Tunggu Sebentar",
      en: "Wait a moment"
    },
    MONDAY: {
      id: "Senin",
      en: "Monday"
    },
    TUESDAY: {
      id: "Selasa",
      en: "Tuesday"
    },
    WEDNESDAY: {
      id: "Rabu",
      en: "Wednesday"
    },
    THURSDAY: {
      id: "Kamis",
      en: "Thursday"
    },
    FRIDAY: {
      id: "Jumat",
      en: "Friday"
    },
    SATURDAY: {
      id: "Sabtu",
      en: "Saturday"
    },
    SUNDAY: {
      id: "Minggu",
      en: "Sunday"
    },
    CLOSE: {
      id: "Tutup",
      en: "Close"
    },
    PRODUCT_TAKE_AWAY: {
      id: "**DIBAWA PULANG",
      en: "**TAKEAWAY"
    },
    RELOAD: {
      id: "Muat Ulang",
      en: "Reload",
    },
  },
  item_page: {
    DAY: {
      id: "H",
      en: "D"
    },
    ORDER_NAME: {
      id: "Nama Pemesan",
      en: "Buyer's Name"
    },
    ORDER_PHONE: {
      id: "No Handphone Pemesan",
      en: "Buyer's Phone"
    },
    PUT_ORDER_NAME: {
      id: "Isi nama pemesan",
      en: "Fill in the Buyer's Name"
    },
    PUT_ORDER_PHONE: {
      id: "Isi no handphone pemesan",
      en: "Fill in the Buyer's Phone"
    },
    ADDRESS: {
      id: "Alamat",
      en: "Address"
    },
    STORE_PROFILE: {
      id: "Profil Toko",
      en: "Store Profile"
    },
    MULTI_OUTLETS: {
      id: "{length} Outlet Lain",
      en: "{length} Other Locations",
    },
    RESERVATION: {
      id: "Reservasi Tempat",
      en: "Reserve a Table"
    },
    BACK_TO_STORE: {
      id: "Kembali ke toko",
      en: "Back to Homepage"
    },
    MAKE_RESERVATION: {
      id: "Buat Reservasi",
      en: "Make a reservation"
    },
    PERSON_QTY: {
      id: "Jumlah Orang",
      en: "Number of Parties"
    },
    PUT_PERSON_QTY: {
      id: "Isi Jumlah Orang",
      en: "Fill in the Number of Parties"
    },
    PUT_NOTES: {
      id: "Isi notes di sini",
      en: "Fill in the Notes"
    },
    MADE_BY: {
      id: "Dibuat dengan",
      en: "Powered by"
    },
    MAKE_ONLINE_STORE: {
      id: ". Bikin toko online yuk!",
      en: ". Build your store here!"
    },
    CHOOSE_VARIANT: {
      id: "Pilih Varian",
      en: "Choose Variant",
    },
    ADD_OTHER_VARIANT: {
      id: "Tambah Varian lain",
      en: "Add more variants",
    },
    ADD_NOTES: {
      id: "Tambah Catatan",
      en: "Add Notes",
    },
    STORE_CLOSE: {
      id: "Toko Sedang Tutup",
      en: "Store is closed",
    },
    STORE_CLOSE_MESSAGE: {
      id: "Coba ganti jadwal lain",
      en: "Choose another schedule",
    },
    STORE_CLOSE_MESSAGE_MULTI_OUTLET_RESERVATION: {
      id: "Coba lihat outlet lain, ganti jadwal, atau reservasi tempat",
      en: "Choose another location, schedule, or reserve a table",
    },
    STORE_CLOSE_MESSAGE_MULTI_OUTLET: {
      id: "Coba lihat outlet lain atau ganti jadwal",
      en: "Choose another location or schedule",
    },
    STORE_CLOSE_MESSAGE_RESERVATION: {
      id: "Coba ganti jadwal lain atau reservasi tempat",
      en: "Choose another schedule or reserve a table",
    },
    BACK_TO_FOODCOURT: {
      id: "Kembali ke Foodcourt",
      en: "Back to Food Court",
    },
    CATEGORY: {
      id: "Kategori",
      en: "Category",
    },
    NO_LIMIT: {
      id: "Tidak ada batas",
      en: "No limit"
    },
    MAX: {
      id: "Maksimal",
      en: "Maximum"
    },
    OPTION: {
      id: "pilihan",
      en: "options selected"
    },
    NOTE_FOR_SELLER: {
      id: "Catatan untuk penjual",
      en: "Notes to Store"
    },
    GIVE_REVIEW: {
      id: "Beri Ulasan",
      en: "Give Feedback",
    },
    FEEDBACK: {
      id: "Saran",
      en: "Feedback"
    },
    FEEDBACK_PLACEHOLDER: {
      id: "Beri kami saran untuk lebih baik",
      en: "Please share your feedback"
    },
    SEND: {
      id: "Kirim",
      en: "Send"
    },
    CLOSE: {
      id: "Tutup",
      en: "Close"
    },
    SEARCH_PLACEHOLDER: {
      id: "Lagi cari apa nih?",
      en: "I am looking for..."
    },
    SEARCH_NOT_FOUND: {
      id: "Produk yang di cari tidak ditemukan, coba dengan nama lain.",
      en: "Items not found, please try with a different name."
    },
    OPEN_24H: {
      id: "Buka 24 Jam",
      en: "Open 24 Hours"
    },
    ERR0R_PERSON_QTY: {
      id: "Melebihi batas maksimum.",
      en: "More than the maximum capacity."
    },
    RESERVATION_DATE: {
      id: "Tanggal Reservasi",
      en: "Reservation Date",
    },
    RESERVATION_TIME: {
      id: "Waktu Reservasi",
      en: "Reservation Time",
    },
    ORDER_DETAILS: {
      id: "Detail Pesanan",
      en: "Order Details",
    },
    FOR_TAKE_AWAY: {
      id: "Untuk Dibawa Pulang",
      en: "Pack for To Go",
    },
  },
  contact_page: {
    DROPSHIPPER_CHECKBOX: {
      id: "Apakah pesanan ini untuk orang lain?",
      en: "Is this order for someone else?"
    },
    BUYER_INFO: {
      id: "Informasi Pembeli",
      en: "Buyer Information",
    },
    RECIPIENT_INFO: {
      id: "Informasi Penerima",
      en: "Recipient Information",
    },
    USE_OTHER_ADDRESS: {
      id: "Pilih Alamat Lain",
      en: "Choose another address"
    },
    ADD_ADDRESS: {
      id: "Tambah Alamat",
      en: "Add Address"
    },
    CHOOSE_DELIVERY: {
      id: "Pilih Pengiriman",
      en: "Choose Delivery"
    },
    INPUT_ADDRESS_FIRST: {
      id: "Masukan alamat terlebih dahulu.",
      en: "First, input your address"
    },
    SEARCH_LOCATION: {
      id: "Cari Lokasi",
      en: "Search Location"
    },
    PIN_LOCATION: {
      id: "Tandai Lokasi",
      en: "Pin Location"
    },
    CHOOSE_COURIER: {
      id: "Pilih Kurir",
      en: "Choose Courier"
    },
    DELIVER_BY_MERCHANT: {
      id: "Dikirim oleh merchant",
      en: "Deliver by Merchant"
    },
    ADDRESS_OUT_OF_RANGE: {
      id: "Maaf, alamat Anda diluar jangkauan pengiriman yang diaktifkan oleh penjual. Apakah Anda ingin memberikan saran untuk penjual memperluas pengiriman ke wilayah Anda?",
      en: "Sorry, your address is out of stores' delivery range. Would you like to submit feedback for store to expand to your location?"
    },
    MARK_PIN_POINT: {
      id: "Tandai Alamat Pengiriman",
      en: "Pin Address for Shipping"
    },
    INSTRUCTION_PLACEHOLDER: {
      id: "Contoh: Pagar Putih, depan angkringan",
      en: "Example: White Fence, in front of X shop"
    },
    SAVE_ADDRESS: {
      id: "Simpan Alamat",
      en: "Save Address"
    }
  },
  review_page: {
    PAY_NOW: {
      id: "Bayar Sekarang",
      en: "Pay Now",
    },
    PAY_LATER: {
      id: "Bayar Nanti",
      en: "Pay Later",
    },
    COIN: {
      id: "Koin",
      en: "Coins",
    },
    ERR_MINUS_INPUT: {
      id: "Minimal 0 koin",
      en: "Can't be less than 0",
    },
    COINS_REDEEMED: {
      id: "Koin Dipakai",
      en: "Coins Redeemed"
    },
    ERR_OTP: {
      id: "OTP yang anda masukkan salah",
      en: "Incorrect OTP",
    },
    DOESNT_MEET_MIN_TRX: {
      id: "Minimum transaksi",
      en: "Minimum transactions requirement"
    },
    INSUFFICIENT_COINS: {
      id: "Koin Anda tidak cukup",
      en: "Insufficient coins"
    },
    POTENTIAL_COIN: {
      id: "Transaksi ini berpotensi mendapatkan XX Koin",
      en: "Get XX coins after completing transactions"
    },
    MAX_COINS_USED: {
      id: "Maksimal penukaran koin adalah",
      en: "Maximum coins used are",
    },
    ERR_MAX_TRX: {
      id: "Penukaran koin melebihi nilai transaksi, tidak ada pengembalian koin",
      en: "Coins redeemed are more than the transaction amount. There will be no refund for the difference."
    },
    AMOUNTS_TO: {
      id: "Setara",
      en: "Amounts to",
    },
    FROM_XX_COIN: {
      id: "dari XX koin",
      en: "from XX coins"
    },
    MY_COIN: {
      id: "Koin Saya",
      en: "My Coins"
    },
    REDEEM_COIN: {
      id: "Tukar",
      en: "Redeem"
    },
    RESEND_CODE_IN: {
      id: "Kirim ulang kode dalam",
      en: "Resend code in"
    },
    RESEND_VIA: {
      id: "Kirim Ulang via",
      en: "Resend via"
    },
    PLEASE_CHECK_OTP_MESSAGE: {
      id: "Silahkan cek {method} kamu. Kami sudah mengirim kode OTP ke nomor",
      en: "We have sent the OTP code to your number"
    },
    INPUT_OTP_CODE: {
      id: "Masukkan kode OTP",
      en: "Input the OTP Code"
    },
    REDEEM_POINT_FOR_DISCOUNT: {
      id: "Tukar Koin untuk Diskon",
      en: "Redeem coins to get discounts"
    },
    MANUAL_EDC: {
      id: "Mesin EDC",
      en: "EDC",
    },
    CASHIER: {
      id: "Kasir",
      en: "Cashier"
    },
    OTHER: {
      id: "Lainnya",
      en: "Other"
    },
    DETAIL_ORDER: {
      id: "Rincian Order",
      en: "Order details"
    },
    DISCOUNT_CODE: {
      id: "Diskon kode",
      en: "Discount code"
    },
    CONFIRM_ORDER: {
      id: "Konfirmasi Pesanan",
      en: "Confirm Order"
    },
    YOU_HAVE: {
      id: "Anda mempunyai",
      en: "You have"
    },
    VALID: {
      id: "Berlaku",
      en: "Applied for"
    },
    PRODUCT_LIST: {
      id: "List Produk",
      en: "Items List"
    },
    ALL_PRODUCT: {
      id: "Semua Produk",
      en: "All items"
    },
    PAID_WITH: {
      id: "Bayar dengan",
      en: "Pay with"
    },
    TNC: {
      id: "SYARAT & KETENTUAN",
      en: "TERMS & CONDITIONS"
    },
    SHIPPING_TYPE: {
      id: "Tipe pengiriman",
      en: "Delivery method"
    },
    WITHOUT_MIN_PURCHASE: {
      id: "Tanpa minimum pembelian",
      en: "No minimum purchase amount"
    },
    MIN_PURCHASE: {
      id: "Minimum pembelian",
      en: "Minimum purchase amount"
    },
    EXCLUDE_DELIVERY_FEE: {
      id: "di luar ongkos kirim",
      en: "excluding shipping fees"
    },
    SECURE_PAYMENT: {
      id: "Pembayaran aman menggunakan",
      en: "Secured payment via"
    },
    NOT_ELIGIBLE_PAYMENT_METHOD: {
      id: "Tidak berlaku untuk tipe pengiriman atau promo yang dipilih",
      en: "Not applicable for fulfillment method or voucher selected.",
    },
    DEBIT_CREDIT_CARD: {
      id: "Kartu Debit/Kredit",
      en: "Debit/Credit Card",
    },
    PAY_AT_CASHIER: {
      id: "Bayar di Kasir",
      en: "Pay at the Cashier",
    },
    SEARCH_VOUCHER_LABEL: {
      id: "Punya Kode Voucher?",
      en: "Do you have a voucher?",
    },
    VALID_VOUCHER: {
      id: "Bisa Dipakai",
      en: "Eligible"
    },
    INVALID_VOUCHER: {
      id: "Tidak Memenuhi Syarat",
      en: "Not eligible"
    },
    SELECT: {
      id: "Pilih",
      en: "Select"
    },
    CHANGE_SHIPPING: {
      id: "Ganti pengiriman",
      en: "Change Delivery Method"
    },
    USED: {
      id: "dipakai",
      en: "are used"
    },
    CHECK: {
      id: "Lihat",
      en: "View"
    },
    USE: {
      id: "Gunakan",
      en: "Apply"
    },
    CANCEL: {
      id: "Batal",
      en: "Cancel"
    },
    EVERY: {
      id: "Setiap",
      en: "Every"
    },
    DAY: {
      id: "hari",
      en: "day"
    },
    ON_HOURS: {
      id: "jam",
      en: "on"
    },
  },
  message: {
    NO_PRODUCT_AVAILABLE_THIS_WEEK: {
      id: "Tidak ada produk yang tersedia untuk dibeli di minggu ini, silahkan pilih tanggal lainnya.",
      en: "No item available this week. Choose another date."
    },
    FILL_PHONE_NUMBER: {
      id: "Untuk pengiriman bukti faktur pembelian dan pengiriman.",
      en: "For sending the invoice and shipment.",
    },
    ZERO_RESTRICT_PHONE_NUMBER: {
      id: "Masukkan nomor telpon Anda tanpa angka 0 di awal (Ex: 81212341234)",
      en: "Fill in your phone number without 0 at the front (e.g.,: 81212341234)",
    },
    ERROR_ZERO_RESTRICT_PHONE_NUMBER: {
      id: "Masukkan nomor Anda mulai dari angka 8.",
      en: "Input your phone number start from 8 (without 0)",
    },
    ADDRESS_UTILIZATION: {
      id: "Kami akan menggunakan informasi ini untuk memperkirakan biaya pengiriman.",
      en: "We will use this information to estimate the delivery cost.",
    },
    VOUCHER_OUT_OF_STOCK: {
      id: "Voucher habis, silakan ganti voucher lain",
      en: "No more voucher left. Please select other vouchers.",
    },
    PROMO_VALID_BY_DATE: {
      id: "Promo ini hanya berlaku sampai tanggal yang ditentukan.",
      en: "Voucher is only valid until date specified."
    },
    OPAPER_RIGHTS: {
      id: "Opaper berhak melakukan tindakan - tindakan yang diperlukan seperti pembatalan promo jika ditemukan pelanggaran syarat & ketentuan dari pihak Opaper",
      en: "Opaper has the right to take necessary actions such as canceling the promo if a violation of the terms & conditions is found from Opaper."
    },
    BUYER_AGREEMENT: {
      id: "Dengan mengikuti promo ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan yang berlaku.",
      en: "You are agreeing to all terms and conditions by applying this voucher to your order."
    },
    FRAUD_AGREEMENT: {
      id: "Saya memahami bahwa saya memilih opsi pengiriman",
      en: "I understand that I chose this fulfillment method"
    },
    PICKUP_METHOD: {
      id: "diambil oleh pembeli",
      en: "pick-up"
    },
    COURIER_BY_SELLER: {
      id: "kurir oleh penjual",
      en: "merchants-owned delivery services"
    },
    AGREE_OF_RISK: {
      id: "Saya menyetujui resiko ini.",
      en: "I understand the risk."
    },
    ERROR_RESERVATION: {

    }
  },
  popup: {
    ERR_OTP_EXP: {
      id: "OTP sudah tidak valid, silakan masukkan kembali OTP yang baru",
      en: "OTP is not valid, please enter the new OTP number",
    },
    ERR_NOT_ACTIVATE_LOYALTY: {
      id: "Program Loyalitas pelanggan sedang dinon aktifkan oleh toko.",
      en: "Shop currently deactivates the loyalty program."
    },
    ERR_LOYALTY_DATA_CHANGED: {
      id: "Pengaturan pengunaan koin telah berubah. Silahkan coba lagi.",
      en: "Coins cannot be redeemed. Please try again."
    },
    TITLE_DINE_IN_INFO: {
      id: "Khusus Pemesanan Langsung",
      en: "Not for pre-order items",
    },
    DESC_DINE_IN_INFO: {
      id: "Opsi makan di tempat hanya bisa untuk pemesanan langsung (bukan pre-order). Klik lanjutkan agar pesanan diproses sekarang. Yakin ingin melanjutkan pesanan?",
      en: "Dine-in option is not available for pre-order. Choosing Next will get your order processed immediately. Do you want to continue?",
    },
    TITLE_RESERVATION_SUCCESS: {
      id: "Terima Kasih",
      en: "Thank You"
    },
    DESC_RESERVATION_SUCCESS: {
      id: "Permintaan reservasi Anda telah tercatat. Merchant akan mengonfirmasi reservasi ini secepatnya. Silakan hubungi merchant, jika Anda ingin membatalkan reservasi ini.",
      en: "Your reservation request has been sent. Store will confirm your reservation soon. Please contact store directly to cancel this reservation."
    },
    TITLE_PAY_AT_CASHIER: {
      id: "Selesaikan Pembayaran di Kasir",
      en: "Complete Payment at the Cashier",
    },
    DESC_PAY_AT_CASHIER_CASH: {
      id: "Silakan datang ke Kasir dan siapkan uang tunai Anda untuk melakukan pembayaran.",
      en: "Please prepare your cash and visit the cashier to settle your payment.",
    },
    DESC_PAY_AT_CASHIER_CARD: {
      id: "Silakan datang ke Kasir dan siapkan kartu kredit/debit Anda untuk menyelesaikan pembayaran.",
      en: "Please prepare your debit/credit card and visit the cashier to settle your payment.",
    },
    DESC_PAY_AT_CASHIER_CUSTOM_PAYMENT: {
      id: "Silahkan datang ke kasir dan siapkan metode bayar pilihan anda untuk menyelesaikan pembayaran.",
      en: "Please go to the cashier to complete your payment.",
    },
    TITLE_REVIEW_PRODUCT: {
      id: "Ulasan Berhasil Dikirim",
      en: "Feedback is submitted",
    },
    DESC_ASK_REVIEW_PRODUCT: {
      id: "Terima kasih sudah belanja di toko kami. Suka dengan produknya? Bantu kami dengan love produk ini ya.",
      en: "Thanks for your purchase. Please give us some loves if you like the items.",
    },
    DESC_SUCCESS_REVIEW_PRODUCT: {
      id: "Terima kasih atas penilaian yang Anda berikan untuk produk yang telah Anda pesan.",
      en: "Thanks for your rating",
    },
    DESC_FAILED_REVIEW_PRODUCT: {
      id: "Mohon maaf Anda tidak dapat memberikan ulasan.",
      en: "Sorry, you can no longer give feedback"
    },
    TITLE_SERVER_ERROR: {
      id: "Maaf, ada kendala.",
      en: "Sorry, something is not right."
    },
    DESC_SERVER_ERROR: {
      id: "Pesanan Anda tidak berhasil, silakan coba lagi.",
      en: "Uh, order did not go through. Please try again."
    },
    DESC_RESERVATION_ERROR: {
      id: "Reservasi Anda tidak berhasil, silakan coba lagi.",
      en: "Uh, reservation did not go through. Please try again."
    },
    ACTION_REPORT_SERVER_ERROR: {
      id: "Laporkan masalah",
      en: "Report issue(s)",
    },
    ACTION_BACK_TO_ORDER: {
      id: "Kembali ke Pesanan",
      en: "Back to Order"
    },
    ACTION_TRY_AGAIN: {
      id: "Coba Lagi",
      en: "Try Again"
    },
    ACTION_CANCEL: {
      id: "Gagalkan",
      en: "Cancel"
    },
    VOUCHER_AND_OTHERS: {
      id: "{type} dan {num} Lainnya",
      en: "{type} and {num} Others",
    },
  },
  errors: {
    NOT_FOUND_TITLE: {
      id: "Halaman tidak ditemukan!",
      en: "Page not found!"
    },
    NOT_FOUND_CONTENT: {
      id: "Maaf, halaman invoice pesananmu tidak ditemukan. Silahkan hubungi kasir untuk melakukan pengecekan.",
      en: "Sorry, your order invoice page was not found. Please contact the cashier."
    },
    SERVER_TITLE: {
      id: "Server Error!",
      en: "Server error!",
    },
    SERVER_CONTENT: {
      id: "Maaf, halaman invoice tidak bisa ditampilkan karena server mengalami kendala. Silahkan coba kembali.",
      en: "Sorry, the invoice page cannot be displayed because the server is experiencing problems. Please try again.",
    },
    CONNECTION_TITLE: {
      id: "Koneksi Terganggu!",
      en: "Connection Interrupted!",
    },
    CONNECTION_CONTENT: {
      id: "Maaf, halaman invoice tidak bisa ditampilkan karena koneksi buruk. Harap cek koneksi dan muat ulang halaman.",
      en: "Sorry, the invoice page cannot be displayed due to bad connection. Please check connection and refresh the page.",
    },
  },
  open_bill: {
    BUYER_INFO_NOT_COMPLETE: {
      id: "Info Pelanggan Tidak Lengkap",
      en: "Missing Customer Info",
    },
    THANK_YOU_WITH_NAME: {
      id: "Terima Kasih Atas Pesanannya, {name}!",
      en: "Thank you for your order, {name}!",
    },
    THANK_YOU_WITHOUT_NAME: {
      id: "Terima Kasih Atas Pesanannya!",
      en: "Thank you for your order!",
    },
    SHARE_OPEN_BILL_LINK: {
      id: "Bagikan Menu",
      en: "Share Menu",
    },
    ADD_MORE_PRODUCT: {
      id: "Tambah Pesanan",
      en: "Add Order",
    },
    DOWN_PAYMENT_REIMBURSE: {
      id: "DP Reimburse",
      en: "DP Reimbursement",
    },
    TOTAL_ORDER: {
      id: "Total",
      en: "Total",
    },
    PAY_FINISH_BTN: {
      id: "Bayar {price}",
      en: "Pay {price}",
    },
  },
  order_summary: {
    CHOOSE_PAYMENT_METHOD: {
      id: "Pilih Metode Pembayaran",
      en: "Select Payment Method",
    },
    TRACK_ORDER: {
      id: "Lacak",
      en: "Track",
    },
    SEND_INVOICE_TO_WA: {
      id: "Kirim faktur ke WA",
      en: "Send invoice to WA",
    },
    LOOKUP_INVOICE: {
      id: "Lihat bukti pembayaran",
      en: "View proof of payment",
    },
    THANK_YOU_TITLE: {
      id: "Terima Kasih",
      en: "Thank You",
    },
    PURCHASE_IN_SHOP: {
      id: "Telah belanja di {shopName}.",
      en: "For shopping at {shopName}.",
    },
    NOTES_STATUS_CREATED: {
      id: "Silahkan klik Pilih E-Wallet/Bank untuk membayar dan mengonfirmasi pesanan Anda.",
      en: "Select an E-Wallet/Bank to pay and confirm your order.",
    },
    NOTES_STATUS_READY_TO_SHIP: {
      id: "Kami telah menerima pembayaran Anda, mohon tunggu pesanan Anda.",
      en: "We have received your payment, please wait for your order.",
    },
    NOTES_STATUS_CANCELLED: {
      id: "Pesanan telah digagalkan",
      en: "The order has been cancelled.",
    },
    NOTES_STATUS_COMPLETED: {
      id: "Terima kasih atas pesanan Anda. Pesanan sudah dikirim.",
      en: "Thank you for your order. The order has been sent.",
    },
    NOTES_STATUS_PENDING: {
      id: "Pencairan wallet belum diproses.",
      en: "Wallet disbursement has not been processed.",
    },
    NOTES_STATUS_WAITING: {
      id: "Mohon menyelesaikan pembayaran untuk mengonfirmasi pesanan Anda.",
      en: "Please complete payment to confirm your order.",
    },
    ORDER_SERIAL: {
      id: "ORDER NO. {orderSN}",
      en: "ORDER NUMBER {orderSN}",
    },
    ORDER_DATE: {
      id: "WAKTU TRANSAKSI {transactionTime}",
      en: "ORDER TIME {transactionTime}",
    },
    ORDER_STATUS_CREATED: {
      id: "Tunggu dana",
      en: "Unpaid",
    },
    ORDER_STATUS_READY_TO_SHIP: {
      id: "Sudah dibayar",
      en: "Paid",
    },
    ORDER_STATUS_SHIPPED: {
      id: "Sudah dikirim",
      en: "Delivered",
    },
    ORDER_STATUS_CANCELLED: {
      id: "Gagal",
      en: "Cancelled",
    },
    ORDER_STATUS_COMPLETED: {
      id: "Selesai",
      en: "Completed",
    },
    ORDER_STATUS_PENDING: {
      id: "Menunggu pencairan",
      en: "Pending Disbursement",
    },
    ORDER_STATUS_WAITING: {
      id: "Belum dibayar",
      en: "Unpaid",
    },
    BUYER_NAME: {
      id: "Nama Pembeli",
      en: "Buyer Name",
    },
    BUYER_PHONE: {
      id: "Telepon Pembeli",
      en: "Buyer Phone",
    },
    SHIPPING_METHOD: {
      id: "Metode Pengiriman",
      en: "Shipping Method",
    },
    SHIPPING_PICKUP: {
      id: "Diambil pembeli",
      en: "Takeaway",
    },
    SHIPPING_SELF_SHIPPING: {
      id: "Dikirim oleh merchant",
      en: "Delivery",
    },
    SHIPPING_DINE_IN: {
      id: "Makan di tempat",
      en: "Dine-in",
    },
    SHIPPING_ORDER_DATE: {
      id: "Tanggal",
      en: "Date",
    },
    SHIPPING_DATE: {
      id: "Tanggal Pengiriman",
      en: "Delivery date",
    },
    RECIPIENT_NAME: {
      id: "Nama Penerima",
      en: "Recipient's name",
    },
    RECIPIENT_PHONE: {
      id: "Telepon Penerima",
      en: "Recipient Phone",
    },
    RECIPIENT_ADDRESS: {
      id: "Alamat",
      en: "Address",
    },
    PICK_UP_ADDRESS: {
      id: "Alamat Pickup",
      en: "Pickup Address",
    },
    RECIPIENT_ADDRESS_NOTES: {
      id: "Instruksi Alamat",
      en: "Instruction",
    },
    ORDER_DETAIL: {
      id: "Rincian Order",
      en: "Order Details",
    },
    SHIPPING_AMOUNT: {
      id: "Ongkos Pengiriman",
      en: "Shipping Fee",
    },
    COIN_USAGE: {
      id: "Koin ({num} Koin)",
      en: "Coins ({num} Coins)",
    },
    GRAND_TOTAL: {
      id: "Total",
      en: "Total",
    },
    CHANGE_PAYMENT_METHOD: {
      id: "Ganti Metode Pembayaran",
      en: "Change Payment Method",
    },
    PAYMENT_CASH_TITLE: {
      id: "Pembayaran Tunai",
      en: "Cash",
    },
    PAYMENT_EDC_TITLE: {
      id: "Pembayaran Debit/Kredit",
      en: "Debit/Credit",
    },
    PAYMENT_CUSTOM_TITLE: {
      id: "Pembayaran {custom}",
      en: "{custom} Payment",
    },
    PAYMENT_CHANGED_CONTENT: {
      id: "Metode pembayaran berhasil diganti.",
      en: "Payment method changed successfully.",
    },
    PAYMENT_MERCHANT_CONTENT: {
      id: "Silahkan pilih metode pembayaran lain atau hubungi toko apabila ingin tetap menggunakan metode pembayaran ini.",
      en: "Please choose another payment method or contact the store if you want to continue using this payment method.",
    },
    TOAST_SUCCESS_SEND_INVOICE_WA: {
      id: "Salinan faktur terkirim ke WA",
      en: "A copy of the invoice is sent to WA",
    },
    ERR_ERROR_OCCURED: {
      id: "Maaf Terjadi kesalahan",
      en: "Sorry, There is an Error",
    },
  },
  reservation: {
    DOWN_PAYMENT: {
      id: "Uang Muka",
      en: "Deposit",
    },
    DOWN_PAYMENT_NOTES: {
      id: "Pastikan informasi sudah benar. Uang muka tidak dapat dikembalikan, apabila restoran telah mengkonfirmasi reservasi ini.",
      en: "Please confirm your reservation details. Deposits are not refundable after restaurant has confirmed the reservation.",
    },
  },
}

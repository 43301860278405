import BottomSheetAddress from '../../../components/BottomSheetAddress';
import ButtonFull from '../../../components/ButtonFull';
import { useContactStepHook } from '../../../hooks/ContactStep';
import { useItemsStepHook } from '../../../hooks/ItemsStep';
import useListAddressesHook from '../../../hooks/ListAddresses';
import { IAddress } from '../../../interfaces';
import { E_COLOR } from '../../../interfaces/dataTypes';
import {} from '../../../styles/ContactStep';
import {
  AddressBox,
  AddressBoxHeader,
  AddressBoxLabel,
  Checkbox,
  Container,
  Header,
  TextButton,
  Title,
  AddressBoxDetail,
  Button,
} from '../../../styles/ListAddresses';

const ListAddresses = () => {
  const {
    currentAddresses,
    setIsShowBottomSheetAddress,
    activeAddressesIndex,
    handleSelectOtherAddress,
    handleChangeSpecificAddress,
    handleSaveAddress,
    isShowBottomSheetAddress,
    setIsMapPickerOpen,
    isMapPickerOpen,
    currentFillAddress,
    handleInputAddress,
    handleSelectAutoCompleteAddress,
    setInputSearchAddress,
    inputSearchAddress,
    setCurrentFillAddress,
    language,
  } = useContactStepHook();
  //@ts-ignore
  const token = localStorage.getItem('token')

  return (
    <Container>
      <Header>
        <Title>Daftar Alamat</Title>
        {!!token && 
          <TextButton onClick={() => setIsShowBottomSheetAddress(true)}>
            Tambah Alamat +
          </TextButton>
        }
      </Header>
      {currentAddresses.map((address: IAddress, index: number) => {
        const {
          detail,
          label,
          name,
          phone,
        } = address;
        return (
          <AddressBox isSelected={index === activeAddressesIndex} key={index}>
            <AddressBoxHeader>
              <AddressBoxLabel>{label}</AddressBoxLabel>
              <div className='round'>
                <input
                  type='checkbox'
                  id={`${index}`}
                  checked={index === activeAddressesIndex}
                  onClick={() => handleSelectOtherAddress(index)}
                />
                <label htmlFor={`${index}`}></label>
              </div>
            </AddressBoxHeader>
            <AddressBoxDetail>
              {name} ({phone})
            </AddressBoxDetail>
            <AddressBoxDetail>{detail}</AddressBoxDetail>

            <Button
              isSelected={index === activeAddressesIndex}
              onClick={() => handleChangeSpecificAddress(index)}
            >
              Ubah alamat
            </Button>
          </AddressBox>
        );
      })}
      {/* <ButtonFull
        hexPrimary={E_COLOR.DARK_BLUE}
        customWidth='calc(100% - 32px)'
        customMargin='41px 16px 30px'
        isPrimary={true}
        isDisabled={false}
        onClick={handleSaveAddress}
      >
        Pilih Alamat
      </ButtonFull> */}
      {/* <BottomSheetAddress
        isShow={isShowBottomSheetAddress}
        setShow={setIsShowBottomSheetAddress}
        setIsMapPickerOpen={setIsMapPickerOpen}
        handleInputAddress={handleInputAddress}
        handleSelectedAddress={handleSelectAutoCompleteAddress}
        handleSaveAddress={handleSaveAddress}
        isMapPickerOpen={isMapPickerOpen}
        inputSearchBar={inputSearchAddress}
        setInputSearchBar={setInputSearchAddress}
        currentFillAddress={currentFillAddress}
        setCurrentFillAddress={setCurrentFillAddress}
        language={language}
      /> */}
    </Container>
  );
};

export default ListAddresses;

import { useState } from "react";
import styled from "styled-components";
import { E_COLOR, E_COURIER_NAME, E_FONT_WEIGHT } from "../interfaces/dataTypes";
import { useTrackingOrderHook } from "../hooks/TrackingOrder";
import { getMonthName, getTimeHourMinutes, getUnformattedDate } from "../utils";

import BackAsset from "../assets/back.png";
import TrackAsset from "../assets/track.png";
import StatusAsset from "../assets/status.png";
import DoneAsset from "../assets/done.png";
import CopyAsset from "../assets/copy.png";
import GojekAsset from "../assets/courier/gojek.png";
import GrabAsset from "../assets/courier/grab.png";
import IndahAsset from "../assets/courier/indah.png";
import JneAsset from "../assets/courier/jne.png";
import JntAsset from "../assets/courier/jnt.png";
import LionAsset from "../assets/courier/lion.png";
import PaxelAsset from "../assets/courier/paxel.png";
import SicepatAsset from "../assets/courier/sicepat.png";
import TikiAsset from "../assets/courier/tiki.png";
import WahanaAsset from "../assets/courier/wahana.png";
import NinjaAsset from "../assets/courier/ninja.png";
import AnterajaAsset from "../assets/courier/anteraja.png";
import RpxAsset from "../assets/courier/rpx.png";

const Container = styled.div``;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 19px 16px;
	border-bottom: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
`;

const BackButton = styled.img`
	width: 16px;
	height: 16px;
`;

const TitleHeader = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 25px;
`;

const InfoIcon = styled.img`
	visibility: hidden;
	width: 20px;
	height: 20px;
`;

const ShippingInfoContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 35px 16px 30px;
`;

const CarIcon = styled.img`
	height: 16px;
	padding-right: 20px;
`;

const ShippingInfo = styled.div`
	width: 100%;
`;
const ShippingInfoWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const EstimationLabel = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.MEDIUM};
	font-size: 14px;
	line-height: 21px;
`;

const EstimationValue = styled.div`
	color: ${E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

const CourierLabel = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.MEDIUM};
	font-size: 14px;
	line-height: 21px;
`;

const CourierIcon = styled.img`
	height: 30px;
`;

const InvoiceWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px;
	margin-bottom: 16px;
	border-bottom: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
`;

const InvoiceLabel = styled.div`
	width: 30%;
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

const InvoiceNumber = styled.div`
	width: 70%;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	text-align: right;
	padding-right: 10px;
  word-break: break-word;
`;

const CopyIcon = styled.img`
	height: 17px;
`;

const ProgressWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 0 0 5px 16px;
`;

const ProgressDate = styled.div`
	min-width: 35px;
  max-width: 40px;
	padding-right: 8px;
	word-break: break-all;
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 10px;
	line-height: 15px;
`;

const ProgressIconWrapper = styled.div`
	position: relative;
  float: right;
`;

const ProgressIcon = styled.img`
	width: 11px;
	height: 11px;
`;

const ProgressLineTop = styled.div<{ isLong: boolean }>`
	position: absolute;
	height: ${({ isLong }) => isLong ? "38px" : "30px"};
	margin: ${({ isLong }) => isLong ? "-36px 0 0 5px" : "-28px 0 0 5px"};
	border-left: 1.5px solid ${E_COLOR.NEW_LIGHT_GREY};
`;

const ProgressLineBottom = styled.div<{ isLong: boolean }>`
	position: absolute;
	height: ${({ isLong }) => isLong ? "23px" : "15px"};
	margin: -4px 0 0 5px;
	border-left: 1.5px solid ${E_COLOR.NEW_LIGHT_GREY};
`;

const ProgressText = styled.div<{ isCurrent: boolean }>`
	padding: 10px 16px 3px 0;
	color: ${({ isCurrent }) =>
		isCurrent ? E_COLOR.NEW_BLACK : E_COLOR.GREY_DISABLED};
	font-weight: ${({ isCurrent }) =>
		isCurrent ? E_FONT_WEIGHT.EXTRA_BOLD : E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 15px;
	width: 100%;
`;

const DriverInfo = styled.div`
	padding: 0 16px 3px 0;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 17px;
	width: 100%;
`;

const GreyLine = styled.div`
	width: 100%;
	height: 8px;
	background-color: ${E_COLOR.NEW_GREY_LINE};
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 16px;
	padding-bottom: 10px;
	border-bottom: 1px dashed ${E_COLOR.NEW_LIGHT_GREY};
`;

const DimNotification = styled.div<{ isShow: boolean }>`
  bottom: 0;
  display: ${({ isShow }) => (isShow ? "block" : "none")};
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0 auto 100px;
  padding: 7px 25px;
  background-color: ${E_COLOR.NEW_LIGHT_GREY};
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.113762);
  color: ${E_COLOR.LIGHT_BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 14px;
  line-height: 21px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  z-index: 7;
`;

const TrackingOrder = () => {
	const { data, backToSummaryPage } = useTrackingOrderHook();
	const { track, driver, awb, recipient } = data;
  const [isShowDimNotification, setIsShowDimNotification] = useState(false);
  let timer: any;

	const OUT_FOR_DELIVERY_CODE = 1367;

	const handleSeeInstantTracking = (url: string) => {
		// @ts-ignore
		const a = document.createElement("a");
		a.setAttribute("href", url);
		a.setAttribute("target", "_blank");
		a.click();
	};

  let courierAsset = "";
  if(data.courier.name === E_COURIER_NAME.gosend) {
    courierAsset = GojekAsset;
  } else if (data.courier.name === E_COURIER_NAME.grab) {
    courierAsset = GrabAsset;
  } else if (data.courier.name === E_COURIER_NAME.indahCargo) {
    courierAsset = IndahAsset;
  } else if (data.courier.name === E_COURIER_NAME.jne) {
    courierAsset = JneAsset;
  } else if (data.courier.name === E_COURIER_NAME.jnt) {
    courierAsset = JntAsset;
  } else if (data.courier.name === E_COURIER_NAME.lion) {
    courierAsset = LionAsset;
  } else if (data.courier.name === E_COURIER_NAME.ninja) {
    courierAsset = NinjaAsset;
  } else if (data.courier.name === E_COURIER_NAME.paxel) {
    courierAsset = PaxelAsset;
  } else if (data.courier.name === E_COURIER_NAME.rpx) {
    courierAsset = RpxAsset;
  } else if (data.courier.name === E_COURIER_NAME.sicepat) {
    courierAsset = SicepatAsset;
  } else if (data.courier.name === E_COURIER_NAME.tiki) {
    courierAsset = TikiAsset;
  } else if (data.courier.name === E_COURIER_NAME.wahana) {
    courierAsset = WahanaAsset;
  }  else if (data.courier.name === E_COURIER_NAME.anteraja) {
    courierAsset = AnterajaAsset;
  }

  const handleCopy = () => {
    setIsShowDimNotification(true);
    timer = setTimeout(() => setIsShowDimNotification(false), 1000);

    // @ts-ignore
    const el = document.createElement("textarea");
    el.value = awb;
    // @ts-ignore
    document.body.appendChild(el);
    el.select();
    // @ts-ignore
    document.execCommand("copy");
    // @ts-ignore
    document.body.removeChild(el);
    
  };

	return (
		<Container>
			<Header>
				<BackButton src={BackAsset} onClick={backToSummaryPage} />
				<TitleHeader>Lacak</TitleHeader>
				<InfoIcon />
			</Header>
			<ShippingInfoContainer>
				<CarIcon src={TrackAsset} />
				<ShippingInfo>
					{/* <ShippingInfoWrapper>
						<EstimationLabel>Estimasi Tiba Tanggal</EstimationLabel>
						<EstimationValue>29 Juli 2021</EstimationValue>
					</ShippingInfoWrapper> */}
					<ShippingInfoWrapper>
						<EstimationLabel>Dikirim menggunakan kurir</EstimationLabel>
						<CourierIcon src={courierAsset} />
					</ShippingInfoWrapper>
				</ShippingInfo>
			</ShippingInfoContainer>
			<GreyLine />
			<InvoiceWrapper>
				<InvoiceLabel>No Resi</InvoiceLabel>
				<InvoiceNumber>{awb}</InvoiceNumber>
				<CopyIcon src={CopyAsset} onClick={handleCopy} />
			</InvoiceWrapper>
			{track.map((tracking, index) => {
				const { externalStatus, createdDate, logisticStatus, trackURL } =
					tracking;
          
				const date = new Date(createdDate);
        const description = externalStatus.description.replace("[drive_name]", driver.name).replace("[receiver_name]",recipient.name);
				return (
					<ProgressWrapper>
						<ProgressDate>
							{date.getDate()} {getMonthName(date)} <br />{" "}
							{getTimeHourMinutes(date)}
						</ProgressDate>
						<ProgressIconWrapper>
							{index !== 0 && <ProgressLineTop isLong={logisticStatus[0].id === OUT_FOR_DELIVERY_CODE} />}
							<ProgressIcon src={index === 0 ? StatusAsset : DoneAsset} />
							{index !== track.length - 1 && <ProgressLineBottom isLong={logisticStatus[0].id === OUT_FOR_DELIVERY_CODE} />}
						</ProgressIconWrapper>
						<TextWrapper>
							<ProgressText isCurrent={index === 0}>
								{description}
							</ProgressText>
							{logisticStatus[0].id === OUT_FOR_DELIVERY_CODE && (
								<DriverInfo>
                  Driver: {driver.name}
                  <br />
                  Telpon: {`${driver.phoneNumber.slice(0,5)}******${driver.phoneNumber.substring(11,driver.phoneNumber.length)}`}
                </DriverInfo>
							)}
						</TextWrapper>
					</ProgressWrapper>
				);
			})}

<DimNotification isShow={isShowDimNotification}>Tersalin</DimNotification>
		</Container>
	);
};

export default TrackingOrder;

export enum E_FONT_WEIGHT {
	EXTRA_BOLD = "700",
	BOLD = "600",
	MEDIUM = "500",
	REGULAR = "400",
	LIGHT = "300",
}

export enum E_COLOR {
	BLACK = "#000",
	NEW_BLACK = "#262626",
	LIGHT_BLACK = "#333333",
	BORDER_BLACK = "#dddddd",
	BLACK_TRANSPARANT_BACKGROUND = "rgba(38, 38, 38, 0.6);",
	WHITE = "#fff",
	BLUE = "#5bb3ff",
	LIGHT_BLUE = "rgba(30, 165, 252, 0.12)",
	DARK_BLUE = "#1ea5fc",
	NEW_YELLOW = "#ffca03",
	LIGHT_YELLOW = "#ffe9b1",
	VERY_LIGHT_YELLOW = "#fff8e6",
	YELLOW = "#f2c94c",
	DARK_YELLOW = "#ffb803",
	DARK_ORANGE = "#fe8050",
	LIGHT_ORANGE = "rgba(254, 128, 80, 0.12)",
	GREEN = "#219653",
	DARK_GREEN = "#00ab4f",
	LIGHT_GREEN = "rgba(0, 171, 79, 0.12)",
	RED = "#de4e29",
	LIGHT_RED = "#fcedea",
	NEW_GREY_BACKGROUND = "#f9f9f9",
	LIGHT_GREY = "#f6f6f6",
	GREY = "#3f434a",
	NEW_GREY = "#F2F3F7",
	GREY_DISABLED = "#939393",
	GREY_BORDER = "#c4c4c4",
	GREY_LIGHT_BORDER = "#dce1e7",
	GREY_MEDIUM = "#666666",
	GREY_LAYER = "rgba(153, 153, 153, 0.5)",
	NEW_GREY_TEXT = "#908e8e",
	BLACK_LINE = "#e2e2e2",
	GREY_LINE = "#dadada",
	NEW_GREY_LINE = "#f5f5f5",
	NEW_LIGHT_GREY = "#e9e9e9",
	DARK_GREY = "#3d3d3d",
	GREY_BACKGROUND = "rgba(0, 0, 0, 0.3)",
	GRADIENT_LIGHT_BLUE = "linear-gradient(270deg, #c9defa 0%, #dce4fc 100%)",
	GRADIENT_BLUE = "linear-gradient(270deg, #1a73e9 0%, #6c92f4 100%)",
	BLUE_BORDER = "#84cfff",
	GRADIENT_GREEN = "linear-gradient(270deg, #1dde7d 0%, #72dfc5 100%);",
	DARK_PINK = "#ef6687",
	LIGHT_PINK = "rgba(239, 102, 135, 0.12)",
	ORANGE = "#ff4422",
	BROWN = "#bf8a02",
}

export enum E_COURIER_NAME {
	gosend = "GO-SEND",
	anteraja = "Anteraja",
	grab = "Grab Express",
	indahCargo = "Indah Cargo",
	jnt = "J&T",
	jne = "JNE",
	lion = "Lion Parcel",
	ninja = "Ninja Xpress",
	rpx = "RPX",
	sicepat = "SiCepat",
	tiki = "Tiki",
	wahana = "Wahana",
	paxel = "Paxel",
}

export const COURIER_NAME_LIST = [
	"GO-SEND",
	"Anteraja",
	"Grab Express",
	"Indah Cargo",
	"J&T",
	"JNE",
	"Lion Parcel",
	"Ninja Xpress",
	"RPX",
	"SiCepat",
	"Tiki",
	"Wahana",
	"Paxel",
];

export enum E_STEP_STATE {
	DONE = "DONE",
	ON_PROGRESS = "ON_PROGESS",
	NOT_YET = "NOT_YET",
}

export enum E_PAGE_PATHS {
	DATE = "date",
	ITEMS = "items",
	CONTACT = "contact",
	REVIEW = "review",
	PAYMENT = "payment",
}

export enum E_ACTION {
	SAVE_CATEGORIES = "SAVE_CATEGORIES",
	SET_LOADING = "SET_LOADING",
	SAVE_ONE_WEEK_CATEGORY = "SAVE_ONE_WEEK_CATEGORY",
	SAVE_VOUCHERS = "SAVE_VOUCHERS",
	SAVE_SHIPS = "SAVE_SHIPS",
	SAVE_SELECTED_DATE = "SAVE_SELECTED_DATE",
	SAVE_FULFILLMENT = "SAVE_FULFILLMENT",
	SAVE_SELECTED_ITEMS = "SAVE_SELECTED_ITEMS",
	SAVE_SELECTED_SHIP = "SAVE_SELECTED_SHIP",
	SAVE_SELECTED_PRODUCT_VOUCHER = "SAVE_SELECTED_PRODUCT_VOUCHER",
	SAVE_SELECTED_SHIPPING_VOUCHER = "SAVE_SELECTED_SHIPPING_VOUCHER",
	SAVE_SHOP = "SAVE_SHOP",
	SAVE_BUYER = "SAVE_BUYER",
	SAVE_LOGIN = "SAVE_LOGIN",
	SAVE_ADDRESSES = "SAVE_ADDRESSES",
	SAVE_TOTAL = "SAVE_TOTAL",
	SAVE_SOURCE = "SAVE_SOURCE",
	SAVE_EVENT_TYPE = "SAVE_EVENT_TYPE",
	CHANGE_LANGUAGE = "CHANGE_LANGUAGE",
	SAVE_TABLE_NUMBER = "SAVE_TABLE_NUMBER",
	SAVE_HIDE_OTHER_OUTLETS = "SAVE_HIDE_OTHER_OUTLETS",
  CLOSE_POPUP_COMMON = "CLOSE_POPUP_COMMON",
	SAVE_FOODCOURT_CART = "SAVE_FOODCOURT_CART",
	SAVE_MERCHANT = "SAVE_MERCHANT",
	SAVE_ORDER_SN = "SAVE_ORDER_SN",
}

export enum E_VOUCHER_TYPE {
	ORDER = "order",
	SHIPPING = "shipping",
}

export enum E_DELIVERY_OPTION {
	DELIVERY = "deliver",
	PICK_UP = "pick_up",
	SELF_SHIPPING = "self_shipping",
	DINE_IN = "dine_in",
	OPEN_BILL = "open_bill",
}

export enum E_ORDER_STATUS {
	created = "ORDER_STATUS_CREATED",
	ready_to_ship = "ORDER_STATUS_READY_TO_SHIP",
	shipped = "ORDER_STATUS_SHIPPED",
	cancelled = "ORDER_STATUS_CANCELLED",
	completed = "ORDER_STATUS_COMPLETED",
	pending = "ORDER_STATUS_PENDING",
}

export enum E_SHIPPING_TYPE_WORDING {
	pickup = "SHIPPING_PICKUP",
	self_shipping = "SHIPPING_SELF_SHIPPING",
	dine_in = "SHIPPING_DINE_IN",
}

export enum E_ORDER_STATUS_WORDING {
	created = "NOTES_STATUS_CREATED",
	ready_to_ship = "NOTES_STATUS_READY_TO_SHIP",
	shipped = "",
	cancelled = "NOTES_STATUS_CANCELLED",
	completed = "NOTES_STATUS_COMPLETED",
	pending = "NOTES_STATUS_PENDING",
	waiting = "NOTES_STATUS_WAITING",
}

export enum E_DAYS_INDO {
	sun = "M",
	mon = "S",
	tue = "S",
	wed = "R",
	thu = "K",
	fri = "J",
	sat = "S",
}

export enum E_OTP_STATE {
	ENTER_PHONE_NUMBER = "ENTER_PHONE_NUMBER",
	ENTER_OTP_CODE = "ENTER_OTP_CODE",
}

export enum E_USER_AGENT {
	WINDOWS_PHONE = "Windows Phone",
	ANDROID = "Android",
	IOS = "iOS",
}

import { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { E_COLOR, E_FONT_WEIGHT } from "../../interfaces/dataTypes";
import CloseAsset from "../../assets/close.png";
import { ISelectedShip, IShip } from "../../interfaces";
import ShipOptions from "./ShipOptions";
import { SHIPPING_WORDING } from "../../constants";
import { parseIDR } from "../../utils";
import ButtonSmall from "../ButtonSmall";
import { WORDING } from "../../constants/translate";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden" : "scroll")};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
	z-index: 6;
	display: ${(props) => (props.isShow ? "block" : "none")};
	position: fixed;
	width: 100vh;
	max-width: 600px;
	background-color: rgba(26, 26, 26, 0.5);
	height: 100vh;
	bottom: 0;
	top: 0;
	overflow: hidden;
`;

const Content = styled.div`
  width: 100%;
  max-width: 600px;
  max-height: 70vh;
  padding-bottom: 20px;
  position: fixed;
  background-color: ${E_COLOR.WHITE};
  bottom: 0;
  border-radius: 16px 16px 0 0;
  overflow-y: scroll;
}
`;

const DragLine = styled.div`
	display: block;
	margin: 7px auto 23px;
	border-radius: 100px;
	background-color: ${E_COLOR.NEW_LIGHT_GREY};
	width: 78px;
	height: 4px;
`;

const ImgClose = styled.img`
	width: 16px;
	height: 16px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	border-radius: 5px;
	margin: 31px 20px 9px 18px;
`;

const HeaderLabel = styled.div`
	width: 70%;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 27px;
`;

const Option = styled.div`
	border-bottom: 1px solid ${E_COLOR.BLACK_LINE};
	padding: 18px 0 4px;
	margin: 0 16px;

	&:last-child {
		border-bottom: none;
	}
`;

const Label = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	margin-bottom: 4px;
`;

const Description = styled.div`
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 19px;
`;

const NoShippingErrorContainer = styled.div`
	margin: 10px 16px;
`;
const NoShippingErrorLabel = styled.div`
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 19px;
`;

const NoShippingErrorOption = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 0 10px 35vw;
`;

interface IProps {
	isShow: boolean;
	isSelectType: boolean;
	setShow: (isShow: boolean) => void;
	options: IShip[];
	selectedShip: ISelectedShip;
	handleSelectShip: (
		name: string,
		price: number,
		rateId: number | string,
		type: string,
		withInsurance: boolean,
		insuranceRate: number
	) => void;
	deliveryOptions: string[];
	merchantShip: {
		price: number;
		note: string;
	};
	handleReportShipping: () => void;
	language: "id" | "en";
}

const BottomSheetShipOptions = ({
	isShow,
	isSelectType,
	setShow,
	options,
	handleSelectShip,
	selectedShip,
	deliveryOptions,
	merchantShip,
	handleReportShipping,
	language,
}: IProps) => {
	const handleCloseBackground = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (e.target === e.currentTarget) {
			setShow(false);
		}
	};

	const handleClickedOption = (
		name: string,
		price: number,
		rateId: number | string,
		type: string,
		withInsurance: boolean,
		insuranceRate: number
	) => {
		handleSelectShip(name, price, rateId, type, withInsurance, insuranceRate);
		setShow(false);
	};

	const handleClickedReport = () => {
		setShow(false);
		handleReportShipping();
	};

	const compare = (a: any, b: any) => {
		if (a.final_rate < b.final_rate) {
			return -1;
		}
		if (a.final_rate > b.final_rate) {
			return 1;
		}
		return 0;
	};

	const renderLogisticList = () => {
		const ship = options.filter(
			(option) => option.type === selectedShip.type
		)[0];

		const logistics = ship.logistics.sort(compare);
		return logistics.map((logistic) => {
			const {
				final_rate,
				name,
				rate_id,
				rate_name,
				with_insurance,
				insurance_rate,
			} = logistic;
			return (
				<Option
					onClick={() =>
						handleClickedOption(
							name,
							final_rate,
							rate_id,
							ship.type,
							with_insurance,
							insurance_rate
						)
					}
				>
					<Label>
						{name} {rate_name} (Rp{" "}
						{parseIDR(
							with_insurance ? final_rate - insurance_rate : final_rate
						)}
						)
					</Label>
				</Option>
			);
		});
	};

	const renderLogisticTypes = () => {
		// move INSTANT shipping to first index
		const indexInstantShipping = options.findIndex(
			(shipping) => shipping.type === "instant"
		);
    
		if (indexInstantShipping !== -1) {
			options.splice(0, 0, options[indexInstantShipping]);
			options.splice(indexInstantShipping + 1, 1);
		}

		// move SAME DAY shipping to second index
		const indexSameDayShipping = options.findIndex(
			(shipping) => shipping.type === "same day"
		);
		if (indexSameDayShipping !== -1) {
			options.splice(1, 0, options[indexSameDayShipping]);
			options.splice(indexSameDayShipping + 1, 1);
		}

		return options.map((option) => {
			const { type, logistics } = option;
			if (logistics.length > 0) {
				const ship = SHIPPING_WORDING.filter(
					(shipping) => shipping.key === type
				)[0];

				// auto select first index of shipping
				const { final_rate, name, rate_id, with_insurance, insurance_rate } =
					logistics.sort(compare)[0];
				return (
					<Option
						onClick={() =>
							handleClickedOption(
								name,
								final_rate,
								rate_id,
								type,
								with_insurance,
								insurance_rate
							)
						}
					>
						<Label>{ship.label}</Label>
						<Description>{ship.estimation}</Description>
					</Option>
				);
			}
		});
	};
  
	return (
		// @ts-ignore
		<Container isShow={isShow} onClick={(e) => handleCloseBackground(e)}>
			<GlobalStyle isShow={isShow} />
			<Content>
				<DragLine />
				<Header>
					<HeaderLabel>
						{isSelectType ? WORDING.contact_page.CHOOSE_DELIVERY[language] : WORDING.contact_page.CHOOSE_COURIER[language]}
					</HeaderLabel>
					<ImgClose onClick={() => setShow(false)} src={CloseAsset} />
				</Header>
				{isSelectType && deliveryOptions?.includes("self_shipping") && (
					<Option
						onClick={() =>
							handleClickedOption(
								WORDING.contact_page.DELIVER_BY_MERCHANT[language],
								merchantShip?.price,
								"self_shipping",
								"self_shipping",
								false,
								0
							)
						}
					>
						<Label>
							{WORDING.contact_page.DELIVER_BY_MERCHANT[language]} (Rp. {parseIDR(merchantShip?.price)})
						</Label>
						<Description>{merchantShip?.note}</Description>
					</Option>
				)}
				{isSelectType ? renderLogisticTypes() : renderLogisticList()}

				{/* handle if there is no shipping available */}
				{isSelectType &&
					!deliveryOptions?.includes("self_shipping") &&
					options.length === 0 && (
						<NoShippingErrorContainer>
							<NoShippingErrorLabel>
								{WORDING.contact_page.ADDRESS_OUT_OF_RANGE[language]}
							</NoShippingErrorLabel>
							<NoShippingErrorOption>
								<ButtonSmall
									handleClick={handleClickedReport}
									label={WORDING.general.YES[language]}
									isPositif={true}
								/>
								<ButtonSmall
									handleClick={() => setShow(false)}
									label={WORDING.general.NO[language]}
									isPositif={false}
								/>
							</NoShippingErrorOption>
						</NoShippingErrorContainer>
					)}
			</Content>
		</Container>
	);
};

export default BottomSheetShipOptions;

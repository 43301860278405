import styled from 'styled-components';
import { E_COLOR, E_FONT_WEIGHT } from '../interfaces/dataTypes';

export const Container = styled.div`
  margin: 12px 20px;
`;

export const IconNotFound = styled.img`
  display: block;
  margin: 30px auto;
  width: 285px;
`;

export const Title = styled.div`
  margin-bottom: 8px;
  color: ${E_COLOR.NEW_BLACK};
  font-size: 18px;
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  line-height: 27px;
  text-align: center;
`;

export const Description = styled.div`
  color: ${E_COLOR.NEW_BLACK};
  font-size: 14px;
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  line-height: 21px;
  text-align: center;
`;

export const Footer = styled.div`
  margin: 28px;
  color: ${E_COLOR.NEW_BLACK};
  font-size: 12px;
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  line-height: 21px;
  text-align: center;
`;

export const IconGooglePlay = styled.img`
  display: block;
  margin: 30px auto;
  width: 160px;
`;

import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { E_COLOR, E_FONT_WEIGHT } from '../interfaces/dataTypes';

const Container = styled.div<{ customWidth: string; customMargin: string }>`
  display: flex;
  width: ${({ customWidth }) =>
    customWidth ? customWidth : 'calc(100% - 40px)'};
  max-width: 600px;
  max-height: 150px;
  margin: ${({ customMargin }) => (customMargin ? customMargin : '20px')};
  text-align: center;
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 16px;
  line-height: 25px;
`;

const ButtonNext = styled.div<{
  isDisabled: boolean;
  isPrimary: boolean;
  color?: string;
}>`
  width: 100%;
  padding: 13px 0;
  background-color: ${({ isDisabled, isPrimary, color }) =>
    isDisabled
      ? E_COLOR.NEW_LIGHT_GREY
      : isPrimary
      ? color ? color : E_COLOR.DARK_BLUE
      : E_COLOR.WHITE};
  border-radius: 10px;
  color: ${({ isDisabled, isPrimary, color }) =>
    isDisabled
      ? E_COLOR.GREY_DISABLED
      : isPrimary
      ? E_COLOR.WHITE
      : color ? color : E_COLOR.DARK_BLUE};
  border: 1px solid
    ${({ isDisabled, color }) => (isDisabled ? E_COLOR.LIGHT_GREY : color ? color : E_COLOR.DARK_BLUE)};
`;

interface IProps {
  isPrimary: boolean;
  isDisabled: boolean;
  children: ReactNode;
  onClick: () => void;
  id?: string;
  customWidth?: string;
  customMargin?: string;
  hexPrimary: string;
}

const ButtonFull = ({
  isPrimary,
  isDisabled,
  children,
  onClick,
  id,
  customWidth,
  customMargin,
  hexPrimary,
}: IProps) => {
  const handlClickButton = () => {
    onClick();
  };
  
  return (
    <Container
      customWidth={customWidth || ''}
      customMargin={customMargin || ''}
    >
      <ButtonNext
        color={hexPrimary}
        isDisabled={isDisabled}
        onClick={isDisabled ? () => {} : handlClickButton}
        isPrimary={isPrimary}
        id={id}
      >
        {children}
      </ButtonNext>
    </Container>
  );
};

export default ButtonFull;

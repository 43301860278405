// /// <reference no-default-lib="true"/>
// import React, { useEffect, useState } from 'react';
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
// } from 'react-google-maps';
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   fgetLatLng,
// } from 'react-places-autocomplete';
// import Geocode from 'react-geocode';
// import { GOOGLE_API_KEY, MONAS_LAT, MONAS_LNG } from '../constants';

// interface IProps {
//   setNewCoordinates: ({ lat: number, lng: number }) => void;
//   initialCoordinates: { lat: number; lng: number };
//   setAddressText: (lat: string, lng: string) => void;
// }

// Geocode.setApiKey(GOOGLE_API_KEY);

// const Maps = ({
//   initialCoordinates,
//   setNewCoordinates,
//   setAddressText,
// }: IProps) => {
//   const [coordinates, setCoordinates] = useState({
//     lat: parseFloat(initialCoordinates.lat ? initialCoordinates.lat : MONAS_LAT),
//     lng: parseFloat(initialCoordinates.lng ? initialCoordinates.lng : MONAS_LNG),
//   });

//   const MapWithAMarker = withScriptjs(
//     withGoogleMap((props) => (
//       <GoogleMap
//         defaultZoom={15}
//         defaultCenter={coordinates ? coordinates : initialCoordinates}
//         options={{ streetViewControl: false, mapTypeControl: false, fullscreenControl: false, zoomControl: false }}
//       >
//         <Marker
//           draggable={true}
//           onDragEnd={handleDragEnd}
//           position={coordinates ? coordinates : initialCoordinates}
//         />
//       </GoogleMap>
//     ))
//   );

//   const handleDragEnd = (event) => {
//     const newLat = event.latLng.lat();
//     const newLng = event.latLng.lng();
//     setAddressText(newLat, newLng);
//     setCoordinates({ lat: newLat, lng: newLng });
//     setNewCoordinates({ lat: newLat, lng: newLng });
//   };

//   useEffect(() => {
//     setCoordinates({
//       lat: parseFloat(initialCoordinates.lat),
//       lng: parseFloat(initialCoordinates.lng),
//     });
//   }, [initialCoordinates]);

//   return (
//     <MapWithAMarker
//       googleMapURL={
//         `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`
//       }
//       loadingElement={<div style={{ height: `100%` }} />}
//       containerElement={<div style={{ height: `100vh`, width: `100vw` }} />}
//       mapElement={<div style={{ height: `100%` }} />}
//     />
//   );
// };

// export default Maps;

import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import Geocode from 'react-geocode';
import { GOOGLE_API_KEY, MONAS_LAT, MONAS_LNG } from '../constants';

Geocode.setApiKey(GOOGLE_API_KEY);

const containerStyle = {
  width: "100vw",
  maxWidth: "600px",
  height: "100vh",
}

interface IProps {
  setNewCoordinates: ({ lat, lng }) => void;
  initialCoordinates: { lat: number, lng: number };
  setAddressText: (lat: string, lng: string) => void;
}

const Maps = ({ setNewCoordinates, initialCoordinates, setAddressText }: IProps) => {
  const [coordinates, setCoordinates] = useState({
        lat: initialCoordinates.lat ? initialCoordinates.lat : MONAS_LAT,
        lng: initialCoordinates.lng ? initialCoordinates.lng : MONAS_LNG,
      });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY,
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // @ts-ignore
    const bounds = new window.google.maps.LatLngBounds(initialCoordinates);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const handleDragEnd = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    setAddressText(newLat, newLng);
    setCoordinates({ lat: newLat, lng: newLng });
    setNewCoordinates({ lat: newLat, lng: newLng });
  };

  useEffect(() => {
    setCoordinates({
      lat: initialCoordinates.lat,
      lng: initialCoordinates.lng,
    });
  }, [initialCoordinates]);
      

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={coordinates ? coordinates : initialCoordinates}
      zoom={7}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{ streetViewControl: false, mapTypeControl: false, fullscreenControl: false, zoomControl: false }}
    >
      <Marker
          draggable={true}
          onDragEnd={handleDragEnd}
          position={coordinates ? coordinates : initialCoordinates}
        />
    </GoogleMap>
) : <></>
}

export default React.memo(Maps);
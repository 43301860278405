import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { IPathParams, IResTracking } from "../interfaces";
import { getTracking } from "../services/Order";

export const useTrackingOrderHook = () => {
  const INITIAL_DATA_TRACKING: IResTracking = {
    awb: "",
    courier: {},
    date: "",
    destination: {},
    driver: {},
    origin: {},
    recipient: {},
    seller: {},
    track: []
  } 
  const [data, setData] = useState(INITIAL_DATA_TRACKING);
	const params: IPathParams = useParams();
	const history = useHistory();
	const { orderSN } = params;
  
  const fetchTrackingData = async () => {
		const response = await getTracking(orderSN);
    const { detail, tracking } = response;
    const { activeDate, consignee, consigner, courier, awbNumber, destination, driver, origin } = detail;
    const tempData: IResTracking = {
      awb: awbNumber,
      courier,
      date: activeDate,
      destination,
      driver,
      origin,
      recipient: consignee,
      seller: consigner,
      track: tracking.reverse(),
    }
    
		setData(tempData);
	};

  const backToSummaryPage = () => {
    history.push(`/order_sn/${orderSN}`);
  }

	useEffect(() => {
		// Prevent robot tracking
		// @ts-ignore
		document.querySelector('meta[name="robots"]').setAttribute("content", "noindex");

    fetchTrackingData();
	}, []);

	return {
    data,
    backToSummaryPage,
	};
};

import { E_PAGE_PATHS } from "./../interfaces/dataTypes";
export const ORDER_STEPS: {
	number: number;
	label: string;
	path: string;
	percentageBar: string;
}[] = [
	{
		number: 1,
		label: "Tanggal",
		path: E_PAGE_PATHS.DATE,
		percentageBar: "29",
	},
	{
		number: 2,
		label: "Produk",
		path: E_PAGE_PATHS.ITEMS,
		percentageBar: "50",
	},
	{
		number: 3,
		label: "Pembeli",
		path: E_PAGE_PATHS.CONTACT,
		percentageBar: "72",
	},
	{
		number: 4,
		label: "Ulasan",
		path: E_PAGE_PATHS.REVIEW,
		percentageBar: "93",
	},
];

export const SHIPPING_WORDING = [
	{
		estimation: "Estimasi tiba hari ini",
		label: "Same Day",
		key: "same day",
	},
	{
		estimation: "Estimasi tiba 1-2 jam",
		label: "Instant",
		key: "instant",
	},
	{
		estimation: "Estimasi tiba 1-2 jam",
		label: "Express",
		key: "express",
	},
	{
		estimation: "Estimasi tiba 2-7 hari",
		label: "Reguler",
		key: "regular",
	},
	{
		estimation: "Estimasi tiba 3-8 hari",
		label: "Trucking",
		key: "trucking",
	},
	{
		estimation: "Estimasi tiba 3-8 hari",
		label: "Merchant",
		key: "self_shipping",
	},
];

export const ERR_VALIDATION_TYPE = {
	INVALID_PRICE: "invalid_price",
	OUT_OF_STOCK: "out_of_stock",
	MISSING_PRODUCT: "missing_product",
	MISSING_VARIANT: "missing_variant",
	MISSING_VARIANT_MANDATORY: "missing_variant_mandatory",
};

export const ERR_VALIDATION_WORDING = {
	OUT_OF_STOCK_PRODUCT: "Item habis, hapus item",
	OUT_OF_STOCK_VARIANT: "Item varian habis, opsi telah berubah",
	OUT_OF_STOCK_VARIANT_MANDATORY: "Item varian wajib habis, hapus item",
	STOCK_INSUFFICIENT_PRODUCT: "Item berkurang, hanya tersedia {available}",
	STOCK_INSUFFICIENT_VARIANT:
		"Item varian berkurang, hanya tersedia {available}",
	PRICE_INCREASE: "Harga mengalami kenaikan",
	PRICE_DECRESE: "Harga mengalami penurunan",
	PRICE_VARIANT_INCREASE: "Harga varian mengalami kenaikan",
	PRICE_VARIANT_DECRESE: "Harga varian mengalami penuruan",
};

// CONSTANTS
export const TOKEN_LIFE_TIME = 3600000; // 1 hour
export const GOOGLE_API_KEY = process.env.REACT_APP_API_KEY;
export const MONAS_LAT = -6.175392;
export const MONAS_LNG = 106.827153;
export const PLATFORM_FEE = 200;

// LOCAL STORAGE CONSTANTS
export const AUTO_FILL_CONTACT = "AUTO_FILL_CONTACT"; // contact information from latest submitted order
export const LS_LANGUAGE = "__OP_LANG";

// URL PARAMS CONSTANTS
export const URL_PARAM_SOURCE = "source";
export const URL_PARAM_FOOD_COURT = "foodCourt";
export const URL_PARAM_EVENT_TYPE = "event_type";
export const URL_PARAM_IS_REDIRECT = "is_redirect";
export const URL_PARAM_TABLE_NUMBER = "table";
export const URL_VALUE_EVENT_FOOD_FESTIVAL = "food_festival";
export const URL_PARAM_RESERVATION = "reserve";
export const URL_VALUE_RESERVATION = "true";
export const URL_PARAM_QRIS_PAYMENT = "payment";
export const URL_PARAM_ORDER_SN = "invoice";
export const URL_PARAM_QRIS_EXPIRED = "expired";
export const URL_PARAM_QRIS_AMOUNT = "amount";
export const URL_PARAM_QRIS_OTHER_PAYMENT_TYPE = "other_payment_type";
export const URL_VALUE_SOURCE_QR_TABLE = "qr-code-table";
export const URL_VALUE_SOURCE_PICK_UP = "pick-up";
export const URL_PARAM_CHANGE_PAYMENT_METHOD = "change_method";
export const URL_PARAM_HIDE_OTHER_OUTLET = "single_outlet";
export const URL_PARAM_FOODCOURT_MERCHANT = "merchant";
export const URL_VALUE_HIDE_OTHER_OUTLET = "true";

// Halo, saya ingin bertanya tentang pesanan No. OPXXXXX2ID1631517533600 untuk Jojo pengiriman tanggal 18-9-2021 https://test.opaper.app/order_sn/OPXXXXX2ID1631517533600
export const ASK_MERCHANT_ORDER_WORDING =
	"https://wa.me/{shopPhone}?text=Halo,%20saya%20ingin%20bertanya%20tentang%20pesanan%20No.%20{orderSN}%20untuk%20{buyerName}%20{shippingType}%20tanggal%20{shippingDate}%20{link}";
// Halo Shop, saya ingin bertanya
export const ASK_MERCHANT_WORDING =
	"https://wa.me/{shopPhone}?text=Halo%20{shopName},%20saya%20ingin%20bertanya";
export const REPORT_ISSUE_WORDING =
	"https://wa.me/6282111118872?text=Halo,%20saya%20mengalami%20kendala%20teknis%20saat%20mau%20order%20dari%20toko%20{shopName}.%0dIni%20info%20pembelian%20saya:%0dPembeli:%20{buyerName}%0dNomor%20Telpon:%20{buyerPhone}%0dPenerima:%20{recipientName}%0dNomor%20Telpon:%20{recipientPhone}%0dAlamat:%20{address}%0dOpsi%20Pengiriman:%20{shipping}%0dVoucher:%20{voucher}%0dLogged%20In:%20{isLoggedIn}";
export const REPORT_NO_SHIP_AVAILABLE_WORDING =
	"https://wa.me/6282111118872?text=Halo,%20saya%20mau%20pesan%20dari%20toko%20{shopName}%20dan%20mau%20memberi%20saran%20untuk%20memperluas%20jangkauan%20pengiriman%20ke:%0d{address}";
export const INVOICE_TO_BUYER =
	"https://api.whatsapp.com/send?text=Di%20bawah%20adalah%20link%20faktur%20dari%C2%A0{shopName}%C2%A0untuk%20ORDER%20NO%3A%20{orderSN}%20sebesar%C2%A0Rp%20{total}%0A%0AUntuk%20mengkonfirmasi%20pesanan%2C%20harap%20kirimkan%20pembayaran%20dengan%20jalur%20aman%20lewat%20Xendit%20dengan%20mengklik%20tombol%20%22Pilih%20Metode%20Pembayaran%22%20di%20faktur%20ini%C2%A0%C2%A0%0A%0A{linkInvoice}%0A%0AApabila%20ada%20pertanyaan%20lebih%20lanjut%2C%20mohon%20hubungi%20{shopName}%20langsung%20di%C2%A0https%3A%2F%2Fwa.me%2F{shopPhone}";
export const REPORT_ISSUE_RESERVATION =
	"https://wa.me/6282111118872?text=Halo,%20saya%20mengalami%20kendala%20teknis%20saat%20reservasi";

// URLS
const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_CATEGORY_BUYER = "/buyer";
const API_CATEGORY_ORDER = "/order";
const API_CATEGORY_AUTH = "/auth";
const API_CATEGORY_PRODUCT = "/product";
const API_VERSION_1 = "/v1";
const API_VERSION_2 = "/v2";
export const URL_SHOP_INFO = `${BASE_URL}${API_CATEGORY_BUYER}${API_VERSION_1}/shop/:shopLink`;
export const URL_STOCK_LIST = `${BASE_URL}${API_CATEGORY_BUYER}${API_VERSION_1}/stocks/:shopId?`;
export const URL_STOCK_LIST_WITH_CATEGORY = `${BASE_URL}${API_CATEGORY_BUYER}${API_VERSION_1}/stocks-categorized/:shopId?`;
export const URL_SHIPPING_INFO = `${BASE_URL}${API_CATEGORY_BUYER}${API_VERSION_1}/rates`;
export const URL_ORDER = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}`;
export const URL_ORDER_SN = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/:orderSN`;
export const URL_CHECK_PAYMENT_SN = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/get-other-payment-data`;
export const URL_ORDER_STATUS = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/status`;
export const URL_USER_PREFERENCE = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/buyer-preferences`;
export const URL_SEND_WA_INVOICE = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/send-whatsapp/:orderSN`;
export const URL_PROMOTION = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/buyer-promotion/shop/:shopId`;
export const URL_PROMOTION_BY_CODE = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/buyer-promotion/code/:code/shop/:shopId`;
export const URL_CHANGE_PAYMENT_METHOD = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/change-payment`;
export const URL_TRACKING = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}${API_CATEGORY_ORDER}/:orderSN/shipping`;
export const URL_RATING_FORM = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/get-rating/:ratingHash`;
export const URL_RATING_SUBMIT = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/rating/submit`;
export const URL_FOOD_COURT_INFO = `${BASE_URL}${API_CATEGORY_BUYER}${API_VERSION_1}/food-court/:foodCourtName`;
export const URL_RESERVATION = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/shop/:shopId/reservations`;
export const URL_COIN_BALANCE = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/get-coin-balance/:shopId`;
export const URL_COIN_CHECK = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/is-have-coin`;
export const URL_OTP = `${BASE_URL}${API_CATEGORY_AUTH}${API_VERSION_1}/send-buyer-otp`;
export const URL_VALIDATE_OTP = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/validate-buyer-otp`;
export const URL_OPEN_BILL = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/open-bill-web`;
export const URL_CLOSE_BILL = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_2}/close-bill-web`;
export const URL_VALIDATE_ORDER = `${BASE_URL}${API_CATEGORY_PRODUCT}${API_VERSION_2}/validate-prices`;
export const URL_FOODCOURT_MERCHANT_LIST = `${BASE_URL}/shop${API_VERSION_1}/foodcourts/:foodcourtId/tenants`;
export const URL_EDIT_CONTACT = `${BASE_URL}${API_CATEGORY_ORDER}${API_VERSION_1}/edit-order-recipient`;

export const SHOP_URI_FOR_POPUP = ["ptdi"];
export const SHOP_ID_FOR_POPUP = [41075];
export const SHOP_ID_FOR_EXCLUDE_TAKEAWAY = [39729];
export const SHOP_LINK_FOOD_EVENT_AS_FOOD_COURT = ["mcc_gastropreneur", "jojo_fc"];

// FIREBASE CONFIG
export const firebaseConfig = {
	apiKey: "AIzaSyDpTMHWMekAmQtBS0PBl3j6CZKUlaAzhdw",
	authDomain: "opaper-nonprod.firebaseapp.com",
	projectId: "opaper-nonprod",
	storageBucket: "opaper-nonprod.appspot.com",
	messagingSenderId: "561796361012",
	appId: "1:561796361012:web:d8bda8c0028757bf2ad361",
	measurementId: "G-6J06728RGY",
};

import {
	URL_COIN_BALANCE,
	URL_COIN_CHECK,
	URL_FOODCOURT_MERCHANT_LIST,
	URL_FOOD_COURT_INFO,
	URL_OTP,
	URL_PROMOTION,
	URL_PROMOTION_BY_CODE,
	URL_RATING_FORM,
	URL_RATING_SUBMIT,
	URL_RESERVATION,
	URL_SHOP_INFO,
	URL_STOCK_LIST,
	URL_STOCK_LIST_WITH_CATEGORY,
	URL_VALIDATE_OTP,
} from "./../constants/index";
import { getData, postData } from ".";
import {
	IReqCoinBalance,
	IReqLoyaltyStatus,
	IReqOtp,
	IReqRating,
	IReqReservation,
} from "../interfaces";

export const getShopInfo = async (shopLink: string) => {
	try {
		const url = URL_SHOP_INFO.replace(":shopLink", shopLink);
		const response = await getData(url);

		return {
			...response.data,
		};
	} catch {
		console.log("error when fetching shop info.");
		return { error: "error when fetching shop info0000." };
	}
};

export const getStockList = async (shopId: number, params: string) => {
	try {
		const url = URL_STOCK_LIST.replace(":shopId", `${shopId}`);
		const response = await getData(url, params);

		return response.data.stock_by_date;
	} catch {
		console.log("error when fetching stock list.");

		return {
			data: {
				stock_by_date: [],
			},
		};
	}
};

export const getStockCategorizedList = async (
	shopId: number,
	params: string
) => {
	try {
		const url = URL_STOCK_LIST_WITH_CATEGORY.replace(":shopId", `${shopId}`);
		const response = await getData(url, params);

		return response.data.stock_by_date;
	} catch {
		console.log("error when fetching stock list.");

		return {
			data: {
				stock_by_date: [],
			},
		};
	}
};

export const getPromotionVoucher = async (
	shopId: number,
	payload: { phone_number: string; phone_country: string }
) => {
	try {
		const url = URL_PROMOTION.replace(":shopId", `${shopId}`);
		const response = await postData(url, payload);

		if (response?.data?.promotions) {
			return response.data.promotions;
		} else {
			return [];
		}
	} catch {
		console.log("error when fetching promotion list.");
	}
};

export const getPromotionVoucherByCode = async (
	shopId: number,
	code: string,
	payload: { phone_number: string; phone_country: string }
) => {
	try {
		const url = URL_PROMOTION_BY_CODE.replace(":shopId", `${shopId}`).replace(
			":code",
			code
		);
		const response = await postData(url, payload);

		if (response.code === 500) {
			return response.err_msg;
		} else {
			return response.data;
		}
	} catch {
		console.log("error when fetching promotion by code list.");
	}
};

export const getRatingForm = async (ratingHash: string) => {
	try {
		const url = URL_RATING_FORM.replace(":ratingHash", ratingHash);
		const response = await getData(url);

		return {
			...response.data,
		};
	} catch {
		console.log("error when fetching rating form.");
		return { error: "error when fetching rating form." };
	}
};

export const postRatingForm = async (payload: IReqRating) => {
	try {
		const url = URL_RATING_SUBMIT;
		const response = await postData(url, payload);

		return response.data;
	} catch {
		console.log("error when post rating.");
	}
};

export const getFoodEventInfo = async (foodCourtName: string) => {
	try {
		const url = URL_FOOD_COURT_INFO.replace(":foodCourtName", foodCourtName);
		const response = await getData(url);

		return {
			...response.data,
		};
	} catch {
		console.log("error when fetching shop info.");
		return { error: "error when fetching shop info0000." };
	}
};

export const postReservation = async (
	payload: IReqReservation,
	shopId: string
) => {
	try {
		const url = URL_RESERVATION.replace(":shopId", shopId);
		const response = await postData(url, payload);

		return response.data;
	} catch {
		console.log("error when post rating.");
	}
};

export const getLoyaltyStatus = async (payload: IReqLoyaltyStatus) => {
	try {
		const url = URL_COIN_CHECK;
		const response = await postData(url, payload);

		return response.data;
	} catch {
		console.log("error when post rating.");
	}
};

export const getCoinBalance = async (
	shopId: number,
	payload: IReqCoinBalance
) => {
	try {
		const url = URL_COIN_BALANCE.replace(":shopId", `${shopId}`);
		const response = await postData(url, payload);

		return response;
	} catch {
		console.log("error when post rating.");
	}
};

export const getOTP = async (payload: IReqOtp) => {
	try {
		const url = URL_OTP;
		const response = await postData(url, payload);

		return response;
	} catch {
		console.log("error when post rating.");
	}
};

export const postValidateOTP = async (payload: IReqCoinBalance) => {
	try {
		const url = URL_VALIDATE_OTP;
		const response = await postData(url, payload);

		return response;
	} catch {
		console.log("error when post rating.");
	}
};

export const getFoodcourtMerchantList = async (foodcourtId: string) => {
	try {
		const url = URL_FOODCOURT_MERCHANT_LIST.replace(":foodcourtId", foodcourtId);
		// const url = "https://mocki.io/v1/04609d4f-e00b-4659-9f2c-533a57c7239b";
		const response = await getData(url);
    
		return response.data;
	} catch {
		console.log("error when fetching merchant list.");
		return { error: "error when fetching merchant list." };
	}
};

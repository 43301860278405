/// <reference no-default-lib="true"/>
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  E_ACTION,
  E_COLOR,
  E_FONT_WEIGHT,
  E_OTP_STATE,
} from '../interfaces/dataTypes';
import CloseAsset from '../assets/close.png';
import PhoneInput from 'react-phone-input-2';
import ButtonFull from './ButtonFull';
import * as firebaseAuth from 'firebase/auth';
import * as firebaseApp from 'firebase/app';
import { firebaseConfig } from '../constants';
import { useAppContext } from '../providers/useAppContext';
import PopupMaps from './PopupMaps';
import { WORDING } from '../constants/translate';

const Container = styled.div<{ isShow: boolean }>`
  z-index: 10;
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  position: fixed;
  width: 100vh;
  max-width: 600px;
  background-color: rgba(26, 26, 26, 0.5);
  height: 100vh;
  bottom: 0;
  top: 0;
`;

const Content = styled.div`
  width: 100%;
  padding-bottom: 20px;
  max-width: 600px;
  position: fixed;
  background-color: ${E_COLOR.WHITE};
  overflow: hidden;
  bottom: 0;
  border-radius: 16px 16px 0 0;
`;

const CloseIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const Thumbnail = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
  border-radius: 5px;
  margin: 16px auto;
`;

const Header = styled.div`
  margin: 0 16px 14px;
  color: ${E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 16px;
  line-height: 25px;
`;

const Description = styled.div`
  margin: 4px 16px 20px;
  color: ${E_COLOR.LIGHT_BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 14px;
  line-height: 21px;
`;

// const OtpCodeInput = styled.input`
//   display: block;
//   margin: 0 auto;
//   letter-spacing: 30px;
//   border: 0;
//   background-image: linear-gradient(
//     to left,
//     black 70%,
//     rgba(255, 255, 255, 0) 0%
//   );
//   background-position: bottom;
//   background-size: 50px 1px;
//   background-repeat: repeat-x;
//   background-position-x: 35px;
//   width: 220px;
//   outline: none;
// `;

const OtpCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 43px 58px;
`;

const OtpCodeInput = styled.input`
  color: ${E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  border-width: 0 0 2px;
  border-color: ${E_COLOR.GREY_DISABLED};
  width: 26px;
  margin: 0 10px;
  outline: none;
  -webkit-appearance: none;
`;

const ErrorLabel = styled.div<{ clickable: boolean }>`
  margin: 8px 16px;
  color: ${E_COLOR.RED};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 12px;
  line-height: 19px;
  text-align: center;
`;
const CountdownSendOtpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 90px;
`;

const CountdownSendOtpLabel = styled.div<{ clickable: boolean }>`
  color: ${({ clickable }) =>
    clickable ? E_COLOR.DARK_BLUE : E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 12px;
  line-height: 19px;
  padding-bottom: 12px;
`;

const CountdownSendOtpTime = styled.div`
  margin-left: 5px;
  color: ${E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 12px;
  line-height: 19px;
`;

interface IProps {
  isShow: "y" | "n" | "exchangeCoin" | "submitOpenBill";
  setShow: (isShow: "y" | "n" | "exchangeCoin" | "submitOpenBill") => void;
  phone: string;
  resendOTP: (method: "sms" | "whatsapp") => void;
  setShowExchangeCoinBottomSheet: (isShow: boolean) => void;
  setOtpCode: (otpCode: string) => void;
  checkOtpValid: (otpCode: string, isFetchCoinBalance: boolean) => void;
  handleSubmit?: () => void;
  otpCode: string;
  language: "id" | "en";
}

const BottomSheetOTP = ({
  isShow,
  setShow,
  phone,
  resendOTP,
  setOtpCode,
  setShowExchangeCoinBottomSheet,
  checkOtpValid,
  otpCode,
  language,
  handleSubmit,
}: IProps) => {
  const [input, setInput] = useState(['', '', '', '', '', '']);
  let input1Ref = useRef(null);
  let input2Ref = useRef(null);
  let input3Ref = useRef(null);
  let input4Ref = useRef(null);
  let input5Ref = useRef(null);
  let input6Ref = useRef(null);
  const [otpMethod, setOtpMethod] = useState('whatsapp');
  const [countdown, setCountdown] = useState(0);
  const [otpState, setOtpState] = useState(E_OTP_STATE.ENTER_OTP_CODE);
  const [isResendCode, setIsResendCode] = useState(false);
  const [isError, setIsError] = useState(false);
  const { dispatch, state } = useAppContext();
  let intervalRef = useRef();
  let confirmRes = null;

  const decreaseCountdown = () => {
    if (otpState === E_OTP_STATE.ENTER_OTP_CODE) {
      setCountdown((countdown) => countdown - 1);
    }
  };

  useEffect(() => {
    if (otpState === E_OTP_STATE.ENTER_OTP_CODE) {
      if (countdown > 0) {
        intervalRef.current = setInterval(decreaseCountdown, 1000);
        return () => clearInterval(intervalRef.current);
      } else {
        clearInterval(intervalRef.current);
      }
    }
  }, [countdown, otpState]);

  useEffect(() => {
      let timeoutId;

      if (isError) {
          setInput(['', '', '', '', '', '']);
          input1Ref.current.focus();
          timeoutId = setTimeout(() => {
            setIsError(false);
          }, 3000);
      }

      return () => timeoutId && clearTimeout(timeoutId);
  }, [isError]);

  const handleResendOtp = (method: "sms" | "whatsapp") => {
    setCountdown(30);
    setOtpMethod(method);
    resendOTP(method);
  }

  const handleOTPChange = async (newInputChar: string) => {
    const focusId = document.activeElement.id;

    const newInput = [...input];
    newInput[focusId] = newInputChar;
    setInput(newInput);
    if (newInputChar === '') {
      if (focusId === '5') {
        input5Ref.current.focus();
      } else if (focusId === '4') {
        input4Ref.current.focus();
      } else if (focusId === '3') {
        input3Ref.current.focus();
      } else if (focusId === '2') {
        input2Ref.current.focus();
      } else if (focusId === '1') {
        input1Ref.current.focus();
      }
    } else {
      if (focusId === '0') {
        input2Ref.current.focus();
      } else if (focusId === '1') {
        input3Ref.current.focus();
      } else if (focusId === '2') {
        input4Ref.current.focus();
      } else if (focusId === '3') {
        input5Ref.current.focus();
      } else if (focusId === '4') {
        input6Ref.current.focus();
      } else if (focusId === '5') {
        let formattedOtpCode = '';
        input.forEach((inputChar) => (formattedOtpCode = `${formattedOtpCode}${inputChar}`));
        const valid = await checkOtpValid(`${formattedOtpCode}${newInputChar}`, true);
        setIsError(!valid);
        if(valid) {
          setShow(false);
          if(otpCode === "") {
            if(isShow === "exchangeCoin") {
              setShowExchangeCoinBottomSheet(true);
            } else if(isShow === "submitOpenBill") {
              handleSubmit(`${formattedOtpCode}${newInputChar}`);
            }
          }
          setOtpCode(`${formattedOtpCode}${newInputChar}`);
          setInput(['', '', '', '', '', '']);
        }
      }
    }
  };

  const handleOnKeyUp = (e) => {
    const focusId = document.activeElement.id;
    const keyCode = e.keyCode;
    if (input[focusId] === '' && keyCode === 8) {
      if (focusId === '5') {
        input5Ref.current.focus();
      } else if (focusId === '4') {
        input4Ref.current.focus();
      } else if (focusId === '3') {
        input3Ref.current.focus();
      } else if (focusId === '2') {
        input2Ref.current.focus();
      } else if (focusId === '1') {
        input1Ref.current.focus();
      }
    } else if (input[focusId] !== '' && keyCode !== 8) {
      if (focusId === '0') {
        input2Ref.current.focus();
      } else if (focusId === '1') {
        input3Ref.current.focus();
      } else if (focusId === '2') {
        input4Ref.current.focus();
      } else if (focusId === '3') {
        input5Ref.current.focus();
      } else if (focusId === '4') {
        input6Ref.current.focus();
      }
    }
  };

  // const sendOtpCode = (isResend: boolean) => {
  //   setIsError(false);
  //   dispatch({
  //     type: E_ACTION.SET_LOADING,
  //     payload: true,
  //   });

  //   if (isResend) {
  //     setIsResendCode(true);
  //     firebaseApp.initializeApp(firebaseConfig);
  //     const auth = firebaseAuth.getAuth();
  //     const phoneNumber = `+${phone}`;
  //     // @ts-ignore
  //     window.recaptchaVerifier = new firebaseAuth.RecaptchaVerifier(
  //       'resend-button',
  //       {
  //         size: 'invisible',
  //         callback: (response: any) => {
  //           // reCAPTCHA solved, allow signInWithPhoneNumber.
  //         },
  //       },
  //       auth
  //     );
  //     // @ts-ignore
  //     const appVerifier = window.recaptchaVerifier;
  //     firebaseAuth
  //       .signInWithPhoneNumber(auth, phoneNumber, appVerifier)
  //       .then((confirmationResult) => {
  //         setOtpState(E_OTP_STATE.ENTER_OTP_CODE);
  //         window.confirmationResult = confirmationResult;
  //         dispatch({
  //           type: E_ACTION.SET_LOADING,
  //           payload: false,
  //         });
  //       })
  //       .catch((error) => {
  //         console.log('err', error);
  //         setIsError(true);
  //         dispatch({
  //           type: E_ACTION.SET_LOADING,
  //           payload: false,
  //         });
  //       });
  //   } else {
  //     firebaseApp.initializeApp(firebaseConfig);
  //     const auth = firebaseAuth.getAuth();
  //     const phoneNumber = `+${phone}`;
  //     // @ts-ignore
  //     window.recaptchaVerifier = new firebaseAuth.RecaptchaVerifier(
  //       'sign-in-button',
  //       {
  //         size: 'invisible',
  //         callback: (response: any) => {
  //           // reCAPTCHA solved, allow signInWithPhoneNumber.
  //         },
  //       },
  //       auth
  //     );
  //     // @ts-ignore
  //     const appVerifier = window.recaptchaVerifier;
  //     firebaseAuth
  //       .signInWithPhoneNumber(auth, phoneNumber, appVerifier)
  //       .then((confirmationResult) => {
  //         setOtpState(E_OTP_STATE.ENTER_OTP_CODE);
  //         window.confirmationResult = confirmationResult;
  //         dispatch({
  //           type: E_ACTION.SET_LOADING,
  //           payload: false,
  //         });
  //       })
  //       .catch((error) => {
  //         setIsError(true);
  //         dispatch({
  //           type: E_ACTION.SET_LOADING,
  //           payload: false,
  //         });
  //       });
  //   }
  // };

  // const gatherOtpCode = () => {
  //   setIsError(false);
  //   dispatch({
  //     type: E_ACTION.SET_LOADING,
  //     payload: true,
  //   });
  //   const confirmationResult = window.confirmationResult;
  //   let otpCode = '';
  //   input.forEach((inputChar) => (otpCode = `${otpCode}${inputChar}`));

  //   confirmationResult
  //     .confirm(otpCode)
  //     .then((result: any) => {
  //       // User signed in successfully.
  //       const user = result.user;
  //       localStorage.setItem('token', user.accessToken);
  //       localStorage.setItem('receiveTokenAt', new Date().getTime());
  //       fetchLoginInfo(user.accessToken);
  //       // ...
  //       dispatch({
  //         type: E_ACTION.SET_LOADING,
  //         payload: false,
  //       });
  //       setShow(false);
  //     })
  //     .catch((error: any) => {
  //       console.log('err', error);
  //       // User couldn't sign in (bad verification code?)
  //       // ...
  //       dispatch({
  //         type: E_ACTION.SET_LOADING,
  //         payload: false,
  //       });
  //       setIsError(true);
  //     });
  // };

  return (
    <Container isShow={isShow === "y" || isShow === "exchangeCoin" || isShow === "submitOpenBill" ? true : false}>
      {otpState === E_OTP_STATE.ENTER_PHONE_NUMBER ? (
        <Content>
          <Thumbnail>
            <CloseIcon src={CloseAsset} onClick={() => setShow(false)} />
          </Thumbnail>
          <Header>Masuk ke Opaper</Header>
          <Description>
            Supaya kedepannya Anda tidak perlu masukan detail Anda lagi.
          </Description>
          <Description>Nomor HP</Description>
          <PhoneInput
            country={'id'}
            value={phone}
            placeholder='Nomor Telepon'
            type='number'
            onChange={(input) => setPhone(input)}
            inputStyle={{
              backgroundColor: E_COLOR.NEW_GREY,
              height: '50px',
              width: '100%',
              paddingLeft: '60px',
              border: `1px solid ${E_COLOR.NEW_GREY}`,
              fontSize: '16px',
            }}
            buttonStyle={{
              backgroundColor: E_COLOR.WHITE,
              marginRight: '10px',
              padding: '0 4px',
              border: `1px solid ${E_COLOR.NEW_GREY}`,
            }}
            containerStyle={{
              margin: '0 16px 30px',
              width: 'calc(100% - 32px)',
            }}
          />
          {isError ? (
            <ErrorLabel>
              Terjadi kesalahan. Mohon kembali lagi nanti.
            </ErrorLabel>
          ) : (
            <ButtonFull
              isPrimary
              isDisabled={false}
              onClick={() => sendOtpCode(false)}
              id='sign-in-button'
            >
              Masuk
            </ButtonFull>
          )}
        </Content>
      ) : (
        <Content>
          <Thumbnail>
            <CloseIcon src={CloseAsset} onClick={() => setShow(false)} />
          </Thumbnail>
          <Header>{WORDING.review_page.INPUT_OTP_CODE[language]}</Header>
          <Description>
            {WORDING.review_page.PLEASE_CHECK_OTP_MESSAGE[language].replace('{method}', otpMethod === 'whatsapp' ? 'WhatsApp' : 'SMS')}{' '}
            +{phone?.charAt(0) === "+" ? phone?.substring(1, phone.length - 3) : phone?.substring(0, phone.length - 3)}***
          </Description>
          <OtpCodeWrapper>
            <OtpCodeInput
              autoComplete='off'
              maxLength={1}
              onChange={(e) => handleOTPChange(e.target.value)}
              value={input[0]}
              ref={input1Ref}
              id='0'
              onKeyUp={(e) => handleOnKeyUp(e)}
              type='tel'
              onfocus='this.value = this.value;'
            />
            <OtpCodeInput
              autoComplete='off'
              maxLength={1}
              onChange={(e) => handleOTPChange(e.target.value)}
              ref={input2Ref}
              value={input[1]}
              id='1'
              onKeyUp={(e) => handleOnKeyUp(e)}
              type='tel'
              onfocus='this.value = this.value;'
            />
            <OtpCodeInput
              autoComplete='off'
              maxLength={1}
              onChange={(e) => handleOTPChange(e.target.value)}
              ref={input3Ref}
              value={input[2]}
              id='2'
              onKeyUp={(e) => handleOnKeyUp(e)}
              type='tel'
              onfocus='this.value = this.value;'
            />
            <OtpCodeInput
              autoComplete='off'
              maxLength={1}
              onChange={(e) => handleOTPChange(e.target.value)}
              ref={input4Ref}
              value={input[3]}
              id='3'
              onKeyUp={(e) => handleOnKeyUp(e)}
              type='tel'
              onfocus='this.value = this.value;'
            />
            <OtpCodeInput
              autoComplete='off'
              maxLength={1}
              onChange={(e) => handleOTPChange(e.target.value)}
              ref={input5Ref}
              value={input[4]}
              id='4'
              onKeyUp={(e) => handleOnKeyUp(e)}
              type='tel'
              onfocus='this.value = this.value;'
            />
            <OtpCodeInput
              autoComplete='off'
              maxLength={1}
              onChange={(e) => handleOTPChange(e.target.value)}
              ref={input6Ref}
              value={input[5]}
              id='5'
              onKeyUp={(e) => handleOnKeyUp(e)}
              type='tel'
              onfocus='this.value = this.value;'
            />
          </OtpCodeWrapper>
          {isError && (
            <ErrorLabel>
              {WORDING.review_page.ERR_OTP[language]}
            </ErrorLabel>
          )}
          {countdown > 0 ? (
            <CountdownSendOtpWrapper>
              <CountdownSendOtpLabel clickable={false}>
                {WORDING.review_page.RESEND_CODE_IN[language]}
              </CountdownSendOtpLabel>

              <CountdownSendOtpTime>{countdown} {WORDING.general.SECONDS[language]}</CountdownSendOtpTime>
            </CountdownSendOtpWrapper>
          ) : isResendCode ? (
            <CountdownSendOtpWrapper>
              <CountdownSendOtpLabel clickable={false}>
                Kode telah dikirim ulang.
              </CountdownSendOtpLabel>
            </CountdownSendOtpWrapper>
          ) : (
            <CountdownSendOtpWrapper>
              <CountdownSendOtpLabel
                id='resend-button'
                clickable={true}
                onClick={() => handleResendOtp("sms")}
              >
                {WORDING.review_page.RESEND_VIA[language]} SMS
              </CountdownSendOtpLabel>
              <CountdownSendOtpLabel
                id='resend-button'
                clickable={true}
                onClick={() => handleResendOtp("whatsapp")}
              >
                {WORDING.review_page.RESEND_VIA[language]} WhatsApp
              </CountdownSendOtpLabel>
            </CountdownSendOtpWrapper>
          )}
          {/* <ButtonFull isPrimary isDisabled={false} onClick={() => {}}>
            Masuk ke Opaper
          </ButtonFull> */}
        </Content>
      )}
    </Container>
  );
};

export default BottomSheetOTP;

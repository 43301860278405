import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";
import Geocode from "react-geocode";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";
import Maps from "./Maps";

import BackAsset from "../assets/back.png";
import MagnifyingAsset from "../assets/magnifying.png";
import ButtonFull from "./ButtonFull";
import { IAddress } from "../interfaces";
import { MONAS_LAT, MONAS_LNG } from "../constants";
import { WORDING } from "../constants/translate";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden" : "scroll")};
  }
`;

const Container = styled.div<{ isOpen: boolean }>`
	z-index: 6;
	display: ${(props) => (props.isOpen ? "block" : "none")};
	position: fixed;
	width: 100vh;
	max-width: 600px;
	background-color: rgba(26, 26, 26, 0.5);
	height: 100vh;
	bottom: 0;
	top: 0;
	overflow: hidden;
`;

const Header = styled.div`
	margin-bottom: 15px;
	font-size: 15px;
	font-weight: ${E_FONT_WEIGHT.BOLD};
	color: ${E_COLOR.BLACK};
`;

const Error = styled.div`
	margin-bottom: 15px;
	font-size: 12px;
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	color: ${E_COLOR.RED};
`;

const ButtonsWrapper = styled.div`
	display: flex;
`;

const Button = styled.div<{ isCancel: boolean }>`
	flex-grow: 1;
	width: 100px;
	margin: 8px 10px 0;
	padding: 10px 0;
	background-color: ${({ isCancel }) =>
		isCancel ? E_COLOR.WHITE : E_COLOR.DARK_BLUE};
	border: 1px solid ${E_COLOR.DARK_BLUE};
	border-radius: 7px;
	text-align: center;
	color: ${({ isCancel }) => (isCancel ? E_COLOR.DARK_BLUE : E_COLOR.WHITE)};
	font-weight: ${E_FONT_WEIGHT.BOLD};
`;

const TopBar = styled.div`
	width: calc(100vw - 32px);
	position: absolute;
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 16px;
	z-index: 8;
	max-width: 600px;
`;

const BackContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${E_COLOR.WHITE};
	border-radius: 50%;
	min-width: 50px;
	height: 50px;
	margin-right: 15px;
`;

const BackIcon = styled.img`
	width: 12px;
`;

const SearchBar = styled.div`
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	width: 100%;
	height: 100%;
	background-color: ${E_COLOR.WHITE};
	border: 1px solid ${E_COLOR.BORDER_BLACK};
	border-radius: 15px;
	max-width: 400px;
`;

const MagnifyingIcon = styled.img`
	width: 20px;
	margin-right: 16px;
`;

const SearchInput = styled.input`
	width: 100%;
	outline-width: 0;
	border: 1px solid ${E_COLOR.WHITE};
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;

	::placeholder {
		color: ${E_COLOR.LIGHT_BLACK};
		font-weight: ${E_FONT_WEIGHT.REGULAR};
		font-size: 14px;
		line-height: 21px;
	}
`;

const AddressSuggestion = styled.div`
	left: 0;
	width: 55vw;
	max-width: 400px;
	background-color: ${E_COLOR.WHITE};
	color: ${E_COLOR.GREY};
	border: 1px solid ${E_COLOR.GREY_BORDER};
	padding: 7px 8px;
	font-size: 13px;
`;

const BottomSection = styled.div`
	position: absolute;
	bottom: 0;
	z-index: 8;
	width: 100vw;
	max-width: 600px;
	margin-bottom: 90px;
`;

const SelectedAddressContainer = styled.div`
	background-color: ${E_COLOR.WHITE};
	padding: 14px 11px;
	margin: 0 16px 16px;
	height: 100%;
	color: ${E_COLOR.GREY};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 17px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
`;

const BackgroundMap = styled.div`
	background-color: ${E_COLOR.WHITE};
	width: 100vw;
	height: 100vh;
	z-index: 7;
`;

interface IProps {
	isOpen: boolean;
	handleClose: () => void;
	inputSearchBar: string;
	setInputSearchBar: (inputSearchBar: string) => void;
	currentFillAddress: IAddress;
	setCurrentFillAddress: any;
	language: "id" | "en";
	handleSaveAddress: (lat: number, lng: number, detail: string) => void;
}

const PopupMaps = ({
	isOpen,
	handleClose,
	inputSearchBar,
	setInputSearchBar,
	currentFillAddress,
	setCurrentFillAddress,
	language,
	handleSaveAddress,
}: IProps) => {
	const [temporararyCoordinates, setTemporaryCoordinates] = useState({
		lat: currentFillAddress.lat ? currentFillAddress.lat : MONAS_LAT,
		lng: currentFillAddress.lng ? currentFillAddress.lng : MONAS_LNG,
	});
	const [temporaryAddress, setTemporaryAddress] = useState(
		currentFillAddress.detail
	);

	const handleChooseFinalLocation = async () => {
		// setCurrentFillAddress({
		// 	...currentFillAddress,
		// 	lat: temporararyCoordinates.lat,
		// 	lng: temporararyCoordinates.lng,
		// 	detail: temporaryAddress,
		// });
		handleSaveAddress(temporararyCoordinates.lat, temporararyCoordinates.lng, temporaryAddress);
		handleClose();
	};

	const setAddressText = (lat: string, lng: string) => {
		Geocode.fromLatLng(lat, lng).then(
			(response: any) => {
				const address = response.results[0].formatted_address;
				setTemporaryAddress(address);
				setTemporaryCoordinates({ lat: parseFloat(lat), lng: parseFloat(lng) });
			},
			(error: any) => {
				console.error(error);
			}
		);
	};

	const handleSelectedAutoCompleteAddress = async (value: string) => {
		const results = await geocodeByAddress(value);
		const latLng = await getLatLng(results[0]);

		setTemporaryAddress(value);
		setTemporaryCoordinates(latLng);
	};

	const getUserCurrentLocation = () => {
		const logPosition = (position: any) => {
			// @ts-ignore
			const { latitude, longitude } = position.coords;
			setTemporaryCoordinates({ lat: latitude, lng: longitude });
			setAddressText(`${latitude}`, `${longitude}`);
			setCurrentFillAddress({ lat: latitude, lng: longitude });
		};

		const logError = (error: any) => {
			// init with monas coordinate if needed
		};

		// @ts-ignore
		if (navigator.geolocation) {
			// @ts-ignore
			navigator.geolocation.getCurrentPosition(logPosition, logError);
		}
	};

	useEffect(() => {
		if (!temporaryAddress) {
			setAddressText(
				`${temporararyCoordinates.lat}`,
				`${temporararyCoordinates.lng}`
			);
		}
		// if(currentFillAddress.lat !== MONAS_LAT) {
		// 	setTemporaryCoordinates({ lat: currentFillAddress.lat, lng: currentFillAddress.lng })
		// 	setAddressText(`${currentFillAddress.lat}`, `${currentFillAddress.lng}`);
		// }

		if (!currentFillAddress.lat) {
			getUserCurrentLocation();
		}
	}, [temporaryAddress]);

	return (
		<Container isOpen={isOpen}>
		{/* <GlobalStyle isShow={isOpen} /> */}
			<TopBar>
				<BackContainer onClick={() => handleClose()}>
					<BackIcon src={BackAsset} />
				</BackContainer>
				<SearchBar>
					<MagnifyingIcon src={MagnifyingAsset} />
					<PlacesAutocomplete
						value={inputSearchBar}
						onChange={setInputSearchBar}
						onSelect={handleSelectedAutoCompleteAddress}
						key={"address-map"}
					>
						{({
							getInputProps,
							suggestions,
							getSuggestionItemProps,
							loading,
						}) => (
							<React.Fragment>
								{/* @ts-ignore */}
								<SearchInput
									{...getInputProps({
										placeholder: WORDING.contact_page.SEARCH_LOCATION[language],
									})}
								/>

								<div
									style={{ position: "absolute", paddingTop: "50px", top: "0" }}
								>
									{loading ? <div>...loading</div> : null}
									{suggestions.map((suggestion) => {
										return (
											<AddressSuggestion
												{...getSuggestionItemProps(suggestion)}
												key={suggestion.index}
											>
												{suggestion.description}
											</AddressSuggestion>
										);
									})}
								</div>
							</React.Fragment>
						)}
					</PlacesAutocomplete>
				</SearchBar>
			</TopBar>
			<BackgroundMap>
				{/* @ts-ignore */}
				<Maps
					initialCoordinates={temporararyCoordinates}
					setNewCoordinates={setTemporaryCoordinates}
					setAddressText={setAddressText}
				/>
			</BackgroundMap>

			<BottomSection>
				<SelectedAddressContainer>
					{/* {currentFillAddress.detail
						? currentFillAddress.detail
						: temporaryAddress || "-"} */}

					{temporaryAddress}
				</SelectedAddressContainer>
				<ButtonFull
					hexPrimary={E_COLOR.DARK_BLUE}
					isPrimary={true}
					isDisabled={false}
					onClick={handleChooseFinalLocation}
				>
					{WORDING.contact_page.PIN_LOCATION[language]}
				</ButtonFull>
			</BottomSection>
		</Container>
	);
};

export default PopupMaps;

import React from "react";
import styled from "styled-components";
import {
  E_COLOR,
  E_DELIVERY_OPTION,
  E_FONT_WEIGHT,
} from "../interfaces/dataTypes";
import CloseAsset from "../assets/close.png";
import PickupAsset from "../assets/pickup.svg";
import DineInSVG from "../assets/dinein";
import { WORDING } from "../constants/translate";
import DeliverySVG from "../assets/delivery";
import PickupSVG from "../assets/pickup";

const Container = styled.div<{ isShow: boolean }>`
  z-index: 6;
  display: ${(props) => (props.isShow ? "block" : "none")};
  position: fixed;
  width: 100vh;
  max-width: 600px;
  background-color: rgba(26, 26, 26, 0.5);
  height: 100vh;
  bottom: 0;
  top: 0;
`;

const Content = styled.div`
  width: 100%;
  padding-bottom: 20px;
  max-width: 600px;
  position: fixed;
  background-color: ${E_COLOR.WHITE};
  overflow: hidden;
  bottom: 0;
  border-radius: 16px 16px 0 0;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px;
`;

const Title = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 18px;
	line-height: 27px;
`;

const CloseIcon = styled.img`
	width: 16px;
	height: 16px;
`;

const OptionCard = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
  border-radius: 8px;
  margin: 10px 16px;
  padding: 17px;
`
const OptionIcon = styled.img`
  width: 45px;
  height: 45px;
  margin-right: 16px;
`

const OptionDetail = styled.div`
  
`

const OptionLabel = styled.div`
color: ${E_COLOR.NEW_BLACK};
font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
font-size: 14px;
line-height: 21px;
`

const OptionDescription = styled.div`
color: ${E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 12px;
  line-height: 19px;
`

interface IProps {
  isShow: boolean;
  setShow: (isShow: boolean) => void;
  handleChooseOrderMethod: (method: E_DELIVERY_OPTION) => void;
  shopAddress: string;
  isEnableDineIn: boolean;
  isEnablePickup: boolean;
  deliveryOptions: E_DELIVERY_OPTION[];
  setShowConfirmDineInInfo: (isShow: boolean) => void;
  isShowDineInConfirm: boolean;
  language: "id" | "en";
  hexPrimary: string;
}

const BottomSheetOrderMethod = ({
  isShow,
  setShow,
  handleChooseOrderMethod,
  shopAddress,
  isEnableDineIn,
  deliveryOptions,
  setShowConfirmDineInInfo,
  isShowDineInConfirm,
  language,
  hexPrimary,
  isEnablePickup
}: IProps) => {

  const handleCloseBackground = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (e.target === e.currentTarget) {
			setShow(false);
		}
	};

  return (
    <Container isShow={isShow} onClick={() => setShow(false)}>
      <Content>
      <Header>
					<Title>{WORDING.general.FULFILLMENT_METHOD[language]}</Title>
					<CloseIcon onClick={(e) => handleCloseBackground(e)} src={CloseAsset} />
				</Header>
        {isEnableDineIn && deliveryOptions.includes(E_DELIVERY_OPTION.DINE_IN) && <OptionCard onClick={isShowDineInConfirm ? () => setShowConfirmDineInInfo(true) : () => handleChooseOrderMethod(E_DELIVERY_OPTION.DINE_IN)} id="bt-dine-in">
          <DineInSVG
            color={hexPrimary ? hexPrimary : E_COLOR.DARK_BLUE}
            margin="0 14px 0 0"
            widthHeight="45px"
          />
          <OptionDetail>
            <OptionLabel>{WORDING.general.DINE_IN[language]}</OptionLabel>
          </OptionDetail>
        </OptionCard>}
        {isEnablePickup && deliveryOptions.includes(E_DELIVERY_OPTION.PICK_UP) && <OptionCard onClick={() => handleChooseOrderMethod(E_DELIVERY_OPTION.PICK_UP)} id="bt-pick-up">
          <PickupSVG
            margin="0 14px 0 0"
            color={hexPrimary ? hexPrimary : E_COLOR.DARK_BLUE}
          />
          <OptionDetail>
            <OptionLabel>{WORDING.general.PICK_UP[language]}</OptionLabel>
            <OptionDescription>{shopAddress}</OptionDescription>
          </OptionDetail>
        </OptionCard>}
        {(deliveryOptions.includes(E_DELIVERY_OPTION.DELIVERY) || deliveryOptions.includes(E_DELIVERY_OPTION.SELF_SHIPPING)) && <OptionCard onClick={() => handleChooseOrderMethod(E_DELIVERY_OPTION.DELIVERY)} id="bt-delivery">
          <DeliverySVG
            margin="0 14px 0 0"
            color={hexPrimary ? hexPrimary : E_COLOR.DARK_BLUE}
          />
          <OptionDetail>
            <OptionLabel>{WORDING.general.DELIVERY[language]}</OptionLabel>
          </OptionDetail>
        </OptionCard>}
      </Content>
    </Container>
  );
};

export default BottomSheetOrderMethod;

import React, { useEffect, useState } from "react";

import {
	Container,
	GreyLine,
	Product,
	ProductNamePrice,
	ProductNotes,
	Prop,
	PropAndValue,
	Total,
	TotalLabel,
	TotalPrice,
	Value,
	Title,
	PropValueWrapper,
	ButtonNext,
	ButtonWrapper,
	WrapperVoucher,
	TotalTopContainer,
	CartIcon,
	TotalTopLabel,
	TotalTopPrice,
	ShopName,
	ShopAddress,
	BuyerCard,
	DashedLine,
	DeliveryCard,
	DeliveryDateWrapper,
	CarIcon,
	DeliveryDateLabel,
	DeliveryDate,
	ItemCard,
	ItemQuantity,
	ItemDetail,
	ItemNamePriceWrapper,
	ItemNamePrice,
	ItemNotes,
	ItemButtonWrapper,
	ItemButton,
	ShippingCard,
	TopInfoContainer,
	ShopInfoWrapper,
	TableNumberContainer,
	TableNumberWrapper,
	TableNumberLabel,
	TableNumberValue,
	DimNotification,
	PotentialCoinWrapper,
	PotentialCoinLabel,
	ItemPriceWrapper,
	ItemDiscountWrapper,
	ItemDiscount,
	NotifChanges,
	ItemCardWrapper,
	ButtonBack,
} from "../../styles/ReviewStep";
import PopupErrorSuccess from "../../components/PopupErrorSuccess";
import {
	E_COLOR,
	E_DELIVERY_OPTION,
	E_PAGE_PATHS,
} from "../../interfaces/dataTypes";
import { useReviewStepHook } from "../../hooks/ReviewStep";
import {
	parseIDR,
	getMonthName,
	countPotentialCoin,
	calcDiscountPercentage,
} from "../../utils";
import { useHistory } from "react-router-dom";
import PopupAgreement from "../../components/PopupAgreement";
import Voucher from "../../components/Voucher";
import { useItemsStepHook } from "../../hooks/ItemsStep";
import CartSVG from "../../assets/cart";
import BackSVG from "../../assets/back";
import CarAsset from "../../assets/car.png";
import BottomSheetItemDetail from "../../components/BottomSheetItemDetail";
import { ISelectedShip } from "../../interfaces";
import BottomSheetPaymentOption from "../../components/BottomSheetPaymentOption";
import { WORDING } from "../../constants/translate";
import DineInSVG from "../../assets/dinein";
import PickupSVG from "../../assets/pickup";
import DeliverySVG from "../../assets/delivery";
import LoyaltyPoint from "../../components/LoyaltyPoint";
import BottomSheetOTP from "../../components/BottomSheetOTP";
import { ERR_VALIDATION_TYPE } from "../../constants";

const ReviewStep = () => {
	const {
		handleSubmit,
		selectedItems,
		totalAmount,
		selectedShip,
		isPostError,
		setIsPostError,
		selectedDate,
		shop,
		discountVoucherAmount,
		reportIssue,
		handleClickedConfirmOrder,
		isShowAgreementFraud,
		setIsShowAgreementFraud,
		buyer,
		isShowBottomSheetPaymentOption,
		setIsShowBottomSheetPaymentOption,
		selectedPaymentMethod,
		setSelectedPaymentMethod,
		searchedCode,
		setSearchedCode,
		fetchPromotionVoucher,
		fetchPromotionVoucherByCode,
		selectedProductVoucher,
		selectedShippingVoucher,
		handleSelectVoucher,
		currentEligibleVouchers,
		currentNotEligibleVouchers,
		voucherErrorMessage,
		tax,
		serviceFee,
		selectedTime,
		amountItemOnly,
		isSearchingVoucherByCode,
		isShowDimNotification,
		eventType,
		sendOTPtoBuyer,
		coinToExchange,
		setOtpCode,
		handleTextInput,
		buyerCoin,
		checkOTPValid,
		showOtpBottomSheet,
		setShowOtpBottomSheet,
		otpCode,
		popupErrorMsg,
		roundedValue,
		handleOpenBill,
		askBuyerForOtp,
		otpLS,
		validationErrors,
		getWordingErrorValidation,
		foodcourtCart,
		merchant,
    isHideTakeaway
	} = useReviewStepHook();
	const {
		handleChangeDetailExistingItem,
		handleDeleteExistingItem,
		setIsShowBottomSheetItemDetail,
		isShowBottomSheetItemDetail,
		handleAddSelectedItem,
		currentItem,
		editedItem,
		setEditedItem,
		countQuantityItemSameId,
		language,
		isPopupCommonSelected,
	} = useItemsStepHook();
	const [isShowBottomSheetVoucher, setIsShowBottomSheetVoucher] =
		useState(false);
	const {
		name,
		phone,
		recipientName,
		recipientPhone,
		address,
		addressNote,
		tableNumber,
	} = buyer;
	const {
		name: shipName,
		price: shipPrice,
		type,
	} = selectedShip as ISelectedShip;
	const history = useHistory();
	const [isShowExchangeCoinBottomSheet, setIsShowExchangeCoinBottomSheet] =
		useState(false);
	const [isRetriedPreviously, setIsRetried] = useState(false);
	let timer: any;

	const handleClickedChangeItem = (id: string) => {
		handleChangeDetailExistingItem(id);
		setIsShowBottomSheetItemDetail(true);
	};

	const handleRetryOpenBill = () => {
		askBuyerForOtp();
		setIsPostError(false);
	};

	return (
		<Container>
			<PopupErrorSuccess
				isRetriedPreviously={isRetriedPreviously}
				reportIssue={reportIssue}
				setIsRetried={setIsRetried}
				handleRetry={
					popupErrorMsg === WORDING.popup.ERR_OTP_EXP[language]
						? handleRetryOpenBill
						: handleSubmit
				}
				isOpen={isPostError}
				handleClose={() => setIsPostError(false)}
				isSuccess={false}
				language={language}
				desc={popupErrorMsg}
				isCancelButtonOnly={
					popupErrorMsg === WORDING.popup.ERR_LOYALTY_DATA_CHANGED[language] ||
					popupErrorMsg === WORDING.popup.ERR_NOT_ACTIVATE_LOYALTY[language]
				}
			/>
			<TotalTopContainer color={shop.hexPrimary}>
				<CartSVG
					color={shop.hexPrimary ? shop.hexPrimary : E_COLOR.DARK_BLUE}
				/>
				<TotalTopLabel>{WORDING.general.TOTAL_PAYMENT[language]}</TotalTopLabel>
				<TotalTopPrice>
					Rp{totalAmount <= 0 ? "0" : parseIDR(totalAmount)}
				</TotalTopPrice>
			</TotalTopContainer>

			{shop.loyaltyData && (
				<PotentialCoinWrapper>
					<PotentialCoinLabel>
						{WORDING.review_page.POTENTIAL_COIN[language].replace(
							"XX",
							`${countPotentialCoin(
								amountItemOnly,
								discountVoucherAmount,
								parseInt(coinToExchange),
								shop.loyaltyData?.valuePerCoin
							)}`
						)}
					</PotentialCoinLabel>
				</PotentialCoinWrapper>
			)}

			<TopInfoContainer>
				<ShopInfoWrapper>
					<ShopName>{shop.name}</ShopName>
					<ShopAddress>{shop.address}</ShopAddress>
				</ShopInfoWrapper>
				{selectedShip.name === E_DELIVERY_OPTION.DINE_IN && (
					<TableNumberWrapper>
						<TableNumberContainer>
							<TableNumberLabel>
								{WORDING.general.TABLE_NUMBER[language]}
							</TableNumberLabel>
							<TableNumberValue>{tableNumber}</TableNumberValue>
						</TableNumberContainer>
					</TableNumberWrapper>
				)}
			</TopInfoContainer>

			{selectedShip.name !== E_DELIVERY_OPTION.DINE_IN && <GreyLine />}

			<BuyerCard
				isHaveTwoSection={
					shipName !== E_DELIVERY_OPTION.PICK_UP &&
					selectedShip.name !== E_DELIVERY_OPTION.DINE_IN
				}
			>
				<PropAndValue>
					<Prop>{WORDING.general.BUYER_NAME[language]}</Prop>
					<Value>{name ? name : "DARI KIOSK"}</Value>
				</PropAndValue>
				{phone && (
					<PropAndValue>
						<Prop>{WORDING.general.PHONE_NUMBER[language]}</Prop>
						<Value>{phone}</Value>
					</PropAndValue>
				)}
				{shipName !== E_DELIVERY_OPTION.PICK_UP &&
					selectedShip.name !== E_DELIVERY_OPTION.DINE_IN && (
						<React.Fragment>
							<PropAndValue>
								<Prop>{WORDING.general.RECIPIENT_NAME[language]}</Prop>
								<Value>{recipientName}</Value>
							</PropAndValue>
							<PropAndValue>
								<Prop>{WORDING.general.PHONE_NUMBER[language]}</Prop>
								<Value>{recipientPhone}</Value>
							</PropAndValue>
						</React.Fragment>
					)}
			</BuyerCard>

			{shipName !== E_DELIVERY_OPTION.PICK_UP &&
				selectedShip.name !== E_DELIVERY_OPTION.DINE_IN && (
					<ShippingCard
						isHaveTwoSection={shipName !== E_DELIVERY_OPTION.PICK_UP}
					>
						<PropAndValue>
							<Prop>{WORDING.general.COURIER[language]}</Prop>
							<Value>
								{shipName}{" "}
								{type === E_DELIVERY_OPTION.SELF_SHIPPING ? "" : type}
							</Value>
						</PropAndValue>
						<PropAndValue>
							<Prop>{WORDING.general.ADDRESS[language]}</Prop>
							<Value>{address}</Value>
						</PropAndValue>
						<PropAndValue>
							<Prop>{WORDING.general.INSTRUCTION[language]}</Prop>
							<Value isSmall={true}>{addressNote ? addressNote : "-"}</Value>
						</PropAndValue>
					</ShippingCard>
				)}

			{!isPopupCommonSelected && <DashedLine />}

			{!isPopupCommonSelected && (
				<DeliveryCard>
					{shipName === E_DELIVERY_OPTION.DINE_IN ? (
						<DineInSVG
							widthHeight="44px"
							color={shop.hexPrimary ? shop.hexPrimary : E_COLOR.DARK_BLUE}
							margin="0"
						/>
					) : shipName === E_DELIVERY_OPTION.PICK_UP ? (
						<PickupSVG
							color={shop.hexPrimary ? shop.hexPrimary : E_COLOR.DARK_BLUE}
							margin="0"
						/>
					) : (
						<DeliverySVG
							color={shop.hexPrimary ? shop.hexPrimary : E_COLOR.DARK_BLUE}
							margin="0"
						/>
					)}
					<DeliveryDateWrapper>
						<DeliveryDateLabel>
							{shipName === E_DELIVERY_OPTION.PICK_UP
								? WORDING.general.PICK_UP_DATE[language]
								: shipName === E_DELIVERY_OPTION.DINE_IN
								? WORDING.general.DINE_IN[language]
								: WORDING.general.DELIVERY_DATE[language]}
						</DeliveryDateLabel>
						<DeliveryDate>
							{selectedDate &&
								`${selectedDate.getDate()} ${getMonthName(
									selectedDate!,
									language === "en"
								)} ${selectedDate!.getFullYear()}`}{" "}
							{shipName === E_DELIVERY_OPTION.DINE_IN
								? `(${WORDING.general.NOW[language]})`
								: selectedTime === WORDING.general.IMMEDIATELY[language]
								? WORDING.general.IMMEDIATELY[language]
								: selectedTime?.substring(0, 5)}
						</DeliveryDate>
					</DeliveryDateWrapper>
				</DeliveryCard>
			)}

			{/* <GreyLine /> */}

			<Title marginTop="30px" isWithBackColor={true}>
				{WORDING.review_page.DETAIL_ORDER[language]}
			</Title>
			{selectedItems && foodcourtCart.total === 0
				? Object.keys(selectedItems!).map((idItem: string, index: number) => {
						const errs = validationErrors.filter(
							(err) => `${err.product_id}` === idItem
						);
						const isProductOos =
							(errs[0]?.type === ERR_VALIDATION_TYPE.OUT_OF_STOCK ||
								errs[0]?.type === ERR_VALIDATION_TYPE.MISSING_PRODUCT) &&
							errs[0]?.expected_value === 0;
						const isMissingVariant =
							errs[0]?.type === ERR_VALIDATION_TYPE.MISSING_VARIANT;
						const isMissingVariantMandatory =
							errs[0]?.type === ERR_VALIDATION_TYPE.MISSING_VARIANT_MANDATORY;
						const { data, quantity, notes, variants, is_takeaway } = selectedItems[idItem];
						let total = data.discount_price
							? parseInt(data.discount_price)
							: parseInt(data.price);
						return (
							<ItemCardWrapper
								isLastItem={index === Object.keys(selectedItems!).length - 1}
							>
								<ItemCard>
									<ItemQuantity
										isStrike={isProductOos || isMissingVariantMandatory}
									>
										{quantity}x
									</ItemQuantity>
									<ItemDetail>
										<ItemNamePriceWrapper>
											<ItemNamePrice
												isBold={false}
												isStrike={isProductOos || isMissingVariantMandatory}
											>
												{data.name}
											</ItemNamePrice>
											{data.discount_price ? (
												<ItemPriceWrapper>
													<ItemNamePrice
														isBold={false}
														isStrike={isProductOos || isMissingVariantMandatory}
													>
														Rp
														{parseIDR(parseInt(data.discount_price) * quantity)}
													</ItemNamePrice>
													<ItemDiscountWrapper>
														<ItemDiscount
															isPercentage={true}
															isStrike={
																isProductOos || isMissingVariantMandatory
															}
														>
															-
															{calcDiscountPercentage(
																parseInt(data.price),
																parseInt(data.discount_price)
															)}
															%
														</ItemDiscount>
														<ItemDiscount isPercentage={false}>
															{parseIDR(parseInt(data.price) * quantity)}
														</ItemDiscount>
													</ItemDiscountWrapper>
												</ItemPriceWrapper>
											) : (
												<ItemNamePrice
													isBold={false}
													isStrike={isProductOos || isMissingVariantMandatory}
												>
													Rp{parseIDR(parseInt(data.price) * quantity)}
												</ItemNamePrice>
											)}
										</ItemNamePriceWrapper>
										{Object.keys(variants) &&
											Object.keys(variants).map((id: string) => {
												return Object.keys(variants[id]).map(
													(variantOptionId: string) => {
														const { name, price, category } =
															variants[id][variantOptionId];
														total = total + parseInt(price);
														const isCurrVariantMissing =
															isMissingVariant &&
															`${errs[0].variant_detail_id}` ===
																variantOptionId;
														return (
															<ItemNamePriceWrapper>
																<ItemNamePrice
																	width="65%"
																	isBold={false}
																	isStrike={
																		isProductOos ||
																		isCurrVariantMissing ||
																		isMissingVariantMandatory
																	}
																>
																	{category}: {name}
																</ItemNamePrice>
																<ItemNamePrice
																	textAlign="right"
																	width="35%"
																	isBold={false}
																	isStrike={
																		isProductOos ||
																		isCurrVariantMissing ||
																		isMissingVariantMandatory
																	}
																>
																	Rp{parseIDR(parseInt(price) * quantity)}
																</ItemNamePrice>
															</ItemNamePriceWrapper>
														);
													}
												);
											})}
                      {is_takeaway && (
                        <ItemNamePriceWrapper>
                          <ItemNamePrice
                            width="65%"
                            isBold
                            isStrike={isProductOos || isMissingVariantMandatory}
                          >
                            {WORDING.general.PRODUCT_TAKE_AWAY[language]}
                          </ItemNamePrice>
                          <ItemNamePrice
                            textAlign="right"
                            width="35%"
                            isBold={false}
                            isStrike={isProductOos || isMissingVariantMandatory}
                          >
                            Rp{parseIDR(+data.takeaway_cost * quantity)}
                          </ItemNamePrice>
                        </ItemNamePriceWrapper>
                      )}
										{/* <ItemNamePriceWrapper>
									<ItemNamePrice isBold={false}>Total</ItemNamePrice>
									<ItemNamePrice isBold={true}>
										Rp{parseIDR(total * quantity)}
									</ItemNamePrice>
								</ItemNamePriceWrapper> */}
										{notes !== "" && <ItemNotes>{notes}</ItemNotes>}
										<ItemButtonWrapper>
											<ItemButton
												color={
													isProductOos || isMissingVariantMandatory
														? E_COLOR.NEW_GREY_TEXT
														: shop.hexPrimary
												}
												onClick={
													isProductOos || isMissingVariantMandatory
														? () => {}
														: () => handleClickedChangeItem(idItem)
												}
											>
												{WORDING.general.CHANGE[language]}
											</ItemButton>
											<ItemButton
												color={shop.hexPrimary}
												onClick={() => handleDeleteExistingItem(idItem)}
											>
												{WORDING.general.REMOVE[language]}
											</ItemButton>
										</ItemButtonWrapper>
									</ItemDetail>
								</ItemCard>
								{errs.length > 0 && (
									<NotifChanges
										major={
											errs[0]?.type === ERR_VALIDATION_TYPE.OUT_OF_STOCK ||
											errs[0]?.type === ERR_VALIDATION_TYPE.MISSING_PRODUCT ||
											errs[0]?.type ===
												ERR_VALIDATION_TYPE.MISSING_VARIANT_MANDATORY
										}
									>
										{getWordingErrorValidation(errs[0])}
									</NotifChanges>
								)}
							</ItemCardWrapper>
						);
				  })
				: foodcourtCart?.total > 0 &&
				  Object.keys(foodcourtCart?.cart)?.map(
						(shopId: string, index: number) => {
              const cartItems = foodcourtCart?.cart?.[shopId]?.items || {};

							return (
								<React.Fragment>
									{Object.keys(selectedItems).length > 0 && Object.keys(cartItems).length > 0 && <Title isWithBackColor={false}>
										{foodcourtCart?.cart[shopId]?.shopName}
									</Title>}
									{Object.keys(foodcourtCart.cart[shopId]?.items).map(
										(idItem) => {
											const errs = validationErrors.filter(
												(err) => `${err.product_id}` === idItem
											);
											const isProductOos =
												(errs[0]?.type === ERR_VALIDATION_TYPE.OUT_OF_STOCK ||
													errs[0]?.type ===
														ERR_VALIDATION_TYPE.MISSING_PRODUCT) &&
												errs[0]?.expected_value === 0;
											const isMissingVariant =
												errs[0]?.type === ERR_VALIDATION_TYPE.MISSING_VARIANT;
											const isMissingVariantMandatory =
												errs[0]?.type ===
												ERR_VALIDATION_TYPE.MISSING_VARIANT_MANDATORY;
											if(selectedItems[idItem]) {
												const { data, quantity, notes, variants } =
												selectedItems[idItem];
											let total = data.discount_price
												? parseInt(data.discount_price)
												: parseInt(data.price);
											return (
												<ItemCardWrapper
													isLastItem={
														index === Object.keys(selectedItems!).length - 1
													}
												>
													<ItemCard>
														<ItemQuantity
															isStrike={
																isProductOos || isMissingVariantMandatory
															}
														>
															{quantity}x
														</ItemQuantity>
														<ItemDetail>
															<ItemNamePriceWrapper>
																<ItemNamePrice
																	isBold={false}
																	isStrike={
																		isProductOos || isMissingVariantMandatory
																	}
																>
																	{data.name}
																</ItemNamePrice>
																{data.discount_price ? (
																	<ItemPriceWrapper>
																		<ItemNamePrice
																			isBold={false}
																			isStrike={
																				isProductOos ||
																				isMissingVariantMandatory
																			}
																		>
																			Rp
																			{parseIDR(
																				parseInt(data.discount_price) * quantity
																			)}
																		</ItemNamePrice>
																		<ItemDiscountWrapper>
																			<ItemDiscount
																				isPercentage={true}
																				isStrike={
																					isProductOos ||
																					isMissingVariantMandatory
																				}
																			>
																				-
																				{calcDiscountPercentage(
																					parseInt(data.price),
																					parseInt(data.discount_price)
																				)}
																				%
																			</ItemDiscount>
																			<ItemDiscount isPercentage={false}>
																				{parseIDR(
																					parseInt(data.price) * quantity
																				)}
																			</ItemDiscount>
																		</ItemDiscountWrapper>
																	</ItemPriceWrapper>
																) : (
																	<ItemNamePrice
																		isBold={false}
																		isStrike={
																			isProductOos || isMissingVariantMandatory
																		}
																	>
																		Rp
																		{parseIDR(parseInt(data.price) * quantity)}
																	</ItemNamePrice>
																)}
															</ItemNamePriceWrapper>
															{Object.keys(variants) &&
																Object.keys(variants).map((id: string) => {
																	return Object.keys(variants[id]).map(
																		(variantOptionId: string) => {
																			const { name, price, category } =
																				variants[id][variantOptionId];
																			total = total + parseInt(price);
																			const isCurrVariantMissing =
																				isMissingVariant &&
																				`${errs[0].variant_detail_id}` ===
																					variantOptionId;
																			return (
																				<ItemNamePriceWrapper>
																					<ItemNamePrice
																						width="65%"
																						isBold={false}
																						isStrike={
																							isProductOos ||
																							isCurrVariantMissing ||
																							isMissingVariantMandatory
																						}
																					>
																						{category}: {name}
																					</ItemNamePrice>
																					<ItemNamePrice
																						textAlign="right"
																						width="35%"
																						isBold={false}
																						isStrike={
																							isProductOos ||
																							isCurrVariantMissing ||
																							isMissingVariantMandatory
																						}
																					>
																						Rp
																						{parseIDR(
																							parseInt(price) * quantity
																						)}
																					</ItemNamePrice>
																				</ItemNamePriceWrapper>
																			);
																		}
																	);
																})}
															{/* <ItemNamePriceWrapper>
									<ItemNamePrice isBold={false}>Total</ItemNamePrice>
									<ItemNamePrice isBold={true}>
										Rp{parseIDR(total * quantity)}
									</ItemNamePrice>
								</ItemNamePriceWrapper> */}
															{notes !== "" && <ItemNotes>{notes}</ItemNotes>}
															<ItemButtonWrapper>
																<ItemButton
																	color={
																		isProductOos || isMissingVariantMandatory
																			? E_COLOR.NEW_GREY_TEXT
																			: shop.hexPrimary
																	}
																	onClick={
																		isProductOos || isMissingVariantMandatory
																			? () => {}
																			: () => handleClickedChangeItem(idItem)
																	}
																>
																	{WORDING.general.CHANGE[language]}
																</ItemButton>
																<ItemButton
																	color={shop.hexPrimary}
																	onClick={() =>
																		handleDeleteExistingItem(idItem)
																	}
																>
																	{WORDING.general.REMOVE[language]}
																</ItemButton>
															</ItemButtonWrapper>
														</ItemDetail>
													</ItemCard>
													{errs.length > 0 && (
														<NotifChanges
															major={
																errs[0]?.type ===
																	ERR_VALIDATION_TYPE.OUT_OF_STOCK ||
																errs[0]?.type ===
																	ERR_VALIDATION_TYPE.MISSING_PRODUCT ||
																errs[0]?.type ===
																	ERR_VALIDATION_TYPE.MISSING_VARIANT_MANDATORY
															}
														>
															{getWordingErrorValidation(errs[0])}
														</NotifChanges>
													)}
												</ItemCardWrapper>
											);
											} else {
                        return null;
                      }
										}
									)}
								</React.Fragment>
							);
						}
				  )}
			{selectedShip.name !== E_DELIVERY_OPTION.DINE_IN &&
				selectedShip.name !== E_DELIVERY_OPTION.PICK_UP && (
					<Product>
						<PropValueWrapper>
							<ProductNamePrice>
								{WORDING.general.SHIPPING_FEE[language]}
							</ProductNamePrice>
							<ProductNamePrice>
								Rp
								{parseIDR(
									selectedShip?.withInsurance
										? shipPrice - selectedShip?.insuranceRate
										: shipPrice
								)}
							</ProductNamePrice>
						</PropValueWrapper>
					</Product>
				)}
			{(selectedShip!.isUserUseInsurance || selectedShip!.withInsurance) && (
				<Product>
					<PropValueWrapper>
						<ProductNamePrice>
							{WORDING.general.INSURANCE[language]}
						</ProductNamePrice>
						<ProductNamePrice>
							Rp {parseIDR(selectedShip!.insuranceRate)}
						</ProductNamePrice>
					</PropValueWrapper>
				</Product>
			)}
			{discountVoucherAmount > 0 && (
				<Product>
					<PropValueWrapper>
						<ProductNamePrice>
							{WORDING.review_page.DISCOUNT_CODE[language]}
							<TotalLabel inline={true}>
								{selectedProductVoucher
									? selectedShippingVoucher
										? `${selectedProductVoucher?.code}, ${selectedShippingVoucher?.code}`
										: selectedProductVoucher?.code
									: selectedShippingVoucher?.code}
							</TotalLabel>
						</ProductNamePrice>
						<ProductNamePrice>
							- Rp {parseIDR(discountVoucherAmount)}
						</ProductNamePrice>
					</PropValueWrapper>
				</Product>
			)}
			{coinToExchange && shop.loyaltyData && (
				<Product>
					<PropValueWrapper>
						<ProductNamePrice>
							{WORDING.review_page.COIN[language]} ({coinToExchange}{" "}
							{WORDING.review_page.COIN[language]})
						</ProductNamePrice>
						<ProductNamePrice>
							-Rp{" "}
							{parseIDR(
								shop.loyaltyData?.valuePerCoin * parseInt(coinToExchange) >
									amountItemOnly - discountVoucherAmount
									? amountItemOnly - discountVoucherAmount
									: shop.loyaltyData?.valuePerCoin * parseInt(coinToExchange)
							)}
						</ProductNamePrice>
					</PropValueWrapper>
				</Product>
			)}
			{selectedShip.name === E_DELIVERY_OPTION.DINE_IN &&
				shop.serviceFeePercentage !== "" && (
					<Product>
						<PropValueWrapper>
							<ProductNamePrice>
								{WORDING.general.SERVICE_FEE[language]}
							</ProductNamePrice>
							<ProductNamePrice>
								Rp
								{parseIDR(serviceFee)}
							</ProductNamePrice>
						</PropValueWrapper>
					</Product>
				)}
			{((shipName === E_DELIVERY_OPTION.DINE_IN &&
				shop.taxDeliveryOption?.includes(E_DELIVERY_OPTION.DINE_IN)) ||
				(shipName === E_DELIVERY_OPTION.PICK_UP &&
					shop.taxDeliveryOption?.includes(E_DELIVERY_OPTION.PICK_UP)) ||
				(selectedShip.rateId === E_DELIVERY_OPTION.SELF_SHIPPING &&
					shop.taxDeliveryOption?.includes(E_DELIVERY_OPTION.SELF_SHIPPING)) ||
				(selectedShip.name !== E_DELIVERY_OPTION.DINE_IN &&
					selectedShip.name !== E_DELIVERY_OPTION.PICK_UP &&
					selectedShip.rateId !== E_DELIVERY_OPTION.SELF_SHIPPING &&
					shop.taxDeliveryOption?.includes(E_DELIVERY_OPTION.DELIVERY))) &&
				shop.taxPercentage !== "" && (
					<Product>
						<PropValueWrapper>
							<ProductNamePrice>
								{WORDING.general.TAX[language]} {shop.taxPercentage}%
							</ProductNamePrice>
							<ProductNamePrice>Rp{parseIDR(tax)}</ProductNamePrice>
						</PropValueWrapper>
					</Product>
				)}
			{!shop.platformFeeByMerchant && (
				<Product>
					<PropValueWrapper>
						<ProductNamePrice>
							{WORDING.general.PLATFORM_FEE[language]}
						</ProductNamePrice>
						<ProductNamePrice>Rp{parseIDR(shop.platformFee)}</ProductNamePrice>
					</PropValueWrapper>
				</Product>
			)}
			{shop.rounding && roundedValue !== 0 && (
				<Product>
					<PropValueWrapper>
						<ProductNamePrice>
							{WORDING.general.ROUNDING[language]}
						</ProductNamePrice>
						<ProductNamePrice>
							{roundedValue < 0 ? "-" : ""}Rp
							{parseIDR(roundedValue < 0 ? roundedValue * -1 : roundedValue)}
						</ProductNamePrice>
					</PropValueWrapper>
				</Product>
			)}
			<WrapperVoucher>
				<Voucher
					code={searchedCode}
					fetchPromotionVoucher={fetchPromotionVoucher}
					fetchPromotionVoucherByCode={fetchPromotionVoucherByCode}
					isShowBottomSheetVoucher={isShowBottomSheetVoucher}
					selectedProductVoucher={selectedProductVoucher}
					selectedShippingVoucher={selectedShippingVoucher}
					setCode={setSearchedCode}
					setIsShowBottomSheetVoucher={setIsShowBottomSheetVoucher}
					handleSelectVoucher={handleSelectVoucher}
					eligibleVouchers={currentEligibleVouchers}
					notEligibleVouchers={currentNotEligibleVouchers}
					errorMessage={voucherErrorMessage}
					amountItemOnly={amountItemOnly}
					isSearchingVoucherByCode={isSearchingVoucherByCode}
					language={language}
					hexPrimary={shop.hexPrimary}
				/>
			</WrapperVoucher>
			{shop.loyaltyData && buyer.isHaveCoin && (
				<LoyaltyPoint
					isHaveCoin={buyer.isHaveCoin}
					sendOTPTrigger={sendOTPtoBuyer}
					coins={buyerCoin}
					coinToExchange={coinToExchange}
					handleTextInput={handleTextInput}
					hexColor={shop.hexPrimary}
					language={language}
					setOtpCode={setOtpCode}
					loyaltyData={shop.loyaltyData}
					totalAmountItemOnly={amountItemOnly}
					otpCode={otpCode}
					discountVoucherAmount={discountVoucherAmount}
					isShowExchangeCoinBottomSheet={isShowExchangeCoinBottomSheet}
					setIsShowExchangeCoinBottomSheet={setIsShowExchangeCoinBottomSheet}
					setShowOTPBottomSheet={setShowOtpBottomSheet}
					checkOtpValid={checkOTPValid}
				/>
			)}
			<DashedLine />
			<Total>
				<TotalLabel>Total</TotalLabel>
				<TotalPrice>
					Rp {totalAmount <= 0 ? "0" : parseIDR(totalAmount)}
				</TotalPrice>
			</Total>

			{/* <ButtonWrapper>
				<ButtonNext
					color={shop.hexPrimary}
					isPrimary={true}
					onClick={
						selectedItems && Object.keys(selectedItems).length === 0
							? () => {}
							: handleClickedConfirmOrder
					}
					isDisabled={selectedItems && Object.keys(selectedItems).length === 0}
				>
					{shop.openBillSetting === "open" &&
					selectedShip.name === E_DELIVERY_OPTION.DINE_IN
						? WORDING.review_page.PAY_NOW[language]
						: WORDING.review_page.CONFIRM_ORDER[language]}
				</ButtonNext>
				{shop.openBillSetting === "open" &&
					selectedShip.name === E_DELIVERY_OPTION.DINE_IN && (
						<ButtonNext
							color={shop.hexPrimary}
							isPrimary={false}
							onClick={
								selectedItems && Object.keys(selectedItems).length === 0
									? () => {}
									: otpCode || otpLS
									? () => handleOpenBill()
									: () => askBuyerForOtp(true)
							}
							isDisabled={
								selectedItems && Object.keys(selectedItems).length === 0
							}
						>
							{WORDING.review_page.PAY_LATER[language]}
						</ButtonNext>
					)}
				<ButtonCancel onClick={() => history.push(E_PAGE_PATHS.CONTACT)}>
					{WORDING.general.BACK[language]}
				</ButtonCancel>
			</ButtonWrapper> */}
			<ButtonWrapper>
				<ButtonBack
					color={shop.hexPrimary}
					onClick={() => history.push(E_PAGE_PATHS.CONTACT)}
				>
					<BackSVG
						color={shop.hexPrimary ? shop.hexPrimary : E_COLOR.DARK_BLUE}
					/>
				</ButtonBack>
				{(shop.openBillSetting === "open" &&
					shop?.isTenant !== true &&
					merchant?.isTenant !== true) &&
					selectedShip.name === E_DELIVERY_OPTION.DINE_IN && (
						<ButtonNext
							onClick={
								selectedItems && Object.keys(selectedItems).length === 0
									? () => {}
									: otpCode || otpLS
									? () => handleOpenBill()
									: () => askBuyerForOtp(true)
							}
							isDisabled={
								selectedItems && Object.keys(selectedItems).length === 0
							}
							isPrimary={false}
							color={shop.hexPrimary}
						>
							{WORDING.review_page.PAY_LATER[language]}
						</ButtonNext>
					)}
				<ButtonNext
					onClick={
						selectedItems && Object.keys(selectedItems).length === 0
							? () => {}
							: handleClickedConfirmOrder
					}
					isDisabled={selectedItems && Object.keys(selectedItems).length === 0}
					isPrimary={true}
					color={shop.hexPrimary}
				>
					{shop.openBillSetting === "open" &&
					selectedShip.name === E_DELIVERY_OPTION.DINE_IN
						? WORDING.review_page.PAY_NOW[language]
						: WORDING.review_page.CONFIRM_ORDER[language]}
				</ButtonNext>
			</ButtonWrapper>
			<PopupAgreement
				isShow={isShowAgreementFraud}
				setIsShow={setIsShowAgreementFraud}
				handleSubmit={() => setIsShowBottomSheetPaymentOption(true)}
				isSelectPickup={shipName === E_DELIVERY_OPTION.PICK_UP}
				language={language}
			/>
			<BottomSheetItemDetail
				isShow={isShowBottomSheetItemDetail}
				setShow={setIsShowBottomSheetItemDetail}
				item={currentItem}
				handleAddSelectedItem={handleAddSelectedItem}
				selectedItem={editedItem}
				setEditedItem={setEditedItem}
				countQuantityItemSameId={countQuantityItemSameId}
				handleDeleteExistingItem={handleDeleteExistingItem}
				language={language}
				hexPrimary={shop.hexPrimary}
        isPickUp={selectedShip.name !== E_DELIVERY_OPTION.DINE_IN}
        isHideTakeaway={isHideTakeaway}
			/>
			<BottomSheetPaymentOption
				isShow={isShowBottomSheetPaymentOption}
				setShow={setIsShowBottomSheetPaymentOption}
				selectedPaymentMethod={selectedPaymentMethod}
				setSelectedPaymentMethod={setSelectedPaymentMethod}
				handleSubmitPaymentMethod={handleSubmit}
				isCashEligible={
					(selectedShip.name === E_DELIVERY_OPTION.SELF_SHIPPING ||
						selectedShip.name === E_DELIVERY_OPTION.PICK_UP ||
						selectedShip.name === E_DELIVERY_OPTION.DINE_IN) &&
					(selectedProductVoucher || selectedShippingVoucher
						? selectedProductVoucher?.payment_type?.includes("cash") ||
						  selectedShippingVoucher?.payment_type?.includes("cash")
						: true)
				}
				isXenditEligible={
					selectedProductVoucher || selectedShippingVoucher
						? selectedProductVoucher?.payment_type?.includes("xendit") ||
						  selectedShippingVoucher?.payment_type?.includes("xendit")
						: true
				}
				isEDCEligible={
					(selectedShip.name === E_DELIVERY_OPTION.SELF_SHIPPING ||
						selectedShip.name === E_DELIVERY_OPTION.PICK_UP ||
						selectedShip.name === E_DELIVERY_OPTION.DINE_IN) &&
					(selectedProductVoucher || selectedShippingVoucher
						? selectedProductVoucher?.payment_type?.includes("manual-edc") ||
						  selectedShippingVoucher?.payment_type?.includes("manual-edc")
						: true)
				}
        getEligibilityForCustomPayment={(payment) => (
					(selectedShip.name === E_DELIVERY_OPTION.SELF_SHIPPING ||
						selectedShip.name === E_DELIVERY_OPTION.PICK_UP ||
						selectedShip.name === E_DELIVERY_OPTION.DINE_IN) &&
					(selectedProductVoucher || selectedShippingVoucher
						? selectedProductVoucher?.payment_type?.includes(payment) ||
						  selectedShippingVoucher?.payment_type?.includes(payment)
						: true)
        )}
				eventType={eventType}
				eligiblePaymentMethods={shop.paymentOptions}
				language={language}
			/>
			<DimNotification
				isShow={isShowDimNotification}
				isPrimary={validationErrors.length > 0}
			>
				{validationErrors.length > 0
					? "Terjadi Perubahan. Konfirmasi Ulang Pesanan"
					: WORDING.message.VOUCHER_OUT_OF_STOCK[language]}
			</DimNotification>
			<BottomSheetOTP
				isShow={showOtpBottomSheet}
				setShow={setShowOtpBottomSheet}
				phone={phone}
				resendOTP={sendOTPtoBuyer}
				setShowExchangeCoinBottomSheet={setIsShowExchangeCoinBottomSheet}
				setOtpCode={setOtpCode}
				checkOtpValid={checkOTPValid}
				otpCode={otpCode}
				language={language}
				handleSubmit={handleOpenBill}
			/>
		</Container>
	);
};

export default ReviewStep;

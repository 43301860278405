import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import PhoneInput from "react-phone-input-2";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";
import PinAsset from "../assets/pin.png";
import PinBlueAsset from "../assets/pinBlue.png";
import ArrowAsset from "../assets/greyArrow.png";
import InputText from "./InputText";
import ButtonFull from "./ButtonFull";
import PopupMaps from "./PopupMaps";
import { Swipe } from "mirsahib-react-swipe-component";
import { IAddress } from "../interfaces";
import { MONAS_LAT, MONAS_LNG } from "../constants";
import { WORDING } from "../constants/translate";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden" : "scroll")};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
	z-index: 6;
	display: ${(props) => (props.isShow ? "block" : "none")};
	position: fixed;
	width: 100vh;
	max-width: 600px;
	background-color: rgba(26, 26, 26, 0.5);
	height: 100vh;
	bottom: 0;
	top: 0;
	overflow: hidden;
`;

const Content = styled.div`
  width: 100%;
  max-width: 600px;
  max-height: 70vh;
  padding-bottom: 20px;
  position: fixed;
  background-color: ${E_COLOR.WHITE};
  bottom: 0;
  border-radius: 16px 16px 0 0;
  overflow-y: scroll;
}
`;

const DragLine = styled.div`
	display: block;
	margin: 23px auto;
	border-radius: 100px;
	background-color: ${E_COLOR.NEW_LIGHT_GREY};
	width: 78px;
	height: 8px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px 16px 0;
`;

const Title = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 25px;
`;

const TextButton = styled.div`
	display: inline-block;
	float: right;
	padding-left: 7px;
	color: ${E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 12px;
	line-height: 18px;
`;

const ImgPin = styled.img`
	width: 11px;
	height: 14px;
`;

const SelectMapWrapper = styled.div``;

const DetailNotes = styled.div<{ isError: boolean }>`
	margin: ${({ isError }) => (isError ? "0 16px 0" : "24px 16px 20px")};
	color: ${({ isError }) => (isError ? E_COLOR.RED : E_COLOR.LIGHT_BLACK)};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 18px;
`;

const Label = styled.div`
	margin: 6px 16px 0;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
`;

export const InputAddress = styled.textarea`
	box-sizing: border-box;
	width: 92%;
	height: 85px;
	margin: 6px 16px;
	padding: 16px;
	border: 1px solid ${E_COLOR.NEW_GREY};
	border-radius: 8px;
	background-color: ${E_COLOR.NEW_GREY};
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 21px;
	outline-width: 0;
	font-family: "Open Sans";
	resize: none;

	&:focus {
		// border-color: ${E_COLOR.BLUE};
	}

	::placeholder {
		color: ${E_COLOR.GREY_DISABLED};
		font-weight: ${E_FONT_WEIGHT.REGULAR};
		font-size: 16px;
		line-height: 24px;
	}
`;

export const AddressSuggestion = styled.div`
	color: ${E_COLOR.RED};
	border: 1px solid ${E_COLOR.GREY_BORDER};
	padding: 7px 8px;
`;

export const PinPointButton = styled.div`
	display: flex;
	justify-content: space-between;
	min-height: 63px;
	align-items: center;
	padding: 5px 19px 7px 14px;
	margin: 24px 16px 18px;
	border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
	border-radius: 10px;
`;

export const PinIcon = styled.img`
	width: 18px;
	padding-right: 10px;
`;

export const TextPin = styled.div<{ small: boolean }>`
	width: 100%;
	margin-top: 5px;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: ${({ small }) => (small ? "12px" : "14px")};
	line-height: ${({ small }) => (small ? "19px" : "21px")};
`;

export const ArrowIcon = styled.img`
	width: 10px;
	padding-left: 13px;
`;

export const FieldInstruction = styled.div<{ isError: boolean }>`
	margin: 8px 16px 0;
	color: ${({ isError }) => (isError ? E_COLOR.RED : E_COLOR.NEW_GREY_TEXT)};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 16px;
`;

interface IProps {
	isShow: boolean;
	setShow: (isShow: boolean) => void;
	setIsMapPickerOpen: (isOpen: boolean) => void;
	handleInputAddress: (name: string, value: string | number) => void;
	handleSelectedAddress: (address: string) => void;
	// handleSaveAddress: () => void;
	isMapPickerOpen: boolean;
	inputSearchBar: string;
	setInputSearchBar: (inputSearchBar: string) => void;
	currentFillAddress: IAddress;
  setCurrentFillAddress: any;
	language: "id" | "en";
}

const BottomSheetAddress = ({
	isShow,
	setShow,
	setIsMapPickerOpen,
	handleInputAddress,
	// handleSaveAddress,
	isMapPickerOpen,
	inputSearchBar,
	setInputSearchBar,
	currentFillAddress,
  setCurrentFillAddress,
	language,
}: IProps) => {
	const isDisabled =
		currentFillAddress.name === "" ||
		currentFillAddress.detail === "" ||
		currentFillAddress.label === "" ||
		currentFillAddress.phone === "" || !currentFillAddress.detail;
  
	const onSave = () => {
		setShow(false);
		// @ts-ignore
		window.scrollTo(0, 0);
		// handleSaveAddress();
	};

	const handleCloseBackground = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (e.target === e.currentTarget) {
			setShow(false);
		}
	};

	return (
		<Container isShow={isShow} onClick={(e) => handleCloseBackground(e)}>
			<GlobalStyle isShow={isShow} />
			<Content>
				<Swipe
					nodeName="div"
					className="test"
					detectTouch
					onSwipedDown={() => setShow(false)}
				>
					<DragLine />
				</Swipe>
				<Header>
					<Title>{WORDING.general.ADDRESS[language]}</Title>
				</Header>

				<DetailNotes isError={false}>
					{WORDING.message.ADDRESS_UTILIZATION[language]}
				</DetailNotes>
				<Label>{WORDING.general.NAME[language]}</Label>
				<InputText
					placeholder={WORDING.general.RECIPIENT_NAME[language]}
					name="name"
					onChange={handleInputAddress}
					type="text"
					value={currentFillAddress.name}
					customMargin="8px 16px"
				/>
				<Label>{WORDING.general.PHONE_NUMBER[language]}</Label>
				<PhoneInput
					country={"id"}
					value={currentFillAddress.phone}
					placeholder={WORDING.general.PHONE_NUMBER[language]}
					onChange={(input) => handleInputAddress("phone", input)}
					inputStyle={{
						backgroundColor: E_COLOR.NEW_GREY,
						height: "50px",
						width: "100%",
						paddingLeft: "60px",
						border: `1px solid ${E_COLOR.NEW_GREY}`,
						fontSize: "16px",
						fontWeight: "bold",
					}}
					buttonStyle={{
						backgroundColor: E_COLOR.WHITE,
						marginRight: "10px",
						padding: "0 4px",
						border: `1px solid ${E_COLOR.NEW_GREY}`,
					}}
					containerStyle={{ margin: "6px 16px", width: "90%" }}
					countryCodeEditable={false}
				/>
				<FieldInstruction
					isError={
						currentFillAddress.phone?.charAt(2) === "0" &&
						currentFillAddress.phone?.substring(0, 2) === "62"
					}
				>
					{currentFillAddress.phone?.charAt(2) === "0" &&
					currentFillAddress.phone?.substring(0, 2) === "62"
						? WORDING.message.ERROR_ZERO_RESTRICT_PHONE_NUMBER[language]
						: WORDING.message.ZERO_RESTRICT_PHONE_NUMBER[language]}
				</FieldInstruction>
				{!currentFillAddress.detail ? (
					<PinPointButton onClick={() => setIsMapPickerOpen(true)}>
						<PinIcon src={PinAsset} />
						<TextPin small={false}>{WORDING.contact_page.MARK_PIN_POINT[language]}</TextPin>
						<ArrowIcon src={ArrowAsset} />
					</PinPointButton>
				) : (
					<PinPointButton onClick={() => setIsMapPickerOpen(true)}>
						<PinIcon src={PinBlueAsset} />
						<TextPin small={true}>{currentFillAddress.detail}</TextPin>
						<ArrowIcon src={ArrowAsset} />
					</PinPointButton>
				)}
				{/* <Label>Detail alamat (Opsional)</Label>
				<InputText
					placeholder="Nama Alamat, contoh: rumah"
					name="label"
					onChange={handleInputAddress}
					type="text"
					value={currentFillAddress.label}
					customMargin="8px 16px"
				/> */}
				<Label>{WORDING.general.ADD_INSTRUCTION[language]} ({WORDING.general.OPTIONAL[language]})</Label>
				<InputText
					placeholder={WORDING.contact_page.INSTRUCTION_PLACEHOLDER[language]}
					name="instruction"
					onChange={handleInputAddress}
					type="text"
					value={currentFillAddress.instruction}
					customMargin="8px 16px"
				/>
				<ButtonFull hexPrimary={E_COLOR.DARK_BLUE} isPrimary={true} isDisabled={isDisabled} onClick={onSave}>
					{WORDING.contact_page.SAVE_ADDRESS[language]}
				</ButtonFull>
				{/* <PopupMaps
					handleClose={() => {
						setIsMapPickerOpen(false);
					}}
					isOpen={isMapPickerOpen}
					// initialCoordinates={
					// 	currentFillAddress.lat === undefined
					// 		? userCoordinates
					// 		: { lat: currentFillAddress.lat, lng: currentFillAddress.lng }
					// }
					inputSearchBar={inputSearchBar}
					setInputSearchBar={setInputSearchBar}
					currentFillAddress={currentFillAddress}
					setCurrentFillAddress={setCurrentFillAddress}
					language={language}
					handleSaveAddress={handleSaveAddress}
				/> */}
			</Content>
		</Container>
	);
};

export default BottomSheetAddress;

import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle, keyframes } from "styled-components";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";
import { getHourMinuteFromTime } from "../utils";
import CloseAsset from "../assets/close.png";
import UpArrowAsset from "../assets/upArrow.png";
import DownArrowAsset from "../assets/downArrow.png";
import ButtonFull from "./ButtonFull";
import { WORDING } from "../constants/translate";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh !important" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden !important" : "scroll")};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
	z-index: 6;
	display: ${(props) => (props.isShow ? "block" : "none")};
	position: fixed;
	width: 100vh;
	max-width: 600px;
	background-color: rgba(26, 26, 26, 0.5);
	height: 100vh;
	bottom: 0;
	top: 0;
`;

const Content = styled.div`
	width: 100%;
	padding-bottom: 50px;
	max-width: 600px;
	min-height: 100px;
	position: fixed;
	background-color: ${E_COLOR.WHITE};
	overflow: hidden;
	bottom: 0;
	border-radius: 16px 16px 0 0;
`;

const ImgClose = styled.img`
	float: right;
	width: 16px;
	height: 16px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	border-radius: 5px;
	margin: 31px 20px 20px 18px;
`;

const HeaderLabel = styled.div`
	width: 70%;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 27px;
`;

const FulfillmentTypeContainer = styled.div``;

const RadioWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 0 16px 14px;
`;

const RadioContainer = styled.div`
	display: inline-block;
	vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const Icon = styled.svg`
	fill: none;
	stroke: white;
	stroke-width: 3px;
`;

const StyledRadio = styled.div<{ checked: boolean }>`
	display: inline-block;
	width: 23px;
	height: 23px;
	background: ${({ checked }) => (checked ? E_COLOR.DARK_BLUE : E_COLOR.WHITE)};
	border-radius: 50%;
	margin: 0 9px 2px 0;
	transition: all 150ms;
	border: 1px solid
		${({ checked }) => (checked ? E_COLOR.DARK_BLUE : E_COLOR.GREY_DISABLED)};

	${Icon} {
		visibility: ${(props) => (props.checked ? "visible" : "hidden")};
	}
`;

const RadioLabel = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	width: 60%;
`;

const SelectedTime = styled.div`
	display: flex;
	border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
	border-radius: 8px;
	padding: 15px;
	margin: 0 16px 8px;
`;

const SelectedTimeIcon = styled.img`
	width: 18px;
	height: 18px;
	margin-right: 12px;
`;

const SelectedTimeValue = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.MEDIUM};
	font-size: 14px;
	line-height: 21px;
`;

const TimePickerContainer = styled.div`
	width: 220px;
	margin: 0 auto;
	display: flex;
	justify-content: space-around;
	align-items: center;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
`;

const TimePickerButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const TimePickerSingle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${E_COLOR.LIGHT_BLUE};
	width: 70px;
	height: 70px;
	border-radius: 8px;
	margin: 10px 0;
`;

const SlideDown = keyframes`
  from {top: 0px;}
  to {top: 5px;}
`;

const TimePickerValue = styled.div`
	color: ${E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 36px;
	line-height: 37px;
	animation: ${SlideDown} 5s;
	animation-timing-function: ease;
`;

const TimePickerButton = styled.img`
	width: 18px;
`;

interface IProps {
  isShow: boolean;
  setShow: (isShow: boolean) => void;
	setTime: (time: string) => void;
	operationHour: any;
	time: string;
	language: "id" | "en";
	hexPrimary: string;
	title?: string;
  isToday?: boolean;
}

const BottomSheetTime = ({ isShow, setShow, operationHour, setTime, time, language, title, hexPrimary, isToday = false }: IProps) => {
	const [hour, setHour] = useState(0);
	const [minute, setMinute] = useState(0);

	useEffect(() => {
		setHour(getHourMinuteFromTime(time).hour);
		setMinute(getHourMinuteFromTime(time).minute);
	}, [time]);

  const handleChangeHoursWithOperationHour = (isUp: boolean) => {
    const startOperationHour = operationHour.start.slice(0, 5);
    const endOperationHour = operationHour.end.slice(0, 5);

    let currHour = hour;
    let currMin = minute;

    const { hour: startHour, minute: startMin } = getHourMinuteFromTime(startOperationHour);
    const { hour: endHour, minute: endMin } = getHourMinuteFromTime(endOperationHour);

    const today = new Date();

    const todayHours = today.getHours();
    const todayMinutes = today.getMinutes();

    const roundedMinutes = Math.ceil(todayMinutes / 10) * 10;
    const roundedHours = (roundedMinutes >= 60 ? 1 : 0) + todayHours;

    const resultMinutes = roundedMinutes >= 60 ? 0 : roundedMinutes;
    const resultHours = roundedHours >= 24 ? 0 : roundedHours;

    const totalMinutesStart = startHour * 60 + startMin;
    const totalMinutesEnd = endHour * 60 + endMin;
    let totalMinutesCurr = currHour * 60 + currMin;
    const totalMinutesToday = resultHours * 60 + resultMinutes;

		const open24H = startOperationHour === "00:00" && endOperationHour === "00:00";
  
    if (open24H) {
      if (isUp && hour !== 23) {
        totalMinutesCurr = (currHour + 1) * 60 + currMin;
        currHour += 1;
      }
  
      if (!isUp && hour !== 0) {
        totalMinutesCurr = (currHour - 1) * 60 + currMin;
        currHour -= 1;
      }

      // Check today
      if (isToday) {
        if (totalMinutesToday >= totalMinutesCurr) {
          currHour = resultHours;
          currMin = resultMinutes;
        }
      }

      setHour(currHour);
      setMinute(currMin);
      return;
    }

    if (isUp && hour !== 23) {
      totalMinutesCurr = (currHour + 1) * 60 + currMin;
    } else if (!isUp && hour !== 0) {
      totalMinutesCurr = (currHour - 1) * 60 + currMin;
    }

    if (totalMinutesStart <= totalMinutesCurr && totalMinutesCurr <= totalMinutesEnd) {
      currHour = (totalMinutesCurr - currMin) / 60;
    } else if (totalMinutesStart > totalMinutesCurr) {
      currHour = startHour;
      currMin = startMin;
    } else if (totalMinutesEnd < totalMinutesCurr) {
      currHour = endHour;
      currMin = endMin;
    }

    // Check today
    if (isToday) {
      if (totalMinutesToday >= totalMinutesCurr) {
        currHour = resultHours;
        currMin = resultMinutes;
      }
    }

    setHour(currHour);
    setMinute(currMin);
  };

  const handleChangeMinutesWithOperationHour = (isUp: boolean) => {
    const startOperationHour = operationHour.start.slice(0, 5);
    const endOperationHour = operationHour.end.slice(0, 5);

    let currHour = hour;
    let currMin = minute;

    const { hour: startHour, minute: startMin } = getHourMinuteFromTime(startOperationHour);
    const { hour: endHour, minute: endMin } = getHourMinuteFromTime(endOperationHour);

    const today = new Date();

    const todayHours = today.getHours();
    const todayMinutes = today.getMinutes();

    const roundedMinutes = Math.ceil(todayMinutes / 10) * 10;
    const roundedHours = (roundedMinutes >= 60 ? 1 : 0) + todayHours;

    const resultMinutes = roundedMinutes >= 60 ? 0 : roundedMinutes;
    const resultHours = roundedHours >= 24 ? 0 : roundedHours;

    const totalMinutesStart = startHour * 60 + startMin;
    const totalMinutesEnd = endHour * 60 + endMin;
    let totalMinutesCurr = currHour * 60 + currMin;
    const totalMinutesToday = resultHours * 60 + resultMinutes;

		const open24H = startOperationHour === "00:00" && endOperationHour === "00:00";
  
    if (open24H) {
      if (isUp && minute < 50) {
        totalMinutesCurr = currHour * 60 + (currMin + 10);
        currMin += 10;
      }
  
      if (!isUp && minute >= 10) {
        totalMinutesCurr = currHour * 60 + (currMin - 10);
        currMin -= 10;
      }

      // Check today
      if (isToday) {
        if (totalMinutesToday >= totalMinutesCurr) {
          currHour = resultHours;
          currMin = resultMinutes;
        }
      }

      setHour(currHour);
      setMinute(currMin);
      return;
    }

    if (isUp && minute < 50) {
      totalMinutesCurr = currHour * 60 + (currMin + 10);
    } else if (!isUp && minute >= 10) {
      totalMinutesCurr = currHour * 60 + (currMin - 10);
    }

    if (totalMinutesStart <= totalMinutesCurr && totalMinutesCurr <= totalMinutesEnd) {
      currMin = totalMinutesCurr - (currHour * 60);
    } else if (totalMinutesStart > totalMinutesCurr) {
      currHour = startHour;
      currMin = startMin;
    } else if (totalMinutesEnd < totalMinutesCurr) {
      currHour = endHour;
      currMin = endMin;
    }

    // Check today
    if (isToday) {
      if (totalMinutesToday >= totalMinutesCurr) {
        currHour = resultHours;
        currMin = resultMinutes;
      }
    }

    setHour(currHour);
    setMinute(currMin);
  };

	const handleSaveTime = () => {
		setTime(`${hour}:${minute}`);
		setShow(false);
	}

  return (
    <Container isShow={isShow}>
			<GlobalStyle isShow={isShow} />
			<Content>
				<Header>
					<HeaderLabel>{title ? title : WORDING.general.DELIVERY_TIME[language]}</HeaderLabel>
					<ImgClose onClick={() => setShow(false)} src={CloseAsset} />
				</Header>
				
					<TimePickerContainer>
						<TimePickerButtonsWrapper>
							<TimePickerButton
								src={UpArrowAsset}
								onClick={() => handleChangeHoursWithOperationHour(true)}
							/>
							<TimePickerSingle>
								<TimePickerValue>
									{hour < 10 ? `0${hour}` : hour}
								</TimePickerValue>
							</TimePickerSingle>
							<TimePickerButton
								src={DownArrowAsset}
								onClick={() => handleChangeHoursWithOperationHour(false)}
							/>
						</TimePickerButtonsWrapper>
						:
						<TimePickerButtonsWrapper>
							<TimePickerButton
								src={UpArrowAsset}
								onClick={() => handleChangeMinutesWithOperationHour(true)}
							/>
							<TimePickerSingle>
								<TimePickerValue>
									{minute < 10 ? `0${minute}` : minute}
								</TimePickerValue>
							</TimePickerSingle>
							<TimePickerButton
								src={DownArrowAsset}
								onClick={() => handleChangeMinutesWithOperationHour(false)}
							/>
						</TimePickerButtonsWrapper>
					</TimePickerContainer>
				<ButtonFull
					hexPrimary={hexPrimary}
					isDisabled={false}
					isPrimary={true}
					onClick={handleSaveTime}
					customMargin="30px 16px 12px"
					customWidth="calc(100% - 32px)"
					id="bt-apply-date-time"
				>
					{WORDING.general.APPLY[language]}
				</ButtonFull>
			</Content>
		</Container>
  )
}

export default BottomSheetTime;

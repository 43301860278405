import { IOutlet } from "../interfaces";
import { E_USER_AGENT } from "../interfaces/dataTypes";

// formatted date is date that follow backend's date formatting
export const getFormattedDate = (date?: Date) => {
  const formattedDate = date ? date : new Date();
  return `${formattedDate.getDate()}-${
    formattedDate.getMonth() + 1
  }-${formattedDate.getFullYear()}`;
};

// unformatted date is date that follow JS date formatting
export const getUnformattedDate = (date?: string) => {
  if (date) {
    const d = date.split("-");
    return new Date(parseInt(d[2]), parseInt(d[1]) - 1, parseInt(d[0]));
  } else {
    return new Date();
  }
};

export const getMonthName = (date: Date, isEng?: boolean) => {
  const monthIndo = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ];

  const monthEng = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = date.getMonth();

  return isEng ? monthEng[month] : monthIndo[month];
};

export const getDayName = (date: Date, isEng?: boolean) => {
  const days = isEng
    ? ["S", "M", "T", "W", "T", "F", "S"]
    : ["M", "S", "S", "R", "K", "J", "S"];
  const day = date.getDay();

  return days[day];
};

export const getDayBackendName = (date: Date) => {
  const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
  const day = date.getDay();

  return days[day];
};

export const getLongDayName = (date: Date, isEng?: boolean) => {
  const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
  const daysEng = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const day = date.getDay();
  return isEng ? daysEng[day] : days[day];
};

export const getDateDay7 = (isAfter: boolean, date?: Date) => {
  const d = date ? date : new Date();
  if (isAfter) {
    d.setDate(d.getDate() + 7);
  } else {
    d.setDate(d.getDate() - 7);
  }

  return d;
};

export const getExpiredDate = (transactionTime: string) => {
  const time = transactionTime.split(" ");
  const unformattedDate = getUnformattedDate(time[0]);
  unformattedDate.setDate(unformattedDate.getDate() + 3);

  return `${unformattedDate.getDate()} ${getMonthName(unformattedDate, false)} ${unformattedDate.getFullYear()}`;
}

export const parseIDR = (amount: number) => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const getStepState = (path: string) => {
  const paths = path.split("/");
  const stepState = paths[2] ? paths[2] : "date";
  return stepState;
};

export const getMondayDate = (d: Date) => {
  const monday = new Date(d.getTime());
  const day = monday.getDay(),
    diff = monday.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(monday.setDate(diff));
};

export const countPriceAfterDiscount = (
  itemPrice: number,
  discountPercent: number,
  maxDiscount: number
) => {
  const discount = (itemPrice * discountPercent) / 100;
  const finalDiscount = discount > maxDiscount ? maxDiscount : discount;
  const priceAfterDiscount = itemPrice - finalDiscount;

  return priceAfterDiscount;
};

export const countDiscount = (
  itemPrice: number,
  discountPercent: number,
  maxDiscount?: number | null
) => {
  const maximum = maxDiscount ? maxDiscount : 99999999; //99M
  const discount = (itemPrice * discountPercent) / 100;
  const finalDiscount = discount > maximum ? maximum : discount;

  return finalDiscount;
};

export const getFullDateLabel = (date: Date, withYear?: boolean) => {
  if (withYear) {
    return `${date.getDate()} ${getMonthName(date)} ${date.getFullYear()}`;
  }

  return `${date.getDate()} ${getMonthName(date)}`;
};

export const findIfDateInclude = (date: Date, startDate: Date | null, endDate: Date | null) => {
  if(startDate === null || endDate === null) {
    return false;
  } else {
    return date >= startDate && date <= endDate;
  }
}

export const findIfTimeInclude = (time: string, startTime: string, endTime: string) => {
  const timeHour = parseInt(time.substring(0,2));
  const timeMinute = parseInt(time.substring(3,5));
  const startTimeHour = parseInt(startTime.substring(0,2));
  const startTimeMinute = parseInt(startTime.substring(3,5));
  const endTimeHour = parseInt(endTime.substring(0,2)) === 0 ? 24 : parseInt(endTime.substring(0,2));
  const endTimeMinute = parseInt(endTime.substring(3,5));
  
  let included = false;
  if(timeHour >= startTimeHour && timeHour < endTimeHour) {
    included = true;
  } else if(timeHour === endTimeHour) {
    if (
      (startTimeHour === endTimeHour && timeMinute >= startTimeMinute && timeMinute <= endTimeMinute) ||
      (startTimeHour !== endTimeHour && timeMinute <= endTimeMinute)
    ) {
      included = true;
    }
  }

  return included;
}

export const getMobileOperatingSystem = () => {
  // @ts-ignore
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
      return E_USER_AGENT.WINDOWS_PHONE;
  }

  if (/android/i.test(userAgent)) {
      return E_USER_AGENT.ANDROID;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return E_USER_AGENT.IOS;
  }

  return "unknown";
}

export const getTimeHourMinutes = (date: Date) => {
  return `${date.getHours() < 10 ? "0" : ""}${date.getHours()}:${date.getMinutes() < 10 ? "0" : ""}${date.getMinutes()}`
}

export const convertOtherTimeToLocal = (time: string) => {
  // format time from be example "07:00+2"
  const local = new Date();
  const offsetOther = parseInt(time.slice(time.length-2,time.length));
  const offsetLocal = local.getTimezoneOffset() * -1 / 60;
  const hourOther = parseInt(time.slice(0,2));
  const minuteOther = parseInt(time.slice(3,5));

  const offsetLocalMins = (offsetLocal % 1) * 60;
  const offset = Math.floor(offsetLocal) - offsetOther;
  const hourLocal = hourOther + offset;
  
  let resultHour = hourLocal;
  let resultMins = minuteOther + offsetLocalMins;
  if(resultMins > 59) {
    if(resultMins === 60) {
      resultMins = 0;
      resultHour = resultHour + 1;
    } else {
      resultMins = resultMins % 60;
      resultHour = resultHour + 1;
    }
  }
  resultHour = resultHour > 24 ? resultHour - 24 : resultHour < 0 ? 24 + resultHour : resultHour;

  return `${resultHour < 10 ? "0" : ""}${resultHour}:${resultMins < 10 ? "0" : ""}${resultMins}`;
}

export const findIfPassedTime = (timeToFind: string, timeToCompare: string) => {
  const hourToFind = parseInt(timeToFind.substring(0,2));
  const hourToCompare = parseInt(timeToCompare.substring(0,2));
  const minuteToFind = parseInt(timeToFind.substring(3,5));
  const minuteToCompare = parseInt(timeToCompare.substring(3,5));

  let isPassed = false;
  if(hourToFind >= hourToCompare) {
    if(hourToFind === hourToCompare) {
      if(minuteToFind > minuteToCompare) {
        isPassed = true;
      }
    } else {
      isPassed = true;
    }
  }

  return isPassed;
}

export const findDistanceByCoordinates = (lat1: number, lng1: number, lat2: number, lng2: number) => {
  const R = 6371; // km
  const dLat = convertNumDegreeToRad(lat2-lat1);
  const dLon = convertNumDegreeToRad(lng2-lng1);
  const lat1Rad = convertNumDegreeToRad(lat1);
  const lat2Rad = convertNumDegreeToRad(lat2);

  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1Rad) * Math.cos(lat2Rad); 
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  const d = R * c;
  return d.toFixed(1);
}

const convertNumDegreeToRad = (numDegree: number) => {
  return numDegree * Math.PI / 180;
}

// time format "12:30"
export const getHourMinuteFromTime = (time: string) => {
  const splitTime = time.split(":");
  
  return { hour: parseInt(splitTime[0]), minute: parseInt(splitTime[1]) };
}

export const getInitialFromSentence = (sentence: string) => {
  var matches = sentence.match(/\b(\w)/g);
  var acronym = matches.join('');

  return acronym;
}

export const countPotentialCoin = (amountItem: number, discountVoucherAmount: number, coinToExchange: number, valuePerCoin: number) => {
  let result = amountItem-discountVoucherAmount-((coinToExchange ? coinToExchange : 0) * (valuePerCoin ? valuePerCoin : 0));
  result = result < 0 ? 0 : result;
  return Math.floor(result/1000);
}

export const calcRoundedValue = (roundType: "round" | "round_up", roundValue: number, value: number) => {
  if(roundType === "round") {
    return Math.round((value/roundValue))*roundValue;
  } else {
    return Math.ceil((value/roundValue))*roundValue;
  }
}

export const calcDiscountPercentage = (price: number, discountedPrice: number) => {
  return ((price-discountedPrice)/price*100).toFixed(0);
}

export const convertLocalTimeToOtherByOffset = (localOffset: number, targetOffset: number) => {
  const timestamp = new Date().getTime() + ((localOffset - targetOffset) * 60000);
  
  return new Date(timestamp);
}

import React, { useState } from "react";
import styled from "styled-components";
import { E_COLOR, E_DELIVERY_OPTION, E_FONT_WEIGHT } from "../../interfaces/dataTypes";
import { ISelectedShip, IShip } from "../../interfaces";
import { parseIDR } from "../../utils";
import { ReactComponent as TruckAsset } from "../../assets/truck.svg";
import ArrowAsset from "../../assets/greyArrow.png";
import BottomSheetShipOption from "./BottomSheetShipOptions";
import { SHIPPING_WORDING } from "../../constants";
import { WORDING } from "../../constants/translate";

const Container = styled.div`
  margin: 18px 16px 20px;
`;

const Option = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid ${E_COLOR.BLACK_LINE};
`;

const ErrorShipping = styled.div`
  margin: 7px 0 18px;
  font-size: 15px;
  line-height: 24px;
  color: ${E_COLOR.GREY_DISABLED};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
`;

const OptionLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionLabel = styled.div`
  color: ${E_COLOR.BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 14px;
  line-height: 17px;
`;

const OptionRadio = styled.input`
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${E_COLOR.BLACK_LINE};

  &:checked {
    background: ${E_COLOR.DARK_BLUE};
    border: solid 2px ${E_COLOR.DARK_BLUE};
  }
`;

const ButtonDefault = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
  border-radius: 10px;
  padding: 21px 15px;
`;

const ButtonFilled = styled.div`
  align-items: center;
  border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
  border-radius: 10px;
  padding: 21px 15px 17px;
`;

const SelectedTypeSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 22px;
  border-bottom: 1px solid ${E_COLOR.BLACK_LINE};
`;

const TypeLabel = styled.div`
  width: 100%;
  color: ${E_COLOR.LIGHT_BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 14px;
  line-height: 21px;
  padding: 0 15px;
`;

const SelectedLogisticSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
`;

const LogisticLabel = styled.div`
  width: 100%;
  color: ${E_COLOR.LIGHT_BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 14px;
  line-height: 21px;
  padding-right: 15px;
`;

const ButtonLabel = styled.div`
  width: 100%;
  color: ${E_COLOR.LIGHT_BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 14px;
  line-height: 21px;
  padding: 0 14px;
`;

const ImgArrow = styled.img`
  width: 7.5px;
  height: 15px;
`;

const Checkbox = styled.input`
  width: 22px;
  height: 22px;
  border-radius: 5px;
  border: none;
  margin-right: 14px;
  border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
  background: ${E_COLOR.WHITE};

  input:checked {
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    height: 4px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    width: 9px;
  }
`;

const InsuranceSection = styled.div`
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: space-between;
`;

const InsurancePartWrapper = styled.div`
  display: flex;
  align-items: center;
`

const InsuranceLabel = styled.div`
  color: ${E_COLOR.LIGHT_BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 14px;
  line-height: 21px;
`

interface IProps {
  handleSelectShip: (
    name: string,
    price: number,
    rateId: number | string,
    type: string,
    withInsurance: boolean,
    insuranceRate: number,
  ) => void;
  options: IShip[];
  selectedShip: ISelectedShip;
  isAddressFilled: boolean;
  deliveryOptions: string[];
  merchantShip: {
    price: number;
    note: string;
  };
  isWithInsurance: boolean;
  setIsWithInsurance: (isWithInsurance: boolean) => void;
  handleReportShipping: () => void;
  language: "id" | "en";
}

const ShipOptions = ({
  handleSelectShip,
  options,
  selectedShip,
  isAddressFilled,
  deliveryOptions,
  merchantShip,
  isWithInsurance,
  setIsWithInsurance,
  handleReportShipping,
  language,
}: IProps) => {
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [isChooseShippingType, setIsChooseShippingType] = useState(true);
  const { name, price, type, withInsurance, insuranceRate } = selectedShip;

  const handleClickChooseShipping = (isSelectType: boolean) => {
    if (type !== "self_shipping" && isSelectType) {
      setIsBottomSheetOpen(true);
      setIsChooseShippingType(isSelectType);
    }
    if(isSelectType) {
      setIsBottomSheetOpen(true);
      setIsChooseShippingType(isSelectType);
    } else {
      if (type !== "self_shipping") {
        setIsBottomSheetOpen(true);
        setIsChooseShippingType(isSelectType);
      }
    }
  };
  
  return (
    <React.Fragment>
      <Container>
        {selectedShip.type ? (
            <React.Fragment>
              <ButtonFilled>
                <SelectedTypeSection
                  onClick={() => handleClickChooseShipping(true)}
                >
                  <TruckAsset
                    stroke={E_COLOR.DARK_BLUE}
                    width="24px"
                    height="24px"
                  />
                  <TypeLabel>
                    {
                      SHIPPING_WORDING.filter(
                        (shipping) => shipping.key === type
                      )[0].label
                    }
                  </TypeLabel>
                  <ImgArrow src={ArrowAsset} />
                </SelectedTypeSection>
                <SelectedLogisticSection
                  onClick={() => handleClickChooseShipping(false)}
                >
                  <LogisticLabel>
                    {name} (Rp {parseIDR(withInsurance ? price - insuranceRate : price || 0)})
                  </LogisticLabel>
                  <ImgArrow src={ArrowAsset} />
                </SelectedLogisticSection>
              </ButtonFilled>
              {selectedShip.type !== "self_shipping" && <InsuranceSection>
                <InsurancePartWrapper>
                  <Checkbox
                    type='checkbox'
                    value='save'
                    name='save-info'
                    id='check-save'
                    checked={isWithInsurance || selectedShip.withInsurance}
                    onChange={() => setIsWithInsurance(!isWithInsurance)}
                  />
                  <InsuranceLabel>{WORDING.general.INSURANCE[language]}</InsuranceLabel>
                </InsurancePartWrapper>
                <InsuranceLabel>Rp {parseIDR(selectedShip.insuranceRate)}</InsuranceLabel>
              </InsuranceSection>}
            </React.Fragment>
          ) : isAddressFilled ? (
            <ButtonDefault onClick={() => handleClickChooseShipping(true)}>
              <TruckAsset
                stroke={E_COLOR.NEW_BLACK}
                width="24px"
                height="24px"
              />
              <ButtonLabel>{WORDING.contact_page.CHOOSE_COURIER[language]}</ButtonLabel>
              <ImgArrow src={ArrowAsset} />
            </ButtonDefault>
          ) : (<ErrorShipping>
          {WORDING.contact_page.INPUT_ADDRESS_FIRST[language]}
          </ErrorShipping>)}
      </Container>
      <BottomSheetShipOption
        handleSelectShip={handleSelectShip}
        selectedShip={selectedShip}
        isShow={isBottomSheetOpen}
        isSelectType={isChooseShippingType}
        setShow={setIsBottomSheetOpen}
        options={options}
        deliveryOptions={deliveryOptions}
        merchantShip={merchantShip}
        handleReportShipping={handleReportShipping}
        language={language}
      />
    </React.Fragment>
  );
};

export default ShipOptions;

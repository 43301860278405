import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ItemList from "../../components/ItemList";
import ItemCards from "../../components/ItemCards";
import BottomSheetDates from "../../components/BottomSheetDates";
import {
	Container,
	TextPrice,
	TextTotal,
	TotalPriceContainer,
	FixedComponentWrapper,
	AlarmIcon,
	ArrowRightIcon,
	ChatButton,
	ChatButtonText,
	ChatIcon,
	DateButton,
	DateText,
	ShopName,
	ShopProfileWrapper,
	ErrorMessage,
	ButtonWrapper,
	ButtonNext,
	CloseStoreLabel,
	HeaderImage,
	ProductViewIcon,
	ProductViewLabel,
	ProductViewWrapper,
	Footer,
	Logo,
	WAButton,
	WALabel,
	WALogo,
	CarouselContainer,
	SeeOtherOutletButton,
	OutletIcon,
	OtherOutletLabel,
	SearchBar,
	SearchIcon,
	SearchInput,
	SearchBarWrapper,
	SearchBarRelative,
	MerchantWrapper,
	MerchantLogo,
	MerchantName,
	ArrowIcon,
	FoodCourtContainer,
	BackButtonFoodCourt,
	ArrowLeftAsset,
	BackButtonFoodCourtLabel,
	SearchCategorySticky,
	Categories,
	CategoryWrapper,
	CategoryLabel,
	CategoryActiveHighlight,
	FoodCourtLabel,
	ShopHeaderWrapper,
	InfoIcon,
	GreyLayerBanner,
	StoreCloseBar,
	StoreCloseBarHeaderText,
	StoreCloseBarText,
	ButtonActionWrapper,
	DropdownLang,
	CurrentLang,
	CurrentLangIcon,
	CurrentLangText,
	ArrowDownIcon,
	OtherLang,
	OtherLangText,
	CompanyLogo,
	MerchantInitial,
	MerchantInitialLogo,
	FoodcourtContainer,
	FoodcourtBanner,
	FoodcourtHeader,
	FoodcourtName,
	FoodcourtInfoIcon,
	FoodcourtInstruction,
	FoodcourtMerchantList,
	FoodcourtMerchant,
	FoodcourtMerchantLogo,
	FoodcourtMerchantName,
	BackToFoodcourtStickyButton,
	SearchBarRegular,
	BackIcon,
	BackText,
	FoodcourtMerchantClose,
	FoodcourtMerchantLogoWrapper,
	FoodcourtCartTenant,
	TotalIconWrapper,
	IconTotalCart,
	IconTotalNum,
	ButtonOrderDetail,
	TextTotalLight,
	TextTotalPrice,
	TextTotalWrapper,
} from "../../styles/ItemsStep";
import { useItemsStepHook } from "../../hooks/ItemsStep";
import {
	getLongDayName,
	findIfDateInclude,
	getMonthName,
	parseIDR,
	findIfTimeInclude,
	convertOtherTimeToLocal,
	getInitialFromSentence,
} from "../../utils";
import blueArrowRightAsset from "../../assets/blueArrowRight.png";
import alarmAsset from "../../assets/alarm.png";
import headerImageAsset from "../../assets/header.png";
import OutletAsset from "../../assets/outlet.png";
import listViewAsset from "../../assets/listView.png";
import cardViewAsset from "../../assets/cardView.png";
import WAAsset from "../../assets/wa.png";
import LogoAsset from "../../assets/logo.png";
import LogoWhiteAsset from "../../assets/logoWhite.png";
import SearchAsset from "../../assets/searchBlack.png";
import ArrowAsset from "../../assets/arrow.png";
import InfoAsset from "../../assets/info.png";
import WhiteArrowLeftAsset from "../../assets/whiteArrowLeft.png";
import ReservationAsset from "../../assets/calendarBlack.png";
import IdAsset from "../../assets/id.png";
import EnAsset from "../../assets/en.png";
import BackAsset from "../../assets/back.png";
import PitikUnderThreeAsset from "../../assets/pitikUnderThree.png";
import WhiteArrowDownAsset from "../../assets/whiteArrowDown.png";
import FoodcourtBannerAsset from "../../assets/foodcourtBanner.png";
import FoodcourtDefaultAsset from "../../assets/foodcourtDefault.png";
import InfoBlackAsset from "../../assets/infoBlack.png";
import CartAsset from "../../assets/cart.png";
import BottomSheetItemDetail from "../../components/BottomSheetItemDetail";
import BottomSheetExistingItem from "../../components/BottomSheetExistingItem";
import BottomSheetOrderMethod from "../../components/BottomSheetOrderMethod";
import { E_DELIVERY_OPTION } from "../../interfaces/dataTypes";
import BottomSheetOutlets from "../../components/BottomSheetOutlets";
import BottomSheetDateTime from "../../components/BottomSheetDateTime";
import BottomSheetDineInInfo from "../../components/BottomSheetDineInInfo";
import BottomSheetRating from "../../components/BottomSheetRating";
import Search from "../../components/Search";
import BottomSheetSuccess from "../../components/BottomSheetSuccess";
import PopupCommon from "../../components/PopupCommon";
import { WORDING } from "../../constants/translate";
import {
	SHOP_LINK_FOOD_EVENT_AS_FOOD_COURT,
	URL_VALUE_SOURCE_PICK_UP,
	URL_VALUE_SOURCE_QR_TABLE,
} from "../../constants";
import { DimNotification } from "../../styles/ReviewStep";
import BottomSheetFoodcourtSuggestion from "../../components/BottomSheetFoodcourtSuggestion";
import BottomSheetOTP from "../../components/BottomSheetOTP";
import BottomSheetOrderDetail from "../../components/BottomSheetOrderDetail";

const ItemsStep = () => {
	const {
		currentCategories,
		totalItem,
		temporaryTotalAmount,
		handleClickAction,
		handleNextAndPrevDateInterval,
		handleSelectDate,
		shop,
		sendWaMessageToMerchant,
		currentItems,
		currentOneWeekCategories,
		currentDate,
		handleOpenItemDetail,
		currentItem,
		isShowBottomSheetItemDetail,
		setIsShowBottomSheetItemDetail,
		handleAddSelectedItem,
		isShowBottomSheetExistingItem,
		setIsShowBottomSheeExistingtItem,
		filterSelectedItemBySameId,
		handleChangeDetailExistingItem,
		handleDeleteExistingItem,
		editedItem,
		setEditedItem,
		handleChooseOrderMethod,
		isShowBottomSheetOrderMethod,
		setIsShowBottomSheetOrderMethod,
		countQuantitySelectedItems,
		countQuantityItemSameId,
		selectedDate,
		fulfillmentTime,
		currentTime,
		setCurrentTime,
		handleSaveDateTime,
		currentOperationalHour,
		isShopCloseForSelectedTime,
		isShowBottomSheetDineInInfo,
		setIsShowBottomSheetDineInInfo,
		handleConfirmDineInOrder,
		isShopCloseNow,
		setIsShowBottomSheetRating,
		isShowBottomSheetRating,
		handleSubmitRating,
		itemsRating,
		buyer,
		successSubmitRating,
		listItem,
		searchedItems,
		findSearchedItems,
		setKeyword,
		keyword,
		isSearchComponentSticky,
		shopType,
		foodEventData,
		handleRedirectOtherShop,
		foodEventName,
		activeCategory,
		currRoute,
		isShowBottomSheetSuccess,
		setIsShowBottomSheetSuccess,
		language,
		handleChangeLanguage,
		isDropdownLangActive,
		setDropdownLangActive,
		handleClosePopupSuccessReservation,
		tableNumber,
		sourceState,
		isShowDimNotification,
		isHideOtherOutlet,
		isPopupCommonSelected,
		isPopupCommonOpen,
		handleClosePopupCommon,
		merchants,
		handleClickFoodcourtMerchant,
		handleAddToCartFoodcourt,
		foodcourtCart,
		merchant,
		isTreatAsMerchantFoodcourt,
		// OPEN BILL
		isOpenBill,
		phoneNumberOTP,
		showOTPBottomSheet,
		setShowOTPBottomSheet,
		sendOTPtoBuyer,
		checkOTPValid,
		handleSubmitOTPOpenBill,
		isHideTakeaway,
	} = useItemsStepHook();
	const [isShowBottomSheetDates, setIsShowBottomSheetDates] = useState(false);
	const [isShowBottomSheetOutlets, setIsShowBottomSheetOutlets] =
		useState(false);
	const [isShowBottomSheetTime, setIsShowBottomSheetTime] = useState(false);
	const [
		isShowBottomSheetFoodcourtSuggestion,
		setIsShowBottomSheetFoodcourtSuggestion,
	] = useState(false);
	const [isShowSearch, setIsShowSearch] = useState(false);
	const [isShowOrderDetail, setIsShowOrderDetail] = useState(false);
	const [isViewList, setIsViewList] = useState(false);
	let openDate = shop.close.end ? new Date(shop.close.end) : new Date();
	openDate.setDate(openDate.getDate() + 1);

	const settings = {
		dots: true,
		arrows: false,
		draggable: true,
		autoplay: true,
		autoplaySpeed: 3000,
	};

	const handleBackToFoodcourt = () => {
		setIsShowBottomSheetFoodcourtSuggestion(false);
		handleAddToCartFoodcourt(merchant.name);
	};

	const handleVisitMerchant = (link: string) => {
		setIsShowBottomSheetFoodcourtSuggestion(false);
		handleAddToCartFoodcourt(link, true);
	};

	const handleOnClickCategory = (id: string) => {
		// @ts-ignore
		const element = document.getElementById(id);
		const headerOffset = 60;
		const elementPosition = element?.getBoundingClientRect?.().top;
		// @ts-ignore
		const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

		// @ts-ignore
		window.scrollTo({
			top: offsetPosition,
			behavior: "smooth",
		});
	};

	const handleClickNext = () => {
		//	@ts-ignore
		const orderSN = sessionStorage.getItem("ordersn");
		// check if buyer currently add more product for open bill order
		if (orderSN) {
			handleChooseOrderMethod(E_DELIVERY_OPTION.DINE_IN);
		} else {
			if (shop.deliveryOptions.length === 1) {
				if (shop.deliveryOptions.includes(E_DELIVERY_OPTION.PICK_UP)) {
					handleChooseOrderMethod(E_DELIVERY_OPTION.PICK_UP);
				} else if (shop.deliveryOptions.includes(E_DELIVERY_OPTION.DELIVERY)) {
					handleChooseOrderMethod(E_DELIVERY_OPTION.DELIVERY);
				} else if (shop.deliveryOptions.includes(E_DELIVERY_OPTION.DINE_IN)) {
					handleChooseOrderMethod(E_DELIVERY_OPTION.DINE_IN);
				} else if (
					shop.deliveryOptions.includes(E_DELIVERY_OPTION.SELF_SHIPPING)
				) {
					handleChooseOrderMethod(E_DELIVERY_OPTION.DELIVERY);
				}
			} else if (sourceState === URL_VALUE_SOURCE_PICK_UP) {
				handleChooseOrderMethod(E_DELIVERY_OPTION.PICK_UP);
			} else if (
				((sourceState === URL_VALUE_SOURCE_QR_TABLE || tableNumber) &&
					shop.deliveryOptions.includes(E_DELIVERY_OPTION.DINE_IN)) ||
				merchant
			) {
				//means its central foodcourt
				if (merchant && !isShowBottomSheetFoodcourtSuggestion) {
					if (shop.deliveryOptions.includes(E_DELIVERY_OPTION.DELIVERY)) {
						setIsShowBottomSheetOrderMethod(true);
					} else {
						setIsShowBottomSheetFoodcourtSuggestion(true);
					}
				} else {
					handleChooseOrderMethod(E_DELIVERY_OPTION.DINE_IN);
				}
			} else {
				setIsShowBottomSheetOrderMethod(true);
			}
		}
	};

	const banners =
		shopType === "FOOD_EVENT"
			? foodEventData?.banners
			: shopType === "FOODCOURT"
			? shop?.banners
			: merchant?.banners
			? merchant.banners
			: shop.banners;

	const renderShop = () => {
		const logoUrl =
			shopType === "FOODCOURT" || shopType === "FOOD_EVENT"
				? shop?.logoUrl
				: merchant?.logoUrl;

		return (
			<React.Fragment>
				{isPopupCommonSelected && (
					<PopupCommon
						isOpen={isPopupCommonOpen}
						buttonLabel="Baik, Saya Mengerti"
						description="Anak dibawah 3 tahun tidak memerlukan tiket. Dan apabila sudah dibeli, tiket tidak bisa direfund."
						imageUrl={PitikUnderThreeAsset}
						title="Perlu Diketahui"
						onClose={handleClosePopupCommon}
					/>
				)}
				{isShopCloseForSelectedTime && (
					<StoreCloseBar>
						<StoreCloseBarHeaderText>
							{WORDING.item_page.STORE_CLOSE[language]}
						</StoreCloseBarHeaderText>
						<StoreCloseBarText>
							{shop.outlets.length > 1
								? shop.reservation
									? WORDING.item_page
											.STORE_CLOSE_MESSAGE_MULTI_OUTLET_RESERVATION[language]
									: WORDING.item_page.STORE_CLOSE_MESSAGE_MULTI_OUTLET[language]
								: shop.reservation
								? WORDING.item_page.STORE_CLOSE_MESSAGE_RESERVATION[language]
								: WORDING.item_page.STORE_CLOSE_MESSAGE[language]}
						</StoreCloseBarText>
					</StoreCloseBar>
				)}
				<CarouselContainer>
					{banners?.length > 1 ? (
						<Slider {...settings}>
							{banners.map((image: string) => (
								<React.Fragment>
									<HeaderImage src={image} alt="product" />
								</React.Fragment>
							))}
						</Slider>
					) : (
						<React.Fragment>
							<HeaderImage
								src={banners?.length === 1 ? banners[0] : headerImageAsset}
							/>
						</React.Fragment>
					)}
					<DropdownLang>
						<CurrentLang
							onClick={() => setDropdownLangActive(!isDropdownLangActive)}
						>
							<CurrentLangIcon src={language === "id" ? IdAsset : EnAsset} />
							<CurrentLangText>
								{language === "id" ? "ID" : "EN"}
							</CurrentLangText>
							<ArrowDownIcon src={WhiteArrowDownAsset} />
						</CurrentLang>
						{isDropdownLangActive && (
							<OtherLang onClick={handleChangeLanguage}>
								<CurrentLangIcon src={language === "id" ? EnAsset : IdAsset} />
								<OtherLangText>{language === "id" ? "EN" : "ID"}</OtherLangText>
								{/* <ArrowDownIcon src={WhiteArrowDownAsset} /> */}
							</OtherLang>
						)}
					</DropdownLang>
					{logoUrl && <CompanyLogo src={logoUrl} />}
					{isShopCloseForSelectedTime && <GreyLayerBanner />}
				</CarouselContainer>
				<ShopHeaderWrapper
					onClick={() => handleClickAction(true)}
					id="btn-shop-info"
				>
					<ShopName isGrey={isShopCloseForSelectedTime}>
						{merchant ? merchant.name : shop.name}
					</ShopName>
					<InfoIcon src={InfoAsset} />
				</ShopHeaderWrapper>
				{(foodEventName || merchant) && isTreatAsMerchantFoodcourt && (
					<BackButtonFoodCourt
						onClick={() =>
							handleAddToCartFoodcourt(foodEventName || merchant.name)
						}
					>
						<ArrowLeftAsset src={WhiteArrowLeftAsset} />
						<BackButtonFoodCourtLabel>
							{WORDING.item_page.BACK_TO_FOODCOURT[language]}
						</BackButtonFoodCourtLabel>
					</BackButtonFoodCourt>
				)}
				{!isTreatAsMerchantFoodcourt && (
					<ButtonActionWrapper>
						{shop.outlets.length > 1 &&
							!tableNumber &&
							sourceState !== URL_VALUE_SOURCE_QR_TABLE &&
							!isHideOtherOutlet && (
								<SeeOtherOutletButton
									onClick={() => setIsShowBottomSheetOutlets(true)}
									isSingle={shop.outlets.length > 1 && !shop.reservation}
								>
									<OutletIcon src={OutletAsset} />
									<OtherOutletLabel>
										{WORDING.item_page.MULTI_OUTLETS[language].replace(
											"{length}",
											`${shop.outlets.length - 1}`
										)}
									</OtherOutletLabel>
								</SeeOtherOutletButton>
							)}
						{shop.reservation && (
							<SeeOtherOutletButton
								onClick={() => handleClickAction(false)}
								isSingle={
									(shop.reservation && shop.outlets.length <= 1) ||
									tableNumber !== "" ||
									sourceState === URL_VALUE_SOURCE_QR_TABLE ||
									isHideOtherOutlet
								}
							>
								<OutletIcon src={ReservationAsset} />
								<OtherOutletLabel>
									{WORDING.item_page.RESERVATION[language]}
								</OtherOutletLabel>
							</SeeOtherOutletButton>
						)}
					</ButtonActionWrapper>
				)}
				{!tableNumber &&
					sourceState !== URL_VALUE_SOURCE_QR_TABLE &&
					!isPopupCommonSelected &&
					!isTreatAsMerchantFoodcourt && (
						<DateButton
							onClick={() => setIsShowBottomSheetTime(true)}
							id="btn-date-time"
							isMultiOutlet={shop.outlets.length > 1 || shop.reservation}
							color={shop.hexPrimary}
						>
							<AlarmIcon src={alarmAsset} />
							<DateText>
								{`${getLongDayName(
									currentDate,
									language === "en"
								)}, ${currentDate.getDate()} ${getMonthName(
									currentDate,
									language === "en"
								)} ${currentDate.getFullYear()} ${
									fulfillmentTime
										? currentTime.substring(0, 5)
										: WORDING.general.IMMEDIATELY[language]
								}`}
							</DateText>
						</DateButton>
					)}
				<ProductViewWrapper>
					<ProductViewLabel>
						{WORDING.general.PRODUCT[language]}
					</ProductViewLabel>
					<ProductViewIcon
						src={isViewList ? listViewAsset : cardViewAsset}
						onClick={() => setIsViewList(!isViewList)}
						id="bt-view-mode"
					/>
				</ProductViewWrapper>
				{selectedDate &&
					!isShopCloseForSelectedTime &&
					isSearchComponentSticky && (
						<SearchBarRelative>
							{/* <SearchBarWrapper isSticky={isSearchComponentSticky}>
						<SearchBar
							isSticky={isSearchComponentSticky}
							onClick={() => setIsShowSearch(true)}
						>
							<SearchIcon src={SearchAsset} />
							<SearchInput placeholder="Lagi cari apa nih?" disabled />
						</SearchBar>
					</SearchBarWrapper> */}
							<SearchBarWrapper isSticky={isSearchComponentSticky}>
								{isTreatAsMerchantFoodcourt && (
									<BackToFoodcourtStickyButton
										onClick={() =>
											handleAddToCartFoodcourt(foodEventName || merchant.name)
										}
									>
										<BackIcon src={BackAsset} />
										<BackText>
											{WORDING.item_page.BACK_TO_FOODCOURT[language]}
										</BackText>
									</BackToFoodcourtStickyButton>
								)}
								<SearchBarRegular>
									<SearchIcon
										src={SearchAsset}
										onClick={() => setIsShowSearch(true)}
									/>
									<Categories>
										{currentCategories.map((category) => {
											return (
												<CategoryWrapper
													isActive={category.name === activeCategory}
													id={`NAV_${category.name.replace(" ", "_")}`}
													onClick={() =>
														handleOnClickCategory(
															category.name.replace(" ", "_")
														)
													}
												>
													<CategoryLabel
														isActive={category.name === activeCategory}
													>
														{category.name}
													</CategoryLabel>
													<CategoryActiveHighlight
														isActive={category.name === activeCategory}
														color={shop.hexPrimary}
													/>
												</CategoryWrapper>
											);
										})}
									</Categories>
								</SearchBarRegular>
							</SearchBarWrapper>
						</SearchBarRelative>
					)}
				{!selectedDate && (
					<ErrorMessage>
						{WORDING.message.NO_PRODUCT_AVAILABLE_THIS_WEEK[language]}
					</ErrorMessage>
				)}
				{isViewList ? (
					<ItemList
						categories={currentCategories}
						selectedItems={currentItems}
						isShopClose={isShopCloseForSelectedTime}
						handleOpenItemDetail={handleOpenItemDetail}
						filterSelectedItemBySameId={filterSelectedItemBySameId}
						countQuantitySelectedItems={countQuantitySelectedItems}
						language={language}
						hexPrimary={shop.hexPrimary}
					/>
				) : (
					<ItemCards
						categories={currentCategories}
						selectedItems={currentItems}
						isShopClose={isShopCloseForSelectedTime}
						handleOpenItemDetail={handleOpenItemDetail}
						filterSelectedItemBySameId={filterSelectedItemBySameId}
						countQuantitySelectedItems={countQuantitySelectedItems}
						language={language}
						hexPrimary={shop.hexPrimary}
					/>
				)}
				{selectedDate && (
					<FixedComponentWrapper>
						<ButtonWrapper>
							<TotalIconWrapper>
								<IconTotalCart src={CartAsset} />
								{totalItem > 0 && (
									<IconTotalNum color={shop.hexPrimary}>
										{totalItem}
									</IconTotalNum>
								)}
							</TotalIconWrapper>
							<TotalPriceContainer>
								<TextTotalWrapper>
									<TextTotalLight>Total:</TextTotalLight>
									<TextTotalPrice>
										Rp {parseIDR(temporaryTotalAmount)}
									</TextTotalPrice>
								</TextTotalWrapper>
								<ButtonOrderDetail
									color={shop.hexPrimary}
									isClickable={totalItem > 0}
									onClick={() =>
										totalItem > 0 ? setIsShowOrderDetail(true) : () => {}
									}
								>
									{WORDING.item_page.ORDER_DETAILS[language]}
								</ButtonOrderDetail>
							</TotalPriceContainer>
							<ButtonNext
								color={shop.hexPrimary}
								onClick={
									totalItem > 0 && !isShopCloseForSelectedTime
										? handleClickNext
										: () => {}
								}
								isDisabled={totalItem === 0 || isShopCloseForSelectedTime}
								id="bt-next-items-page"
							>
								{WORDING.general.NEXT[language]}
							</ButtonNext>
							{/* <TotalPriceContainer>
								<TextTotal>Total</TextTotal>
								<TextPrice>Rp {parseIDR(temporaryTotalAmount)}</TextPrice>
							</TotalPriceContainer>
							<ButtonNext
								color={shop.hexPrimary}
								onClick={
									totalItem > 0 && !isShopCloseForSelectedTime
										? handleClickNext
										: () => {}
								}
								isDisabled={totalItem === 0 || isShopCloseForSelectedTime}
								id="bt-next-items-page"
							>
								{WORDING.general.NEXT[language]}
							</ButtonNext> */}
						</ButtonWrapper>
					</FixedComponentWrapper>
				)}
				<Footer href="https://www.opaper.app">
					{WORDING.item_page.MADE_BY[language]} &nbsp; <Logo src={LogoAsset} />
					&nbsp;{WORDING.item_page.MAKE_ONLINE_STORE[language]}
				</Footer>
				<WAButton onClick={sendWaMessageToMerchant}>
					<WALogo src={WAAsset} />
				</WAButton>
				<BottomSheetOrderDetail
					currentItems={currentItems}
					setShow={setIsShowOrderDetail}
					isShow={isShowOrderDetail}
					language={language}
				/>
				<BottomSheetDates
					isShow={isShowBottomSheetDates}
					setShow={setIsShowBottomSheetDates}
					oneWeekCategories={currentOneWeekCategories}
					selectedDate={currentDate}
					handleSelectDate={handleSelectDate}
					handleNextAndPrev={handleNextAndPrevDateInterval}
					operationalDays={shop.operationalDays}
					preOrderDays={shop.preOrder}
					setShowTimePicker={setIsShowBottomSheetTime}
					isCloseAll={currRoute === "reservation"}
					language={language}
					hexPrimary={shop.hexPrimary}
				/>
				<BottomSheetItemDetail
					isShow={isShowBottomSheetItemDetail}
					setShow={setIsShowBottomSheetItemDetail}
					item={currentItem}
					handleAddSelectedItem={handleAddSelectedItem}
					selectedItem={editedItem}
					setEditedItem={setEditedItem}
					countQuantityItemSameId={countQuantityItemSameId}
					handleDeleteExistingItem={handleDeleteExistingItem}
					language={language}
					hexPrimary={shop.hexPrimary}
					isHideTakeaway={isHideTakeaway}
					isPickUp={sourceState === URL_VALUE_SOURCE_PICK_UP}
				/>
				<BottomSheetExistingItem
					isShow={isShowBottomSheetExistingItem}
					setShow={setIsShowBottomSheeExistingtItem}
					selectedItems={filterSelectedItemBySameId(`${currentItem.id}`)}
					setIsShowBottomSheetItemDetail={setIsShowBottomSheetItemDetail}
					handleChangeDetailExistingItem={handleChangeDetailExistingItem}
					handleDeleteExistingItem={handleDeleteExistingItem}
					language={language}
				/>
				<BottomSheetOrderMethod
					handleChooseOrderMethod={handleChooseOrderMethod}
					isShow={isShowBottomSheetOrderMethod}
					setShow={setIsShowBottomSheetOrderMethod}
					shopAddress={shop.address}
					isEnableDineIn={
						shop.preOrder === 0 &&
						currentDate.setHours(0, 0, 0, 0) ===
							new Date().setHours(0, 0, 0, 0) &&
						!isShopCloseNow
					}
					deliveryOptions={shop.deliveryOptions}
					setShowConfirmDineInInfo={setIsShowBottomSheetDineInInfo}
					isShowDineInConfirm={fulfillmentTime ? true : false}
					language={language}
					hexPrimary={shop.hexPrimary}
					isEnablePickup={merchant ? false : true}
				/>
				{!shop.isFoodcourt && (
					<BottomSheetOutlets
						isShow={
							!isShowBottomSheetSuccess &&
							isShowBottomSheetOutlets &&
							!tableNumber &&
							!isHideOtherOutlet &&
							!isTreatAsMerchantFoodcourt
						}
						setShow={setIsShowBottomSheetOutlets}
						outlets={shop.outlets}
						currLat={buyer.currLat}
						currLng={buyer.currLng}
						language={language}
					/>
				)}
				<BottomSheetDateTime
					isShow={isShowBottomSheetTime}
					setShow={setIsShowBottomSheetTime}
					setShowDate={setIsShowBottomSheetDates}
					fulfillmentTime={fulfillmentTime}
					handleSaveDateTime={handleSaveDateTime}
					time={currentTime}
					setTime={setCurrentTime}
					date={`${currentDate.getDate()} ${getMonthName(
						currentDate,
						language === "en"
					)} ${currentDate.getFullYear()}`}
					operationHour={currentOperationalHour}
					language={language}
					hexPrimary={shop.hexPrimary}
				/>
				<BottomSheetDineInInfo
					isShow={isShowBottomSheetDineInInfo}
					setShow={setIsShowBottomSheetDineInInfo}
					handleConfirm={handleConfirmDineInOrder}
					language={language}
					hexPrimary={shop.hexPrimary}
				/>
				<BottomSheetRating
					isShow={isShowBottomSheetRating}
					setShow={setIsShowBottomSheetRating}
					handleSubmitRating={handleSubmitRating}
					items={itemsRating}
					isSuccessSubmit={successSubmitRating}
					language={language}
				/>
				<BottomSheetSuccess
					isShow={isShowBottomSheetSuccess}
					setShow={setIsShowBottomSheetSuccess}
					message={WORDING.popup.DESC_RESERVATION_SUCCESS[language]}
					title={WORDING.popup.TITLE_RESERVATION_SUCCESS[language]}
					language={language}
					handleClose={handleClosePopupSuccessReservation}
				/>
				<BottomSheetFoodcourtSuggestion
					isShow={isShowBottomSheetFoodcourtSuggestion}
					setShow={setIsShowBottomSheetFoodcourtSuggestion}
					currentMerchant={merchant}
					merchants={merchants}
					handleBackToFoodcourt={handleBackToFoodcourt}
					handleNext={handleClickNext}
					handleClickVisitMerchant={handleVisitMerchant}
					foodcourtName={shop.name}
				/>
				<Search
					searchedItems={searchedItems}
					isShow={isShowSearch}
					setShow={setIsShowSearch}
					keyword={keyword}
					setKeyword={setKeyword}
					isViewList={isViewList}
					selectedItems={currentItems}
					isShopClose={isShopCloseForSelectedTime}
					handleOpenItemDetail={handleOpenItemDetail}
					filterSelectedItemBySameId={filterSelectedItemBySameId}
					countQuantitySelectedItems={countQuantitySelectedItems}
					handleClickNext={handleClickNext}
					totalAmount={temporaryTotalAmount}
					language={language}
					hexPrimary={shop.hexPrimary}
				/>
				{isOpenBill && (
					<BottomSheetOTP
						isShow={showOTPBottomSheet}
						setShow={setShowOTPBottomSheet}
						phone={phoneNumberOTP}
						resendOTP={sendOTPtoBuyer}
						setShowExchangeCoinBottomSheet={() => {}}
						setOtpCode={() => {}}
						checkOtpValid={checkOTPValid}
						otpCode={""}
						language={language}
						handleSubmit={handleSubmitOTPOpenBill as any}
					/>
				)}
				<DimNotification isShow={isShowDimNotification} isPrimary={true}>
					Terjadi Perubahan. Konfirmasi Ulang Pesanan
				</DimNotification>
			</React.Fragment>
		);
	};

	const renderFoodEvent = () => {
		if (SHOP_LINK_FOOD_EVENT_AS_FOOD_COURT.includes(foodEventData.link)) {
			return (
				<FoodcourtContainer>
					{/* <FoodcourtBanner src={FoodcourtBannerAsset} /> */}
					{/* {!shop.isOpen && (
						<StoreCloseBar isSingleText={true}>
							<StoreCloseBarHeaderText>
								Foodcourt Sedang Tutup
							</StoreCloseBarHeaderText>
						</StoreCloseBar>
					)} */}
					<CarouselContainer>
						{foodEventData.banners?.length > 1 ? (
							<Slider {...settings}>
								{banners.map((image: string) => (
									<React.Fragment>
										<HeaderImage src={image} alt="product" />
									</React.Fragment>
								))}
							</Slider>
						) : (
							<React.Fragment>
								<HeaderImage
									src={banners?.length === 1 ? banners[0] : headerImageAsset}
								/>
							</React.Fragment>
						)}
						{/* {!shop.isOpen && <GreyLayerBanner />} */}
					</CarouselContainer>
					<FoodcourtHeader>
						<FoodcourtName>{foodEventData.name}</FoodcourtName>
						{/* <FoodcourtInfoIcon
							src={InfoBlackAsset}
							onClick={() => handleClickAction(true)}
						/> */}
					</FoodcourtHeader>
					{/* {shop.isOpen && (
						<FoodcourtInstruction>
							Anda bisa menggabungkan pesanan dari beberapa tenant di satu proses
							pesanan.
						</FoodcourtInstruction>
					)} */}
					<FoodcourtMerchantList>
						{foodEventData.shops.length > 0 &&
							foodEventData.shops.map((shop) => {
								const { link, name, logo_url } = shop;
								return (
									<FoodcourtMerchant
										key={link}
										onClick={() => handleRedirectOtherShop(link)}
									>
										<FoodcourtMerchantLogoWrapper>
											<FoodcourtMerchantLogo
												isGreyscale={false}
												src={logo_url ? logo_url : FoodcourtDefaultAsset}
											/>
										</FoodcourtMerchantLogoWrapper>
										<FoodcourtMerchantName>
											{name.length > 20 ? `${name.substring(0, 20)}...` : name}
										</FoodcourtMerchantName>
										{/* {foodcourtCart &&
											foodcourtCart.cart &&
											foodcourtCart?.cart[shop_id] && (
												<FoodcourtCartTenant>
													{Object.keys(foodcourtCart.cart[shop_id].items).length}{" "}
													Produk
												</FoodcourtCartTenant>
											)} */}
									</FoodcourtMerchant>
								);
							})}
					</FoodcourtMerchantList>
				</FoodcourtContainer>
			);
		} else {
			return (
				<FoodCourtContainer background={foodEventData.banners[0]}>
					<FoodCourtLabel>
						<b>{foodEventData.name}</b>
					</FoodCourtLabel>
					{foodEventData.shops.map((shop) => {
						const { link, name, logo_url } = shop;
						return (
							<MerchantWrapper onClick={() => handleRedirectOtherShop(link)}>
								{logo_url ? (
									<MerchantLogo src={logo_url} />
								) : (
									<MerchantInitialLogo>
										<MerchantInitial>
											{getInitialFromSentence(name).substring(0, 2)}
										</MerchantInitial>
									</MerchantInitialLogo>
								)}
								<MerchantName>
									{name.substring(0, 30)}
									{name.length > 29 && "..."}
								</MerchantName>
							</MerchantWrapper>
						);
					})}
					<Footer href="https://www.opaper.app" isFoodcourt={true}>
						{WORDING.item_page.MADE_BY[language]} &nbsp;{" "}
						<Logo src={LogoWhiteAsset} />
						&nbsp;{WORDING.item_page.MAKE_ONLINE_STORE[language]}
					</Footer>
				</FoodCourtContainer>
			);
		}
	};

	const renderFoodcourt = () => {
		return (
			<FoodcourtContainer>
				{/* <FoodcourtBanner src={FoodcourtBannerAsset} /> */}
				{!shop.isOpen && (
					<StoreCloseBar isSingleText={true}>
						<StoreCloseBarHeaderText>
							Foodcourt Sedang Tutup
						</StoreCloseBarHeaderText>
					</StoreCloseBar>
				)}
				<CarouselContainer>
					{banners?.length > 1 ? (
						<Slider {...settings}>
							{banners.map((image: string) => (
								<React.Fragment>
									<HeaderImage src={image} alt="product" />
								</React.Fragment>
							))}
						</Slider>
					) : (
						<React.Fragment>
							<HeaderImage
								src={banners?.length === 1 ? banners[0] : headerImageAsset}
							/>
						</React.Fragment>
					)}
					{!shop.isOpen && <GreyLayerBanner />}
				</CarouselContainer>
				<FoodcourtHeader>
					<FoodcourtName>{shop.name}</FoodcourtName>
					<FoodcourtInfoIcon
						src={InfoBlackAsset}
						onClick={() => handleClickAction(true)}
					/>
				</FoodcourtHeader>
				{shop.isOpen && (
					<FoodcourtInstruction>
						Anda bisa menggabungkan pesanan dari beberapa tenant di satu proses
						pesanan.
					</FoodcourtInstruction>
				)}
				<FoodcourtMerchantList>
					{merchants.length > 0 &&
						merchants.map((merchant) => {
							const { name, logo_url, shop_id, link, is_open } = merchant;
							return (
								<FoodcourtMerchant
									key={shop_id}
									onClick={
										is_open && shop.isOpen
											? () => handleClickFoodcourtMerchant(link)
											: () => {}
									}
								>
									<FoodcourtMerchantLogoWrapper>
										<FoodcourtMerchantLogo
											isGreyscale={!is_open || !shop.isOpen}
											src={logo_url ? logo_url : FoodcourtDefaultAsset}
										/>
										{!is_open && (
											<FoodcourtMerchantClose>Tutup</FoodcourtMerchantClose>
										)}
									</FoodcourtMerchantLogoWrapper>
									<FoodcourtMerchantName>
										{name.length > 20 ? `${name.substring(0, 20)}...` : name}
									</FoodcourtMerchantName>
									{foodcourtCart &&
										foodcourtCart.cart &&
										foodcourtCart?.cart[shop_id] && (
											<FoodcourtCartTenant>
												{Object.keys(foodcourtCart.cart[shop_id].items).length}{" "}
												Produk
											</FoodcourtCartTenant>
										)}
								</FoodcourtMerchant>
							);
						})}
				</FoodcourtMerchantList>
				{foodcourtCart.total > 0 && (
					<FixedComponentWrapper>
						<ButtonWrapper>
							<TotalPriceContainer>
								<TextTotal>Total</TextTotal>
								<TextPrice>Rp {parseIDR(foodcourtCart.total)}</TextPrice>
							</TotalPriceContainer>
							<ButtonNext
								color={shop.hexPrimary}
								onClick={
									shop.deliveryOptions.includes(E_DELIVERY_OPTION.DELIVERY)
										? () => setIsShowBottomSheetOrderMethod(true)
										: () => handleChooseOrderMethod(E_DELIVERY_OPTION.DINE_IN)
								}
								isDisabled={false}
								id="bt-next-items-page"
							>
								{WORDING.general.NEXT[language]}
							</ButtonNext>
						</ButtonWrapper>
					</FixedComponentWrapper>
				)}
				<BottomSheetOrderMethod
					handleChooseOrderMethod={handleChooseOrderMethod}
					isShow={isShowBottomSheetOrderMethod}
					setShow={setIsShowBottomSheetOrderMethod}
					shopAddress={shop.address}
					isEnableDineIn={true}
					deliveryOptions={shop.deliveryOptions}
					setShowConfirmDineInInfo={setIsShowBottomSheetDineInInfo}
					isShowDineInConfirm={fulfillmentTime ? true : false}
					language={language}
					hexPrimary={shop.hexPrimary}
					isEnablePickup={false}
				/>
			</FoodcourtContainer>
		);
	};

	return (
		<Container isNoBottom={shopType === "FOOD_EVENT"}>
			{shopType === "FOOD_EVENT"
				? renderFoodEvent()
				: shopType === "FOODCOURT"
				? renderFoodcourt()
				: renderShop()}
		</Container>
	);
};

export default ItemsStep;

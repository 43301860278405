import { useReducer } from "react";
import { Switch, Route } from "react-router-dom";
import { AppProvider } from "./providers/AppProvider";
import { appReducer } from "./reducers";
import CreateOrder from "./containers/CreateOrder";
import SummaryOrder from "./containers/SummaryOrder";
import NoMerchant from "./containers/NoMerchant";
import ListAddresses from "./containers/steps/contactStep/ListAddresses";
import ShopProfile from "./containers/steps/itemStep/ShopProfile";
import Reservation from "./containers/steps/itemStep/Reservation";
import { IContextState } from "./interfaces";
import "./styles/App.css";
import TrackingOrder from "./containers/TrackingOrder";
import Loader from "./components/Loader";
import Qris from "./containers/Qris";
import OpenBill from "./containers/OpenBill";
import { LS_LANGUAGE } from './constants';

const App = () => {
	const INITIAL_STATE: IContextState = {
		buyer: {
			address: "",
			addressLabel: "",
			addressNote: "",
			country: "",
			lat: 0,
			lng: 0,
			name: "",
			phone: "",
			recipientName: "",
			recipientPhone: "",
			tableNumber: "",
			currLat: 0,
			currLng: 0,
			isHaveCoin: false,
			coins: 0,
		},
		categories: [],
		isLoading: false,
		login: {
			addresses: [],
			country: "",
			name: "",
			phone: "",
		},
		selectedDate: null,
		fulfillment: null,
		selectedItems: null,
		selectedShip: {
			insuranceRate: 0,
			isUserUseInsurance: false,
			name: "",
			price: 0,
			rateId: 0,
			type: "",
			withInsurance: false,
		},
		selectedShippingVoucher: null,
		selectedProductVoucher: null,
		ships: [],
		shop: {
			address: "",
			deliveryOptions: [],
			id: 0,
			link: "",
			name: "",
			phone: "",
			close: {
				end: null,
				start: null,
			},
			operationalDays: [],
			preOrder: 0,
			shipping: {
				note: "",
				price: 0,
			},
			paymentOptions: [],
			banners: [],
			outlets: [],
			serviceFeePercentage: "",
			taxPercentage: "",
			taxDeliveryOption: null,
			operationHours: {},
			foodEventLink: "",
			platformFeeByMerchant: false,
			platformFee: 0,
			reservation: false,
			logoUrl: "",
			hexPrimary: "",
			loyaltyData: null,
			rounding: null,
			openBillSetting: "close",
			tzOffset: 0,
      isFoodcourt: false,
      isTenant: false,
      isReservationFee: false,
      reservationFeeConfig: null,
      isServiceFeeFreeTax: false,
		},
		vouchers: [],
		addresses: {
			list: [],
			activeIndex: 0,
		},
		total: 0,
		source: "",
		oneWeekCategory: [],
		eventType: "",
    // @ts-ignore
		language: localStorage.getItem(LS_LANGUAGE) ?? "id",
		tableNumber: "",
		isHideOtherOutlet: false,
		isPopupCommonOpen: true,
    foodcourtCart: {
      cart: null,
      total: 0,
      allItems: null,
    },
    merchant: null,
		orderSN: "",
	};

	const state = useReducer(appReducer, INITIAL_STATE);

	return (
		<div className="App">
			<AppProvider value={state}>
				<Loader isLoading={state[0].isLoading} language={state[0].language} />
				<Switch>
					<Route
						path={"/:shopLink/contact/addresses"}
						component={ListAddresses}
					/>
					<Route path={"/:shopLink/profile"} component={ShopProfile} />
					<Route path={"/:shopLink/reservation"} component={Reservation} />
					<Route path={"/qris"} component={Qris} />
					<Route path="/order_sn/:orderSN/tracking" component={TrackingOrder} />
					<Route path="/order_sn/:orderSN" component={SummaryOrder} />
					<Route path="/open/:orderSN" component={OpenBill} />
					<Route path="/:shopLink/" component={CreateOrder} />
					<Route path={"/:shopLink/noMerchantFound"} component={NoMerchant} />
				</Switch>
			</AppProvider>
		</div>
	);
};

export default App;

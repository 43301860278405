/// <reference no-default-lib="true"/>
export const getData = async (url: string, params?: string, token?: string, requestPayload?: any) => {
  try {
    if (token) {
      const response = await fetch(`${url}${params ? params : ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'firebase-token': token,
        },
        mode: 'no-cors',
        body: JSON.stringify(requestPayload),
      });
      return response.json();
    } else {
      const response = await fetch(`${url}${params ? params : ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.json();
    }
  } catch (err) {
    // TODO: handle error
  }
};

export const postData = async (
  url: string,
  requestPayload?: any,
  token?: string
) => {
  try {
    if (token) {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'firebase-token': token,
        },
        body: JSON.stringify(requestPayload),
      });
      return response.json();
    } else {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestPayload),
      });
      return response.json();
    }
  } catch (err) {
    return []
    // TODO: handle error
  }
};
export const putData = async (
  url: string,
  requestPayload?: any,
  token?: string
) => {
  try {
    if (token) {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'firebase-token': token,
        },
        body: JSON.stringify(requestPayload),
      });
      return response.json();
    } else {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestPayload),
      });
      return response.json();
    }
  } catch (err) {
    // TODO: handle error
  }
};

import React from "react";
import styled from "styled-components";
import { IVoucher } from "../interfaces";
import { E_ACTION, E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";
import BottomSheetVoucher from "./BottomSheetVoucher";

import discountAsset from "../assets/discount.png";
import { WORDING } from "../constants/translate";

const VoucherWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${E_COLOR.WHITE};
	border-radius: 12px;
	border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
	padding: 12px;
	margin: 0 16px;
`;

const VoucherDiscountIcon = styled.img`
	width: 22px;
	height: 22px;
`;

const VoucherLabel = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	width: 100%;
	padding-left: 20px;
`;

const VoucherButton = styled.div`
	padding: 6px 20px;
	background-color: ${E_COLOR.DARK_YELLOW};
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 12px;
	line-height: 16px;
	border-radius: 5px;
	width: 34px;
	text-align: center;
	margin-left: 18px;
`;

interface IProps {
	selectedProductVoucher: IVoucher | null;
	selectedShippingVoucher: IVoucher | null;
  handleSelectVoucher: (selectedVoucher: IVoucher | null, isProductVoucher: boolean) => void; 
	eligibleVouchers: IVoucher[];
	notEligibleVouchers: IVoucher[];
	setIsShowBottomSheetVoucher: (isShow: boolean) => void;
  isShowBottomSheetVoucher: boolean;
  fetchPromotionVoucherByCode: () => void;
  code: string;
  setCode: (code: string) => void;
  fetchPromotionVoucher: () => void;
	errorMessage: string;
	amountItemOnly: number;
	isSearchingVoucherByCode: boolean;
	language: "id" | "en";
	hexPrimary: string;
}

const Voucher = ({
	selectedProductVoucher,
	selectedShippingVoucher,
  handleSelectVoucher,
	eligibleVouchers,
	notEligibleVouchers,
	setIsShowBottomSheetVoucher,
  isShowBottomSheetVoucher,
  code,
  fetchPromotionVoucher,
  fetchPromotionVoucherByCode,
  setCode,
	errorMessage,
	amountItemOnly,
	isSearchingVoucherByCode,
	language,
	hexPrimary,
}: IProps) => {

	const numSelectedVoucher = selectedProductVoucher && selectedShippingVoucher ? 2 : selectedProductVoucher || selectedShippingVoucher ? 1 : 0;
	
	return (
		<React.Fragment>
			<VoucherWrapper>
				<VoucherDiscountIcon src={discountAsset} />
				<VoucherLabel>
					{numSelectedVoucher === 0
						? `${WORDING.review_page.YOU_HAVE[language]} ${eligibleVouchers.length} voucher!`
						: `${numSelectedVoucher} Voucher ${WORDING.review_page.USED[language]}`}
				</VoucherLabel>
				<VoucherButton onClick={() => setIsShowBottomSheetVoucher(true)}>
					{numSelectedVoucher > 0  ? WORDING.general.CHANGE[language] : WORDING.review_page.CHECK[language]}
				</VoucherButton>
			</VoucherWrapper>
			<BottomSheetVoucher
				isShow={isShowBottomSheetVoucher}
				setShow={setIsShowBottomSheetVoucher}
				eligibleVouchers={eligibleVouchers}
				notEligibleVouchers={notEligibleVouchers}
				handleSearch={fetchPromotionVoucherByCode}
				code={code}
				setCode={setCode}
				handleSelectVoucher={handleSelectVoucher}
				selectedProductVoucher={selectedProductVoucher}
				selectedShippingVoucher={selectedShippingVoucher}
				fetchPromotionVoucher={fetchPromotionVoucher}
				errorMessage={errorMessage}
				amountItemOnly={amountItemOnly}
				isSearchingVoucherByCode={isSearchingVoucherByCode}
				numSelectedVoucher={numSelectedVoucher}
				language={language}
				hexPrimary={hexPrimary}
			/>
		</React.Fragment>
	);
};

export default Voucher;

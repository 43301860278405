import React from "react";
import styled from "styled-components";
import { E_COLOR, E_FONT_WEIGHT } from "../../../interfaces/dataTypes";
import DisplayPictAsset from "../../../assets/displaypict.png";
import BackAsset from "../../../assets/back.png";
import { useItemsStepHook } from "../../../hooks/ItemsStep";
import { useAppContext } from "../../../providers/useAppContext";
import { useHistory } from "react-router";
import { IContextState } from "../../../interfaces";
import { convertOtherTimeToLocal } from "../../../utils";
import { WORDING } from "../../../constants/translate";

const Container = styled.div``;

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
`;

const BackButton = styled.img`
	width: 14px;
	padding: 25px;
	z-index: 1;
`;

const Title = styled.div`
	width: calc(100% + 62px);
	margin-left: -62px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 25px;
	text-align: center;
`;

const ShopNameWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 16px 16px 0;
`;

const ShopImage = styled.img<{ haveLogo: boolean }>`
	width: 51px;
	height: ${({ haveLogo }) => haveLogo ? "51px" : "54px"};
	margin-right: 25px;
	border-radius: ${({ haveLogo }) => haveLogo ? "50%" : "none"};
	object-fit: ${({ haveLogo }) => haveLogo ? "cover" : "unset"};
`;

const ShopName = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 25px;
`;

const KeyValueWraper = styled.div`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px dashed ${E_COLOR.NEW_LIGHT_GREY};
	padding: 18px 16px 12px;
`;

const KeyText = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
`;

const ValueText = styled.div`
	width: 250px;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	text-align: right;
`;

const Schedule = styled.div`
	padding-top: 26px;
`;

const DayDetailWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 16px 26px;
`;

const Day = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 14px;
`;

const Detail = styled.div<{ isOpen: boolean }>`
	width: 120px;
	color: ${({ isOpen }) => (isOpen ? E_COLOR.BLACK : E_COLOR.GREY_DISABLED)};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	text-align: right;
`;

const GreyLine = styled.div`
	margin: 15px 0 12px;
	width: 100%;
	height: 8px;
	background-color: ${E_COLOR.NEW_GREY_LINE};
`;

const ShopProfile = () => {
	const { shop, language, merchant } = useItemsStepHook();
	const history = useHistory();
	const data = merchant ? merchant : shop;
	const { name, link, address, preOrder, operationalDays, operationHours } =
		data;

	const handleClickBack = () => {
		if(merchant) {
			history.push(`/${shop.link}?merchant=${link}`);
		} else {
			history.push(`/${link}${shop.foodEventLink ? `?foodCourt=${shop.foodEventLink}` : ""}`);
		}
	};

	return (
		<Container>
			<Header>
				<BackButton src={BackAsset} onClick={handleClickBack} />
				<Title>{WORDING.item_page.STORE_PROFILE[language]}</Title>
			</Header>
			<ShopNameWrapper>
				<ShopImage haveLogo={shop.logoUrl !== null} src={shop.logoUrl ? shop.logoUrl : DisplayPictAsset} />
				<ShopName>{name}</ShopName>
			</ShopNameWrapper>
			<GreyLine />
			<KeyValueWraper>
				<KeyText>{WORDING.general.ADDRESS[language]}</KeyText>
				<ValueText>{address}</ValueText>
			</KeyValueWraper>
			{preOrder !== 0 && (
				<KeyValueWraper>
					<KeyText>Pre-order</KeyText>
					<ValueText>{WORDING.item_page.DAY[language]}-{preOrder}</ValueText>
				</KeyValueWraper>
			)}
			<Schedule>
				<DayDetailWrapper>
					<Day>{WORDING.general.MONDAY[language]}</Day>
					<Detail isOpen={operationalDays.includes("mon")}>
						{operationalDays.includes("mon")
							? operationHours.mon.start.slice(0,5) === "00:00" && operationHours.mon.end.slice(0,5) === "00:00" ? WORDING.item_page.OPEN_24H[language] : `${operationHours.mon.start.slice(0,5)} - ${operationHours.mon.end.slice(0,5)}`
							: WORDING.general.CLOSE[language]}
					</Detail>
				</DayDetailWrapper>
				<DayDetailWrapper>
					<Day>{WORDING.general.TUESDAY[language]}</Day>
					<Detail isOpen={operationalDays.includes("tue")}>
						{operationalDays.includes("tue")
            ? operationHours.tue.start.slice(0,5) === "00:00" && operationHours.tue.end.slice(0,5) === "00:00" ? WORDING.item_page.OPEN_24H[language] : `${operationHours.tue.start.slice(0,5)} - ${operationHours.tue.end.slice(0,5)}`
            : WORDING.general.CLOSE[language]}
					</Detail>
				</DayDetailWrapper>
				<DayDetailWrapper>
					<Day>{WORDING.general.WEDNESDAY[language]}</Day>
					<Detail isOpen={operationalDays.includes("wed")}>
						{operationalDays.includes("wed")
            ? operationHours.wed.start.slice(0,5) === "00:00" && operationHours.wed.end.slice(0,5) === "00:00" ? WORDING.item_page.OPEN_24H[language] : `${operationHours.wed.start.slice(0,5)} - ${operationHours.wed.end.slice(0,5)}`
            : WORDING.general.CLOSE[language]}
					</Detail>
				</DayDetailWrapper>
				<DayDetailWrapper>
					<Day>{WORDING.general.THURSDAY[language]}</Day>
					<Detail isOpen={operationalDays.includes("thu")}>
						{operationalDays.includes("thu")
            ? operationHours.thu.start.slice(0,5) === "00:00" && operationHours.thu.end.slice(0,5) === "00:00" ? WORDING.item_page.OPEN_24H[language] : `${operationHours.thu.start.slice(0,5)} - ${operationHours.thu.end.slice(0,5)}`
            : WORDING.general.CLOSE[language]}
					</Detail>
				</DayDetailWrapper>
				<DayDetailWrapper>
					<Day>{WORDING.general.FRIDAY[language]}</Day>
					<Detail isOpen={operationalDays.includes("fri")}>
						{operationalDays.includes("fri")
            ? operationHours.fri.start.slice(0,5) === "00:00" && operationHours.fri.end.slice(0,5) === "00:00" ? WORDING.item_page.OPEN_24H[language] : `${operationHours.fri.start.slice(0,5)} - ${operationHours.fri.end.slice(0,5)}`
            : WORDING.general.CLOSE[language]}
					</Detail>
				</DayDetailWrapper>
				<DayDetailWrapper>
					<Day>{WORDING.general.SATURDAY[language]}</Day>
					<Detail isOpen={operationalDays.includes("sat")}>
						{operationalDays.includes("sat")
            ? operationHours.sat.start.slice(0,5) === "00:00" && operationHours.sat.end.slice(0,5) === "00:00" ? WORDING.item_page.OPEN_24H[language] : `${operationHours.sat.start.slice(0,5)} - ${operationHours.sat.end.slice(0,5)}`
            : WORDING.general.CLOSE[language]}
					</Detail>
				</DayDetailWrapper>
				<DayDetailWrapper>
					<Day>{WORDING.general.SUNDAY[language]}</Day>
					<Detail isOpen={operationalDays.includes("sun")}>
						{operationalDays.includes("sun")
            ? operationHours.sun.start.slice(0,5) === "00:00" && operationHours.sun.end.slice(0,5) === "00:00" ? WORDING.item_page.OPEN_24H[language] : `${operationHours.sun.start.slice(0,5)} - ${operationHours.sun.end.slice(0,5)}`
            : WORDING.general.CLOSE[language]}
					</Detail>
				</DayDetailWrapper>
			</Schedule>
		</Container>
	);
};

export default ShopProfile;

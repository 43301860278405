import styled from 'styled-components';
import { E_COLOR, E_FONT_WEIGHT } from '../interfaces/dataTypes';

export const Container = styled.div`
  margin: 18px 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 22px 16px 16px;
`;

export const Title = styled.div`
  color: ${E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 16px;
  line-height: 25px;
`;

export const TextButton = styled.div`
  display: inline-block;
  float: right;
  padding-left: 7px;
  color: ${E_COLOR.DARK_BLUE};
  font-weight: ${E_FONT_WEIGHT.BOLD};
  font-size: 12px;
  line-height: 18px;
`;

export const AddressBox = styled.div<{ isSelected: boolean }>`
  margin: 0 16px 14px;
  padding: 12px 15px;
  border: 1px solid
    ${({ isSelected }) => (isSelected ? E_COLOR.DARK_BLUE : E_COLOR.BLACK_LINE)};
  border-radius: 10px;
`;

export const AddressBoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AddressBoxLabel = styled.div`
  margin-top: 10px;
  color: ${E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 14px;
  line-height: 21px;
`;

export const Checkbox = styled.input`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
`;

export const AddressBoxDetail = styled.div`
  margin-top: 8px;
  color: ${E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 12px;
  line-height: 19px;
`;

export const Button = styled.div<{ isSelected: boolean }>`
  width: 100%;
  padding: 8px 0;
  margin: 14px 0 4px;
  background-color: ${E_COLOR.WHITE};
  border-radius: 5px;
  color: ${({ isSelected }) =>
    isSelected ? E_COLOR.DARK_BLUE : E_COLOR.GREY_DISABLED};
  border: 1px solid
    ${({ isSelected }) =>
      isSelected ? E_COLOR.DARK_BLUE : E_COLOR.GREY_DISABLED};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 14px;
  line-height: 21px;
  text-align: center;
`;

import { URL_SHIPPING_INFO } from '../constants';
import { postData } from '.';

export const getShippingInfo = async (payload: any) => {
  try {
    const url = URL_SHIPPING_INFO;
    const response = await postData(url, payload);

    return response.data.logistic_types;
  } catch {
    console.log('error when fetching shipping info.');
    return [];
  }
};

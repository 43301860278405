import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";
import { IItemRating, IReqRating } from "../interfaces";
import CloseAsset from "../assets/close.png";
import HeartOnAsset from "../assets/heartOn.png";
import HeartOffAsset from "../assets/heartOff.png";
import PitikPhoneAsset from "../assets/pitikPhone.png";
import PitikDanceAsset from "../assets/pitikDance.png";
import defaultAsset from "../assets/defaultItem.png";
import ButtonFull from "./ButtonFull";
import { WORDING } from "../constants/translate";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh !important" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden !important" : "scroll")};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
	z-index: 6;
	display: ${(props) => (props.isShow ? "block" : "none")};
	position: fixed;
	width: 100vh;
	max-width: 600px;
	background-color: rgba(26, 26, 26, 0.5);
	height: 100vh;
	bottom: 0;
	top: 0;
`;

const Content = styled.div`
	width: 100%;
	max-width: 600px;
	min-height: 100px;
	max-height: 85%;
	position: fixed;
	background-color: ${E_COLOR.WHITE};
	overflow: hidden;
	bottom: 0;
	border-radius: 16px 16px 0 0;
`;

const ScrollAbleContent = styled.div`
	overflow-y: scroll;
	max-width: 600px;
	height: 20vh;
	padding-bottom: 4px;
`;

const ImgClose = styled.img`
	float: right;
	width: 16px;
	height: 16px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	border-radius: 5px;
	margin: 31px 20px 20px 18px;
`;

const HeaderLabel = styled.div`
	width: 70%;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 27px;
`;

const Description = styled.div<{ withLine: boolean }>`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 16px;
	line-height: 24px;
	border-bottom: ${({ withLine }) =>
		withLine ? `1px solid ${E_COLOR.NEW_LIGHT_GREY}` : "none"};
	padding-bottom: 28px;
	margin: 12px 16px 0;
	text-align: center;
`;

const Title = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 27px;
	text-align: center;
	margin-top: 28px;
`

const SubHeader = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 16px;
	line-height: 16px;
`;

const Product = styled.div<{ withLine: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 16px;
	border-bottom: ${({ withLine }) =>
		withLine ? `1px dashed ${E_COLOR.NEW_LIGHT_GREY}` : "none"};
	padding-bottom: 16px;
`;

const ProductImage = styled.img`
	width: 48px;
	height: 48px;
	margin-right: 12px;
	border-radius: 8px;
	object-fit: cover;
`;

const ProductName = styled.div`
	width: 100%;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
`;

const ProductLike = styled.img`
	width: 19px;
	height: 18px;
`;

const TextArea = styled.textarea`
	display: block;
	margin: 5px 16px;
	padding: 8px 10px;
	width: calc(100% - 32px);
	height: 75px;
	box-sizing: border-box;
	background-color: ${E_COLOR.LIGHT_GREY};
	font-size: 14px;
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	color: ${E_COLOR.BLACK};
	outline: none;
	border: 1px solid ${E_COLOR.BLUE};
	border-radius: 7px;
	font-family: inherit;
`;

export const InputLabel = styled.div`
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 19px;
	margin: 10px 16px 0;
`;

export const InputMaxChar = styled.div`
	width: calc(100% - 32px);
	display: flex;
	justify-content: flex-end;
	padding: 4px 16px 0;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 12px;
	font-family: "Nunito Sans";
`;

const IlustrationWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const Ilustration = styled.img`
	width: 148px;
	height: 148px;
`;


interface IProps {
	isShow: boolean;
	setShow: (isShow: boolean) => void;
	items: IItemRating[] | null;
	handleSubmitRating: (payload: IReqRating) => void;
	isSuccessSubmit: boolean;
	language: "id" | "en";
}

const BottomSheetRating = ({
	isShow,
	setShow,
	items,
	handleSubmitRating,
	isSuccessSubmit,
	language,
}: IProps) => {
	const [feedback, setFeedback] = useState("");
	const [productIds, setProductIds] = useState([]);

	const handleLike = (productId: number) => {
		if (!productIds.includes(productId)) {
			const tempProductIds = [...productIds, productId];
			setProductIds(tempProductIds);
		} else {
			const index = productIds.indexOf(productId);
			const tempProductIds = [...productIds];
			tempProductIds.splice(index, 1);
			setProductIds(tempProductIds);
		}
	};

	const handleSubmit = () => {
		handleSubmitRating({
			feedback,
			product_ids: productIds,
			hash: "",
			order_sn: "",
		});
	};

	return (
		<Container isShow={isShow}>
			<GlobalStyle isShow={isShow} />
			<Content>
				<Header>
					<HeaderLabel>{WORDING.item_page.GIVE_REVIEW[language]}</HeaderLabel>
					<ImgClose onClick={() => setShow(false)} src={CloseAsset} />
				</Header>
				{!items && (
					<IlustrationWrapper>
						<Ilustration
							src={isSuccessSubmit ? PitikDanceAsset : PitikPhoneAsset}
						/>
					</IlustrationWrapper>
				)}
				{isSuccessSubmit && <Title>{WORDING.popup.TITLE_REVIEW_PRODUCT[language]}</Title>}
				<Description withLine={items ? true : false}>
					{items
						? WORDING.popup.DESC_ASK_REVIEW_PRODUCT[language]
						: isSuccessSubmit
						? WORDING.popup.DESC_SUCCESS_REVIEW_PRODUCT[language]
						: WORDING.popup.DESC_FAILED_REVIEW_PRODUCT[language]}
				</Description>
				{items && (
					<React.Fragment>
						<ScrollAbleContent>
							{items.map((item, index) => {
								const { name, product_id, product_image, quantity } = item;
								return (
									<Product withLine={index !== items.length - 1}>
										<ProductImage src={product_image ? product_image : defaultAsset} />
										<ProductName>
											{quantity}x {name}
										</ProductName>
										<ProductLike
											src={
												productIds.includes(product_id)
													? HeartOnAsset
													: HeartOffAsset
											}
											onClick={() => handleLike(product_id)}
										/>
									</Product>
								);
							})}
						</ScrollAbleContent>
						<InputLabel>{WORDING.item_page.FEEDBACK[language]} ({WORDING.general.OPTIONAL[language]})</InputLabel>
						{/* @ts-ignore */}
						<TextArea onChange={(e) => setFeedback(e.target.value)}
							value={feedback}
							placeholder={WORDING.item_page.FEEDBACK_PLACEHOLDER[language]}
							maxLength={500}
						/>
						<InputMaxChar>{feedback.length}/500</InputMaxChar>
					</React.Fragment>
				)}
				
				<ButtonFull
					hexPrimary={E_COLOR.DARK_BLUE}
					onClick={items ? handleSubmit : () => setShow(false)}
					isPrimary={true}
					isDisabled={items ? productIds.length === 0 && !feedback : false}
				>
					{items ? WORDING.item_page.SEND[language] : WORDING.item_page.BACK_TO_STORE[language]}
				</ButtonFull>
			</Content>
		</Container>
	);
};

export default BottomSheetRating;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-input-2";
import { E_COLOR, E_FONT_WEIGHT } from "../../../interfaces/dataTypes";
import InputText from "../../../components/InputText";
import ButtonFull from "../../../components/ButtonFull";
import BottomSheetDates from "../../../components/BottomSheetDates";
import BackAsset from "../../../assets/arrowLeft.png";
import ClockAsset from "../../../assets/alarm.png";
import CalendarAsset from "../../../assets/calendarBlack.png";
import BottomSheetTime from "../../../components/BottomSheetTime";
import { useItemsStepHook } from "../../../hooks/ItemsStep";
import { getFormattedDate, getHourMinuteFromTime, parseIDR } from "../../../utils";
import { WORDING } from "../../../constants/translate";
import PopupErrorSuccess from "../../../components/PopupErrorSuccess";
import { FixedComponentWrapper } from '../../../styles/ItemsStep';
// import BottomSheetPaymentOption from '../../../components/BottomSheetPaymentOption';

const Container = styled.div`
	margin: 22px 0;
`;

const BackButton = styled.div`
	display: flex;
	align-items: baseline;
	width: 200px;
`;

const BackIcon = styled.img`
	width: 7px;
	height: 12px;
	margin: 0 13px 0 24px;
`;

const BackLabel = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

const HeaderLabel = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 24px;
	line-height: 36px;
	margin: 3px 16px;
`;

const MerchantName = styled.div<{ color?: string }>`
	color: ${({color}) => color ? color : E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	margin: 0 16px 16px;
`;

const InputLabel = styled.div`
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 19px;
	margin: 0 16px;
`;

const InputLineWrapper = styled.div`
	display: flex;
`;

const InputLabelWrapper = styled.div`
	width: 50%;
`;

const SelectedTime = styled.div`
	display: flex;
	justify-content: space-between;
	background-color: ${E_COLOR.NEW_GREY};
	border-radius: 8px;
	padding: 15px;
	margin: 0 14px 28px;
`;

const SelectedTimeIcon = styled.img`
	width: 18px;
	height: 18px;
`;

const SelectedTimeValue = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.MEDIUM};
	font-size: 14px;
	line-height: 21px;
`;

const FieldInstruction = styled.div`
	margin: 0 16px 28px;
	color: ${E_COLOR.RED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 16px;
`;

const FloatingBottomCard = styled.div`
  width: 100%;
  box-shadow: 0px -2px 8px 0px #00000014;
  background-color: ${E_COLOR.WHITE};
`;

const ReservationFeeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 12px;
	line-height: 18px;
  color: ${E_COLOR.NEW_BLACK};
  margin-bottom: 8px;
`;

const ReservationFeeNotesContainer = styled.div`
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 10px;
	line-height: 15px;
  color: #596363;
  margin-top: 8px;
  font-style: italic;
`;

const Reservation = () => {
	const [isShowBottomSheetDates, setIsShowBottomSheetDates] = useState(false);
	const [isShowBottomSheetTime, setIsShowBottomSheetTime] = useState(false);
	const {
		handleInputReservation,
		reservation,
		currentOneWeekCategories,
		handleNextAndPrevDateInterval,
		currentOperationalHour,
    handleSubmitReservation,
    language,
    shop,
    handleBackToStore,
    handleSelectDate,
    currentDate,
    reportIssueReservation,
    isReservationError,
    setIsReservationError,
    // isShowBottomSheetReservationPayment,
    // setIsShowBottomSheetReservationPayment,
    // handleSubmitReservationWithPayment
	} = useItemsStepHook();
  const { isReservationFee, reservationFeeConfig } = shop;
	const { name, countryCode, date, notes, persons, phone, time } = reservation;
  
  const reservationAmount = isReservationFee && reservationFeeConfig ?
    reservationFeeConfig.fee_type === 'flat' ? reservationFeeConfig.amount :
    reservationFeeConfig.fee_type === 'per_person' ? +persons * +reservationFeeConfig.amount : '0'
    : '0';

  const [reservationDate, setReservationDate] = useState(() => {
    const reservationDate = new Date(currentDate.getTime());
    reservationDate.setMinutes(Math.ceil(reservationDate.getMinutes() / 10) * 10);
    return reservationDate;
  });

	const [hour, setHour] = useState(getHourMinuteFromTime(time).hour);
	const [minute, setMinute] = useState(getHourMinuteFromTime(time).minute);
  const [isRetriedPreviously, setIsRetried] = useState(false);

  const today = new Date();
  const isToday = today.getFullYear() === reservationDate.getFullYear() &&
    today.getMonth() === reservationDate.getMonth() &&
    today.getDate() === reservationDate.getDate();

  useEffect(() => {
    const today = new Date();

    const currentHours = today.getHours();
    const currentMinutes = today.getMinutes();

    const roundedMinutes = Math.ceil(currentMinutes / 10) * 10;
    const roundedHours = (roundedMinutes >= 60 ? 1 : 0) + currentHours;

    const resultMinutes = roundedMinutes >= 60 ? 0 : roundedMinutes;
    const resultHours = roundedHours >= 24 ? 0 : roundedHours;

    handleInputReservation("time", `${`0${resultHours}`.slice(-2)}:${`0${resultMinutes}`.slice(-2)}`)
  }, []);

  useEffect(() => {
		setHour(getHourMinuteFromTime(time).hour);
		setMinute(getHourMinuteFromTime(time).minute);
	}, [time]);

	const setTime = (time: string) => {
		setHour(getHourMinuteFromTime(time).hour);
		setMinute(getHourMinuteFromTime(time).minute);
    handleInputReservation("time", `${getHourMinuteFromTime(time).hour}:${getHourMinuteFromTime(time).minute}`)
	};

	return (
		<Container>
			<BackButton onClick={handleBackToStore}>
				<BackIcon src={BackAsset} />
				<BackLabel>{WORDING.item_page.BACK_TO_STORE[language]}</BackLabel>
			</BackButton>
			<HeaderLabel>{WORDING.item_page.RESERVATION[language]}</HeaderLabel>
			<MerchantName color={shop.hexPrimary}>{shop.name}</MerchantName>
			<InputLabel>{WORDING.item_page.ORDER_NAME[language]}</InputLabel>
			<InputText
				placeholder={WORDING.item_page.PUT_ORDER_NAME[language]}
				name="name"
				onChange={handleInputReservation}
				type="text"
				value={name}
				customMargin="4px 16px 28px"
        autoComplete="name"
				maxLength="30"
			/>
			<InputLabel>{WORDING.item_page.ORDER_PHONE[language]}</InputLabel>
			<PhoneInput
				country="id"
				// value="82"
				placeholder={WORDING.item_page.PUT_ORDER_PHONE[language]}
				onChange={(input, data) => handleInputReservation("phone", input, data)}
				inputStyle={{
					backgroundColor: E_COLOR.NEW_GREY,
					height: "55px",
					width: "100%",
					paddingLeft: "60px",
					border: `1px solid ${E_COLOR.NEW_GREY}`,
					borderRadius: "8px",
					fontSize: "16px",
					fontWeight: "bold",
				}}
				buttonStyle={{
					backgroundColor: E_COLOR.WHITE,
					marginRight: "10px",
					padding: "0 4px",
					border: `1px solid ${E_COLOR.NEW_GREY}`,
				}}
				containerStyle={{ margin: "4px 16px 28px", width: "inherit" }}
				countryCodeEditable={true}
        disableCountryGuess={true}
        inputProps={{ name: 'phone', autoComplete: 'tel' }}
			/>
			<InputLabel>{WORDING.item_page.PERSON_QTY[language]}</InputLabel>
			<InputText
				placeholder={WORDING.item_page.PUT_PERSON_QTY[language]}
				name="persons"
				onChange={handleInputReservation}
				type="number"
				value={parseInt(persons) > 0 ? `${persons}` : ""}
				customMargin={parseInt(persons) > 999 ? "4px 16px" : "4px 16px 28px"}
				maxLength="5"
        min={1}
        onWheel={(e: any) => e.target.blur()}
			/>
      {parseInt(persons) > 999 && <FieldInstruction>
        {WORDING.item_page.ERR0R_PERSON_QTY[language]}
			</FieldInstruction>}
			<InputLineWrapper>
				<InputLabelWrapper>
					<InputLabel>{WORDING.general.PICK_DATE[language]}</InputLabel>
					<SelectedTime onClick={() => setIsShowBottomSheetDates(true)}>
						<SelectedTimeValue>{getFormattedDate(reservationDate)}</SelectedTimeValue>
						<SelectedTimeIcon src={CalendarAsset} />
					</SelectedTime>
				</InputLabelWrapper>
				<InputLabelWrapper>
					<InputLabel>{WORDING.general.PICK_TIME[language]}</InputLabel>
					<SelectedTime onClick={() => setIsShowBottomSheetTime(true)}>
						<SelectedTimeValue>
							{hour < 10 ? `0${hour}` : hour}:
							{minute < 10 ? `0${minute}` : minute}
						</SelectedTimeValue>
						<SelectedTimeIcon src={ClockAsset} />
					</SelectedTime>
				</InputLabelWrapper>
			</InputLineWrapper>
			<InputLabel>
				{WORDING.general.NOTES[language]} <i>({WORDING.general.OPTIONAL[language]})</i>
			</InputLabel>
			<InputText
				placeholder={WORDING.item_page.PUT_NOTES[language]}
				name="notes"
				onChange={handleInputReservation}
				type="text"
				value={notes}
				customMargin="4px 16px 28px"
			/>
      <div style={{ paddingTop: isReservationFee ? '140px' : '80px' }} />
      <FixedComponentWrapper>
        <FloatingBottomCard>
          <div style={{ padding: '12px 16px' }}>
            {isReservationFee && (
              <ReservationFeeContainer>
                <span>{WORDING.reservation.DOWN_PAYMENT[language]}:</span>
                <span>Rp{parseIDR(reservationAmount as any)}</span>
              </ReservationFeeContainer>
            )}
            <ButtonFull
              hexPrimary={shop.hexPrimary}
              isDisabled={!name || !phone || phone.length < 11 || !persons || parseInt(persons) > 999} onClick={handleSubmitReservation}
              isPrimary={true}
              customWidth="100%"
              customMargin="0"
            >
              {WORDING.item_page.MAKE_RESERVATION[language]}
            </ButtonFull>
            {isReservationFee && (
              <ReservationFeeNotesContainer>
                {WORDING.reservation.DOWN_PAYMENT_NOTES[language]}
              </ReservationFeeNotesContainer>
            )}
          </div>
        </FloatingBottomCard>
      </FixedComponentWrapper>
      <BottomSheetDates
        isShow={isShowBottomSheetDates}
        setShow={setIsShowBottomSheetDates}
        oneWeekCategories={currentOneWeekCategories}
        selectedDate={currentDate}
        handleSelectDate={(value) => {
          handleSelectDate(value);
          setReservationDate(value);
          handleInputReservation('date', value);
        }}
        handleNextAndPrev={handleNextAndPrevDateInterval}
        operationalDays={shop.operationalDays}
        preOrderDays={0}
        setShowTimePicker={setIsShowBottomSheetTime}
        isCloseAll={true}
        language={language}
        title={WORDING.item_page.RESERVATION_DATE[language]}
				hexPrimary={shop.hexPrimary}
      />
			<BottomSheetTime
				isShow={isShowBottomSheetTime}
				operationHour={currentOperationalHour}
				setShow={setIsShowBottomSheetTime}
				setTime={setTime}
				time={time}
        language={language}
        title={WORDING.item_page.RESERVATION_TIME[language]}
				hexPrimary={shop.hexPrimary}
        isToday={isToday}
			/>
      <PopupErrorSuccess
				isRetriedPreviously={isRetriedPreviously}
				reportIssue={reportIssueReservation}
				setIsRetried={setIsRetried}
				handleRetry={handleSubmitReservation}
				isOpen={isReservationError}
				handleClose={() => setIsReservationError(false)}
				isSuccess={false}
				language={language}
        desc={WORDING.popup.DESC_RESERVATION_ERROR[language]}
			/>
      {/* <BottomSheetPaymentOption
				isShow={isShowBottomSheetReservationPayment}
				setShow={setIsShowBottomSheetReservationPayment}
        selectedPaymentMethod={null}
				setSelectedPaymentMethod={() => null}
				handleSubmitPaymentMethod={handleSubmitReservationWithPayment}
				isCashEligible={false}
				isXenditEligible={true}
				isEDCEligible={false}
        getEligibilityForCustomPayment={() => false}
				eligiblePaymentMethods={shop.paymentOptions}
				language={language}
			/> */}
		</Container>
	);
};

export default Reservation;

import React from "react";
import styled from "styled-components";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";

const Container = styled.div<{ isPositif: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 5px 0;
  background-color: ${E_COLOR.WHITE};
  border: 1px solid ${({ isPositif }) => isPositif ? E_COLOR.DARK_BLUE : E_COLOR.GREY_BORDER};
  border-radius: 6px;
  color: ${({ isPositif }) => isPositif ? E_COLOR.DARK_BLUE : E_COLOR.NEW_GREY_TEXT};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 14px;
  line-height: 21px;
`;

interface IProps {
  handleClick: () => void;
  label: string;
  isPositif: boolean;
}

const ButtonSmall = ({ handleClick, label, isPositif }: IProps) => {
  return <Container isPositif={isPositif} onClick={handleClick}>{label}</Container>;
};

export default ButtonSmall;

import React, { useState, useEffect, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";
import ItemList from "./ItemList";
import ItemCards from "./ItemCards";

import SearchAsset from "../assets/search.png";
import RemoveAsset from "../assets/remove.png";
import EmptyAsset from "../assets/empty.png";
import { IItem, ISelectedItems } from "../interfaces";
import { ButtonNext } from "../styles/ItemsStep";
import { parseIDR } from "../utils";
import { WORDING } from "../constants/translate";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh !important" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden !important" : "scroll")};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
	position: fixed;
	display: ${({ isShow }) => (isShow ? "block" : "none")};
	width: 100vw;
	max-width: 600px;
	background-color: ${E_COLOR.WHITE};
	text-align: center;
	z-index: 5;
	bottom: 0;
	top: 0;
`;

const Content = styled.div`
	width: 100%;
	max-width: 600px;
  height: calc(100vh - 172px);
	overflow-y: scroll;
  margin-top: 90px;
`;

const TopBar = styled.div`
	width: calc(100vw - 32px);
	position: absolute;
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 16px;
	z-index: 8;
	max-width: 600px;
`;

const SearchBar = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	width: 100%;
	height: 100%;
	background-color: ${E_COLOR.NEW_GREY_BACKGROUND};
	border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
	border-radius: 15px;
	max-width: 400px;
`;

const SearchIcon = styled.img`
	width: 24px;
	margin-right: 10px;
`;

const RemoveIcon = styled.img`
	width: 16px;
	margin-left: 8px;
`;

const Input = styled.input`
	width: 100%;
	outline-width: 0;
	border: none;
	background-color: ${E_COLOR.NEW_GREY_BACKGROUND};
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
  outline-width: 0;
	outline: none;
	font-family: "Plus Jakarta Sans";

	::placeholder {
		color: ${E_COLOR.GREY_DISABLED};
		font-weight: ${E_FONT_WEIGHT.REGULAR};
		font-size: 14px;
		line-height: 21px;
		font-family: "Plus Jakarta Sans";
	}
`;

const CloseButton = styled.div<{ color?: string }>`
	color: ${({ color }) => color ? color : E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	margin: 0 6px 0 18px;
`;

const NoItemContainer = styled.div`
  margin-top: calc(100vh / 5);
`

const NoItemImg = styled.img`
  width: 116px;
`

const NoItemLabel = styled.div`
  color: ${E_COLOR.LIGHT_BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 14px;
  line-height: 21px;
  margin: 24px 16px;
`

export const FixedComponentWrapper = styled.div`
	position: fixed;
	bottom: -1px;
	width: 100%;
	max-width: 600px;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 15px 16px 14px 22px;
	background-color: ${E_COLOR.WHITE};
	box-shadow: 0px -8px 36px rgba(0, 0, 0, 0.08);
	border-radius: 20px 20px 0px 0px;
`;

export const TotalPriceContainer = styled.div``;

export const TextTotal = styled.div`
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 12px;
	line-height: 19px;
	margin-bottom: 7px;
	text-align: left;
`;

export const TextPrice = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 27px;
`;

interface IProps {
	isShow: boolean;
	setShow: (isShow: boolean) => void;
	keyword: string;
	setKeyword: (keyword: string) => void;
	isViewList: boolean;
	searchedItems: IItem[];
	isShopClose: boolean;
	selectedItems: ISelectedItems;
	handleOpenItemDetail: (item: IItem) => void;
	filterSelectedItemBySameId: (id: string) => ISelectedItems;
	countQuantitySelectedItems: (selectedItems: ISelectedItems) => number;
  totalAmount: number;
  handleClickNext: () => void;
	language: "id" | "en";
	hexPrimary: string;
}

const Search = ({
	isShow,
	setShow,
	keyword,
	setKeyword,
	isViewList,
	searchedItems,
	isShopClose,
	selectedItems,
	countQuantitySelectedItems,
	filterSelectedItemBySameId,
	handleOpenItemDetail,
  totalAmount,
  handleClickNext,
	language,
	hexPrimary,
}: IProps) => {
	const inputRef = useRef();
  
  const handleCancelSearch = () => {
    setShow(false);
    setKeyword("");
  }

	useEffect(() => {
		const inputEl = inputRef.current;
		// @ts-ignore
		inputEl.focus();
	}, []);

	return (
		<Container isShow={isShow}>
      <GlobalStyle isShow={isShow} />
			<TopBar>
				<SearchBar>
					<SearchIcon src={SearchAsset} />
					{/* @ts-ignore */}
					<Input onChange={(e) => setKeyword(e.target.value)}
						placeholder={WORDING.item_page.SEARCH_PLACEHOLDER[language]}
						value={keyword}
						autoFocus={true}
						ref={inputRef}
					/>
					<RemoveIcon src={RemoveAsset} onClick={() => setKeyword("")} />
				</SearchBar>
				<CloseButton color={hexPrimary} onClick={handleCancelSearch}>{WORDING.general.CANCEL[language]}</CloseButton>
			</TopBar>
      <Content>
        {searchedItems.length > 0 ? (isViewList ? (
          <ItemList
            items={searchedItems}
            selectedItems={selectedItems}
            isShopClose={isShopClose}
            handleOpenItemDetail={handleOpenItemDetail}
            filterSelectedItemBySameId={filterSelectedItemBySameId}
            countQuantitySelectedItems={countQuantitySelectedItems}
						language={language}
						hexPrimary={hexPrimary}
          />
        ) : (
          <ItemCards
            items={searchedItems}
            selectedItems={selectedItems}
            isShopClose={isShopClose}
            handleOpenItemDetail={handleOpenItemDetail}
            filterSelectedItemBySameId={filterSelectedItemBySameId}
            countQuantitySelectedItems={countQuantitySelectedItems}
						language={language}
						hexPrimary={hexPrimary}
          />
        )) : (
          <NoItemContainer>
            <NoItemImg src={EmptyAsset} />
            <NoItemLabel>{WORDING.item_page.SEARCH_NOT_FOUND[language]}</NoItemLabel>
          </NoItemContainer>
        )}
      </Content>
        <FixedComponentWrapper>
					<ButtonWrapper>
						<TotalPriceContainer>
							<TextTotal>Total</TextTotal>
							<TextPrice>Rp {parseIDR(totalAmount)}</TextPrice>
						</TotalPriceContainer>
						<ButtonNext
							color={hexPrimary}
							onClick={
								totalAmount
									? handleClickNext
									: () => {}
							}
							isDisabled={totalAmount === 0}
						>
							{WORDING.general.NEXT[language]}
						</ButtonNext>
					</ButtonWrapper>
				</FixedComponentWrapper>
		</Container>
	);
};

export default Search;

interface IProps {
  color: string;
  margin: string;
}

const PickupSVG = ({ color, margin }: IProps) => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ minWidth: "45px", minHeight: "45px", margin: margin }}>
    <circle cx="22.5" cy="22.5" r="22.5" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.6773 10H27.349C27.4475 10 27.5423 10.0281 27.6206 10.0879C28.1165 10.466 30.0631 12.115 32 16C33.7449 19.5 34 24 34 24V33.5C34 33.7761 33.7761 34 33.5 34H28.9764L30.1176 28.5657C30.7985 28.386 31.2224 27.6509 30.9751 26.9442L30.3251 25.0871C30.1496 24.5857 29.6764 24.25 29.1453 24.25H21.8547C21.3236 24.25 20.8504 24.5857 20.6749 25.0871L20.4429 25.75H15.3604C14.8223 25.75 14.3447 26.0943 14.1745 26.6047L13.2885 29.2628L13.2255 29.4518L13.2646 29.6471L14.1351 34H10.5C10.2239 34 10 33.7761 10 33.5V24C10 24 10 20 12 16C13.7066 12.5869 15.8214 10.5943 16.3668 10.114C16.4529 10.0382 16.5626 10 16.6773 10ZM21.9225 33.5185L21.9711 33.75H15.6149L14.9149 30.25H20.942L21.9225 33.5185ZM19.9585 27.25C19.9093 27.8046 20.241 28.3245 20.75 28.5226V28.75H15.0406L15.5406 27.25H19.9585ZM23.5788 34H27.4436L28.5761 28.6071H22.4239L23.3775 33.1482L23.5253 33.6408C23.5614 33.7613 23.5786 33.882 23.5788 34ZM22.1408 27.1071H29.4429L28.9679 25.75H22.0321L21.7979 26.4192C21.8017 26.4264 21.8054 26.4337 21.809 26.441L22.118 27.059C22.126 27.0749 22.1336 27.091 22.1408 27.1071ZM19.25 14C18.0074 14 17 15.0074 17 16.25C17 17.4926 18.0074 18.5 19.25 18.5H24.75C25.9926 18.5 27 17.4926 27 16.25C27 15.0074 25.9926 14 24.75 14H19.25Z" fill="white"/>
    </svg>
  )
}

export default PickupSVG;
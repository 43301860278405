import React from 'react';
import styled from 'styled-components';
import { E_COLOR } from '../interfaces/dataTypes';

const Container = styled.div<{ isThick: boolean; isDashed: boolean; customMargin?: string }>`
  margin: ${({ isThick, customMargin }) => (customMargin ? customMargin : isThick ? '0' : '0 16px')};
  height: ${({ isThick, isDashed }) =>
    isThick ? '8px' : isDashed ? '0' : '1px'};
  background-color: ${({ isThick, isDashed }) =>
    isThick ? E_COLOR.NEW_GREY_LINE : isDashed ? 'none' : E_COLOR.GREY_LINE};
  border-bottom: ${({ isDashed }) =>
    isDashed ? `1px dashed ${E_COLOR.GREY_LINE}` : 'none'};
`;

interface IProps {
  isThick: boolean;
  isDashed?: boolean;
  customMargin?: string;
}

const BorderSection = ({ isThick, isDashed, customMargin }: IProps) => {
  return <Container isThick={isThick} isDashed={isDashed || false} customMargin={customMargin} />;
};

export default BorderSection;

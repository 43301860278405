import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";
import { IItemRating, IReqRating } from "../interfaces";
import CloseAsset from "../assets/close.png";
import CoinAsset from "../assets/coin.png";
import CoinBalanceBgAsset from "../assets/coinBalanceBg.png";
import ButtonFull from "./ButtonFull";
import { WORDING } from "../constants/translate";
import InputText from "./InputText";
import { parseIDR } from "../utils";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh !important" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden !important" : "scroll")};
  }
`;

// const Container = styled.div<{ isShow: boolean }>`
// 	z-index: 6;
// 	display: ${(props) => (props.isShow ? "block" : "none")};
// 	position: fixed;
// 	width: 100vh;
// 	max-width: 600px;
// 	background-color: rgba(26, 26, 26, 0.5);
// 	height: 100vh;
// 	bottom: 0;
// 	top: 0;
// 	overflow: hidden;
// `;

const Container = styled.div<{ isShow: boolean }>`
	z-index: 6;
	display: ${(props) => (props.isShow ? "block" : "none")};
	position: fixed;
	width: 100vh;
	max-width: 600px;
	background-color: rgba(26, 26, 26, 0.5);
	height: 100vh;
	bottom: 0;
	top: 0;
	overflow: hidden;
	background-color: ${E_COLOR.WHITE};
	width: 100%;
`;

const Content = styled.div`
	width: 100%;
	max-width: 600px;
	height: calc(100vh - 16px);
	position: fixed;
	background-color: ${E_COLOR.WHITE};
	overflow: hidden;
	bottom: 0;
`;

const ScrollAbleContent = styled.div`
	overflow-y: scroll;
	max-width: 600px;
	height: 20vh;
	padding-bottom: 4px;
`;

const Title = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 18px;
	line-height: 27px;
`;

const ImgClose = styled.img`
	float: right;
	width: 16px;
	height: 16px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	border-radius: 5px;
	margin: 31px 20px 20px 18px;
`;

const CoinBalanceWrapper = styled.div`
	background-image: url(${CoinBalanceBgAsset});
	display: flex;
	margin: 16px;
	background-position-y: center;
	background-position-x: center;
	border-radius: 8px;
	padding: 12px 16px;
	justify-content: space-between;
`;

const CoinIcon = styled.img`
	width: 24px;
	margin-right: 12px;
`;

const CoinLabel = styled.div`
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
	width: 90%;
`;
const CoinBalance = styled.div`
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
`;

const CoinInputCard = styled.div`
	border-radius: 12px;
	border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
	padding: 12px;
	margin: 16px;
`;

const CoinInputWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const TotalCoin = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	margin-left: 12px;
`;

const CoinConverter = styled.div<{ isError: boolean }>`
	color: ${({ isError }) => (isError ? E_COLOR.RED : E_COLOR.NEW_BLACK)};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	margin: 12px 0;
`;

const ErrorMessage = styled.div`
	color: ${E_COLOR.RED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	margin: 12px 0;
`;

interface IProps {
	isShow: boolean;
	setShow: (isShow: boolean) => void;
	hexColor: string;
	language: "id" | "en";
	totalCoin: number;
	coinToExhange: string;
	handleTextInput: (name: string, value: string) => void;
	loyaltyData: {
		maxCoinUsed: number;
		minPurchase: number;
		valuePerCoin: number;
	};
	amountItemOnly: number;
}

const BottomSheetExchangeCoin = ({
	isShow,
	setShow,
	hexColor,
	language,
	handleTextInput,
	coinToExhange,
	totalCoin,
	loyaltyData,
	amountItemOnly,
}: IProps) => {
	const [tempCoinToExchange, setTempCoinToExchange] = useState(coinToExhange);

  // @ts-ignore
  window.scrollTo(0,0)

	const handleClose = () => {
		setTempCoinToExchange(coinToExhange);
		setShow(false);
	};

	const handleSave = () => {
    if(tempCoinToExchange === "0") {
      handleTextInput("coinToExchange", "");
    } else {
      handleTextInput("coinToExchange", tempCoinToExchange);
    }
		
		setShow(false);
	};

	const handleChangeTempCoinToExchange = (name: string, value: string) => {
		setTempCoinToExchange(value);
	};

	const disabledSaveButton =
		parseInt(tempCoinToExchange) > totalCoin ||
		parseInt(tempCoinToExchange) > loyaltyData?.maxCoinUsed ||
    parseInt(tempCoinToExchange) < 0 ||
    !tempCoinToExchange;
	const coinValidation =
		tempCoinToExchange &&
		parseInt(tempCoinToExchange) <= totalCoin &&
		parseInt(tempCoinToExchange) <= loyaltyData?.maxCoinUsed;
	const discountExceeded =
		parseInt(tempCoinToExchange) * loyaltyData?.valuePerCoin > amountItemOnly;
  const exchangeCoinMoreThanBalance = parseInt(tempCoinToExchange) > totalCoin;

  let errMsg = "";
  if(exchangeCoinMoreThanBalance) {
    errMsg = WORDING.review_page.INSUFFICIENT_COINS[language];
  } else if(parseInt(tempCoinToExchange) > loyaltyData?.maxCoinUsed) {
    errMsg = `${WORDING.review_page.MAX_COINS_USED[language]} ${loyaltyData?.maxCoinUsed}`;
  } else if(discountExceeded) {
    errMsg = WORDING.review_page.ERR_MAX_TRX[language];
  } else if(parseInt(tempCoinToExchange) < 0) {
    errMsg = WORDING.review_page.ERR_MINUS_INPUT[language];
  }

	return (
		<Container isShow={isShow}>
			<GlobalStyle isShow={isShow} />
			{/* <Content> */}
				<Header>
					<Title>{WORDING.review_page.REDEEM_COIN[language]}</Title>
					<ImgClose onClick={handleClose} src={CloseAsset} />
				</Header>
				<CoinBalanceWrapper>
					<CoinIcon src={CoinAsset} />
					<CoinLabel>{WORDING.review_page.MY_COIN[language]}</CoinLabel>
					<CoinBalance>{totalCoin}</CoinBalance>
				</CoinBalanceWrapper>
				<CoinInputCard>
					<CoinInputWrapper>
						<InputText
							name="coinToExchange"
							onChange={handleChangeTempCoinToExchange}
							value={tempCoinToExchange}
							placeholder={coinToExhange}
							type="number"
							width="50%"
							customMargin="0"
              maxLength="6"
						/>
						<TotalCoin>{WORDING.review_page.FROM_XX_COIN[language].replace("XX", `${totalCoin}`)}</TotalCoin>
					</CoinInputWrapper>
          <CoinConverter isError={false}>
            {WORDING.review_page.AMOUNTS_TO[language]} Rp
            {!tempCoinToExchange ? "0" : `
							${parseIDR(
								parseInt(tempCoinToExchange) * loyaltyData?.valuePerCoin
							)}`}
          </CoinConverter>
          {errMsg !== "" && <ErrorMessage>
            {errMsg}
          </ErrorMessage>}
					<ButtonFull
						isPrimary={false}
						hexPrimary={hexColor}
						isDisabled={disabledSaveButton}
						onClick={disabledSaveButton ? () => {} : handleSave}
						customMargin="0"
						customWidth="100%"
					>
						{WORDING.review_page.REDEEM_COIN[language]}
					</ButtonFull>
				</CoinInputCard>
			{/* </Content> */}
		</Container>
	);
};

export default BottomSheetExchangeCoin;

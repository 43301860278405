import { LS_LANGUAGE } from '../constants';
import { IContextState } from '../interfaces';
import { E_ACTION } from '../interfaces/dataTypes';

export const appReducer = (state: IContextState, action: { type: E_ACTION, payload: any }) => {
  switch (action.type) {
    case E_ACTION.SAVE_SELECTED_ITEMS:
      return { ...state, selectedItems: action.payload };
    case E_ACTION.SAVE_SELECTED_SHIP:
      return { ...state, selectedShip: action.payload };
    case E_ACTION.SAVE_SELECTED_DATE:
      return { ...state, selectedDate: action.payload };
    case E_ACTION.SET_LOADING:
      return { ...state, isLoading: action.payload };
    case E_ACTION.SAVE_CATEGORIES:
      return { ...state, categories: action.payload };
    case E_ACTION.SAVE_VOUCHERS:
      return { ...state, vouchers: action.payload };
    case E_ACTION.SAVE_SELECTED_PRODUCT_VOUCHER:
      return { ...state, selectedProductVoucher: action.payload };
    case E_ACTION.SAVE_SELECTED_SHIPPING_VOUCHER:
      return { ...state, selectedShippingVoucher: action.payload };
    case E_ACTION.SAVE_BUYER:
        const buyer = { ...state.buyer, ...action.payload };
        return { ...state, buyer };
    case E_ACTION.SAVE_ONE_WEEK_CATEGORY:
      return { ...state, oneWeekCategory: action.payload };
    case E_ACTION.SAVE_LOGIN:
      return { ...state, login: action.payload };
    case E_ACTION.SAVE_SHIPS:
      return { ...state, ships: action.payload };
    case E_ACTION.SAVE_SHOP:
      return { ...state, shop: action.payload };
    case E_ACTION.SAVE_ADDRESSES:
      return { ...state, addresses: action.payload };
    case E_ACTION.SAVE_TOTAL:
      return { ...state, total: action.payload };
    case E_ACTION.SAVE_SOURCE:
      return { ...state, source: action.payload };
    case E_ACTION.SAVE_FULFILLMENT:
        return { ...state, fulfillment: action.payload };
    case E_ACTION.SAVE_EVENT_TYPE:
      return { ...state, eventType: action.payload };
    case E_ACTION.CHANGE_LANGUAGE:
      // @ts-ignore
      localStorage.setItem(LS_LANGUAGE, action.payload);
      return { ...state, language: action.payload };
    case E_ACTION.SAVE_TABLE_NUMBER:
      return { ...state, tableNumber: action.payload };
    case E_ACTION.SAVE_HIDE_OTHER_OUTLETS:
        return { ...state, isHideOtherOutlet: action.payload };
    case E_ACTION.CLOSE_POPUP_COMMON:
        return { ...state, isPopupCommonOpen: false };
    case E_ACTION.SAVE_FOODCOURT_CART:
        return { ...state, foodcourtCart: action.payload };
    case E_ACTION.SAVE_MERCHANT:
        return { ...state, merchant: action.payload };
    case E_ACTION.SAVE_ORDER_SN:
      return { ...state, orderSN: action.payload };
    default:
      throw new Error('Unexpected action');
  }
};

import styled, { createGlobalStyle } from "styled-components";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";
import PitikInfoAsset from "../assets/pitikInfo.png";
import ButtonFull from "./ButtonFull";
import { WORDING } from "../constants/translate";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh !important" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden !important" : "scroll")};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
  z-index: 2;
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  position: fixed;
  width: 100vh;
  max-width: 600px;
  background-color: rgba(26, 26, 26, 0.5);
  height: 100vh;
  margin: -16px;
  bottom: 0;
  top: 0;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 50px;
  max-width: 600px;
  position: fixed;
  background-color: ${E_COLOR.WHITE};
  overflow: hidden;
  bottom: 0;
  border-radius: 16px 16px 0 0;
`;

const Header = styled.div`
  width: 100%;
  border-radius: 5px;
  margin: 24px 20px 20px 18px;
`;

const HeaderLabel = styled.div`
	width: 70%;
  margin: 0 16px;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 27px;
`;

const DineInLogo = styled.img`
	width: 148px;
	height: 148px;
  margin-top: 47px;
`;

const Headline = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 24px;
	line-height: 36px;
	margin: 16px 100px;
  text-align: center;
`;

const Description = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	margin: 0 16px 16px;
  text-align: center;
`;

const CloseButton = styled.div`
  color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
  margin-top: 8px;
`

const Image = styled.img`
  width: 200px;
  height: 200px;
`

const Instruction = styled.div`
  color: ${E_COLOR.BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 16px;
  line-height: 24px;
  margin: 28px 16px;
  text-align: center;
`

interface IProps {
	isShow: boolean;
	setShow: (isShow: boolean) => void;
  isCash: boolean;
  isEDC: boolean;
  isCustomPayment: boolean;
  language: "id" | "en";
}

const BottomSheetPaymentInstruction = ({ isShow, setShow, isCash, isEDC, isCustomPayment, language }: IProps) => {
	return (
		<Container isShow={isShow}>
			<GlobalStyle isShow={isShow} />
			<Content>
				<Header>
					<HeaderLabel>{WORDING.popup.TITLE_PAY_AT_CASHIER[language]}</HeaderLabel>
				</Header>
        <Image src={PitikInfoAsset} />
        <Instruction>
          {isCash && WORDING.popup.DESC_PAY_AT_CASHIER_CASH[language]}
          {isEDC && WORDING.popup.DESC_PAY_AT_CASHIER_CARD[language]}
          {isCustomPayment && WORDING.popup.DESC_PAY_AT_CASHIER_CUSTOM_PAYMENT[language]}
        </Instruction>
        <ButtonFull hexPrimary={E_COLOR.DARK_BLUE} isDisabled={false} isPrimary={true} onClick={() => setShow(false)}>{WORDING.general.UNDERSTOOD[language]}</ButtonFull>
			</Content>
		</Container>
	);
};

export default BottomSheetPaymentInstruction;

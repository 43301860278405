import {
	postOpenBill,
	postOrder,
	postValidateOrder,
} from "./../services/Order";
import { useAppContext } from "../providers/useAppContext";
import { useState, useEffect, useLayoutEffect } from "react";
import {
	COURIER_NAME_LIST,
	E_ACTION,
	E_DELIVERY_OPTION,
	E_VOUCHER_TYPE,
} from "../interfaces/dataTypes";
import {
	IReqOrder,
	IContact,
	IProduct,
	IPathParams,
	IContextState,
	T_PAYMENT_TYPE,
	IVoucher,
	IReqCoinBalance,
	IReqOtp,
	IShop,
	IReqOpenBill,
	IReqValidateOrder,
	IErrValidation,
} from "../interfaces";
import { calcRoundedValue, countDiscount, getFormattedDate } from "../utils";
import { useHistory, useParams } from "react-router-dom";
import {
	ERR_VALIDATION_TYPE,
	ERR_VALIDATION_WORDING,
	PLATFORM_FEE,
	REPORT_ISSUE_WORDING,
	SHOP_ID_FOR_EXCLUDE_TAKEAWAY,
	URL_PARAM_CHANGE_PAYMENT_METHOD,
} from "../constants";
import {
	getCoinBalance,
	getOTP,
	getPromotionVoucher,
	getPromotionVoucherByCode,
	getShopInfo,
	postValidateOTP,
} from "../services/Shop";
import { WORDING } from "../constants/translate";
import { useItemsStepHook } from "./ItemsStep";

export const useReviewStepHook = () => {
	const [totalItem, setTotalItem] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	const [isPostError, setIsPostError] = useState(false);
	const [discountVoucherAmount, setDiscountVoucherAmount] = useState(0);
	const [amountItemOnly, setAmountItemOnly] = useState(0);
	const [serviceFee, setServiceFee] = useState(0);
	const [tax, setTax] = useState(0);
	const [isShowAgreementFraud, setIsShowAgreementFraud] = useState(false);
	const [isShowBottomSheetPaymentOption, setIsShowBottomSheetPaymentOption] =
		useState(false);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
	const [currentEligibleVouchers, setCurrentEligibleVouchers] = useState(
		[] as IVoucher[]
	);
	const [currentNotEligibleVouchers, setCurrentNotEligibleVouchers] = useState(
		[] as IVoucher[]
	);
	const [searchedCode, setSearchedCode] = useState("");
	const [currentProductVoucher, setCurrentProductVoucher] = useState(null);
	const [currentShippingVoucher, setCurrentShippingVoucher] = useState(null);
	const [voucherErrorMessage, setVoucherErrorMessage] = useState("");
	const [isSearchingVoucherByCode, setIsSearchingVoucherByCode] =
		useState(false);
	const [isShowDimNotification, setIsShowDimNotification] = useState(false);
	const [coinToExchange, setCoinToExchange] = useState("");
	const [buyerCoin, setBuyerCoin] = useState(0);
	const [otpCode, setOtpCode] = useState("");
	const [isOtpExp, setIsOtpExp] = useState(false);
	const [showOtpBottomSheet, setShowOtpBottomSheet] = useState(
		"n" as "y" | "n" | "exchangeCoin" | "submitOpenBill"
	); // "y" | "n" | "exchangeCoin"
	const [popupErrorMsg, setPopupErrorMsg] = useState("");
	const [roundedValue, setRoundedValue] = useState(0);
	const [validationErrors, setValidationErrors] = useState(
		[] as IErrValidation[]
	);
	const history = useHistory();
	const params = useParams();
	const { dispatch, state } = useAppContext();
	const {
		selectedItems,
		selectedProductVoucher,
		selectedShippingVoucher,
		selectedShip,
		buyer,
		shop,
		selectedDate,
		source,
		fulfillment,
		vouchers,
		eventType,
		language,
		categories,
		foodcourtCart,
		merchant,
	} = state as IContextState;
	let timerDim: any;
	const { fetchItems } = useItemsStepHook();

	// @ts-ignore
	const otpLS = localStorage.getItem("otp"); // otp from local storage

	const countTotalAmount = () => {
		let amount = 0;
		let discountProduct = 0;
		let discountShipping = 0;
		let itemQty = 0;
		let grossAmountNoDiscountItem = 0; // no harga coret
    
    let amountWithServiceFee = 0;
    let amountWithTax = 0;
    let promoAmountWithServiceFee = 0;
    let promoAmountWithTax = 0;

		const voucherShippingAmount = selectedShippingVoucher?.amount
			? parseInt(selectedShippingVoucher.amount)
			: 0;
		const voucherShippingMaxAmount = selectedShippingVoucher?.max_amount
			? parseInt(selectedShippingVoucher.max_amount)
			: null;
		const voucherProductAmount = selectedProductVoucher?.amount
			? parseInt(selectedProductVoucher.amount)
			: 0;
		const voucherProductMaxAmount = selectedProductVoucher?.max_amount
			? parseInt(selectedProductVoucher.max_amount)
			: null;

		// iterate items in buyer cart
		Object.keys(selectedItems!).forEach((id: string) => {
			const actualPrice = selectedItems![id].data.discount_price
				? selectedItems![id].data.discount_price
				: selectedItems![id].data.price;
			let itemPrice = parseInt(actualPrice) * selectedItems![id].quantity;
			let variantPrice = 0;
			let itemGrossPrice =
				parseInt(selectedItems![id].data.price) * selectedItems![id].quantity;

			// iterate variant for each item in buyer cart
			Object.keys(selectedItems[id].variants).forEach((variantId: string) => {
				Object.keys(selectedItems[id].variants[variantId]).forEach(
					(variantDetailId: string) => {
						variantPrice =
							variantPrice +
							parseInt(
								selectedItems[id].variants[variantId][variantDetailId].price
							);
					}
				);
			});
			itemPrice = itemPrice + variantPrice * selectedItems![id].quantity;
			itemGrossPrice =
				itemGrossPrice + variantPrice * selectedItems![id].quantity;

      let isBypassServiceFee = selectedItems[id].data.is_bypass_service_fee;

      if (selectedItems[id].is_takeaway) {
        isBypassServiceFee = true;
        const takeawayCost = +selectedItems[id].data.takeaway_cost * selectedItems![id].quantity;
        itemPrice += takeawayCost;
        itemGrossPrice += takeawayCost;
      }

			if (selectedProductVoucher) {
				if (
					selectedProductVoucher?.product_ids?.includes(parseInt(id)) ||
					selectedProductVoucher.is_all_product
				) {
          const discountItem = countDiscount(
            itemPrice,
            voucherProductAmount,
            voucherProductMaxAmount
          );

					discountProduct = discountProduct + discountItem;
          
          if (!isBypassServiceFee) {
            promoAmountWithServiceFee += discountItem;
          }

          if (!selectedItems[id].data.is_bypass_tax) {
            promoAmountWithTax += discountItem;
          }
				}
			}

      if (!isBypassServiceFee) {
        amountWithServiceFee += itemPrice;
      }

      if (!selectedItems[id].data.is_bypass_tax) {
        amountWithTax += itemPrice;
      }

			amount = amount + itemPrice;
			itemQty = itemQty + selectedItems![id].quantity;
			grossAmountNoDiscountItem = grossAmountNoDiscountItem + itemGrossPrice;
		});

		let shippingPrice = selectedShip ? selectedShip.price : 0;

		if (selectedShippingVoucher) {
			discountShipping = Math.round(discountShipping);
			discountShipping = voucherShippingMaxAmount
				? countDiscount(
						shippingPrice,
						voucherShippingAmount,
						voucherShippingMaxAmount
				  )
				: countDiscount(shippingPrice, voucherShippingAmount);
		}
		if (selectedProductVoucher) {
			discountProduct = Math.round(discountProduct);
			discountProduct =
				voucherProductMaxAmount && discountProduct >= voucherProductMaxAmount
					? voucherProductMaxAmount
					: discountProduct;

      promoAmountWithServiceFee =
        voucherProductMaxAmount && promoAmountWithServiceFee >= voucherProductMaxAmount
          ? voucherProductMaxAmount
          : promoAmountWithServiceFee;

      promoAmountWithTax =
        voucherProductMaxAmount && promoAmountWithTax >= voucherProductMaxAmount
          ? voucherProductMaxAmount
          : promoAmountWithTax;
		}

		// if withInsurance is true from shipper, the shipping price is included insurance
		let insuranceRate = selectedShip?.withInsurance
			? 0
			: selectedShip?.isUserUseInsurance
			? selectedShip.insuranceRate
			: 0;

		if (amount - discountProduct < shop.loyaltyData?.minPurchase) {
			setCoinToExchange("");
		}

		// set amount item only to state before counted the shipping price etc
		setAmountItemOnly(amount);

		// fetch promotion voucher after total amount counted without shippingprice and etc
		if (vouchers.length === 0) {
			fetchPromotionVoucher(shop.id, amount);
		} else {
			// re-filter voucher with the latest total amount (item amount only)
			filterVouchers(vouchers, amount);
			if (selectedProductVoucher) {
				const selectedVoucherStillEligible = checkEligibleVoucher(
					selectedProductVoucher,
					amount
				);
				if (!selectedVoucherStillEligible) {
					handleSelectVoucher(null, true);
				}
			}
			if (selectedShippingVoucher) {
				const selectedVoucherStillEligible = checkEligibleVoucher(
					selectedShippingVoucher,
					amount
				);
				if (!selectedVoucherStillEligible) {
					handleSelectVoucher(null, false);
				}
			}
		}

		// count discount from loyalty points
		let discountCoin = coinToExchange
			? parseInt(coinToExchange) *
			  (shop.loyaltyData ? shop.loyaltyData.valuePerCoin : 0)
			: 0;

		let serviceFeeTemp = 0;
		let taxTemp = 0;
		if (selectedShip.name === E_DELIVERY_OPTION.DINE_IN) {
			if (shop.serviceFeePercentage !== "") {
        const serviceFeeAmount = amountWithServiceFee - promoAmountWithServiceFee;

				// service fee = total amount after discount
				serviceFeeTemp = Math.round(
					((serviceFeeAmount -
						discountShipping -
						(discountCoin >= amount - discountProduct
							? amount - discountProduct
							: discountCoin)) *
						parseInt(shop.serviceFeePercentage)) /
						100
				);

        serviceFeeTemp = serviceFeeTemp < 0 ? 0 : serviceFeeTemp;

				// service fee = total amout before discount
				// serviceFeeTemp = Math.round(
				// 	(grossAmountNoDiscountItem * parseInt(shop.serviceFeePercentage)) /100);
			}
		}

		// tax is count after amount added by shipping price and insurance rate
		amount = amount + shippingPrice + insuranceRate;

		if (
			(selectedShip.name === E_DELIVERY_OPTION.DINE_IN &&
				shop.taxDeliveryOption?.includes(E_DELIVERY_OPTION.DINE_IN)) ||
			(selectedShip.name === E_DELIVERY_OPTION.PICK_UP &&
				shop.taxDeliveryOption?.includes(E_DELIVERY_OPTION.PICK_UP)) ||
			(selectedShip.rateId === E_DELIVERY_OPTION.SELF_SHIPPING &&
				shop.taxDeliveryOption?.includes(E_DELIVERY_OPTION.SELF_SHIPPING)) ||
			(selectedShip.name !== E_DELIVERY_OPTION.DINE_IN &&
				selectedShip.name !== E_DELIVERY_OPTION.PICK_UP &&
				selectedShip.rateId !== E_DELIVERY_OPTION.SELF_SHIPPING &&
				shop.taxDeliveryOption?.includes(E_DELIVERY_OPTION.DELIVERY))
		) {
			if (shop.taxPercentage !== "") {
        let shippingFeeAmount = shippingPrice + insuranceRate - discountShipping;

        if (shippingFeeAmount < 0) {
          shippingFeeAmount = 0;
        }

        const taxAmount = amountWithTax - promoAmountWithTax
          - (discountCoin >= amountWithTax - promoAmountWithTax
            ? amountWithTax - promoAmountWithTax
            : discountCoin);
				// tax = total amount after discount and after service fee
				taxTemp = Math.round(
					((taxAmount +
						(shop.isServiceFeeFreeTax ? 0 : serviceFeeTemp) +
						shippingFeeAmount) *
						parseFloat(shop.taxPercentage)) /
						100
				);

        taxTemp = taxTemp < 0 ? 0 : taxTemp;
			}
		}
		setServiceFee(serviceFeeTemp);
		setTax(taxTemp);
		amount = amount - discountProduct - discountShipping - discountCoin;

		// check if after redeem points the total amount is negative
		amount = amount < 0 ? 0 : amount;

		// add the service fee and tax after count the discount
		amount = amount + serviceFeeTemp + taxTemp;

		amount = shop.platformFeeByMerchant ? amount : amount + shop.platformFee;

		if (shop.rounding) {
			const roundedAmount = calcRoundedValue(
				shop.rounding.type,
				shop.rounding.value,
				amount
			);
			setRoundedValue(roundedAmount - amount);

			amount = roundedAmount;
		}

		setTotalAmount(amount);
		setTotalItem(itemQty);
		setDiscountVoucherAmount(discountProduct + discountShipping);
	};

	const handleSubmit = async (paymentMethod?: string) => {
		const isPassedValidation = await handleValidation();
		if (isPassedValidation) {
			dispatch({
				type: E_ACTION.SET_LOADING,
				payload: true,
			});

			const { name, phone } = buyer;

			const { price, rateId } = selectedShip!;

			const products: IProduct[] = [];

			Object.keys(selectedItems!).forEach((id: string) => {
				const { notes, quantity, variants, is_takeaway } = selectedItems![id];
				let variant_details = [];

				if (variants) {
					Object.keys(variants).forEach((variantId: string) => {
						Object.keys(variants[variantId]).forEach(
							(variantDetailId: string) => {
								variant_details.push({
									id: parseInt(variantDetailId),
									variant_id: parseInt(variantId),
								});
							}
						);
					});
				}

				if (quantity > 0) {
					products.push({
						description: notes,
						id: parseInt(id),
						quantity,
						variant_details,
            is_takeaway
					});
				}
			});

			let discountShippingAmount = 0;

			if (selectedShippingVoucher) {
				discountShippingAmount = selectedShippingVoucher.max_amount
					? countDiscount(
							price,
							parseInt(selectedShippingVoucher.amount),
							parseInt(selectedShippingVoucher.max_amount)
					  )
					: countDiscount(price, parseInt(selectedShippingVoucher.amount));
			}

			const isPickUpDineIn =
				selectedShip?.name === E_DELIVERY_OPTION.PICK_UP ||
				selectedShip?.name === E_DELIVERY_OPTION.DINE_IN;

			// const promotionIdArr = selectedProductVoucher && selectedShippingVoucher ? [selectedProductVoucher.id, selectedShippingVoucher.id] : selectedProductVoucher ? [selectedProductVoucher.id] : selectedShippingVoucher ? [selectedShippingVoucher.id] : [];
			// const promotionCodeArr = selectedProductVoucher && selectedShippingVoucher ? [selectedProductVoucher.code, selectedShippingVoucher.code] : selectedProductVoucher ? [selectedProductVoucher.code] : selectedShippingVoucher ? [selectedShippingVoucher.code] : [];

			// const promotions = {
			// 	promotion_code: promotionCodeArr,
			// 	promotion_id: promotionIdArr,
			// }

			const promotions =
				selectedProductVoucher && selectedShippingVoucher
					? [
							{
								promotion_id: selectedProductVoucher.id,
								promotion_code: selectedProductVoucher.code,
							},
							{
								promotion_id: selectedShippingVoucher.id,
								promotion_code: selectedShippingVoucher.code,
							},
					  ]
					: selectedProductVoucher
					? [
							{
								promotion_id: selectedProductVoucher.id,
								promotion_code: selectedProductVoucher.code,
							},
					  ]
					: selectedShippingVoucher
					? [
							{
								promotion_id: selectedShippingVoucher.id,
								promotion_code: selectedShippingVoucher.code,
							},
					  ]
					: [];

			const utcFulfillment = new Date(fulfillment.timestamp).toUTCString();
			let shippingPrice = price - discountShippingAmount;
			shippingPrice = selectedShip?.isUserUseInsurance
				? shippingPrice + selectedShip.insuranceRate
				: shippingPrice;
			const coinValue =
				parseInt(coinToExchange) * shop.loyaltyData?.valuePerCoin;
			const payload: IReqOrder = {
				shop_id: shop.id,
				dest_latitude: isPickUpDineIn ? "" : `${buyer.lat}`,
				dest_longitude: isPickUpDineIn ? "" : `${buyer.lng}`,
				name: name ? name : "DARI KIOSK",
				phone_number: `+${phone ? phone : "6288"}`,
				phone_country: phone ? buyer.country : "id",
				rate_id:
					selectedShip?.name === E_DELIVERY_OPTION.PICK_UP ? "" : `${rateId}`,
				recipient_address: isPickUpDineIn ? "" : buyer.address,
				recipient_name: isPickUpDineIn
					? name
					: buyer.recipientName
					? buyer.recipientName
					: name,
				recipient_address_note: isPickUpDineIn ? "" : buyer.addressNote,
				shipping_date: getFormattedDate(selectedDate!),
				products,
				shipping_price: `${shippingPrice}`,
				subtotal: `${totalAmount < 0 ? 0 : totalAmount}`,
				promotions,
				buyer_phone_number: isPickUpDineIn
					? phone
						? phone
						: "6288"
					: buyer.recipientPhone
					? buyer.recipientPhone
					: phone,
				payment_method: paymentMethod || selectedPaymentMethod,
				optional_insurance: selectedShip?.isUserUseInsurance
					? selectedShip.withInsurance
						? false
						: true
					: false,
				source,
				table_no:
					selectedShip?.name === E_DELIVERY_OPTION.DINE_IN
						? buyer.tableNumber
						: null,
				fulfillment_time: fulfillment.timestamp
					? new Date(utcFulfillment).getTime()
					: null,
				coin_used: shop.loyaltyData ? parseInt(coinToExchange) : 0,
				coin_value: shop.loyaltyData
					? coinValue > amountItemOnly - discountVoucherAmount
						? `${amountItemOnly - discountVoucherAmount}`
						: `${coinValue}`
					: "",
				otp: otpCode && shop.loyaltyData ? parseInt(otpCode) : null,
			};
			const response = await postOrder(payload);

			dispatch({
				type: E_ACTION.SET_LOADING,
				payload: false,
			});

			if (response.data === null) {
				if (
					response.code === 400 &&
					response.err_msg === "promotion usage already ran out"
				) {
					setIsShowDimNotification(true);
					timerDim = setTimeout(() => setIsShowDimNotification(false), 2000);
				} else if (response.code === 500) {
					if (response.err_msg === "otp invalid") {
						sendOTPtoBuyer("whatsapp");
						setShowOtpBottomSheet("y");
					} else if (response.err_msg === "shop not support loyalty") {
						refetchLoyaltyData();
						setIsShowBottomSheetPaymentOption(false);
						setIsPostError(true);
						setPopupErrorMsg(WORDING.popup.ERR_NOT_ACTIVATE_LOYALTY[language]);
					} else if (response.err_msg.includes("coin value is not valid")) {
						setIsShowBottomSheetPaymentOption(false);
						setIsPostError(true);
						setPopupErrorMsg(WORDING.popup.ERR_LOYALTY_DATA_CHANGED[language]);
						refetchLoyaltyData();
					} else {
						setPopupErrorMsg("");
						setIsPostError(true);
					}
				} else {
					setPopupErrorMsg("");
					setIsPostError(true);
				}
			} else {
				setIsPostError(false);
				if (
					paymentMethod === "xendit" ||
					paymentMethod === "xendit/qris" ||
					paymentMethod === "xendit/shopeepay" ||
					paymentMethod === "xendit/linkaja" ||
					paymentMethod === "xendit/ovo" ||
					paymentMethod === "xendit/dana" ||
					selectedPaymentMethod === "xendit" ||
					selectedPaymentMethod === "xendit/qris" ||
					selectedPaymentMethod === "xendit/shopeepay" ||
					selectedPaymentMethod === "xendit/linkaja" ||
					selectedPaymentMethod === "xendit/ovo" ||
					selectedPaymentMethod === "xendit/dana"
				) {
					//  @ts-ignore
					let a = document.createElement("a");
					//  @ts-ignore
					document.body.appendChild(a);
					a.style = "display: none";
					a.href = response.data.payment_url;
					// a.download = <your_fileName>;
					a.click();
					//  @ts-ignore
					document.body.removeChild(a);
					//  @ts-ignore
					// window.open(response.payment_url, "_blank");
				}
				history.push(`/order_sn/${response.data.order_sn}`);
			}
		}
	};

	const handleClickedConfirmOrder = () => {
		// if (selectedShip?.rateId === E_DELIVERY_OPTION.SELF_SHIPPING) {
		// 	setIsShowAgreementFraud(true);
		// } else {
		setIsShowBottomSheetPaymentOption(true);
		// }
	};

	const reportIssue = () => {
		// @ts-ignore
		const token = localStorage.getItem("token");
		const isLoggedIn = token ? "Ya" : "Tidak";

		// @ts-ignore
		const waLink = REPORT_ISSUE_WORDING.replace("{shopName}", shop.name)
			.replace("{buyerName}", buyer.name)
			.replace("{buyerPhone}", buyer.phone)
			.replace("{recipientName}", buyer.recipientName)
			.replace("{recipientPhone}", buyer.recipientPhone)
			.replace("{address}", buyer.address)
			.replace("{shipping}", selectedShip?.name)
			.replace(
				"{voucher}",
				selectedProductVoucher || selectedShippingVoucher
					? `${
							selectedProductVoucher
								? selectedShippingVoucher
									? `${selectedProductVoucher.code} - ${selectedShippingVoucher.code}`
									: selectedProductVoucher.code
								: selectedShippingVoucher.code
					  }`
					: "-"
			)
			.replace("{isLoggedIn}", isLoggedIn);
		// @ts-ignore
		window.open(waLink, "_blank");
	};

	const fetchPromotionVoucher = async (
		shopIdInitial?: number,
		amount?: number
	) => {
		setIsSearchingVoucherByCode(false);
		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: true,
		});

		const response = await getPromotionVoucher(
			shopIdInitial ? shopIdInitial : shop.id,
			{ phone_number: buyer.phone, phone_country: buyer.country }
		);

		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: false,
		});
		dispatch({
			type: E_ACTION.SAVE_VOUCHERS,
			payload: response,
		});

		filterVouchers(response, amount);

		// Auto select shipping voucher if available
		// response.forEach((vou) => {
		// 	if (vou.type === E_VOUCHER_TYPE.SHIPPING) {
		// 		setCurrentVoucher(vou);
		// 	}
		// });
		// let i = 0;
		// while (i < response.length) {
		// 	if (response[i].type === E_VOUCHER_TYPE.SHIPPING) {
		// 		setCurrentVoucher(response[i]);
		// 		dispatch({
		// 			type: E_ACTION.SAVE_SELECTED_VOUCHER,
		// 			payload: response[i],
		// 		});

		// 		i = response.length;
		// 	}
		// 	i++;
		// }
	};

	const checkEligibleVoucher = (voucher: IVoucher, amount: number) => {
		const { min_order, shipping_type } = voucher;

		// check if minimum order is passed
		const passedMinOrder = amount >= parseInt(min_order);

		// check if shipping type is passed
		let passedShippingType = false;
		if (shipping_type.includes(`${selectedShip.rateId}`)) {
			passedShippingType = true;
		} else if (
			shipping_type.includes("pickup") &&
			selectedShip.name === E_DELIVERY_OPTION.PICK_UP
		) {
			passedShippingType = true;
		} else if (
			shipping_type.includes("shipper") &&
			COURIER_NAME_LIST.includes(selectedShip.name)
		) {
			passedShippingType = true;
		}

		// check if selected items still included for voucher
		let passedEligibleProduct = false;
		if (voucher.type === E_VOUCHER_TYPE.ORDER) {
			if (voucher.is_all_product) {
				passedEligibleProduct = true;
			} else {
				Object.keys(selectedItems!).every((id: string) => {
					if (voucher.product_ids.includes(parseInt(id))) {
						passedEligibleProduct = true;
						return false;
					}
					return true;
				});
			}
		} else {
			passedEligibleProduct = true;
		}

    // Check is voucher is happy hour
    let passedHappyHour = false;
    if (voucher.is_recurring) {
      const orderTime = selectedDate.getHours() * 60 + selectedDate.getMinutes();
      const [startHour, startMinute] = voucher.recurring_config!.start_time.split(':');
      const [endHour, endMinute] = voucher.recurring_config!.end_time.split(':');

      const startTime = (+startHour * 60) + +startMinute;
      const endTime = (+endHour * 60) + +endMinute;

      if (startTime <= orderTime && orderTime <= endTime) {
        passedHappyHour = true;
      }
    } else {
      passedHappyHour = true;
    }

		return passedMinOrder && passedShippingType && passedEligibleProduct && passedHappyHour;
	};

	const filterVouchers = (vouchers: IVoucher[], amount: number) => {
		const eligibleVoucher = [];
		const notEligibleVoucher = [];
		vouchers.forEach((vou) => {
			const {
				min_order,
				payment_type,
				shipping_type,
				type,
				product_ids,
				is_all_product,
			} = vou;
			const passedMinOrder = amount >= parseInt(min_order);
			let passedShippingType = false;
			if (shipping_type.includes(`${selectedShip.rateId}`)) {
				passedShippingType = true;
			} else if (
				shipping_type.includes("pickup") &&
				selectedShip.name === E_DELIVERY_OPTION.PICK_UP
			) {
				passedShippingType = true;
			} else if (
				shipping_type.includes("shipper") &&
				COURIER_NAME_LIST.includes(selectedShip.name)
			) {
				passedShippingType = true;
			}

			if (passedMinOrder && passedShippingType) {
				// filter if product ids is included
				if (type === E_VOUCHER_TYPE.ORDER) {
					const exist =
						Object.keys(selectedItems).filter((o) => {
							return product_ids.includes(parseInt(o));
						}).length > 0;

					if (exist || is_all_product) {
						eligibleVoucher.push(vou);
					} else {
						notEligibleVoucher.push(vou);
					}
				} else {
					eligibleVoucher.push(vou);
				}
			} else {
				notEligibleVoucher.push(vou);
			}
		});

		setCurrentEligibleVouchers(eligibleVoucher);
		setCurrentNotEligibleVouchers(notEligibleVoucher);
	};

	const fetchPromotionVoucherByCode = async () => {
		setIsSearchingVoucherByCode(true);
		if (searchedCode === "") {
			fetchPromotionVoucher(null, amountItemOnly);
		} else {
			dispatch({
				type: E_ACTION.SET_LOADING,
				payload: true,
			});

			const response: IVoucher = await getPromotionVoucherByCode(
				shop.id,
				searchedCode,
				{ phone_number: buyer.phone, phone_country: buyer.country }
			);

			dispatch({
				type: E_ACTION.SET_LOADING,
				payload: false,
			});

			if (response && typeof response === "object") {
				filterVouchers([response], amountItemOnly);
				dispatch({
					type: E_ACTION.SAVE_VOUCHERS,
					payload: [response],
				});
			} else {
				setCurrentEligibleVouchers([]);
				setCurrentNotEligibleVouchers([]);
				if (typeof response === "string") {
					if (response === "phone number already used for this promotion")
						setVoucherErrorMessage("Melebihi batas penggunaan voucher");
				} else {
					setVoucherErrorMessage("Voucher tidak ditemukan");
				}
			}
		}
	};

	const handleSelectVoucher = (
		voucher: IVoucher,
		isProductVoucher: boolean
	) => {
		// if((voucher.id !== currentVoucher.id) && (voucher.id !== selectedVoucher.id)) {
		if (voucher) {
			if (isProductVoucher) {
				setCurrentProductVoucher(voucher);
				dispatch({
					type: E_ACTION.SAVE_SELECTED_PRODUCT_VOUCHER,
					payload: voucher,
				});
			} else {
				setCurrentShippingVoucher(voucher);
				dispatch({
					type: E_ACTION.SAVE_SELECTED_SHIPPING_VOUCHER,
					payload: voucher,
				});
			}
		} else {
			if (isProductVoucher) {
				setCurrentProductVoucher(null);
				dispatch({
					type: E_ACTION.SAVE_SELECTED_PRODUCT_VOUCHER,
					payload: null,
				});
			} else {
				setCurrentShippingVoucher(null);
				dispatch({
					type: E_ACTION.SAVE_SELECTED_SHIPPING_VOUCHER,
					payload: null,
				});
			}
		}

		// }
	};

	const sendOTPtoBuyer = async (method: "sms" | "whatsapp") => {
		const payload: IReqOtp = {
			phone_number: buyer.phone,
			send_method: method,
		};

		const response = await getOTP(payload);
	};

	const fetchCoinBalance = async (otp: string) => {
		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: true,
		});

		const payload: IReqCoinBalance = {
			phone_number: buyer.phone,
			otp: parseInt(otp),
		};

		const response = await getCoinBalance(shop.id, payload);

		setBuyerCoin(response.data.balance);

		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: false,
		});
	};

	const handleTextInput = (name: string, value: string) => {
		if (name === "coinToExchange") {
			setCoinToExchange(value);
		}
	};

	const checkOTPValid = async (otp: string, isFetchCoinBalance: boolean) => {
		const payload: IReqCoinBalance = {
			phone_number: buyer.phone,
			otp: parseInt(otp),
		};

		const response = await postValidateOTP(payload);
		if (response.code === 200) {
			// @ts-ignore
			localStorage.setItem("otp", otp);

			setIsOtpExp(false);
			if (isFetchCoinBalance) {
				fetchCoinBalance(otp);
			}
		}

		return response.code === 200;
	};

	const refetchLoyaltyData = async () => {
		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: true,
		});

		const responseShopInfo = await getShopInfo(shop.link);

		if (responseShopInfo.shop_id) {
			const { loyalty_data } = responseShopInfo;

			dispatch({
				type: E_ACTION.SAVE_SHOP,
				payload: {
					...shop,
					loyaltyData: loyalty_data
						? {
								maxCoinUsed: loyalty_data.max_coin_used,
								minPurchase: parseInt(loyalty_data.min_order),
								valuePerCoin: parseInt(loyalty_data.value_per_coin),
						  }
						: null,
				} as IShop,
			});

			dispatch({
				type: E_ACTION.SET_LOADING,
				payload: false,
			});
		}
	};

	const handleOpenBill = async (otp?: string) => {
		const isPassedValidation = await handleValidation();
		if (isPassedValidation) {
			dispatch({
				type: E_ACTION.SET_LOADING,
				payload: true,
			});

			const products: IProduct[] = [];

			Object.keys(selectedItems!).forEach((id: string) => {
				const { notes, quantity, variants, is_takeaway } = selectedItems![id];
				let variant_details = [];

				if (variants) {
					Object.keys(variants).forEach((variantId: string) => {
						Object.keys(variants[variantId]).forEach(
							(variantDetailId: string) => {
								variant_details.push({
									id: parseInt(variantDetailId),
									variant_id: parseInt(variantId),
								});
							}
						);
					});
				}

				if (quantity > 0) {
					products.push({
						description: notes,
						id: parseInt(id),
						quantity,
						variant_details,
            is_takeaway
					});
				}
			});

			const { name, phone, tableNumber } = buyer;

			//	@ts-ignore
			const orderSN = sessionStorage.getItem("ordersn");

			const payload: IReqOpenBill = {
				name,
				order_sn: orderSN ? orderSN : null,
				otp: otp
					? parseInt(otp)
					: otpCode
					? parseInt(otpCode)
					: parseInt(otpLS),
				phone_country: phone ? buyer.country : "id",
				phone_number: `+${phone ? phone : "6288"}`,
				products,
				shop_id: shop.id,
				table_no: tableNumber,
			};

			const response = await postOpenBill(payload);

			dispatch({
				type: E_ACTION.SET_LOADING,
				payload: false,
			});

			if (response.data) {
				if (response.data.order_sn) {
					history.push(`/open/${response.data.order_sn}`);

					// @ts-ignore
					sessionStorage.removeItem("ordersn");

					// remove selected items from context
					dispatch({
						type: E_ACTION.SAVE_SELECTED_ITEMS,
						payload: null,
					});
				}
			} else {
				if (response.code === 500 && response.err_msg === "otp invalid") {
					// setPopupErrorMsg(WORDING.popup.ERR_OTP_EXP[language]);
					askBuyerForOtp(true);
					return;
				}
				setIsPostError(true);
			}
		}
	};

	const askBuyerForOtp = (isResubmit?: boolean) => {
		sendOTPtoBuyer("whatsapp");
		setShowOtpBottomSheet(isResubmit ? "submitOpenBill" : "y");
	};

	const handleValidation = async () => {
		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: true,
		});

		const utcFulfillment = new Date(fulfillment.timestamp).toUTCString();
		const products: IProduct[] = [];

		Object.keys(selectedItems!).forEach((id: string) => {
			const { notes, quantity, variants, data, is_takeaway } = selectedItems![id];
			let variant_details = [];

			if (variants) {
				Object.keys(variants).forEach((variantId: string) => {
					Object.keys(variants[variantId]).forEach(
						(variantDetailId: string) => {
							variant_details.push({
								id: parseInt(variantDetailId),
								variant_id: parseInt(variantId),
								price: variants[variantId][variantDetailId].price,
							});
						}
					);
				});
			}

			if (quantity > 0) {
				products.push({
					description: notes,
					id: parseInt(id),
					quantity,
					variant_details,
					price: data.discount_price
						? parseInt(data.discount_price)
						: parseInt(data.price),
          is_takeaway
				});
			}
		});

		const payload: IReqValidateOrder = {
			products: products,
			shipping_date_millis: fulfillment.timestamp
				? new Date(utcFulfillment).getTime()
				: new Date().getTime(),
			shop_id: shop.id,
		};

		const response = await postValidateOrder(payload);
		setValidationErrors(response?.errors);

		if (!response.success) {
			const { id, operationalDays, preOrder, operationHours, close, tzOffset } =
				shop;
			// refetch the stock in case the user back the page to items page
			fetchItems(
				id,
				operationalDays,
				preOrder,
				selectedDate,
				operationHours,
				close,
				tzOffset
			);

			const errInvalidPrice = response.errors.filter(
				(err) => err.type === ERR_VALIDATION_TYPE.INVALID_PRICE
			);
			const errMissingVariant = response.errors.filter(
				(err) => err.type === ERR_VALIDATION_TYPE.MISSING_VARIANT
			);
			if (errInvalidPrice.length > 0) {
				const updatedSelectedItems = { ...selectedItems };
				errInvalidPrice.forEach((err) => {
					if (err.variant_id !== 0 && err.variant_detail_id !== 0) {
						updatedSelectedItems[err.product_id].variants[err.variant_id][
							err.variant_detail_id
						].price = err.expected_value;
					} else {
						if (updatedSelectedItems[err.product_id].data.discount_price) {
							updatedSelectedItems[err.product_id].data.discount_price =
								err.expected_value;
						} else {
							updatedSelectedItems[err.product_id].data.price =
								err.expected_value;
						}
					}
				});

				dispatch({
					type: E_ACTION.SAVE_SELECTED_ITEMS,
					payload: updatedSelectedItems,
				});
			}
			if (errMissingVariant.length > 0) {
				const updatedSelectedItems = { ...selectedItems };

				// remove oos variant from the data that used to display the product detail in review page
				const indexMissingVariant = updatedSelectedItems[
					errMissingVariant[0].product_id
				].data.variant.findIndex(
					(item) => item.id === errMissingVariant[0].variant_id
				);
				updatedSelectedItems[
					errMissingVariant[0].product_id
				].data.variant.splice(indexMissingVariant, 1);

				// remove oos variant from the selected item in the cart
				delete updatedSelectedItems[errMissingVariant[0].product_id].variants[
					errMissingVariant[0].variant_id
				][errMissingVariant[0].variant_detail_id];

				dispatch({
					type: E_ACTION.SAVE_SELECTED_ITEMS,
					payload: updatedSelectedItems,
				});
			}
		}

		dispatch({
			type: E_ACTION.SET_LOADING,
			payload: false,
		});

		if (!response.success) {
			setIsShowBottomSheetPaymentOption(false);
			setIsShowDimNotification(true);
			timerDim = setTimeout((e) => {
				setIsShowDimNotification(false);
			}, 1000);
		}

		return response.success;
	};

	const getWordingErrorValidation = (err: IErrValidation) => {
		let wording = "";
		const {
			expected_value,
			requested_value,
			type,
			variant_detail_id,
			variant_id,
		} = err;
		if (type === ERR_VALIDATION_TYPE.OUT_OF_STOCK) {
			if (expected_value === 0) {
				wording = ERR_VALIDATION_WORDING.OUT_OF_STOCK_PRODUCT;
			} else {
				if (variant_detail_id === 0 && variant_id === 0) {
					wording = ERR_VALIDATION_WORDING.STOCK_INSUFFICIENT_PRODUCT.replace(
						"{available}",
						`${expected_value}`
					);
				} else {
					wording = ERR_VALIDATION_WORDING.STOCK_INSUFFICIENT_VARIANT.replace(
						"{available}",
						`${expected_value}`
					);
				}
			}
		} else if (type === ERR_VALIDATION_TYPE.INVALID_PRICE) {
			if (expected_value > requested_value) {
				if (variant_detail_id === 0 && variant_id === 0) {
					wording = ERR_VALIDATION_WORDING.PRICE_INCREASE;
				} else {
					wording = ERR_VALIDATION_WORDING.PRICE_VARIANT_INCREASE;
				}
			} else {
				if (variant_detail_id === 0 && variant_id === 0) {
					wording = ERR_VALIDATION_WORDING.PRICE_DECRESE;
				} else {
					wording = ERR_VALIDATION_WORDING.PRICE_VARIANT_DECRESE;
				}
			}
		} else if (type === ERR_VALIDATION_TYPE.MISSING_VARIANT) {
			wording = ERR_VALIDATION_WORDING.OUT_OF_STOCK_VARIANT;
		} else if (type === ERR_VALIDATION_TYPE.MISSING_PRODUCT) {
			wording = ERR_VALIDATION_WORDING.OUT_OF_STOCK_PRODUCT;
		} else if (type === ERR_VALIDATION_TYPE.MISSING_VARIANT_MANDATORY) {
			wording = ERR_VALIDATION_WORDING.OUT_OF_STOCK_VARIANT_MANDATORY;
		}

		return wording;
	};

	useEffect(() => {
		// Prevent robot tracking
		// @ts-ignore
		document
			.querySelector('meta[name="robots"]')
			.setAttribute("content", "noindex");
			
		if (!shop?.id && !merchant?.id) {
			const { shopLink } = params as IPathParams;
			history.push(`/${shopLink}`);
		} else {
			// @ts-ignore
			window.scrollTo(0, 0);
			countTotalAmount();

			// init selected voucher if back from other page
			if (selectedProductVoucher) {
				setCurrentProductVoucher(selectedProductVoucher);
			}
			if (selectedShippingVoucher) {
				setCurrentShippingVoucher(selectedShippingVoucher);
			}
		}
	}, [
		selectedShippingVoucher,
		selectedProductVoucher,
		selectedItems,
		coinToExchange,
		shop.loyaltyData?.valuePerCoin,
		selectedShip,
	]);

	useLayoutEffect(() => {
		return () => {
			clearTimeout(timerDim);
		};
	}, []);

	return {
		handleSubmit,
		selectedItems,
		totalAmount,
		totalItem,
		selectedShip,
		isPostError,
		setIsPostError,
		selectedDate,
		shop,
		discountVoucherAmount,
		reportIssue,
		handleClickedConfirmOrder,
		isShowAgreementFraud,
		setIsShowAgreementFraud,
		buyer,
		isShowBottomSheetPaymentOption,
		setIsShowBottomSheetPaymentOption,
		selectedPaymentMethod,
		setSelectedPaymentMethod,
		searchedCode,
		setSearchedCode,
		fetchPromotionVoucher,
		fetchPromotionVoucherByCode,
		selectedProductVoucher,
		selectedShippingVoucher,
		handleSelectVoucher,
		currentEligibleVouchers,
		currentNotEligibleVouchers,
		voucherErrorMessage,
		serviceFee,
		tax,
		selectedTime: fulfillment?.time,
		vouchers,
		filterVouchers,
		amountItemOnly,
		isSearchingVoucherByCode,
		isShowDimNotification,
		eventType,
		sendOTPtoBuyer,
		coinToExchange,
		setOtpCode,
		handleTextInput,
		buyerCoin,
		fetchCoinBalance,
		checkOTPValid,
		isOtpExp,
		setIsOtpExp,
		setShowOtpBottomSheet,
		showOtpBottomSheet,
		otpCode,
		popupErrorMsg,
		roundedValue,
		handleOpenBill,
		askBuyerForOtp,
		otpLS,
		validationErrors,
		getWordingErrorValidation,
		foodcourtCart,
		merchant,
    isHideTakeaway: SHOP_ID_FOR_EXCLUDE_TAKEAWAY.includes(shop.id)
	};
};

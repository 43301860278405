import {
	URL_CHANGE_PAYMENT_METHOD,
	URL_CHECK_PAYMENT_SN,
	URL_CLOSE_BILL,
	URL_EDIT_CONTACT,
	URL_OPEN_BILL,
	URL_ORDER,
	URL_ORDER_SN,
	URL_ORDER_STATUS,
	URL_SEND_WA_INVOICE,
	URL_TRACKING,
	URL_USER_PREFERENCE,
	URL_VALIDATE_ORDER,
} from "../constants";
import { postData, getData, putData } from ".";
import {
	ILoginInfo,
	IReqCloseBill,
	IReqEditContact,
	IReqOpenBill,
	IReqPayment,
	IReqValidateOrder,
} from "../interfaces";

export const postOrder = async (payload: any) => {
	try {
		const url = URL_ORDER;
		const response = await postData(url, payload);

		return response;
	} catch {
		console.log("error when post order info.");
	}
};

type TChangeOrder = {
	shop_id?: number;
	order_sn: string;
	new_status: string;
};

// @ts-ignore
const token = localStorage.getItem("token");

export const putOrderStatus = async (payload: TChangeOrder) => {
	try {
		const response = await putData(URL_ORDER_STATUS, payload, token);
		return response.data;
	} catch {
		console.log("error when change order status.");
	}
};

export const getOrder = async (orderSN: string) => {
	try {
		const url = URL_ORDER_SN.replace(":orderSN", orderSN);
		const response = await getData(url);

		return response;
	} catch {
		console.log("error when fetching order info.");
		return { code: 404 };
	}
};

export const postCheckReservationPayment = async (orderSN: string) => {

	try {
		const response = await postData(URL_CHECK_PAYMENT_SN, { payment_sn: orderSN });

		return response;
	} catch {
		console.log("error when fetching order info.");
		return { code: 404 };
	}
};

export const postUserPreference = async (
	token: string,
	payload: ILoginInfo
) => {
	try {
		const url = URL_USER_PREFERENCE;
		const response = await postData(url, payload, token);

		return response.data;
	} catch {
		console.log("error when post user preference.");
	}
};

export const getUserPreference = async (token: string) => {
	try {
		const url = URL_USER_PREFERENCE;
		const response = await getData(url, "", token);

		if (response.data.name) {
			return response.data;
		} else {
			return {
				name: "",
				phone: "",
				addresses: [],
			};
		}
	} catch {
		console.log("error when fetching user preference.");
	}
};

export const postWaInvoice = async (orderSN: string) => {
	try {
		const url = URL_SEND_WA_INVOICE.replace(":orderSN", orderSN);
		const response = await postData(url);

		return response.data;
	} catch {
		console.log("error when post order info.");
	}
};

export const putChangePaymentMethod = async (payload: IReqPayment) => {
	try {
		const response = await putData(URL_CHANGE_PAYMENT_METHOD, payload, token);
		return response;
	} catch {
		console.log("error when change order status.");
	}
};

export const getTracking = async (orderSN: string) => {
	try {
		const url = URL_TRACKING.replace(":orderSN", orderSN);
		const response = await getData(url);

		return response.data.order;
	} catch {
		console.log("error when fetching tracking info.");
	}
};

export const postOpenBill = async (payload: IReqOpenBill) => {
	try {
		const url = URL_OPEN_BILL;
		const response = await postData(url, payload);

		return response;
	} catch {
		console.log("error when post order info.");
	}
};

export const postCloseBill = async (payload: IReqCloseBill) => {
	try {
		const url = URL_CLOSE_BILL;
		const response = await postData(url, payload);

		return response;
	} catch {
		console.log("error when post order info.");
	}
};

export const postValidateOrder = async (payload: IReqValidateOrder) => {
	try {
		const url = URL_VALIDATE_ORDER;
		const response = await postData(url, payload);

		return response.data;
	} catch {
		console.log("error when post order info.");
	}
};

export const postEditContact = async (payload: IReqEditContact) => {
	try {
		const url = URL_EDIT_CONTACT;
		const response = await postData(url, payload);

		return response;
	} catch {
		console.log("error when post edit contact.");
	}
};

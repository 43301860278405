interface IProps {
  color: string;
}

const CartSVG = ({ color }: IProps) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ minWidth: "26px", minHeight: "26px", margin: "-5px 16px 0 0" }}>
    <path d="M2.17188 2.97852H4.60938L8.40104 18.9577H20.3177" stroke={color} stroke-width="1.625" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5625 23.834C11.46 23.834 12.1875 23.1064 12.1875 22.209C12.1875 21.3115 11.46 20.584 10.5625 20.584C9.66504 20.584 8.9375 21.3115 8.9375 22.209C8.9375 23.1064 9.66504 23.834 10.5625 23.834Z" fill={color}/>
    <path d="M17.6094 23.834C18.5068 23.834 19.2344 23.1064 19.2344 22.209C19.2344 21.3115 18.5068 20.584 17.6094 20.584C16.7119 20.584 15.9844 21.3115 15.9844 22.209C15.9844 23.1064 16.7119 23.834 17.6094 23.834Z" fill={color}/>
    <path d="M5.6875 6.5H23.6038C24.3658 6.5 24.8897 7.26581 24.6135 7.97599L21.6644 15.5593C21.5025 15.9757 21.1015 16.25 20.6548 16.25H7.85417L5.6875 6.5Z" fill={color}/>
    </svg>
  )
}

export default CartSVG;
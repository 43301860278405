import { E_FONT_WEIGHT } from "./../interfaces/dataTypes";
import styled from "styled-components";
import { E_COLOR } from "../interfaces/dataTypes";

export const Container = styled.div`
	margin: 0 0 150px;
`;

export const FieldLabel = styled.div<{ customMargin?: string }>`
	margin: ${({ customMargin }) => customMargin ? customMargin : "24px 16px 0"};
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 16px;
	line-height: 19px;
`;

export const SelectMapWrapper = styled.div`
	align-self: flex-end;
	margin: 0 16px 18px;
`;

export const TextButton = styled.div`
	display: inline-block;
	float: right;
	padding-left: 7px;
	color: ${E_COLOR.BLUE};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 12px;
	line-height: 18px;
`;

export const InputAddress = styled.textarea`
	box-sizing: border-box;
	width: 92%;
	height: 80px;
	margin: 6px 13px;
	padding: 16px;
	border: 1px solid ${E_COLOR.NEW_GREY};
	border-radius: 8px;
	background-color: ${E_COLOR.NEW_GREY};
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.MEDIUM};
	font-size: 16px;
	line-height: 19px;
	outline-width: 0;
	font-family: "Open Sans";

	::placeholder {
		color: ${E_COLOR.LIGHT_BLACK};
		font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
		font-size: 16px;
		line-height: 25px;
	}
`;

export const AddressSuggestion = styled.div`
	color: ${E_COLOR.GREY_MEDIUM};
	border: 1px solid ${E_COLOR.GREY_BORDER};
	padding: 7px 8px;
`;

export const SectionLabel = styled.div`
	margin: 24px 16px 0;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
`;

export const ImportantLabel = styled.div`
	margin: 10px 16px 38px;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 27px;
`;

export const ImgPin = styled.img`
	width: 11px;
	height: 14px;
`;

export const ShippingOption = styled.div`
	margin: 30px 16px 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const SelectedShippingOption = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 18px;
`;

export const DetailNotes = styled.div<{ isError: boolean }>`
	margin: ${({ isError }) => (isError ? "0 16px 0" : "24px 16px 20px")};
	color: ${({ isError }) => (isError ? E_COLOR.RED : E_COLOR.LIGHT_BLACK)};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 18px;
`;

export const AddressNotes = styled.div`
	margin: 7px 16px 0;
	display: flex;
	align-items: center;
`;

export const Notes = styled.div<{ isHaveNotes: boolean }>`
	margin-left: ${({ isHaveNotes }) => (isHaveNotes ? "7px" : "0")};
	word-break: break-word;
	color: ${({ isHaveNotes }) =>
		isHaveNotes ? E_COLOR.DARK_ORANGE : E_COLOR.DARK_BLUE};
	font-weight: ${({ isHaveNotes }) =>
		isHaveNotes ? E_FONT_WEIGHT.REGULAR : E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

export const AddressSection = styled.div`
	margin: 24px 16px;
`;

export const AddressHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 3px;
`;

export const AddressLabel = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

export const ChooseAddressButton = styled.div`
	color: ${E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 12px;
	line-height: 18px;
`;

export const AddressDetailWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const AddressDetail = styled.div`
	margin-top: 8px;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 19px;
`;

export const AddressSeparator = styled.div`
	margin: 8px 8px 0;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	background-color: ${E_COLOR.GREY_BORDER};
`;

export const FieldInstruction = styled.div<{ isError: boolean }>`
	margin: 8px 16px 0;
	color: ${({ isError }) => (isError ? E_COLOR.RED : E_COLOR.NEW_GREY_TEXT)};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 16px;
`;

export const TotalContainer = styled.div<{ color?: string }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${({color}) => color ? color : E_COLOR.DARK_BLUE}12;
	border-top: 1px solid ${({color}) => color ? color : E_COLOR.BLUE_BORDER};
	border-bottom: 1px solid ${({color}) => color ? color : E_COLOR.BLUE_BORDER};
	padding: 18px;
`;

export const CartIcon = styled.img`
	width: 26px;
	height: 26px;
	margin: -5px 16px 0 0;
`;

export const TotalLabel = styled.div`
	width: 60%;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	text-align: left;
`;

export const TotalPrice = styled.div`
	width: 30%;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	text-align: right;
`;

export const PinPointButton = styled.div`
	display: flex;
	justify-content: space-between;
	min-height: 63px;
	align-items: center;
	padding: 5px 19px 7px 14px;
	margin: 4px 16px 18px;
	border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
	border-radius: 10px;
`;

export const PinIcon = styled.img`
	width: 18px;
	padding-right: 10px;
`;

export const TextPin = styled.div<{ small: boolean }>`
	width: 100%;
	margin-top: 5px;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: ${({ small }) => (small ? "12px" : "14px")};
	line-height: ${({ small }) => (small ? "19px" : "21px")};
`;

export const ArrowIcon = styled.img`
	width: 10px;
	padding-left: 13px;
`;

export const Label = styled.div`
	margin: 0 16px;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
`;

export const Checkbox = styled.input`
  width: 22px;
  height: 22px;
  border-radius: 5px;
  border: none;
  border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
  background: ${E_COLOR.WHITE};

  input:checked {
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    height: 4px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    width: 9px;
  }
`;

export const DropshipperWrapper = styled.div`
	display: flex;
	align-items: center;
	margin: 9.5px 16px 21.5px;
`

export const ThinSeparator = styled.div`
	margin: 0 16px 16px;
	border-top: 1px solid ${E_COLOR.GREY_LINE};
`

export const DropshipperInfo = styled.div`
`

export const SelfShippingConcent = styled.div`
	display: flex;
	background-color: ${E_COLOR.DARK_BLUE}12;
	margin: 0 16px;
	padding: 9px;
	border-radius: 12px;
`

export const SelfShippingConcentIcon = styled.img`
	width: 14px;
	height: 14px;
	padding: 1px 11px 0 2px;
`

export const SelfShippingConcentText = styled.div`
	color: ${E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 10px;
	line-height: 14px;
`
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import QRCode from "react-qr-code";
import Countdown from "react-countdown";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";
import TimerAsset from "../assets/timer.png";
import QRIS1Asset from "../assets/qris1.png";
import QRIS2Asset from "../assets/qris2.png";
import QRIS3Asset from "../assets/qris3.png";
import QRISBackgroundAsset from "../assets/qr-background.png";
import { useQrisHook } from "../hooks/Qris";
import { getMonthName, getTimeHourMinutes, parseIDR } from "../utils";

const Container = styled.div`
	padding-bottom: 20px;
`;

const ExpiredBar = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${E_COLOR.DARK_BLUE}12;
	border: 1px solid ${E_COLOR.DARK_BLUE};
	padding: 14px;
	margin: 28px 16px 27px;
	border-radius: 12px;
`;

const ExpiredTime = styled.div`
	color: ${E_COLOR.RED};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 12px;
	line-height: 16px;
`;

const PaymentInfo = styled.div`
	text-align: center;
`;

const Label = styled.div`
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 12px;
	line-height: 16px;
`;

const TotalAmount = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 22px;
	padding: 5px 0;
`;

const QRTitle = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	margin-bottom: 16px;
`;

const QRWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	padding: 12px;
	width: 244px;
	margin: 24px auto 24px;
	box-shadow: 0px 4px 24px rgba(51, 51, 51, 0.12);
	border-radius: 12px;
	background-color: ${E_COLOR.WHITE};
`;

const DetailWrapper = styled.div`
	display: flex;
	margin: 0 16px 16px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 16px;
	word-break: break-all;
`;

const DetailNumber = styled.div`
	flex: 1;
	padding: 50px 0 0 7px;
`;

const Detail = styled.div`
	flex: 19;
	padding-top: 50px;
	word-break: break-word;
`;

const ExpiredTimeLeft = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ExpiredTimeLeftText = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 12px;
	line-height: 16px;
`;

const ExpiredTimeLeftCountdown = styled.div`
	display: flex;
	justify-content: space-between;
	background-color: ${E_COLOR.DARK_BLUE};
	border-radius: 30px;
	padding: 5px 7px;
`;

const TimerIcon = styled.img`
	width: 16px;
	height: 16px;
`;

const TimerDigit = styled.div`
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 12px;
	line-height: 16px;
	padding-left: 7px;
`;

const TitleInstruction = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
	margin: 40px 16px 16px;
`;

const ImageInstruction = styled.img`
	width: 156px;
	height: 156px;
`;

const DownloadButton = styled.div`
	width: 238px;
	border: 1px solid ${E_COLOR.DARK_BLUE};
	padding: 8px 14px;
	margin: 28px auto 27px;
	border-radius: 6px;
	color: ${E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	text-align: center;
`;

const QrisBackground = styled.img`
	position: relative;
	width: 228px;
	height: 228px;
`;

const QrisWhiteBackground = styled.div`
	width: 204px;
	height: 204px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: ${E_COLOR.WHITE};
	border-radius: 4px;
`;

const Qris = () => {
	const { orderSN, qrisString, amount, expired } = useQrisHook();
	// @ts-ignore
	const decodedQrisString = window.atob(qrisString);

	const renderer = ({ minutes, seconds }) => {
		// Render a countdown
		return (
			<span>
				{minutes < 10 ? "0" : ""}
				{minutes} : {seconds < 10 ? "0" : ""}
				{seconds}
			</span>
		);
	};

	return (
		<Container>
			<ExpiredBar>
				<ExpiredTime>
					<ExpiredTimeLeft>
						<ExpiredTimeLeftText>
							Selesaikan Pembayaran Dalam:
						</ExpiredTimeLeftText>
						<ExpiredTimeLeftCountdown>
							<TimerIcon src={TimerAsset} />
							<TimerDigit>
								00 :{" "}
								<Countdown
									date={Date.now() + parseInt(expired) - new Date().getTime()}
									renderer={renderer}
								/>
							</TimerDigit>
						</ExpiredTimeLeftCountdown>
					</ExpiredTimeLeft>
					{/* {formattedExp} */}
				</ExpiredTime>
			</ExpiredBar>
			<QRTitle>Scan QR ini di platform pembayaran favoritmu</QRTitle>
			<PaymentInfo>
				<Label>Total Bayar</Label>
				<TotalAmount>Rp{parseIDR(amount)}</TotalAmount>
				<Label>#{orderSN}</Label>
			</PaymentInfo>
			<QRWrapper>
				<QrisBackground src={QRISBackgroundAsset} />
				{/* <QrisWhiteBackground> */}
					<QRCode
						size={180}
						style={{
							height: "auto",
							maxWidth: "160px",
							width: "160px",
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
						}}
						value={decodedQrisString}
						viewBox={`0 0 256 256`}
					/>
				{/* </QrisWhiteBackground> */}
			</QRWrapper>
			{/* <DownloadButton>Unduh QR Code</DownloadButton> */}
			<TitleInstruction>Tidak bisa scan? gunakan cara ini :</TitleInstruction>
			<DetailWrapper>
				<ImageInstruction src={QRIS1Asset} />
				<DetailNumber>1.&nbsp;</DetailNumber>
				<Detail>Screenshot code QRIS yang tertera pada halaman ini.</Detail>
			</DetailWrapper>
			<DetailWrapper>
				<ImageInstruction src={QRIS2Asset} />
				<DetailNumber>2.&nbsp;</DetailNumber>
				<Detail>
					Buka aplikasi pembayaran favoritmu (Gopay, BCA, dll). Pilih pembayaran
					lewat QRIS.
				</Detail>
			</DetailWrapper>
			<DetailWrapper>
				<ImageInstruction src={QRIS3Asset} />
				<DetailNumber>3.&nbsp;</DetailNumber>
				<Detail>
					Unggah screenshoot foto kode QR tadi ke aplikasi pilihanmu dan
					konfirmasi pembayaranmu.
				</Detail>
			</DetailWrapper>
		</Container>
	);
};

export default Qris;

interface IProps {
  color: string;
  widthHeight: string;
  margin: string;
}

const DineInSVG = ({ color, widthHeight, margin }: IProps) => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ minWidth: widthHeight, minHeight: widthHeight, margin: margin }}>
    <circle cx="22.5" cy="22.5" r="22.5" fill={color}/>
    <path d="M22.793 28.3281C26.1711 28.3281 28.9156 25.7371 29.0324 22.5C28.9156 19.2629 26.1711 16.6719 22.793 16.6719C19.4152 16.6719 16.6699 19.2629 16.5547 22.5C16.6695 25.7371 19.4148 28.3281 22.793 28.3281Z" fill="white"/>
    <path d="M22.7895 13.9062C18.0434 13.9062 14.1953 17.7539 14.1953 22.5C14.1953 27.2457 18.0437 31.0938 22.7895 31.0938C27.5352 31.0938 31.3832 27.2457 31.3832 22.5C31.3832 17.7539 27.5352 13.9062 22.7895 13.9062ZM22.7891 28.75C19.3371 28.75 16.5387 26.0465 16.5387 22.7105C16.5387 22.6395 16.5484 22.5703 16.5508 22.5C16.5484 22.4297 16.5387 22.3602 16.5387 22.2895C16.5387 18.9535 19.3375 16.25 22.7891 16.25C26.241 16.25 29.0391 18.9535 29.0391 22.2895C29.0391 22.3602 29.0309 22.4297 29.0289 22.5C29.0312 22.5703 29.0391 22.6395 29.0391 22.7105C29.0387 26.0465 26.241 28.75 22.7891 28.75Z" fill="white"/>
    <path d="M11.3599 12.8989C11.4537 12.5313 11.6392 12.8946 11.6392 12.8946C11.6392 12.8946 11.7041 16.6993 12.1259 16.7407C12.5478 16.7821 12.2228 12.8258 12.722 12.75C13.1861 12.6762 12.9213 16.8036 13.3439 16.7618C13.765 16.7172 13.8361 12.9141 13.8361 12.9141C13.8361 12.9141 14.0236 12.5512 14.1162 12.9161C15.2599 17.4121 14.3345 19.25 14.3345 19.25C14.3345 19.25 14.1783 19.6364 13.8072 19.6383C13.7724 19.6364 13.7986 30.3164 13.7963 31.7524C13.797 32.4504 11.6478 32.4289 11.649 31.734C11.6506 30.2981 11.6924 19.6192 11.6588 19.62C11.2865 19.62 11.1306 19.234 11.1306 19.234C11.1306 19.234 10.2088 17.3973 11.3599 12.8989ZM31.7267 12.7344C32.2103 12.7332 34.2166 14.1797 34.2189 19.5555C34.2213 21.2746 33.2834 21.2743 33.2849 22.3496C33.2865 23.4231 33.2924 31.9614 33.2924 31.9614C33.2924 31.9614 32.2634 32.6672 31.4986 31.9286L31.4845 12.8871C31.4853 12.8875 31.4826 12.734 31.7267 12.7344Z" fill="white"/>
    </svg>
  )
}

export default DineInSVG;
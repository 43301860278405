import { useEffect, useState } from "react";
import { IResOrder } from "./../interfaces/index";
import { useHistory } from "react-router-dom";
import {
	getOrder, postCheckReservationPayment,
} from "./../services/Order";
import {
	URL_PARAM_ORDER_SN,
	URL_PARAM_QRIS_AMOUNT,
	URL_PARAM_QRIS_EXPIRED,
	URL_PARAM_QRIS_OTHER_PAYMENT_TYPE,
	URL_PARAM_QRIS_PAYMENT,
} from "../constants";

export const useQrisHook = () => {
	const [qrisString, setQrisString] = useState("");
	const [orderSN, setOrderSN] = useState("");
	const [amount, setAmount] = useState(0);
	// @ts-ignore
	const url = new URL(window.location.href);
	const expired = url.searchParams.get(URL_PARAM_QRIS_EXPIRED);
	const history = useHistory();
	let timer: any;

	const fetchOrder = async () => {
		// Find url params for source qr code
		// @ts-ignore
		const url = new URL(window.location.href);
		const os = url.searchParams.get(URL_PARAM_ORDER_SN);
		const ex = url.searchParams.get(URL_PARAM_QRIS_EXPIRED);
    const payment = url.searchParams.get(URL_PARAM_QRIS_OTHER_PAYMENT_TYPE);

		if (os && ex) {
      if (payment === 'reservation') {
			  const response: { data: any } = await postCheckReservationPayment(os);
        const isExp = new Date().getTime() > new Date(parseInt(ex)).getTime();
        
        if (
          response?.data?.payment?.status !== "PAID" &&
				  response?.data?.payment?.status !== "EXPIRED" &&
				  !isExp
        ) {
          timer = setTimeout(() => {
            clearTimeout(timer);
            fetchOrder();
          }, 2000);
        } else {
          history.push(`/${response.data?.shop_link}?reserve=true`);
        }

        return;
      }

			const response: { data: IResOrder; code: number } = await getOrder(os);
			const isExp = new Date().getTime() > new Date(parseInt(ex)).getTime();

			if (
				response?.data?.status !== "ready_to_ship" &&
				response?.data?.status !== "completed" &&
				!isExp
			) {
				timer = setTimeout(() => {
					fetchOrder();
				}, 2000);
			} else {
				history.push(`/order_sn/${os}`);
			}

			return response;
		}
	};

	const initial = () => {
		// Find url params for source qr code
		// @ts-ignore
		const qs = url.searchParams.get(URL_PARAM_QRIS_PAYMENT);
		const os = url.searchParams.get(URL_PARAM_ORDER_SN);
		const am = url.searchParams.get(URL_PARAM_QRIS_AMOUNT);

		if (qs) {
			setQrisString(qs);
		}
		if (os) {
			setOrderSN(os);
		}
		if (am) {
			setAmount(parseInt(am));
		}
	};

	useEffect(() => {
		// Prevent robot tracking
		// @ts-ignore
		document
			.querySelector('meta[name="robots"]')
			.setAttribute("content", "noindex");

		initial();
	}, [orderSN]);

	useEffect(() => {
		timer = setTimeout(() => {
			fetchOrder();
		}, 5000);
		return () => clearTimeout(timer);
	}, []);

	return {
		qrisString,
		orderSN,
		amount,
		expired
	};
};

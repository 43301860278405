import React from 'react';
import {
  Container,
  Footer,
  Title,
  Description,
  IconNotFound,
  IconGooglePlay,
} from '../styles/NoMerchant';
import NotFoundAsset from '../assets/notFound.png';
import GooglePlayAsset from '../assets/google.png';

const NoMerchant = () => {
  return (
    <Container>
      <IconNotFound src={NotFoundAsset} />
      <Title>Toko ini tidak ada</Title>
      <Description>
        Jika ini milik anda, unduh Opaper untuk mengklaim bisnis anda
      </Description>
      <IconGooglePlay
        src={GooglePlayAsset}
        onClick={() => {
          /* @ts-ignore */
          window.location.href =
            'https://play.google.com/store/apps/details?id=app.opaper.mobile';
        }}
      />
      <Footer>
        Dibuat oleh <b>PT Opaper International Indonesia.</b>
      </Footer>
    </Container>
  );
};

export default NoMerchant;

import styled, { createGlobalStyle } from "styled-components";
import { ICategory } from "../interfaces";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";

import {
	getUnformattedDate,
	getMonthName,
	getDayName,
	getFormattedDate,
	getDayBackendName,
} from "../utils";
import PrevAsset from "../assets/back.png";
import NextAsset from "../assets/next.png";
import CloseAsset from "../assets/close.png";
import ButtonFull from "./ButtonFull";
import { useEffect, useState } from "react";
import { WORDING } from "../constants/translate";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh !important" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden !important" : "scroll")};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
	z-index: 5;
	display: ${(props) => (props.isShow ? "block" : "none")};
	position: fixed;
	width: 100vh;
	max-width: 600px;
	background-color: rgba(26, 26, 26, 0.5);
	height: 100vh;
	bottom: 0;
	top: 0;
`;

const Content = styled.div`
	width: 100%;
	padding-bottom: 50px;
	max-width: 600px;
	max-height: 70vh;
	position: fixed;
	background-color: ${E_COLOR.WHITE};
	overflow: auto;
	bottom: 0;
	border-radius: 16px 16px 0 0;
`;

const ImgClose = styled.img`
	width: 16px;
	height: 16px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	border-radius: 5px;
	margin: 31px 20px 20px 18px;
`;

const HeaderLabel = styled.div`
	width: 70%;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 27px;
`;

const ChangeDateWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 25px 8px;
`;

const DateNow = styled.div`
	margin: 0 16px;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 25px;
`;

const DateBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 12px;
`;

const Box = styled.div<{ isActive: boolean; isSelected: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: 35px;
	min-height: 72px;
	background-color: ${({ isActive, isSelected }) =>
		isSelected ? E_COLOR.DARK_YELLOW : E_COLOR.WHITE};
	border-radius: 40px;
`;

const DayName = styled.div<{ isActive: boolean; isSelected: boolean }>`
	margin-bottom: 9px;
	color: ${({ isActive, isSelected }) =>
		isSelected
			? E_COLOR.WHITE
			: isActive
			? E_COLOR.GREY_DISABLED
			: E_COLOR.GREY_BORDER};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
`;

const DayDate = styled.div<{ isActive: boolean }>`
	color: ${({ isActive }) =>
		isActive ? E_COLOR.LIGHT_BLACK : E_COLOR.GREY_BORDER};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

const PrevNextIcon = styled.img`
	padding-bottom: 10px;
	align-self: center;
	height: 12px;
	margin-top: 5px;
`;

interface IProps {
	isShow: boolean;
	setShow: (isShow: boolean) => void;
	oneWeekCategories: { categories: ICategory[]; date: string }[];
	selectedDate: Date;
	handleSelectDate: (date: Date) => void;
	handleNextAndPrev: (isNext: boolean) => void;
	operationalDays: string[];
	preOrderDays: number;
	setShowTimePicker: (isShow: boolean) => void;
	isCloseAll: boolean;
	language: "id" | "en";
	hexPrimary: string;
	title?: string;
}

const BottomSheetDates = ({
	handleNextAndPrev,
	handleSelectDate,
	operationalDays,
	preOrderDays,
	selectedDate,
	oneWeekCategories,
	isShow,
	setShow,
	setShowTimePicker,
	isCloseAll,
	language,
	title,
	hexPrimary,
}: IProps) => {
	const [dateTemp, setDateTemp] = useState(selectedDate as Date);
	const today = new Date();
	today.setHours(0, 0, 0, 0);

	// const handleClickedDate = (date: Date) => {
	//   handleSelectDate(date);
	// }

	const handleClose = () => {
		handleSelectDate(dateTemp);
		setShowTimePicker(isCloseAll ? false : true);
		setShow(false);
	};

	useEffect(() => {
		setDateTemp(selectedDate);
	}, [selectedDate]);

	return (
		<Container isShow={isShow}>
			<Content>
				<Header>
					<HeaderLabel>{title ? title : WORDING.general.DELIVERY_DATE[language]}</HeaderLabel>
				</Header>
				<ChangeDateWrapper>
					<PrevNextIcon
						src={PrevAsset}
						onClick={() => handleNextAndPrev(false)}
					/>
					<DateNow>
						{`${getMonthName(selectedDate, language === "en")} ${selectedDate.getFullYear()}`}
					</DateNow>
					<PrevNextIcon
						src={NextAsset}
						onClick={() => handleNextAndPrev(true)}
					/>
				</ChangeDateWrapper>
				<DateBox>
					{oneWeekCategories.length > 0 &&
						oneWeekCategories.map((cat) => {
							const { date, categories } = cat;
							const unformattedDate = getUnformattedDate(date);
							const day = unformattedDate.getDate();
							const dayName = getDayName(unformattedDate, language === "en");
							const dayNameEng = getDayBackendName(unformattedDate);
							let isActive = false;
							if (categories.length > 0) {
								if (operationalDays.includes(dayNameEng)) {
									if (
										unformattedDate.getTime() >=
										today.getTime() + preOrderDays * 86400000
									) {
										isActive = true;
									}
								}
							}
								
							const isSelected = day === dateTemp.getDate();
							return (
								<Box
									onClick={
										isActive ? () => setDateTemp(unformattedDate) : () => {}
									}
									isActive={isActive}
									isSelected={isSelected}
									key={day}
								>
									<DayName isActive={isActive} isSelected={isSelected}>
										{dayName}
									</DayName>
									<DayDate isActive={isActive}>{day}</DayDate>
								</Box>
							);
						})}
				</DateBox>
				<ButtonFull
					hexPrimary={hexPrimary}
					isDisabled={!(operationalDays.includes(getDayBackendName(dateTemp)) && dateTemp.getTime() >=
            today.getTime() + preOrderDays * 86400000)}
					isPrimary={true}
					onClick={handleClose}
					customMargin="30px 16px 12px"
					customWidth="calc(100% - 32px)"
				>
					{WORDING.general.APPLY[language]}
				</ButtonFull>
			</Content>
		</Container>
	);
};

export default BottomSheetDates;

import React, { useEffect, useState } from "react";
import {
	Container,
	Title,
	Background,
	PageDetail,
	StatusCard,
	TransactionStatus,
	GreyText,
	Status,
	PropAndValue,
	Prop,
	Value,
	GreyDashLine,
	GreyLine,
	Dash,
	Dot,
	Product,
	ProductNamePrice,
	ProductNotes,
	Footer,
	FooterText,
	GoogleIcon,
	Total,
	TotalLabel,
	TotalPrice,
	GreyDashLineWrapper,
	MoreButton,
	MoreIcon,
	ButtonWrapper,
	DimNotification,
	WAButton,
	WALogo,
	WALabel,
	AppStoreIcon,
	AlignRight,
	ItemPriceWrapper,
	ItemDiscountWrapper,
	ItemDiscount,
	ContainerErrPage,
	IconErrPage,
	ErrPageTitle,
	ErrPageDescription,
	ErrPageFooter,
	OpaperTitle,
	StoreIcon,
	ErrPageButton,
	ProductShop,
} from "../styles/SummaryOrder";
import { useSummaryOrderHook } from "../hooks/SummaryOrder";
import {
	parseIDR,
	getUnformattedDate,
	getMonthName,
	getExpiredDate,
	getMobileOperatingSystem,
	calcDiscountPercentage,
} from "../utils";
import {
	E_COLOR,
	E_DELIVERY_OPTION,
	E_ORDER_STATUS,
	E_ORDER_STATUS_WORDING,
	E_SHIPPING_TYPE_WORDING,
	E_USER_AGENT,
} from "../interfaces/dataTypes";
import GoogleAsset from "../assets/google.png";
import AppStoreAsset from "../assets/appstore.jpeg";
import PitikCryAsset from "../assets/pitikCry.png";
import MoreAsset from "../assets/more.png";
import WAAsset from "../assets/wa.png";
import ButtonFull from "../components/ButtonFull";
import BottomSheetSummaryOption from "../components/BottomSheetSummaryOption";
import BottomSheetPaymentOption from "../components/BottomSheetPaymentOption";
import PopupErrorSuccess from "../components/PopupErrorSuccess";
import BottomSheetPaymentInstruction from "../components/BottomSheetPaymentInstruction";
import { WORDING } from '../constants/translate';

const SummaryOrder = () => {
	const {
		data,
		orderSN,
		totalQty,
		sendWaMessageToMerchant,
		sendWaInvoiceToBuyer,
		cancelOrder,
		selectedPaymentMethod,
		setSelectedPaymentMethod,
		handleSubmitPaymentMethod,
		isBottomSheetPaymentOptionOpen,
		setIsBottomSheetPaymentOptionOpen,
		isPopupSuccessOpen,
		handleClosePopupSuccessChangeToCashMethod,
		isChangePaymentSuccess,
		openXenditPage,
		isShowDimNotification,
		openTrackingPage,
		statusPage,
		isPopupCommonSelected,
		productsPerShop,
    language
	} = useSummaryOrderHook();
	const {
		name,
		payment_information,
		phone_number,
		products,
		recipient_address,
		recipient_name,
		shipping_date,
		shipping_price,
		shipping_type,
		subtotal,
		status,
		shop_name,
		shop_phone_number,
		transaction_time,
		shop_address,
		recipient_address_note,
		buyer_phone_number,
		payment_method,
		shop_eligible_payment_method,
		order_shipping,
		promotion_payment_type,
		dine_in_service_fee_amount,
		dine_in_tax,
		dine_in_tax_amount,
		is_buyer_pay_platform,
		platform_fee,
		promotions,
		payment_url,
		payment,
		coin_used,
		coin_value,
		rounding_adj,
		is_foodcourt,
    additional_payments,
	} = data;
	const shippingDate = getUnformattedDate(shipping_date);
	const shippingInfo = shipping_type.split("|");
	const [isBottomSheetSummaryOptionOpen, setIsBottomSheetSummaryOptionOpen] =
		useState(false);
	const [
		isShowBottomSheetPaymentInstruction,
		setIsShowBottomSheetPaymentInstruction,
	] = useState(payment_method === "cash" && status === "created");

  const downPayment = additional_payments?.filter(
    (payment) => payment.payment_method === "downpayment"
  )?.[0]?.value;
  
	useEffect(() => {
		setIsShowBottomSheetPaymentInstruction(
			status === "created" &&
				(payment_method === "cash" || payment_method === "manual-edc" || payment_method.startsWith("custom/"))
		);
	}, [data]);
	let wordingButton = "";
	if (status === "created") {
		wordingButton = WORDING.order_summary.CHOOSE_PAYMENT_METHOD[language];
	} else if (
		status === "shipped" &&
		payment_method === "xendit" &&
		shipping_type.includes("|")
	) {
		wordingButton = WORDING.order_summary.TRACK_ORDER[language];
	} else if (status === "cancelled") {
		wordingButton = "Hubungi Penjual";
	} else {
		if (
			payment_method === "cash" ||
			payment?.payment_method === "QRIS" ||
			payment_method === "manual-edc" ||
      payment_method.startsWith("custom/")
		) {
			wordingButton = WORDING.order_summary.SEND_INVOICE_TO_WA[language];
		} else {
			wordingButton = WORDING.order_summary.LOOKUP_INVOICE[language];
		}
	}

	const handleClickedButton = () => {
		if (status === "created") {
			setIsBottomSheetPaymentOptionOpen(true);
		} else if (
			status === "shipped" &&
			payment_method === "xendit" &&
			shipping_type.includes("|")
		) {
			openTrackingPage();
		} else if (status === "cancelled") {
			sendWaMessageToMerchant();
		} else {
			if (
				payment_method === "cash" ||
				payment_method === "manual-edc" ||
				payment.payment_method === "QRIS" ||
        payment_method.startsWith("custom/")
			) {
				sendWaInvoiceToBuyer();
			} else {
				openXenditPage(payment_url);
			}
		}
	};

	const render404 = () => {
		return (
			<ContainerErrPage>
				<IconErrPage src={PitikCryAsset} />
				<ErrPageTitle>{WORDING.errors.NOT_FOUND_TITLE[language]}</ErrPageTitle>
				<ErrPageDescription>
					{WORDING.errors.NOT_FOUND_CONTENT[language]}
				</ErrPageDescription>
				<ErrPageFooter>
					{getMobileOperatingSystem() === E_USER_AGENT.IOS ? (
						<AppStoreIcon src={AppStoreAsset} />
					) : (
						<GoogleIcon src={GoogleAsset} />
					)}
					<OpaperTitle isCenter={false}>
						PT Opaper Internasional Indonesia
					</OpaperTitle>
				</ErrPageFooter>
			</ContainerErrPage>
		);
	};

	const render500 = () => {
		return (
			<ContainerErrPage>
				<IconErrPage src={PitikCryAsset} />
				<ErrPageTitle>{WORDING.errors.SERVER_TITLE[language]}</ErrPageTitle>
				<ErrPageDescription>
					{WORDING.errors.SERVER_CONTENT[language]}
				</ErrPageDescription>
				{/* @ts-ignore */}
				<ErrPageButton onClick={() => window.location.reload()}>
					{WORDING.general.RELOAD[language]}
				</ErrPageButton>
				<ErrPageFooter>
					{getMobileOperatingSystem() === E_USER_AGENT.IOS ? (
						<AppStoreIcon src={AppStoreAsset} />
					) : (
						<GoogleIcon src={GoogleAsset} />
					)}
					<OpaperTitle isCenter={false}>
						PT Opaper Internasional Indonesia
					</OpaperTitle>
				</ErrPageFooter>
			</ContainerErrPage>
		);
	};

	const renderErrConnection = () => {
		return (
			<ContainerErrPage>
				<ErrPageTitle padding="100px 40px 0">{WORDING.errors.CONNECTION_TITLE[language]}</ErrPageTitle>
				<ErrPageDescription>
					{WORDING.errors.CONNECTION_CONTENT[language]}
				</ErrPageDescription>
				{/* @ts-ignore */}
				<ErrPageButton onClick={() => window.location.reload()}>
					{WORDING.general.RELOAD[language]}
				</ErrPageButton>
				<ErrPageFooter>
					<OpaperTitle isCenter={true}>
						PT Opaper Internasional Indonesia
					</OpaperTitle>
				</ErrPageFooter>
			</ContainerErrPage>
		);
	};

	return (
		<React.Fragment>
			{statusPage === 200 ? (
				<React.Fragment>
					<Background>
						<Container>
							<Title>{WORDING.order_summary.THANK_YOU_TITLE[language]}</Title>
							<PageDetail>
                {WORDING.order_summary.PURCHASE_IN_SHOP[language].replace('{shopName}', shop_name)}
              </PageDetail>
							{status !== "shipped" && (
								<StatusCard status={E_ORDER_STATUS[status]}>
									{(payment_method === "cash" ||
										payment_method === "manual-edc" ||
                    payment_method.startsWith("custom/")) &&
									status === "created"
										? WORDING.order_summary[E_ORDER_STATUS_WORDING.waiting]?.[language] ?? '-'
										: WORDING.order_summary[E_ORDER_STATUS_WORDING[status]]?.[language] ?? '-'}
								</StatusCard>
							)}
							<TransactionStatus>
								<div>
									<GreyText>
                    {WORDING.order_summary.ORDER_SERIAL[language].replace('{orderSN}', orderSN)}
                  </GreyText>
									<GreyText>
                    {WORDING.order_summary.ORDER_DATE[language].replace('{transactionTime}', transaction_time)}
                  </GreyText>
								</div>
								<Status status={E_ORDER_STATUS[status]}>
									{status === "created" &&
									(payment_method === "cash" || payment_method === "manual-edc" || payment_method.startsWith("custom/"))
										? WORDING.order_summary.ORDER_STATUS_WAITING[language]
										: WORDING.order_summary[E_ORDER_STATUS[status]]?.[language] ?? ''}
								</Status>
							</TransactionStatus>
							<PropAndValue>
								<Prop>{WORDING.order_summary.BUYER_NAME[language]}</Prop>
								<Value>{name}</Value>
							</PropAndValue>
							<PropAndValue>
								<Prop>{WORDING.order_summary.BUYER_PHONE[language]}</Prop>
								<Value>{phone_number}</Value>
							</PropAndValue>
							<GreyLine />
							{!isPopupCommonSelected && (
								<PropAndValue>
									<Prop>{WORDING.order_summary.SHIPPING_METHOD[language]}</Prop>
									<Value>
										{shipping_type.includes("|")
											? `${shippingInfo[1]} ${shippingInfo[2]}`
											: WORDING.order_summary[E_SHIPPING_TYPE_WORDING[shipping_type]]?.[language] ?? ''}
									</Value>
								</PropAndValue>
							)}
							{!isPopupCommonSelected && (
								<PropAndValue>
									<Prop>
										{shipping_type === E_DELIVERY_OPTION.DINE_IN ||
										shipping_type === E_DELIVERY_OPTION.OPEN_BILL
											? WORDING.order_summary.SHIPPING_ORDER_DATE[language]
											: WORDING.order_summary.SHIPPING_DATE[language]}
									</Prop>
									<Value>{`${shippingDate.getDate()} ${getMonthName(
										shippingDate,
										language === 'en'
									)} ${shippingDate.getFullYear()}`}</Value>
								</PropAndValue>
							)}
							{shipping_type !== E_DELIVERY_OPTION.PICK_UP && (
								<React.Fragment>
									<PropAndValue>
										<Prop>{WORDING.order_summary.RECIPIENT_NAME[language]}</Prop>
										<Value>{recipient_name || name}</Value>
									</PropAndValue>
									<PropAndValue>
										<Prop>{WORDING.order_summary.RECIPIENT_PHONE[language]}</Prop>
										<Value>{buyer_phone_number}</Value>
									</PropAndValue>
								</React.Fragment>
							)}
							{shipping_type !== E_DELIVERY_OPTION.DINE_IN && (
								<React.Fragment>
									<PropAndValue>
										<Prop>
											{shipping_type === E_DELIVERY_OPTION.PICK_UP
												? WORDING.order_summary.PICK_UP_ADDRESS[language]
												: WORDING.order_summary.RECIPIENT_ADDRESS[language]}
										</Prop>
										{shipping_type === E_DELIVERY_OPTION.PICK_UP ? (
											<Value>{shop_address}</Value>
										) : (
											<Value>
												{recipient_address === "" ? "-" : recipient_address}
											</Value>
										)}
									</PropAndValue>
									<PropAndValue>
										<Prop>{WORDING.order_summary.RECIPIENT_ADDRESS_NOTES[language]}</Prop>
										<Value>{recipient_address_note || "-"}</Value>
									</PropAndValue>
								</React.Fragment>
							)}

							<GreyDashLineWrapper>
								<GreyDashLine>
									<Dot />
									<Dash />
									<Dot />
								</GreyDashLine>
							</GreyDashLineWrapper>
							<Title marginTop="80px">{WORDING.order_summary.ORDER_DETAIL[language]}</Title>
							{is_foodcourt
								? productsPerShop.map((productPerShop, index) => {
										return <React.Fragment>
											<ProductShop>
												{productsPerShop[index][0].shop_name}
											</ProductShop>
											<React.Fragment>
												{productPerShop.map(
													(
														{
															name,
															description,
															price,
															discount_price,
															quantity,
															variants,
														},
														index
													) => (
														<React.Fragment>
															<Product
																key={name}
																isVariant={false}
																isSolidLine={
																	index === productPerShop.length - 1
																}
															>
																<ProductNamePrice>
																	{quantity}x {name}
																</ProductNamePrice>
																<AlignRight>
																	{discount_price ? (
																		<ItemPriceWrapper>
																			<ProductNamePrice
																				isAlignRight={true}
																				isHaveNotes={true}
																			>
																				Rp{" "}
																				{parseIDR(
																					parseInt(discount_price) * quantity
																				)}
																			</ProductNamePrice>
																			<ItemDiscountWrapper>
																				<ItemDiscount isPercentage={true}>
																					-
																					{calcDiscountPercentage(
																						parseInt(price),
																						parseInt(discount_price)
																					)}
																					%
																				</ItemDiscount>
																				<ItemDiscount isPercentage={false}>
																					{parseIDR(parseInt(price) * quantity)}
																				</ItemDiscount>
																			</ItemDiscountWrapper>
																		</ItemPriceWrapper>
																	) : (
																		<ProductNamePrice
																			isAlignRight={true}
																			isHaveNotes={true}
																		>
																			Rp {parseIDR(parseInt(price) * quantity)}
																		</ProductNamePrice>
																	)}
																	<ProductNotes>{description}</ProductNotes>
																</AlignRight>
															</Product>
															{variants.map(({ name, price }) => (
																<Product key={name} isVariant={true}>
																	<ProductNamePrice>+ {name}</ProductNamePrice>
																	<ProductNamePrice isAlignRight={true}>
																		Rp {parseIDR(parseInt(price) * quantity)}
																	</ProductNamePrice>
																</Product>
															))}
														</React.Fragment>
													)
												)}
											</React.Fragment>
										</React.Fragment>
								})
								: products.map(
										({
											name,
											description,
											price,
											discount_price,
											quantity,
											variants,
										}) => (
											<React.Fragment>
												<Product key={name} isVariant={false}>
													<ProductNamePrice>
														{quantity}x {name}
													</ProductNamePrice>
													<AlignRight>
														{discount_price ? (
															<ItemPriceWrapper>
																<ProductNamePrice
																	isAlignRight={true}
																	isHaveNotes={true}
																>
																	Rp{" "}
																	{parseIDR(
																		parseInt(discount_price) * quantity
																	)}
																</ProductNamePrice>
																<ItemDiscountWrapper>
																	<ItemDiscount isPercentage={true}>
																		-
																		{calcDiscountPercentage(
																			parseInt(price),
																			parseInt(discount_price)
																		)}
																		%
																	</ItemDiscount>
																	<ItemDiscount isPercentage={false}>
																		{parseIDR(parseInt(price) * quantity)}
																	</ItemDiscount>
																</ItemDiscountWrapper>
															</ItemPriceWrapper>
														) : (
															<ProductNamePrice
																isAlignRight={true}
																isHaveNotes={true}
															>
																Rp {parseIDR(parseInt(price) * quantity)}
															</ProductNamePrice>
														)}
														<ProductNotes>{description}</ProductNotes>
													</AlignRight>
												</Product>
												{variants.map(({ id, name, price }) => {
                          if (id === 0 && name.toUpperCase() === 'TAKEAWAY') {
                            return (
                              <Product key={id} isVariant={true}>
                                <ProductNamePrice>
                                  <b>{WORDING.general.PRODUCT_TAKE_AWAY[language]}</b>
                                </ProductNamePrice>
                                <ProductNamePrice isAlignRight={true}>
                                  Rp {parseIDR(parseInt(price) * quantity)}
                                </ProductNamePrice>
                              </Product>
                            );
                          }

                          return (
                            <Product key={id} isVariant={true}>
                              <ProductNamePrice>+ {name}</ProductNamePrice>
                              <ProductNamePrice isAlignRight={true}>
                                Rp {parseIDR(parseInt(price) * quantity)}
                              </ProductNamePrice>
                            </Product>
												  );
                        })}
											</React.Fragment>
										)
								  )}

							{shipping_type !== E_DELIVERY_OPTION.DINE_IN &&
								shipping_type !== "pickup" &&
								shipping_type !== E_DELIVERY_OPTION.OPEN_BILL && (
									<Product isVariant={false}>
										<ProductNamePrice>{WORDING.order_summary.SHIPPING_AMOUNT[language]}</ProductNamePrice>
										<ProductNamePrice isAlignRight={true}>
											Rp{" "}
											{parseIDR(
												order_shipping?.with_insurance
													? parseInt(shipping_price) -
															order_shipping?.insurance_rate
													: parseInt(shipping_price)
											)}
										</ProductNamePrice>
										<ProductNotes></ProductNotes>
									</Product>
								)}
							{order_shipping?.with_insurance && (
								<Product isVariant={false}>
									<ProductNamePrice>{WORDING.general.INSURANCE[language]}</ProductNamePrice>
									<ProductNamePrice isAlignRight={true}>
										Rp {parseIDR(order_shipping?.insurance_rate)}
									</ProductNamePrice>
									<ProductNotes></ProductNotes>
								</Product>
							)}
							{promotions?.length > 0 && (
								<Product isVariant={false}>
									<ProductNamePrice>
										{WORDING.review_page.DISCOUNT_CODE[language]}{" "}
										<TotalLabel inline={true}>
											{promotions.length === 2
												? `${promotions[0].code}, ${promotions[1].code}`
												: promotions[0].code}
										</TotalLabel>
									</ProductNamePrice>
									<ProductNamePrice isAlignRight={true}>
										- Rp{" "}
										{parseIDR(
											promotions.length === 2
												? parseInt(promotions[0].value) +
														parseInt(promotions[1].value)
												: parseInt(promotions[0].value)
										)}
									</ProductNamePrice>
									<ProductNotes></ProductNotes>
								</Product>
							)}
							{coin_used > 0 && (
								<Product isVariant={false}>
									<ProductNamePrice>
                    {WORDING.order_summary.COIN_USAGE[language].replace('{num}', coin_used)}
									</ProductNamePrice>
									<ProductNamePrice isAlignRight={true}>
										- Rp {parseIDR(parseInt(coin_value))}
									</ProductNamePrice>
									<ProductNotes></ProductNotes>
								</Product>
							)}
							{(shipping_type === E_DELIVERY_OPTION.DINE_IN ||
								shipping_type === E_DELIVERY_OPTION.OPEN_BILL) &&
								dine_in_service_fee_amount !== "" && (
									<Product isVariant={false}>
										<ProductNamePrice>{WORDING.general.SERVICE_FEE[language]}</ProductNamePrice>
										<ProductNamePrice isAlignRight={true}>
											Rp{" "}
											{parseIDR(
												Math.round(parseFloat(dine_in_service_fee_amount))
											)}
										</ProductNamePrice>
										<ProductNotes></ProductNotes>
									</Product>
								)}
							{dine_in_tax_amount !== "" && (
								<Product isVariant={false}>
									<ProductNamePrice>{WORDING.general.TAX[language]} {dine_in_tax}%</ProductNamePrice>
									<ProductNamePrice isAlignRight={true}>
										Rp {parseIDR(Math.round(parseFloat(dine_in_tax_amount)))}
									</ProductNamePrice>
									<ProductNotes></ProductNotes>
								</Product>
							)}
							{is_buyer_pay_platform && (
								<Product isVariant={false}>
									<ProductNamePrice>{WORDING.general.PLATFORM_FEE[language]}</ProductNamePrice>
									<ProductNamePrice isAlignRight={true}>
										Rp {parseIDR(parseFloat(platform_fee))}
									</ProductNamePrice>
									<ProductNotes></ProductNotes>
								</Product>
							)}
							{rounding_adj !== 0 && (
								<Product isVariant={false}>
									<ProductNamePrice>{WORDING.general.ROUNDING[language]}</ProductNamePrice>
									<ProductNamePrice isAlignRight={true}>
										{rounding_adj < 0 ? "-" : ""} Rp{" "}
										{parseIDR(
											rounding_adj < 0 ? rounding_adj * -1 : rounding_adj
										)}
									</ProductNamePrice>
									<ProductNotes></ProductNotes>
								</Product>
							)}
							{downPayment && (
								<Product isVariant={false}>
									<ProductNamePrice>{WORDING.open_bill.DOWN_PAYMENT_REIMBURSE[language]}</ProductNamePrice>
									<ProductNamePrice isAlignRight={true}>
										-Rp{" "}
										{parseIDR(downPayment < 0 ? downPayment * -1 : downPayment)}
									</ProductNamePrice>
									<ProductNotes></ProductNotes>
								</Product>
							)}
							<Total>
								<TotalLabel>{WORDING.order_summary.GRAND_TOTAL[language]}</TotalLabel>
								<TotalPrice>
									Rp{" "}
									{parseInt(subtotal) <= 0 ? "0" : parseIDR(parseInt(subtotal))}
								</TotalPrice>
							</Total>
							<ButtonWrapper>
								<ButtonFull
									hexPrimary={E_COLOR.DARK_BLUE}
									onClick={handleClickedButton}
									isDisabled={false}
									isPrimary
									customMargin="0 8px 0 20px"
								>
									{wordingButton}
								</ButtonFull>
								{!(
									(payment_method === "cash" ||
										payment_method === "manual-edc" ||
                    payment_method.startsWith("custom/") ||
										payment?.payment_method === "QRIS" ||
										payment_method === "xendit") &&
									(status === "ready_to_ship" ||
										status === "shipped" ||
										status === "completed" ||
										status === "created" ||
										status === "cancelled")
								) && (
									<MoreButton
										onClick={() => setIsBottomSheetSummaryOptionOpen(true)}
									>
										<MoreIcon src={MoreAsset} />
									</MoreButton>
								)}
							</ButtonWrapper>
						</Container>
						<Footer>
							{getMobileOperatingSystem() === E_USER_AGENT.IOS ? (
								<AppStoreIcon src={AppStoreAsset} />
							) : (
								<GoogleIcon src={GoogleAsset} />
							)}
							<FooterText>PT Opaper International Indonesia</FooterText>
						</Footer>
						<WAButton onClick={sendWaMessageToMerchant}>
							{/* <WALabel>Chat Penjual</WALabel> */}
							<WALogo src={WAAsset} />
						</WAButton>
						<BottomSheetSummaryOption
							isShow={isBottomSheetSummaryOptionOpen}
							setShow={setIsBottomSheetSummaryOptionOpen}
							connectWithMerchant={sendWaMessageToMerchant}
							askForInvoice={sendWaInvoiceToBuyer}
							cancelOrder={cancelOrder}
							selectedShip={shipping_type}
							changePaymentMethod={() =>
								setIsBottomSheetPaymentOptionOpen(true)
							}
							status={status}
              language={language}
						/>
						<BottomSheetPaymentOption
							isShow={isBottomSheetPaymentOptionOpen}
							setShow={setIsBottomSheetPaymentOptionOpen}
							selectedPaymentMethod={selectedPaymentMethod}
							setSelectedPaymentMethod={setSelectedPaymentMethod}
							handleSubmitPaymentMethod={handleSubmitPaymentMethod}
							isCashEligible={
								(shipping_type === "pickup" ||
									shipping_type === E_DELIVERY_OPTION.DINE_IN ||
									shipping_type === E_DELIVERY_OPTION.OPEN_BILL) &&
								(promotion_payment_type?.includes("cash") ||
								promotion_payment_type?.length === 0
									? true
									: false)
							}
							isXenditEligible={
								promotion_payment_type?.includes("xendit") ||
								promotion_payment_type?.length === 0
									? true
									: false
							}
							isEDCEligible={
								(shipping_type === "pickup" ||
									shipping_type === E_DELIVERY_OPTION.DINE_IN ||
									shipping_type === E_DELIVERY_OPTION.OPEN_BILL) &&
								(promotion_payment_type?.includes("manual-edc") ||
								promotion_payment_type?.length === 0
									? true
									: false)
							}
              getEligibilityForCustomPayment={(payment) => (
                (shipping_type === E_DELIVERY_OPTION.SELF_SHIPPING ||
                  shipping_type === E_DELIVERY_OPTION.PICK_UP ||
                  shipping_type === E_DELIVERY_OPTION.DINE_IN ||
									shipping_type === E_DELIVERY_OPTION.OPEN_BILL) &&
                (promotion_payment_type?.includes(payment) ||
								promotion_payment_type?.length === 0
                  ? true
                  : false)
              )}
							eligiblePaymentMethods={shop_eligible_payment_method}
							customMargin="0 -16px"
							language={language}
						/>
						<BottomSheetPaymentInstruction
							isShow={isShowBottomSheetPaymentInstruction}
							setShow={setIsShowBottomSheetPaymentInstruction}
              isCustomPayment={payment_method.startsWith("custom/")}
              isEDC={payment_method === "manual-edc"}
							isCash={payment_method === "cash"}
							language={language}
						/>
					</Background>
					<PopupErrorSuccess
						isOpen={isPopupSuccessOpen}
						handleClose={handleClosePopupSuccessChangeToCashMethod}
						isSuccess={isChangePaymentSuccess}
						isCancelButtonOnly={!isChangePaymentSuccess}
						title={
							isChangePaymentSuccess
								? selectedPaymentMethod === "cash"
									? WORDING.order_summary.PAYMENT_CASH_TITLE[language]
									: selectedPaymentMethod === "manual-edc"
                      ? WORDING.order_summary.PAYMENT_EDC_TITLE[language]
                      : WORDING.order_summary.PAYMENT_CUSTOM_TITLE[language].replace('{custom}', selectedPaymentMethod?.replace(/^custom\//, ''))
								: WORDING.order_summary.ERR_ERROR_OCCURED[language]
						}
						desc={
							isChangePaymentSuccess
								? WORDING.order_summary.PAYMENT_CHANGED_CONTENT[language]
								: WORDING.order_summary.PAYMENT_MERCHANT_CONTENT[language]
						}
						language={language}
					/>
					<DimNotification isShow={isShowDimNotification}>
						{WORDING.order_summary.TOAST_SUCCESS_SEND_INVOICE_WA[language]}
					</DimNotification>
				</React.Fragment>
			) : statusPage === 404 ? (
				render404()
			) : statusPage === 500 ? (
				render500()
			) : statusPage === -1 ? (
				renderErrConnection()
			) : null}
		</React.Fragment>
	);
};

export default SummaryOrder;

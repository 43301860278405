interface IProps {
  color: string;
  margin: string;
}

const DeliverySVG = ({ color, margin }: IProps) => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ minWidth: "45px", minHeight: "45px", margin: margin }}>
    <circle cx="22.5" cy="22.5" r="22.5" fill={color}/>
    <path d="M27.0859 12.5C26.5443 12.5 26.0858 12.85 25.9108 13.3333H23.3359C22.8776 13.325 22.4945 13.6919 22.4945 14.1585C22.4861 14.6169 22.8532 14.9917 23.3115 15H26.194C26.6857 16.1583 27.5026 18.325 27.5026 20C27.5026 23.3333 25.0026 25 23.3359 25H21.6693C20.7526 25 20.0026 24.25 20.0026 23.3333V21.6667H13.3359C13.3359 21.6667 10.8359 22.5 10.8359 25C11.1359 25.1 11.9281 25.4333 12.8281 25.8333C12.1281 26.45 11.6693 27.3333 11.6693 28.3333C11.6693 30.1667 13.1693 31.6667 15.0026 31.6667C16.8359 31.6667 18.3359 30.1667 18.3359 28.3333C18.3359 28.3167 18.3278 28.3077 18.3278 28.291C18.4528 28.316 18.5771 28.3333 18.7021 28.3333H24.8024C25.4607 28.3333 26.1033 28.066 26.57 27.5993L26.6693 27.5H26.7865C26.7198 27.7667 26.6693 28.0417 26.6693 28.3333C26.6693 30.1667 28.1693 31.6667 30.0026 31.6667C31.8359 31.6667 33.3359 30.1667 33.3359 28.3333C33.3359 27.9333 33.2528 27.5507 33.1195 27.1924L34.1693 26.6667C34.1693 26.6667 33.0113 24.2913 30.6113 23.5579L30.8359 23.3333C30.7859 20.2417 29.0275 16.6083 28.0609 15H29.1693V12.5H27.0859ZM14.0033 13.2503C13.1783 13.2503 12.5026 13.9243 12.5026 14.7493V18.4993C12.5026 19.3243 13.1783 20 14.0033 20H18.502C19.327 20 20.0026 19.3243 20.0026 18.4993V14.7493C20.0026 13.9243 19.327 13.2503 18.502 13.2503H16.6693V17.5C16.6693 17.9583 16.2943 18.3333 15.8359 18.3333C15.3776 18.3333 15.0026 17.9583 15.0026 17.5V13.2503H14.0033ZM14.7438 26.6911C15.4188 26.9994 15.8603 27.1993 16.4186 27.4577C16.577 27.7077 16.6693 28.0083 16.6693 28.3333C16.6693 29.2667 15.9359 30 15.0026 30C14.0693 30 13.3359 29.2667 13.3359 28.3333C13.3359 27.4917 13.9438 26.8161 14.7438 26.6911ZM28.5605 27.5H31.4447C31.5863 27.7417 31.6693 28.025 31.6693 28.3333C31.6693 29.2667 30.9359 30 30.0026 30C29.0693 30 28.3359 29.2667 28.3359 28.3333C28.3359 28.025 28.4189 27.7417 28.5605 27.5Z" fill="white"/>
    </svg>
  )
}

export default DeliverySVG;
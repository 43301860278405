import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { E_COLOR, E_DELIVERY_OPTION, E_FONT_WEIGHT } from '../interfaces/dataTypes';
import { ICategory } from '../interfaces';
import CloseAsset from '../assets/close.png';
import ArrowAsset from '../assets/arrow.png';
import { WORDING } from '../constants/translate';

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? '100vh !important' : '100%')};
    overflow-y: ${({ isShow }) => (isShow ? 'hidden !important' : 'scroll')};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
  z-index: 2;
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  position: fixed;
  width: 100vh;
  max-width: 600px;
  background-color: rgba(26, 26, 26, 0.5);
  height: 100vh;
  margin: -16px;
  bottom: 0;
  top: 0;
`;

const Content = styled.div`
  width: 100%;
  padding-bottom: 50px;
  max-width: 600px;
  position: fixed;
  background-color: ${E_COLOR.WHITE};
  overflow: hidden;
  bottom: 0;
  border-radius: 16px 16px 0 0;
`;

const ImgClose = styled.img`
  float: right;
  width: 16px;
  height: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  border-radius: 5px;
  margin: 31px 20px 20px 18px;
`;

const Option = styled.button`
  all: unset;
  width: 100%;
  cursor: pointer;
  padding: 17px 25px 17px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
  &.active {
    transition: all 0.1s ease-in-out;
    background-color: #1ea3fc8f;
  }
  &:last-child {
    border: none;
`;

const OptionLabel = styled.div`
  width: 80%;
  color: ${E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 16px;
  line-height: 21px;
  &.cancel {
    color: #FE8050;
  }
`;

interface IProps {
  isShow: boolean;
  setShow: (isShow: boolean) => void;
  connectWithMerchant: () => void;
  askForInvoice: () => void;
  cancelOrder: () => void;
  changePaymentMethod: () => void;
  selectedShip: string;
  status: "created" | "ready_to_ship" | "shipped" | "cancelled" | "pending" | "completed";
  language: "id" | "en";
}

const BottomSheetSummaryOption = ({
  isShow,
  setShow,
  askForInvoice,
  connectWithMerchant,
  cancelOrder,
  selectedShip,
  changePaymentMethod,
  status,
  language,
}: IProps) => {
  const [isClicked, setIsClicked] = useState(false)
  const onClickInvoice = () => {
    setIsClicked(true)
    setTimeout(() => {
      setIsClicked(false)
    }, 200)
    setTimeout(() => {
      askForInvoice()
      setShow(false)
    }, 300)
  }
  const onClickConnect = () => {
    connectWithMerchant()
    setShow(false)
  }
  const onClickChangePaymentMethod = () => {
    changePaymentMethod();
    setShow(false);
  }

  return (
    <Container isShow={isShow}>
      <GlobalStyle isShow={isShow} />
      <Content>
        <Header>
          <ImgClose onClick={() => setShow(false)} src={CloseAsset} />
        </Header>
        <Option onClick={onClickInvoice} className={isClicked ? 'active' : 'inactive'}>
          <OptionLabel>{WORDING.order_summary.SEND_INVOICE_TO_WA[language]}</OptionLabel>
        </Option>
        {/* <Option onClick={onClickConnect}>
          <OptionLabel>Hubungi Penjual</OptionLabel>
        </Option> */}
        {(status === "created" && selectedShip !== E_DELIVERY_OPTION.SELF_SHIPPING) && <Option onClick={onClickChangePaymentMethod}>
          <OptionLabel>{WORDING.order_summary.CHANGE_PAYMENT_METHOD[language]}</OptionLabel>
        </Option>}
        {
          // todo uncomment when endpoint is ready
          /* <Option onClick={cancelOrder}>
          <OptionLabel className="cancel">Batalkan pesanan</OptionLabel>
        </Option> */}
      </Content>
    </Container>
  );
};

export default BottomSheetSummaryOption;

import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import {
	E_DELIVERY_OPTION,
	E_OTP_STATE,
	E_VOUCHER_TYPE,
} from "./../interfaces/dataTypes";
import { getShippingInfo } from "./../services/Shipping";
import { useState, useEffect } from "react";
import {
	IContact,
	IShip,
	ISelectedShip,
	IPathParams,
	IAddress,
	IBuyer,
	IContextState,
	ILogin,
	IReqLoyaltyStatus,
	IReqEditContact,
} from "./../interfaces/index";
import { useAppContext } from "./../providers/useAppContext";
import { E_ACTION } from "../interfaces/dataTypes";
import { useParams, useHistory } from "react-router-dom";
import {
	getUserPreference,
	postEditContact,
	postUserPreference,
} from "../services/Order";
import {
	AUTO_FILL_CONTACT,
	REPORT_NO_SHIP_AVAILABLE_WORDING,
	TOKEN_LIFE_TIME,
} from "../constants";
import { getCoinBalance, getLoyaltyStatus } from "../services/Shop";

export const useContactStepHook = () => {
	const [contact, setContact] = useState({ phone: "62" } as IContact);
	const [currentShips, setCurrentShips] = useState([] as IShip[]);
	const [currentShip, setCurrentShip] = useState({} as ISelectedShip);
	const [isMapPickerOpen, setIsMapPickerOpen] = useState(false);
	const [isShowBottomSheetAddress, setIsShowBottomSheetAddress] =
		useState(false);
	const [isPickup, setIsPickup] = useState(null as null | boolean);
	const [phoneOTP, setPhoneOTP] = useState("");
	const [currentAddresses, setCurrentAddresses] = useState([] as IAddress[]);
	const [activeAddressesIndex, setActiveAddressesIndex] = useState(0);
	const [currentFillAddress, setCurrentFillAddress] = useState({} as IAddress);
	const [isAgreeSaveInfo, setIsAgreeSaveInfo] = useState(false);
	const [isBottomSheeOTPOpen, setIsBottomSheetOTPOpen] = useState(false);
	const [inputSearchAddress, setInputSearchAddress] = useState("");
	const [isWithInsurance, setIsWithInsurance] = useState(false);
	const [tableNumber, setTableNumber] = useState("");
	const { dispatch, state } = useAppContext();
	const params = useParams();
	const history = useHistory();
	const { shopLink } = params as IPathParams;
	const {
		shop,
		selectedItems,
		ships,
		buyer,
		selectedShip,
		addresses,
		login,
		isLoading,
		total,
		eventType,
		language,
		merchant,
		tableNumber: tableNumberFromQR,
		orderSN,
	} = state as IContextState;

	// @ts-ignore
	const token = localStorage.getItem("token");
	const isLoggedIn = token ? true : false;

	const handleInputContact = (name: string, value: string, data?: any) => {
		const updatedContact = {
			...contact,
			[name]: value,
		};

		if (name === "phone") {
			if (value.charAt(0) === "0") {
				updatedContact[name] = data.dialCode + value.replace(/^0/, "");
			} else if (!value.startsWith(data.dialCode) && value.length > 2) {
				updatedContact[name] = data.dialCode + value;
			}

			setContact({ ...updatedContact, countryCode: data.countryCode });
		} else if (name === "tableNumber") {
			setTableNumber(value);
		} else {
			setContact(updatedContact);
		}
	};

	const handleInputAddress = (name: string, value: string | number) => {
		const updatedCurrentFillAddress = {
			...currentFillAddress,
			[name]: value,
		};

		setCurrentFillAddress(updatedCurrentFillAddress);
	};

	const handleSelectOtherAddress = (index: number) => {
		setActiveAddressesIndex(index);
		fetchShippingInfo(currentAddresses[index].lat, currentAddresses[index].lng);
	};

	const handleSaveAddress = (lat: number, lng: number, detail: string) => {
		// TODO: Update the array value if you want to add multiple address feature
		const updatedCurrentAddresses = [
			{ ...currentFillAddress, lat, lng, detail },
		];
		setCurrentFillAddress({ ...currentFillAddress, lat, lng, detail });
		setCurrentAddresses(updatedCurrentAddresses);
		dispatch({
			type: E_ACTION.SAVE_ADDRESSES,
			payload: {
				list: updatedCurrentAddresses,
				activeIndex: activeAddressesIndex,
			},
		});

		// TODO: Update the lat lng argument if you want to add multiple address feature
		fetchShippingInfo(lat || buyer.currLat, lng || buyer.currLng);
		// Clean up current fill to empty again
		// setCurrentFillAddress({} as IAddress);
	};

	const handleClickAddNewAddress = () => {
		setIsShowBottomSheetAddress(true);

		// prefilled the name and phone on bottom sheet address if empty
		if (!currentFillAddress.name || !currentFillAddress.phone) {
			setCurrentFillAddress({
				...currentFillAddress,
				name: contact.name,
				phone: contact.phone,
			});
			// handleInputAddress("instruction", "dol")
		}
	};

	const handleChangeSpecificAddress = (index: number) => {
		setCurrentFillAddress(currentAddresses[index]);
		setIsShowBottomSheetAddress(true);
		setActiveAddressesIndex(index);
	};

	const handleSelectShip = (
		name: string,
		price: number,
		rateId: number | string,
		type: string,
		withInsurance: boolean,
		insuranceRate: number
	) => {
		setCurrentShip({
			name,
			price,
			rateId,
			type,
			withInsurance,
			insuranceRate,
			isUserUseInsurance: isWithInsurance,
		});

		dispatch({
			type: E_ACTION.SAVE_SELECTED_SHIP,
			payload: {
				name,
				price,
				rateId,
				type,
				withInsurance,
				insuranceRate,
				isUserUseInsurance: isWithInsurance,
			},
		});
	};

	const setBuyerToContext = () => {
		const { name, phone, countryCode } = contact;
		if (currentShip.name === E_DELIVERY_OPTION.PICK_UP) {
			dispatch({
				type: E_ACTION.SAVE_BUYER,
				payload: {
					country:
						countryCode !== ""
							? countryCode
							: phone.substring(0, 2) === "62"
							? "id"
							: "",
					name,
					phone,
					recipientName: name,
					recipientPhone: phone,
				} as IBuyer,
			});

			// remove shipping voucher from auto apply in items page
			// if (selectedVoucher?.type === E_VOUCHER_TYPE.SHIPPING) {
			// 	dispatch({
			// 		type: E_ACTION.SAVE_SELECTED_VOUCHER,
			// 		payload: null,
			// 	});
			// }
		} else if (currentShip.name === E_DELIVERY_OPTION.DINE_IN) {
			dispatch({
				type: E_ACTION.SAVE_BUYER,
				payload: {
					country:
						countryCode !== ""
							? countryCode
							: phone.substring(0, 2) === "62"
							? "id"
							: "",
					name,
					phone,
					tableNumber,
				} as IBuyer,
			});

			// remove shipping voucher from auto apply in items page
			// if (selectedVoucher?.type === E_VOUCHER_TYPE.SHIPPING) {
			// 	dispatch({
			// 		type: E_ACTION.SAVE_SELECTED_VOUCHER,
			// 		payload: null,
			// 	});
			// }
		} else {
			dispatch({
				type: E_ACTION.SAVE_BUYER,
				payload: {
					address: currentAddresses[activeAddressesIndex]?.detail,
					addressLabel: currentAddresses[activeAddressesIndex]?.label,
					addressNote: currentFillAddress.instruction,
					country:
						countryCode !== ""
							? countryCode
							: phone.substring(0, 2) === "62"
							? "id"
							: "",
					lat: currentAddresses[activeAddressesIndex]?.lat,
					lng: currentAddresses[activeAddressesIndex]?.lng,
					name,
					phone,
					recipientName: currentFillAddress.name,
					recipientPhone: currentFillAddress.phone,
				} as IBuyer,
			});
		}
	};

	const handleSubmit = async () => {
		const { name, phone, countryCode } = contact;

		if (selectedShip.name === E_DELIVERY_OPTION.OPEN_BILL) {
			const payload = {
				name,
				number_of_people: 0,
				order_sn: orderSN,
				phone_country: "id",
				phone_number: `+${phone}`,
				shop_id: shop.id,
				table_no: tableNumber,
			} as IReqEditContact;

			const response = await postEditContact(payload);

			if (response.code === 200) {
				// @ts-ignore
				window.location.href = `${window.location.origin}/open/${orderSN}`;
			}
		} else {
			const isHaveCoin = await fetchLoyaltyStatus();

			if (currentShip.name === E_DELIVERY_OPTION.PICK_UP) {
				dispatch({
					type: E_ACTION.SAVE_BUYER,
					payload: {
						...buyer,
						address: "",
						addressLabel: "",
						addressNote: "",
						country:
							countryCode !== ""
								? countryCode
								: phone.substring(0, 2) === "62"
								? "id"
								: "",
						lat: 0,
						lng: 0,
						name,
						phone,
						recipientName: name,
						recipientPhone: phone,
						tableNumber: "",
						isHaveCoin,
					} as IBuyer,
				});

				// remove shipping voucher from auto apply in items page
				// if (selectedVoucher?.type === E_VOUCHER_TYPE.SHIPPING) {
				// 	dispatch({
				// 		type: E_ACTION.SAVE_SELECTED_VOUCHER,
				// 		payload: null,
				// 	});
				// }
			} else if (currentShip.name === E_DELIVERY_OPTION.DINE_IN) {
				dispatch({
					type: E_ACTION.SAVE_BUYER,
					payload: {
						...buyer,
						address: "",
						addressLabel: "",
						addressNote: "",
						country:
							countryCode !== ""
								? countryCode
								: phone.substring(0, 2) === "62"
								? "id"
								: "",
						lat: 0,
						lng: 0,
						name,
						phone,
						recipientName: "",
						recipientPhone: "",
						tableNumber,
						isHaveCoin,
					} as IBuyer,
				});

				// remove shipping voucher from auto apply in items page
				// if (selectedVoucher?.type === E_VOUCHER_TYPE.SHIPPING) {
				// 	dispatch({
				// 		type: E_ACTION.SAVE_SELECTED_VOUCHER,
				// 		payload: null,
				// 	});
				// }
			} else {
				dispatch({
					type: E_ACTION.SAVE_BUYER,
					payload: {
						...buyer,
						address: currentAddresses[activeAddressesIndex]?.detail,
						addressLabel: currentAddresses[activeAddressesIndex]?.label,
						addressNote: currentFillAddress.instruction,
						country:
							countryCode !== ""
								? countryCode
								: phone.substring(0, 2) === "62"
								? "id"
								: "",
						lat: currentAddresses[activeAddressesIndex]?.lat,
						lng: currentAddresses[activeAddressesIndex]?.lng,
						name,
						phone,
						recipientName: currentFillAddress.name,
						recipientPhone: currentFillAddress.phone,
						tableNumber: "",
						isHaveCoin,
					} as IBuyer,
				});
			}

			dispatch({
				type: E_ACTION.SAVE_SELECTED_SHIP,
				payload: isPickup
					? ({
							name: E_DELIVERY_OPTION.PICK_UP,
							price: 0,
							rateId: 0,
							withInsurance: false,
							insuranceRate: 0,
							type: "",
					  } as ISelectedShip)
					: { ...currentShip, isUserUseInsurance: isWithInsurance },
			});

			// save autofill contact to local storage
			// @ts-ignore
			localStorage.setItem(
				AUTO_FILL_CONTACT,
				JSON.stringify({
					name,
					phone,
					country: countryCode,
					table: tableNumber,
				})
			);

			// if (isAgreeSaveInfo) {
			// 	if (isLoggedIn) {
			// 		const request: ILoginInfo = {
			// 			name: name,
			// 			phone: phone,
			// 			addresses: currentAddresses,
			// 		};
			// 		history.push(`/${shopLink}/review`);
			// 		await postUserPreference(token, request);
			// 	} else {
			// 		setIsBottomSheetOTPOpen(true);
			// 	}
			// } else {
			// 	history.push(`/${shopLink}/review`);
			// }
		}
	};

	const handleSelectAutoCompleteAddress = async (value: string) => {
		const results = await geocodeByAddress(value);
		const latLng = await getLatLng(results[0]);

		setCurrentFillAddress({
			...currentFillAddress,
			detail: value,
			lat: latLng.lat,
			lng: latLng.lng,
		});
		setInputSearchAddress(value);
	};

	const fetchShippingInfo = async (lat: number, lng: number) => {
		const { deliveryOptions } = shop;
		if (deliveryOptions.includes(E_DELIVERY_OPTION.DELIVERY)) {
			const products: { id: number; quantity: number }[] = [];
			if (selectedItems) {
				Object.keys(selectedItems).map((id: string) => {
					if (selectedItems[id].quantity > 0) {
						products.push({
							id: parseInt(id),
							quantity: selectedItems[id].quantity,
						});
					}
				});
			}

			const payload = {
				shop_id: shop.id,
				products,
				destination_coordinate: `${lat},${lng}`,
			};

			dispatch({
				type: E_ACTION.SET_LOADING,
				payload: true,
			});

			const response = await getShippingInfo(payload);
			setCurrentShips(response);

			if (response.length > 0) {
				// auto select shipping by priority (1. instant, 2. same day. 3. other)
				const indexInstantShipping = response.findIndex(
					(shipping) => shipping.type === "instant"
				);
				if (response[indexInstantShipping]?.logistics.length > 0) {
					const { insurance_rate, name, final_rate, rate_id, with_insurance } =
						response[indexInstantShipping].logistics[0];
					setCurrentShip({
						insuranceRate: insurance_rate,
						isUserUseInsurance: false,
						name,
						price: final_rate,
						rateId: rate_id,
						type: "instant",
						withInsurance: with_insurance,
					});
				} else {
					const indexSameDayShipping = response.findIndex(
						(shipping) => shipping.type === "same day"
					);
					if (response[indexSameDayShipping]?.logistics.length > 0) {
						const {
							insurance_rate,
							name,
							final_rate,
							rate_id,
							with_insurance,
						} = response[indexSameDayShipping].logistics[0];
						setCurrentShip({
							insuranceRate: insurance_rate,
							isUserUseInsurance: false,
							name,
							price: final_rate,
							rateId: rate_id,
							type: "same day",
							withInsurance: with_insurance,
						});
					} else {
						let availableIndex = response.findIndex(
							(shipping) => shipping.logistics.length > 0
						);

						if (availableIndex !== -1) {
							const {
								insurance_rate,
								name,
								final_rate,
								rate_id,
								with_insurance,
							} = response[availableIndex].logistics[0];
							setCurrentShip({
								insuranceRate: insurance_rate,
								isUserUseInsurance: false,
								name,
								price: final_rate,
								rateId: rate_id,
								type: response[availableIndex].type,
								withInsurance: with_insurance,
							});
						} else {
							setCurrentShips([]);
						}
					}
				}
			}

			dispatch({
				type: E_ACTION.SAVE_SHIPS,
				payload: response,
			});

			dispatch({
				type: E_ACTION.SET_LOADING,
				payload: false,
			});

			// find if user already choosed a shipping before, and update the selected ship data with the new fetching data
			if (
				currentShip.type !== "self_shipping" &&
				currentShip.rateId &&
				currentShip.rateId !== 0
			) {
				const listShippingSpecificType = response.filter(
					(listShipping: { type: string; logistic: any }) =>
						listShipping.type === currentShip.type
				);

				if (listShippingSpecificType.length > 0) {
					const updatedDataSelectedShip =
						listShippingSpecificType[0]?.logistics?.filter(
							(ship: any) => ship.name === currentShip.name
						);
					if (updatedDataSelectedShip.length > 0) {
						const {
							final_rate,
							insurance_rate,
							name,
							rate_id,
							with_insurance,
						} = updatedDataSelectedShip[0];

						setCurrentShip({
							insuranceRate: insurance_rate,
							isUserUseInsurance: isWithInsurance,
							name,
							price: final_rate,
							rateId: rate_id,
							type: currentShip.type,
							withInsurance: with_insurance,
						});
					}
				} else {
					setCurrentShip({} as ISelectedShip);
					setCurrentShips([]);
				}
			}
		}
	};

	const initiate = () => {
		const { name, phone, country, tableNumber } = buyer;

		// Prevent robot tracking
		// @ts-ignore
		document
			.querySelector('meta[name="robots"]')
			.setAttribute("content", "noindex");

		// IF: Logged in.
		// ELSE: Not logged in.
		if (token) {
			// @ts-ignore
			const tokenAt = localStorage.getItem("receiveTokenAt");
			const timeNow = new Date().getTime();

			if (timeNow > parseInt(tokenAt) + TOKEN_LIFE_TIME) {
				// @ts-ignore
				localStorage.removeItem("token");
				// @ts-ignore
				localStorage.removeItem("receiveTokenAt");
			}

			// Check if we already have list of addresses in Context
			if (login.addresses.length > 0) {
				setCurrentAddresses(login.addresses);
				setContact({
					name: login.name,
					phone: login.phone,
					countryCode: login.country,
				});
			} else {
				fetchLoginInfo(token);
			}
		} else {
			// IF: its first time user went to contact page but already choose order method on item page
			if (selectedShip) {
				if (selectedShip.name === E_DELIVERY_OPTION.PICK_UP) {
					setIsPickup(true);
				} else {
					setIsPickup(false);
				}

				setCurrentShip(selectedShip);

				// fill the table number from scanned qr code
				setTableNumber(tableNumberFromQR);

				// IF: Means that user already fill all the information but just press back from another page
				if (name) {
					setContact({ phone, name, countryCode: country });
					setCurrentShip(selectedShip);
					setCurrentShips(ships);
					setCurrentAddresses(addresses.list);
					setActiveAddressesIndex(addresses.activeIndex);
					setTableNumber(tableNumber);

					if (selectedShip.isUserUseInsurance) {
						setIsWithInsurance(true);
					}
				} else {
					// @ts-ignore
					const autoFillObj = localStorage.getItem(AUTO_FILL_CONTACT);
					if (autoFillObj) {
						const autoFillData = JSON.parse(autoFillObj);
						setContact({
							phone: autoFillData.phone,
							name: autoFillData.name,
							countryCode: autoFillData.country,
						});
					}
				}
			}
		}
	};

	const fetchLoginInfo = async (token: string) => {
		const response = await getUserPreference(token);

		if (response) {
			const { addresses, name, phone }: ILogin = response;
			let updatedCurrentAddresses: IAddress[] = [];

			// Means that user already have saved information in our database
			if (addresses.length > 0) {
				updatedCurrentAddresses = [...currentAddresses, ...addresses];
				setCurrentAddresses(updatedCurrentAddresses);
				dispatch({
					type: E_ACTION.SAVE_ADDRESSES,
					payload: {
						list: updatedCurrentAddresses,
						activeIndex: activeAddressesIndex,
					},
				});
				setActiveAddressesIndex(0);

				// TODO: call setter active index that trigger refetch shipping information

				setContact({
					name: login.name,
					phone: login.phone,
					countryCode: login.country,
				});

				// Prefill the bottom sheet address input
				setCurrentFillAddress({ ...currentFillAddress, name, phone });

				dispatch({
					type: E_ACTION.SAVE_LOGIN,
					payload: response,
				});
			}
		}
	};

	const handleClickChooseOtherAddress = () => {
		history.push(`/${shopLink}/contact/addresses`);
	};

	const sendWaMessageToSupportNoShipAvailable = () => {
		// @ts-ignore
		const waLink = REPORT_NO_SHIP_AVAILABLE_WORDING.replace(
			"{shopName}",
			state.shop.name
		).replace("{address}", currentAddresses[activeAddressesIndex].detail);

		// @ts-ignore
		window.open(waLink, "_blank");
	};

	const fetchLoyaltyStatus = async () => {
		const payload: IReqLoyaltyStatus = {
			phone_number: contact.phone,
			shop_id: shop.id,
		};

		const response = await getLoyaltyStatus(payload);

		return response;
	};

	useEffect(() => {
		if (!state.shop?.id && !state.merchant?.id) {
			if (state.foodcourtCart.total > 0) {
				history.push(`/${state.shop.foodcourtLink}?merchant=${shopLink}`);
			} else {
				history.push(`/${shopLink}`);
			}
		} else {
			// @ts-ignore
			window.scrollTo(0, 0);
			initiate();
		}
	}, [state.selectedItems, JSON.stringify(state.shipOptions)]);

	return {
		contact,
		handleInputContact,
		handleSubmit,
		currentShips,
		handleSelectShip,
		currentShip,
		handleSelectAutoCompleteAddress,
		isMapPickerOpen,
		setIsMapPickerOpen,
		isLoading,
		phoneOTP,
		setPhoneOTP,
		handleInputAddress,
		currentFillAddress,
		handleSaveAddress,
		currentAddresses,
		activeAddressesIndex,
		fetchLoginInfo,
		handleClickChooseOtherAddress,
		setActiveAddressesIndex,
		isBottomSheeOTPOpen,
		setIsBottomSheetOTPOpen,
		inputSearchAddress,
		setInputSearchAddress,
		isWithInsurance,
		setIsWithInsurance,
		sendWaMessageToSupportNoShipAvailable,
		setIsShowBottomSheetAddress,
		isShowBottomSheetAddress,
		handleSelectOtherAddress,
		handleChangeSpecificAddress,
		handleClickAddNewAddress,
		setCurrentFillAddress,
		total,
		selectedShip,
		tableNumber,
		shop,
		history,
		eventType,
		language,
		setBuyerToContext,
		merchant,
		orderSN,
	};
};

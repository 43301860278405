import styled from "styled-components";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";

export const Container = styled.div<{ isNoBottom: boolean }>`
	margin-bottom: ${({ isNoBottom }) => (isNoBottom ? "0" : "160px")};
	overflow: scroll;
`;

export const FixedComponentWrapper = styled.div`
	position: fixed;
	bottom: -1px;
	width: 100%;
	max-width: 600px;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 16px 14px 22px;
	background-color: ${E_COLOR.WHITE};
	box-shadow: 0px -8px 36px rgba(0, 0, 0, 0.08);
	border-radius: 20px 20px 0px 0px;
`;

export const TotalPriceContainer = styled.div`
	width: 60%;
	padding-left: 8px;
`;

export const TotalIconWrapper = styled.div`
	position: relative;
`;

export const IconTotalCart = styled.img`
	position: relative;
	width: 32px;
	height: 32px;
`;

export const IconTotalNum = styled.div<{ color: string }>`
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ color }) => color ? color : E_COLOR.DARK_BLUE};
	color: ${E_COLOR.WHITE};
	font-size: 10px;
	font-weight: 600;
	border-radius: 50%;
	border: 1px solid ${E_COLOR.WHITE};
	width: 16px;
	height: 16px;
`;

export const TextTotal = styled.div`
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 12px;
	line-height: 19px;
	margin-bottom: 7px;
`;

export const TextPrice = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 27px;
`;

export const ButtonOrderDetail = styled.div<{ isClickable: boolean, color: string }>`
	color: ${({ isClickable, color }) => isClickable ? color ? color : E_COLOR.DARK_BLUE :  E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 12px;
	line-height: 18px;
`;

export const TextTotalWrapper = styled.div`
	display: flex;
`;

export const TextTotalLight = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	padding-right: 5px;
`;

export const TextTotalPrice = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 14px;
	line-height: 21px;
`;

export const ShopProfileWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${E_COLOR.WHITE};
	padding: 16px 12px;
	margin: -37px 16px 0;
	box-shadow: 0px 4px 24px rgba(51, 51, 51, 0.13);
	border-radius: 12px;
	position: absolute;
	width: calc(100% - 64px);
`;

export const ShopName = styled.h1<{ isGrey: boolean }>`
	color: ${({ isGrey }) =>
		isGrey ? E_COLOR.GREY_DISABLED : E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 24px;
	line-height: 36px;
	margin: 0;
	font-family: Plus Jakarta Sans;
`;

export const ArrowRightIcon = styled.img`
	padding-left: 16px;
	width: 6px;
	height: 12px;
`;

export const ChatButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${E_COLOR.WHITE};
	padding: 12px 12px 9px;
	border-radius: 12px;
`;

export const ChatButtonText = styled.div`
	color: ${E_COLOR.DARK_YELLOW};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 12px;
	line-height: 19px;
	margin-right: 7px;
`;

export const ChatIcon = styled.img`
	width: 18px;
	height: 18px;
	margin-bottom: 3px;
`;

export const ShopHeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 13px 16px;
`;

export const InfoIcon = styled.img`
	width: 17px;
	height: 17px;
`;

export const DateButton = styled.div<{
	isMultiOutlet: boolean;
	color?: string;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ color }) => (color ? color : E_COLOR.DARK_BLUE)}12;
	border-radius: 12px;
	border: 1px solid ${({ color }) => (color ? color : E_COLOR.DARK_BLUE)};
	margin: ${({ isMultiOutlet }) =>
		isMultiOutlet ? "12px 16px 24px" : "0px 16px 24px"};
	padding: 8px 12px;
`;

export const DateText = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

export const AlarmIcon = styled.img`
	width: 17px;
	margin: 0 12px 2px 0;
`;

export const ErrorMessage = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 12px;
	line-height: 20px;
	text-align: center;
	padding: 35px;
`;

export const ButtonNext = styled.div<{
	isDisabled: boolean;
	color?: string;
}>`
	max-height: 25px;
	padding: 11px 30px;
	background-color: ${({ isDisabled, color }) =>
		isDisabled ? E_COLOR.NEW_LIGHT_GREY : color ? color : E_COLOR.DARK_BLUE};
	border-radius: 10px;
	color: ${({ isDisabled }) =>
		isDisabled ? E_COLOR.GREY_DISABLED : E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 25px;
	border: 1px solid
		${({ isDisabled, color }) =>
			isDisabled ? E_COLOR.LIGHT_GREY : color ? color : E_COLOR.DARK_BLUE};
`;

export const CloseStoreLabel = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 12px;
	line-height: 12px;
	text-align: center;
`;

export const HeaderImage = styled.img`
	width: 100%;
	height: 188px;
	object-fit: cover;
`;

export const ProductViewWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 24px 16px 0;
`;

export const ProductViewLabel = styled.h2`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 27px;
	margin: 0;
`;

export const ProductViewIcon = styled.img`
	width: 20px;
	height: 20px;
`;

export const Footer = styled.a<{ isFoodcourt?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ isFoodcourt }) =>
		isFoodcourt ? E_COLOR.WHITE : E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	padding: 24px 8px;
	text-decoration: none;
`;

export const Logo = styled.img`
	width: 45px;
	height: 19px;
`;

export const WAButton = styled.div`
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: 0;
	right: 0;
	z-index: 5;
	width: 25px;
	height: 25px;
	background-color: ${E_COLOR.DARK_GREEN};
	box-shadow: 0px 4px 24px rgba(51, 51, 51, 0.15);
	border-radius: 50%;
	margin: 0 17px 90px 0;
	padding: 12px;

	@media (min-width: 600px) {
		right: calc((100% - 600px) / 2);
	}
`;

export const WALogo = styled.img`
	width: 20px;
	height: 20px;
`;

export const WALabel = styled.div`
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 12px;
	line-height: 19px;
`;

export const StoreCloseBar = styled.div<{ isSingleText?: boolean }>`
	position: fixed;
	z-index: 4;
	width: calc(100vw - 32px);
	max-width: calc(600px - 42px);
	padding: ${({ isSingleText }) => (isSingleText ? "12px 21px" : "8px 21px")};
	background-color: ${E_COLOR.DARK_BLUE};
	text-align: center;
`;

export const StoreCloseBarHeaderText = styled.div`
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

export const StoreCloseBarText = styled.div`
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 12px;
	line-height: 19px;
`;

export const CarouselContainer = styled.div`
	width: 100%;
	height: 188px;
	position: relative;

	.slick-dots {
		bottom: 0;
		li {
			margin: 0;
			button:before {
				font-size: 10px;
			}
			.slick-active button:before {
				color: white;
			}
		}
	}
	.slick-dots li.slick-active button:before {
		color: white;
	}
	.slick-dots button:hover:before {
		color: white;
	}
`;

export const GreyLayerBanner = styled.div`
	position: absolute;
	width: 100%;
	height: 188px;
	background-color: ${E_COLOR.GREY_LAYER};
	top: 0;
`;

export const ButtonActionWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 16px;
`;

export const SeeOtherOutletButton = styled.div<{ isSingle: boolean }>`
	width: ${({ isSingle }) => (isSingle ? "100%" : "48%")};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px 0;
	background-color: ${E_COLOR.WHITE};
	border-radius: 12px;
	border: 1px solid ${E_COLOR.GREY_DISABLED};
	text-align: center;
`;

export const OutletIcon = styled.img`
	width: 17px;
	padding-right: 10px;
`;

export const OtherOutletLabel = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-size: 14px;
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	line-height: 21px;
`;

export const SearchBarRelative = styled.div`
	position: relative;
`;

export const SearchCategorySticky = styled.div`
	overflow: auto;
	white-space: nowrap;
`;

export const Categories = styled.div`
	display: flex;
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
`;

export const CategoryWrapper = styled.div<{ isActive: boolean }>`
	padding-right: 16px;
	margin: 2px 5px 0;
`;

export const CategoryLabel = styled.div<{ isActive: boolean }>`
	color: ${({ isActive }) =>
		isActive ? E_COLOR.LIGHT_BLACK : E_COLOR.GREY_DISABLED};
	font-size: 14px;
	font-weight: ${({ isActive }) =>
		isActive ? E_FONT_WEIGHT.EXTRA_BOLD : E_FONT_WEIGHT.REGULAR};
	line-height: 21px;
	padding-bottom: 8px;
`;

export const CategoryActiveHighlight = styled.div<{
	isActive: boolean;
	color?: string;
}>`
	display: ${({ isActive }) => (isActive ? "block" : "none")};
	height: 5px;
	border-radius: 10px 10px 0 0;
	background-color: ${({ color }) => (color ? color : E_COLOR.DARK_BLUE)};
`;

export const BackToFoodcourtStickyButton = styled.div`
	display: flex;
	align-items: center;
	padding: 11px 20px 8px;
`;

export const SearchBarRegular = styled.div`
	display: flex;
	padding-top: 12px;
`;

export const BackIcon = styled.img`
	width: 14px;
	height: 14px;
`;

export const BackText = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	padding-left: 14px;
`;

export const SearchBarWrapper = styled.div<{ isSticky: boolean }>`
	width: 100%;
	position: ${({ isSticky }) => (isSticky ? "fixed" : "inherit")};
	background-color: ${E_COLOR.WHITE};
	top: ${({ isSticky }) => (isSticky ? "0" : "inherit")};
	z-index: ${({ isSticky }) => (isSticky ? "3" : "inherit")};
	max-width: 600px;
	white-space: nowrap;
	box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.06);
`;

export const SearchBar = styled.div<{ isSticky: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 28px 16px 12px;
	background-color: ${E_COLOR.NEW_GREY_BACKGROUND};
	border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
	border-radius: 15px;
	width: calc(100vw - 74px);
	max-width: calc(600px - 74px);
`;

export const SearchIcon = styled.img`
	width: 24px;
	padding: 0 20px 12px 28px;
`;

export const SearchInput = styled.input`
	width: 100%;
	outline-width: 0;
	border: none;
	background-color: ${E_COLOR.NEW_GREY_BACKGROUND};
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;

	::placeholder {
		color: ${E_COLOR.GREY_DISABLED};
		font-weight: ${E_FONT_WEIGHT.REGULAR};
		font-size: 14px;
		line-height: 21px;
	}
`;

export const MerchantWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 16px 16px;
	padding: 4px;
	border-radius: 20px;
	border: 1.5px solid ${E_COLOR.NEW_LIGHT_GREY};
	background-color: ${E_COLOR.WHITE};
`;

export const MerchantLogo = styled.img`
	width: 54px;
	height: 54px;
	border-radius: 17px;
	margin-right: 12px;
`;

export const MerchantInitialLogo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${E_COLOR.DARK_BLUE};
	min-width: 54px;
	min-height: 54px;
	border-radius: 17px;
	margin-right: 12px;
`;

export const MerchantInitial = styled.div`
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 32px;
	line-height: 36px;
	font-family: Plus Jakarta Sans;
`;

export const MerchantName = styled.div`
	width: 100%;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
	font-family: Plus Jakarta Sans;
`;

export const ArrowIcon = styled.img`
	width: 7px;
	height: 12px;
	margin-right: 10px;
`;

export const FoodCourtContainer = styled.div<{ background: string }>`
	background-image: url(${({ background }) => background});
	background-size: cover;
	background-position: center;
	min-height: 100vh;
`;

export const FoodCourtLabel = styled.div`
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 24px;
	line-height: 36px;
	padding: 34px 0 24px;
	text-align: center;
	font-family: Plus Jakarta Sans;
`;

export const BackButtonFoodCourt = styled.div`
	position: absolute;
	top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${E_COLOR.BLACK_TRANSPARANT_BACKGROUND};
	padding: 7px 8px 7px 16px;
	border-radius: 8px;
	margin: 24px 16px;
`;

export const ArrowLeftAsset = styled.img`
	width: 7px;
	height: 12px;
	margin: 0 12px 0 0;
`;

export const BackButtonFoodCourtLabel = styled.div`
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

export const DropdownLang = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	margin: 7px 12px 0 0;
`;

export const CurrentLang = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 4px;
	background-color: rgba(38, 38, 38, 0.7);
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 5px;
`;

export const CurrentLangIcon = styled.img`
	width: 28px;
	height: 16px;
	padding-right: 8px;
`;

export const CurrentLangText = styled.div`
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 18px;
	padding: 1px 18px 0 0;
	font-family: Plus Jakarta Sans;
`;

export const ArrowDownIcon = styled.img`
	width: 8px;
	height: 5px;
	padding-right: 8px;
`;

export const OtherLang = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 4px;
	margin-top: 4px;
	background-color: ${E_COLOR.WHITE};
	border: 1px solid rgba(255, 255, 255, 0.2);
	border-radius: 5px;
`;

export const OtherLangText = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 18px;
	padding: 1px 18px 0 0;
	font-family: Plus Jakarta Sans;
`;

export const CompanyLogo = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	margin: 120px 16px 0;
	border-radius: 50%;
	width: 54px;
	height: 54px;
	object-fit: cover;
`;

export const FoodcourtContainer = styled.div``;

export const FoodcourtBanner = styled.img`
	width: 100%;
	height: 188px;
	object-fit: cover;
`;

export const FoodcourtHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 12px 16px 20px;
`;

export const FoodcourtName = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-size: 22px;
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	line-height: 26px;
`;

export const FoodcourtInfoIcon = styled.img`
	width: 24px;
	height: 24px;
`;

export const FoodcourtInstruction = styled.div`
	color: ${E_COLOR.DARK_BLUE};
	font-size: 12px;
	font-weight: ${E_FONT_WEIGHT.BOLD};
	line-height: 16px;
	background-color: ${E_COLOR.LIGHT_BLUE};
	padding: 12px;
	margin: 0 16px 17px;
	border-radius: 8px;
`;

export const FoodcourtMerchantList = styled.div`
	margin: 5px 16px 0;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
	place-items: flex-start;
`;

export const FoodcourtMerchant = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: end;
	align-items: center;
	width: calc(90vw / 3);
	max-width: calc(540px / 3);
	margin-bottom: 20px;
`;

export const FoodcourtMerchantLogo = styled.img<{ isGreyscale: boolean }>`
	position: relative;
	width: 100px;
	height: 100px;
	object-fit: contain;
	filter: ${({ isGreyscale }) => (isGreyscale ? "grayscale(1)" : "none")};
`;

export const FoodcourtMerchantLogoWrapper = styled.div`
	position: relative;
`;

export const FoodcourtMerchantName = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-size: 14px;
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	line-height: 21px;
	margin-top: 10px;
	text-align: center;
`;

export const FoodcourtMerchantClose = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: ${E_COLOR.LIGHT_GREY};
	color: ${E_COLOR.LIGHT_BLACK};
	font-size: 10px;
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	line-height: 15px;
	padding: 7px 8px;
	border-radius: 4px;
`;

export const FoodcourtCartTenant = styled.div`
	color: ${E_COLOR.DARK_BLUE};
	font-size: 12px;
	font-weight: ${E_FONT_WEIGHT.BOLD};
	line-height: 18px;
`;

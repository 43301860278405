/// <reference no-default-lib="true"/>
import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, matchPath } from "react-router-dom";
import { createBrowserHistory } from 'history';
import * as Sentry from "@sentry/react";
import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import registerSW from './serviceWorker';
import { E_PAGE_PATHS } from './interfaces/dataTypes';

const history = createBrowserHistory();
const routes = [
  { path: "/:shopLink/contact/addresses" },
  { path: "/:shopLink/profile" },
  { path: "/:shopLink/reservation" },
  { path: "/qris" },
  { path: "/order_sn/:orderSN/tracking" },
  { path: "/order_sn/:orderSN" },
  { path: "/open/:orderSN" },
  { path: "/:shopLink/noMerchantFound" },
  { path: "/:shopLink/rating/:ratingHash" },
  { path: `/:shopLink/${E_PAGE_PATHS.ITEMS}` },
  { path: `/:shopLink/${E_PAGE_PATHS.CONTACT}` },
  { path: `/:shopLink/${E_PAGE_PATHS.REVIEW}` },
  { path: "/:shopLink" },
];

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, matchPath),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
    <React.StrictMode>
        <Router history={history}>
            <Route component={App} />
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
registerSW();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import styled from "styled-components";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";

import LoaderAsset from "../assets/opaper_loading.gif";
import { WORDING } from "../constants/translate";

const Background = styled.div<{ isLoading: boolean }>`
	position: fixed;
	display: ${({ isLoading }) => (isLoading ? "flex" : "none")};
	align-items: center;
	justify-content: center;
	top: 0;
	height: 100%;
	width: 100vw;
	max-width: 600px;
	background-color: ${E_COLOR.GREY_BACKGROUND};
	z-index: 99;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 200px;
	height: 200px;
	background-color: ${E_COLOR.WHITE};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
`;

const LoaderImage = styled.img`
	margin: 0 auto;
	width: 200px;
	border-radius: 5px;
`;

const LoaderText = styled.div`
	margin-top: 0px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 16px;
	line-height: 17px;
	padding-bottom: 20px;
`;

interface IProps {
	isLoading: boolean;
	language: "id" | "en";
}

const Loader = ({ isLoading, language }: IProps) => {
	return (
		<Background isLoading={isLoading}>
			<Container>
				<LoaderImage src={LoaderAsset} />
				<LoaderText>{WORDING.general.WAIT[language]}</LoaderText>
			</Container>
		</Background>
	);
};

export default Loader;

import React, { useState } from "react";
import styled from "styled-components";
import { E_ACTION, E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";

import coinAsset from "../assets/coin.png";
import { WORDING } from "../constants/translate";
import { parseIDR } from "../utils";
import BottomSheetOTP from "./BottomSheetOTP";
import BottomSheetExchangeCoin from "./BottomSheetExchangeCoin";

const LoyaltyWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${E_COLOR.WHITE};
	border-radius: 12px;
	border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
	padding: 12px;
	margin: 0 16px 20px;
`;

const LoyaltyIcon = styled.img`
	width: 22px;
	height: 22px;
`;

const LoyaltyLabelWrapper = styled.div`
	width: 100%;
`

const LoyaltyLabel = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	padding-left: 20px;
`;

const LoyaltyDescription = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 10px;
	line-height: 14px;
  padding-left: 20px;
`;

const LoyaltyButton = styled.div<{ isDisabled: boolean }>`
	padding: 6px 20px;
	background-color: ${({ isDisabled }) => isDisabled ? E_COLOR.GREY_LIGHT_BORDER : E_COLOR.DARK_YELLOW};
	color: ${({ isDisabled }) => isDisabled ? E_COLOR.GREY_DISABLED : E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 12px;
	line-height: 16px;
	border-radius: 5px;
  text-align: center;
`;

interface IProps {
  coins: number;
  sendOTPTrigger: (method: "sms" | "whatsapp") => void;
  coinToExchange: string;
  handleTextInput: (name: string, value: string) => void;
  setOtpCode: (otpCode: string) => void;
  loyaltyData: {
		maxCoinUsed: number;
		minPurchase: number;
		valuePerCoin: number;
	};
  isHaveCoin: boolean;
  totalAmountItemOnly: number;
  otpCode: string;
  discountVoucherAmount: number;
  isShowExchangeCoinBottomSheet: boolean;
  setIsShowExchangeCoinBottomSheet: (isShow: boolean) => void;
  setShowOTPBottomSheet: (isShowExchangeCoinBottomSheet: "y" | "n" | "exchangeCoin") => void;
  hexColor: string;
  language: "id" | "en";
  checkOtpValid: (otp: string, isFetchCoinBalance: boolean) => Promise<boolean>;
}

const LoyaltyPoint = ({
	coins,
  sendOTPTrigger,
  coinToExchange,
  handleTextInput,
  loyaltyData,
  totalAmountItemOnly,
  setShowOTPBottomSheet,
  otpCode,
  discountVoucherAmount,
  isShowExchangeCoinBottomSheet,
  setIsShowExchangeCoinBottomSheet,
  hexColor,
  language,
  checkOtpValid,
}: IProps) => {

  const handleUseCoin = () => {
    // sms because initital otp, not resend otp
    sendOTPTrigger("whatsapp");
    setShowOTPBottomSheet("exchangeCoin");
  }

  const handleClickExchangeCoin = async () => {
    const isOtpValid = await checkOtpValid(otpCode, true);

    if(isOtpValid) {
      setIsShowExchangeCoinBottomSheet(true);
    } else {
      handleUseCoin();
    }
  }

  const meetMinPurchase = (totalAmountItemOnly - discountVoucherAmount) >= loyaltyData?.minPurchase
  
	return (
		<React.Fragment>
			<LoyaltyWrapper>
				<LoyaltyIcon src={coinAsset} />
				<LoyaltyLabelWrapper>
          <LoyaltyLabel>
            {coinToExchange ? `${parseIDR(parseInt(coinToExchange))} ${WORDING.review_page.COINS_REDEEMED[language]}` : WORDING.review_page.REDEEM_POINT_FOR_DISCOUNT[language]}
          </LoyaltyLabel>
          {!meetMinPurchase && loyaltyData && <LoyaltyDescription>
            {WORDING.review_page.DOESNT_MEET_MIN_TRX[language]} <b>Rp{parseIDR(loyaltyData?.minPurchase)}</b>
          </LoyaltyDescription>}
        </LoyaltyLabelWrapper>
				<LoyaltyButton isDisabled={!meetMinPurchase} onClick={!meetMinPurchase ? () => {} : coinToExchange || otpCode ? handleClickExchangeCoin : handleUseCoin}>
					{coinToExchange ? WORDING.general.CHANGE[language] : WORDING.review_page.REDEEM_COIN[language]}
				</LoyaltyButton>
			</LoyaltyWrapper>
      <BottomSheetExchangeCoin
        coinToExhange={coinToExchange}
        handleTextInput={handleTextInput}
        hexColor={hexColor}
        isShow={isShowExchangeCoinBottomSheet}
        setShow={setIsShowExchangeCoinBottomSheet}
        language={language}
        totalCoin={coins}
        loyaltyData={loyaltyData}
        amountItemOnly={totalAmountItemOnly}
      />
		</React.Fragment>
	);
};

export default LoyaltyPoint;

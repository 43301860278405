import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { E_COLOR, E_FONT_WEIGHT } from '../interfaces/dataTypes';
import { parseIDR } from '../utils';
import CloseAsset from '../assets/close.png';
import defaultAsset from "../assets/defaultItem.png";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { WORDING } from '../constants/translate';

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
body {
  height: ${({ isShow }) => (isShow ? '100vh !important' : '100%')};
  overflow-y: ${({ isShow }) => (isShow ? 'hidden !important' : 'scroll')};
}
`;

const Container = styled.div<{ isShow: boolean }>`
  z-index: 5;
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  position: fixed;
  width: 100vh;
  max-width: 600px;
  background-color: rgba(26, 26, 26, 0.7);
  height: 100vh;
  bottom: 0;
  top: 0;
  overflow: auto;
`;

const Content = styled.div`
  width: 100vw;
  height: 80%;
  margin: 5vh 0 0;
  max-width: 600px;
  padding-bottom: 40px;
  padding-top: 10px;
  border-radius: 16px 16px 0 0;
  overflow-y: auto;
  position: relative;
`;

const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
  background-color: ${E_COLOR.WHITE};
  float: right;
  margin-right: 16px;
  position: absolute;
  top: 0;
  right: 0;
`;

const CloseIcon = styled.img`
  width: 10px;
  height: 10px;
  cursor: pointer;
  z-index: 11;
`;

const Image = styled.img`
  width: calc(100% - 32px) !important;
  height: calc(100vw - 32px) !important;
  max-width: 568px;
  max-height: 568px;
  margin: 17px 16px;
  border-radius: 20px;
  object-fit: cover;
`;

const DetailCard = styled.div`
  background-color: ${E_COLOR.WHITE};
  border-radius: 8px;
  margin: 10px 16px;
  padding: 9px 12px;
`;

const Name = styled.div`
  color: ${E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 8px;
`;

const Price = styled.div`
  color: ${E_COLOR.NEW_BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 8px;
`;

const Description = styled.div`
  color: ${E_COLOR.LIGHT_BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 14px;
  line-height: 21px;
`;
const CarouselContainer = styled.div`
  width: 100%;
  margin: 17px 0;
    .slick-dots {
      bottom: -13px;
      li {
        margin: 0;
        button:before {
          font-size: 10px;
        }
        .slick-active button:before {
          color: white;
        }
      }
    }
    .slick-dots li.slick-active button:before {
      color: white;
    }
    .slick-dots button:hover:before {
      color: white;
    }
`;

const settings = {
  dots: true,
  arrows: false,
  draggable: true,
}

interface IProps {
  isShow: boolean;
  setShow: (isShow: boolean) => void;
  images: string[];
  description: string;
  name: string;
  price: number;
  stock: number;
  language: "id" | "en";
}

const ItemPreview = ({
  price,
  name,
  description,
  images,
  stock,
  isShow,
  setShow,
  language,
}: IProps) => {
  return (
    <>
      {isShow &&
        <Container isShow={isShow}>
          <GlobalStyle isShow={isShow} />
          <Content>
            <Close onClick={() => setShow(false)}>
              <CloseIcon src={CloseAsset} />
            </Close>
            <CarouselContainer>
              {images?.length > 1 ?
                <Slider {...settings}>
                  {images.map((image: string) => <Image src={image} alt="product" />)}
                </Slider>
                : <Image src={images?.length ? images[0] : defaultAsset} />}
            </CarouselContainer>
            <DetailCard>
              <Name>{name}</Name>
              <Price>
                Rp {parseIDR(price)} ({stock} {WORDING.general.LEFT[language]})
              </Price>
              <Description>{description}</Description>
            </DetailCard>
          </Content>
        </Container>
      }
    </>
  );
};

export default ItemPreview;

interface IProps {
  color: string;
}

const BackSVG = ({ color }: IProps) => {
  return (
    <svg width="9" height="18" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 1L1 7L7 13" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default BackSVG;
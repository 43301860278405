import React from "react";
import styled from "styled-components";
import VoucherAsset from "../assets/voucher.png";
import VoucherDisabledAsset from "../assets/voucherDisabled.png";
import { WORDING } from "../constants/translate";
import { IVoucher } from "../interfaces";
import { E_COLOR, E_FONT_WEIGHT, E_VOUCHER_TYPE } from "../interfaces/dataTypes";

const Container = styled.div`
  position: relative;
  margin: 16px 16px 15px;
  width: calc(100% - 32px);
  max-width: 568px;
`;

const VoucherBackground = styled.img`
  width: 100%;
  height: 44px;
`;

const Content = styled.div`
  width: calc(100% - 32px);
  max-width: 568px;
  height: 44px;
  position: absolute;
  z-index: 2;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

const VoucherText = styled.div`
  color: ${E_COLOR.LIGHT_BLACK};
  font-weight: ${E_FONT_WEIGHT.BOLD};
  font-size: 12px;
  line-height: 16px;
  text-align: left;
`;

const VoucherButton = styled.div<{ isList: boolean; isRed: boolean; isDisabled: boolean }>`
  padding: 6px 20px;
  background-color: ${({ isRed, isDisabled }) =>
    isRed ? E_COLOR.ORANGE : isDisabled ? E_COLOR.GREY_DISABLED : E_COLOR.LIGHT_BLACK};
  color: ${E_COLOR.WHITE};
  font-weight: ${E_FONT_WEIGHT.BOLD};
  font-size: ${({ isList }) => (isList ? "10px" : "12px")};
  line-height: 16px;
  border-radius: 5px;
`;

interface IProps {
  voucher: IVoucher;
  voucherLength: number;
  isList: boolean;
  buttonLabel: string;
  setShow: (show: boolean) => void;
  setSelectedVoucher: (voucher: IVoucher | null, isProductVoucher: boolean) => void;
  isRedButton: boolean;
  isNotSearchCode: boolean;
  fetchPromotionVoucher: (shopId: number, amount: number) => void;
  isDisabled: boolean;
  amountItemOnly: number;
  language: "id" | "en";
}

const AvailableVoucher = ({
  voucher,
  isList,
  buttonLabel,
  setShow,
  setSelectedVoucher,
  voucherLength,
  isRedButton,
  isNotSearchCode,
  fetchPromotionVoucher,
  isDisabled,
  amountItemOnly,
  language,
}: IProps) => {
  const handleClickVoucher = (isProductVoucher: boolean) => {
    if (isList) {
      if (isRedButton) {
        setSelectedVoucher(null, isProductVoucher);

        // means that user probably cancel using specific searched voucher
        if(voucherLength === 1 && isNotSearchCode) {
          fetchPromotionVoucher(null, amountItemOnly);
        }
      } else {
        setSelectedVoucher(voucher, isProductVoucher);
      }

      // setShow(false);
    } else {
      setShow(true);
    }
  };

  return (
    <Container>
      <VoucherBackground src={isDisabled ? VoucherDisabledAsset : VoucherAsset} />
      <Content>
        <VoucherText>
          {isList
            ? voucher.code
            : !voucher?.description
            ? `${WORDING.review_page.YOU_HAVE[language]} ${voucherLength} voucher!`
            : `${voucher?.description?.substring(0, 26)} ${
                voucher?.description?.length > 26 ? "..." : ""
              }`}
        </VoucherText>
        <VoucherButton
          isDisabled={isDisabled}
          isList={isList}
          onClick={isDisabled ? () => {} : () => handleClickVoucher(voucher.type === E_VOUCHER_TYPE.ORDER)}
          isRed={isRedButton}
        >
          {buttonLabel}
        </VoucherButton>
      </Content>
    </Container>
  );
};

export default AvailableVoucher;

import styled from "styled-components";
import {
	E_COLOR,
	E_FONT_WEIGHT,
	E_ORDER_STATUS,
} from "../interfaces/dataTypes";

export const Container = styled.div`
	margin: 0 0 80px;
`;

export const GreyText = styled.div`
	max-width: 200px;
	word-break: break-word;
	color: ${E_COLOR.NEW_GREY_TEXT};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 12px;
	line-height: 18px;
`;

export const Status = styled.div<{ status: E_ORDER_STATUS }>`
	color: ${({ status }) =>
		status === E_ORDER_STATUS.created
			? E_COLOR.DARK_ORANGE
			: status === E_ORDER_STATUS.ready_to_ship
			? E_COLOR.DARK_GREEN
			: E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 19px;
`;

export const PropAndValue = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 16px 9px;
`;

export const Prop = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 17.6px;
`;

export const Value = styled.div<{ isSmall?: boolean }>`
	text-align: right;
	max-width: 200px;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${({ isSmall }) =>
		isSmall ? E_FONT_WEIGHT.REGULAR : E_FONT_WEIGHT.BOLD};
	font-size: ${({ isSmall }) => (isSmall ? "12px" : "14px")};
	line-height: ${({ isSmall }) => (isSmall ? "19px" : "21px")};
	word-break: break-word;
`;

export const GreyLine = styled.div`
	margin: 15px 0 23px;
	width: 100%;
	height: 8px;
	background-color: ${E_COLOR.NEW_GREY_LINE};
`;

export const Product = styled.div`
	padding: 6px 0;
	margin: 0 16px;
`;

export const ProductNamePrice = styled.div`
	display: flex;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
`;

export const PropValueWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const ProductNotes = styled.div`
	margin-top: 5px;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 21px;
`;

export const Total = styled.div`
	padding: 18px 16px 11px;
	display: flex;
	justify-content: space-between;
`;

export const TotalLabel = styled.div<{ inline?: boolean }>`
	padding-left: ${({ inline }) => (inline ? "5px" : "0")};
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

export const TotalPrice = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 21px;
`;

export const AlignRight = styled.div`
	text-align: right;
`;

export const Footer = styled.div`
	margin: 23px 0 14px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const GoogleIcon = styled.img`
	height: 26.5px;
`;

export const FooterText = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
`;

export const Desc = styled.div`
	margin-bottom: 30px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 13px;
	line-height: 16px;
`;

export const OrderInfo = styled.div`
	margin-bottom: 13px;
	padding: 23px 16px 21px;
	border: 1px solid ${E_COLOR.BLACK_LINE};
	background-color: ${E_COLOR.LIGHT_GREY};
	border-radius: 8px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 16px;
`;

export const SellerInfo = styled.div`
	margin: 13px 0;
	padding: 23px 16px 21px;
	border: 1px solid ${E_COLOR.BLACK_LINE};
	background-color: ${E_COLOR.LIGHT_GREY};
	border-radius: 8px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 16px;
	line-height: 20px;
`;

export const Watermark = styled.div`
	margin: 30px 0 10px;
	font-style: italic;
	font-size: 16px;
`;

export const ThankYouImg = styled.img`
	display: block;
	width: 60%;
	margin: 50px auto;
`;

export const UserInfoBox = styled.div`
	margin: 30px 0 21px;
`;

export const BoldText = styled.div`
	margin-bottom: 11px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 17px;
`;

export const OrderDate = styled.div`
	margin-bottom: 11px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 17px;
`;

export const UserInfoBoxDropship = styled.div`
	margin-top: 30px;
`;

export const RegularText = styled.div`
	margin-bottom: 11px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 17px;
`;

export const Address = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 17px;
`;

export const SubTitle = styled.div`
	margin: 12px 0;
	color: ${E_COLOR.BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 17px;
`;

export const ItemBox = styled.div`
    display: flex;
    padding: 15px 0 12px;
    border-bottom: 1px solid ${E_COLOR.BLACK_LINE};

    &:nth-child(3) {
      border-top: 1px solid ${E_COLOR.BLACK_LINE};
`;

export const OrderQty = styled.div`
	flex: 1;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 17px;
`;

export const WrapperLabelDetail = styled.div`
	flex: 6;
	display: flex;
	flex-direction: column;
`;

export const ItemLabel = styled.div`
	margin-bottom: 2px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 17px;
`;

export const ItemPrice = styled.div`
	flex: 2;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 17px;
	text-align: right;
`;

export const ItemTotalBox = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 15px 0 12px;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 17px;
`;

export const ItemTotalQty = styled.div``;

export const ItemTotalPrice = styled.div``;

export const Title = styled.div<{ isWithBackColor: boolean, marginTop?: string }>`
	background-color: ${({ isWithBackColor }) => isWithBackColor ? E_COLOR.LIGHT_GREY : "none"};
	margin: ${({ marginTop }) =>
		marginTop ? `${marginTop} 0 0` : "16px 0 0"};
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 16px;
	line-height: 16px;
	padding: ${({ isWithBackColor }) => isWithBackColor ? "12px 16px" : "0 16px"};
`;

// export const ButtonWrapper = styled.div`
// 	background-color: ${E_COLOR.WHITE};
// 	width: 100%;
// 	max-width: 600px;
// 	position: fixed;
// 	bottom: 0;
// `;

export const ButtonWrapper = styled.div`
	width: calc(100% - 32px);
	display: flex;
	justify-content: space-between;
	background-color: ${E_COLOR.WHITE};
	max-width: 568px;
	position: fixed;
	bottom: 0;
	padding: 13px 16px;
`;

export const ButtonBack = styled.div<{ color: string }>`
	background-color: ${E_COLOR.WHITE};
	border-color: ${({ color }) => (color ? color : E_COLOR.DARK_BLUE)};
	border: ${({ color }) =>
		color ? `1px solid ${color}` : `1px solid ${E_COLOR.DARK_BLUE}`};
	border-radius: 10px;
	padding: 14px 20px;
`;

export const ButtonNext = styled.div<{
	isDisabled: boolean;
	isPrimary: boolean;
	color?: string;
}>`
	flex: auto;
	margin-left: 16px;
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	color: ${({ isDisabled, isPrimary, color }) =>
		isDisabled
			? E_COLOR.GREY_DISABLED
			: isPrimary
			? E_COLOR.WHITE
			: color
			? color
			: E_COLOR.DARK_BLUE};
	background-color: ${({ isDisabled, color, isPrimary }) =>
		isDisabled
			? E_COLOR.NEW_LIGHT_GREY
			: isPrimary
			? color
				? color
				: E_COLOR.DARK_BLUE
			: E_COLOR.WHITE};
	border: ${({ isDisabled, color, isPrimary }) =>
		isDisabled || isPrimary
			? "none"
			: color
			? `1px solid ${color}`
			: `1px solid ${E_COLOR.DARK_BLUE}`};
	border-radius: 10px;
	padding: 13px;
`;

// export const ButtonNext = styled.div<{
// 	isDisabled: boolean;
// 	isPrimary: boolean;
// 	color?: string;
// }>`
// 	width: calc(100% - 32px);
// 	margin: 16px 16px 1px;
// 	padding: 14px 0;
// 	background-color: ${({ isDisabled, color, isPrimary }) =>
// 		isDisabled
// 			? E_COLOR.NEW_LIGHT_GREY
// 			: isPrimary
// 			? color
// 				? color
// 				: E_COLOR.DARK_BLUE
// 			: E_COLOR.WHITE};
// 	border-radius: 10px;
// 	border: ${({ isDisabled, color, isPrimary }) =>
// 		isDisabled || isPrimary
// 			? "none"
// 			: color
// 			? `1px solid ${color}`
// 			: `1px solid ${E_COLOR.DARK_BLUE}`};
// 	color: ${({ isDisabled, isPrimary, color }) =>
// 		isDisabled
// 			? E_COLOR.GREY_DISABLED
// 			: isPrimary
// 			? E_COLOR.WHITE
// 			: color
// 			? color
// 			: E_COLOR.DARK_BLUE};
// 	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
// 	font-size: 16px;
// 	line-height: 25px;
// 	text-align: center;
// `;

// export const ButtonCancel = styled.div`
// 	width: calc(100% - 32px);
// 	padding: 14px 16px 30px;
// 	background-color: ${E_COLOR.WHITE};
// 	border-radius: 10px;
// 	color: ${E_COLOR.GREY_DISABLED};
// 	font-weight: ${E_FONT_WEIGHT.REGULAR};
// 	font-size: 16px;
// 	line-height: 25px;
// 	text-align: center;
// `;

export const WrapperVoucher = styled.div`
	padding: 20px 0;
`;

export const ItemCard = styled.div`
	display: flex;
`;

export const ItemQuantity = styled.div<{ isStrike?: boolean }>`
	width: 7%;
	color: ${({ isStrike }) =>
		isStrike ? E_COLOR.NEW_GREY_TEXT : E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	text-decoration: ${({ isStrike }) => (isStrike ? "line-through" : "none")};
`;

export const ItemDetail = styled.div`
	width: 93%;
`;

export const ItemNamePriceWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 8px;
`;

export const ItemPriceWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

export const ItemDiscountWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const ItemDiscount = styled.div<{
	isPercentage: boolean;
	isStrike?: boolean;
}>`
	color: ${({ isPercentage, isStrike }) =>
		isPercentage && !isStrike ? E_COLOR.RED : E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	text-decoration: ${({ isPercentage, isStrike }) =>
		isPercentage && !isStrike ? "none" : "line-through"};
	padding-left: 4px;
`;

export const ItemNamePrice = styled.div<{
	isBold: boolean;
	width?: string;
	textAlign?: string;
	isStrike?: boolean;
}>`
	width: ${({ width }) => width};
	color: ${({ isStrike }) =>
		isStrike ? E_COLOR.NEW_GREY_TEXT : E_COLOR.NEW_BLACK};
	font-weight: ${({ isBold }) =>
		isBold ? E_FONT_WEIGHT.EXTRA_BOLD : E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	text-align: ${({ textAlign }) => textAlign};
	text-decoration: ${({ isStrike }) => (isStrike ? "line-through" : "none")};
`;

export const ItemNotes = styled.div`
	background-color: ${E_COLOR.NEW_LIGHT_GREY};
	border-radius: 4px;
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	padding: 10px;
`;

export const ItemButtonWrapper = styled.div`
	display: flex;
`;

export const ItemButton = styled.div<{ color?: string }>`
	color: ${({ color }) => (color ? color : E_COLOR.DARK_BLUE)};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	padding: 8px 12px 0 0;
`;

export const TotalTopContainer = styled.div<{ color?: string }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${({ color }) => (color ? color : E_COLOR.DARK_BLUE)}12;
	border-top: 1px solid ${({ color }) => (color ? color : E_COLOR.BLUE_BORDER)};
	border-bottom: 1px solid
		${({ color }) => (color ? color : E_COLOR.BLUE_BORDER)};
	padding: 18px;
`;

export const CartIcon = styled.img`
	width: 26px;
	height: 26px;
	margin: -5px 16px 0 0;
`;

export const TotalTopLabel = styled.div`
	width: 60%;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	text-align: left;
`;

export const TotalTopPrice = styled.div`
	width: 30%;
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	text-align: right;
`;

export const ShopName = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	margin: 16px 16px 4px;
`;

export const ShopAddress = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 19px;
	margin: 0 16px 28px;
`;

export const BuyerCard = styled.div<{ isHaveTwoSection: boolean }>`
	margin: ${({ isHaveTwoSection }) =>
		isHaveTwoSection ? "16px 16px 0" : "16px 16px 25px"};
	padding: 12px 0 3px;
	border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
	border-radius: ${({ isHaveTwoSection }) =>
		isHaveTwoSection ? "12px 12px 0 0" : "12px"};
`;

export const ShippingCard = styled.div<{ isHaveTwoSection: boolean }>`
	margin: 0 16px 25px;
	padding: 12px 0 3px;
	border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
	border-top: none;
	border-radius: ${({ isHaveTwoSection }) =>
		isHaveTwoSection ? "0 0 12px 12px" : "12px"};
`;

export const DashedLine = styled.div`
	margin: 0 16px;
	border-bottom: dashed 1px ${E_COLOR.NEW_LIGHT_GREY};
`;

export const DeliveryCard = styled.div`
	display: flex;
	align-items: center;
	margin: 29px 16px;
`;

export const DeliveryDateWrapper = styled.div`
	margin-left: 16px;
`;

export const DeliveryDateLabel = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	margin-bottom: 4px;
`;

export const DeliveryDate = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

export const CarIcon = styled.img`
	width: 44px;
	height: 44px;
`;

export const TopInfoContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const ShopInfoWrapper = styled.div`
	width: 75%;
`;

export const TableNumberWrapper = styled.div`
	width: 25%;
	margin-top: 16px;
`;

export const TableNumberContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export const TableNumberLabel = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75px;
	height: 12px;
	background-color: ${E_COLOR.DARK_YELLOW};
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 9px;
	line-height: 18px;
	border-radius: 2px;
	z-index: 2;
`;

export const TableNumberValue = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 65px;
	height: 40px;
	background-color: ${E_COLOR.DARK_BLUE};
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 24px;
	line-height: 18px;
	border-radius: 8px;
	margin-top: -5px;
	padding-top: 4px;
	z-index: 1;
`;
export const DimNotification = styled.div<{
	isShow: boolean;
	isPrimary: boolean;
}>`
	bottom: 0;
	display: ${({ isShow }) => (isShow ? "block" : "none")};
	position: fixed;
	left: 50%;
	transform: translate(-50%, 0);
	margin: 0 auto 100px;
	padding: 16px 12px;
	background-color: ${({ isPrimary }) =>
		isPrimary ? E_COLOR.NEW_BLACK : E_COLOR.NEW_LIGHT_GREY};
	box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.113762);
	color: ${({ isPrimary }) =>
		isPrimary ? E_COLOR.WHITE : E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	z-index: 7;
	text-align: center;
	width: calc(100vw - 110px);
	max-width: 350px;
`;

export const PotentialCoinWrapper = styled.div`
	background-color: ${E_COLOR.LIGHT_YELLOW};
	margin: 12px 16px 4px;
	padding: 8px 0;
	text-align: center;
	border-radius: 8px;
`;

export const PotentialCoinLabel = styled.div`
	color: ${E_COLOR.BROWN};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 12px;
	line-height: 16px;
`;

export const NotifChanges = styled.div<{ major: boolean }>`
	display: flex;
	background-color: ${({ major }) =>
		major ? E_COLOR.LIGHT_RED : E_COLOR.VERY_LIGHT_YELLOW};
	margin: 4px 0 0;
	padding: 8px;
	color: ${({ major }) => (major ? E_COLOR.RED : E_COLOR.DARK_YELLOW)};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 14px;
	line-height: 21px;
	justify-content: center;
	border-radius: 4px;
`;

export const ItemCardWrapper = styled.div<{ isLastItem: boolean }>`
	margin: 0 16px ${({ isLastItem }) => (isLastItem ? "14px" : "0")};
	padding: 16px 0 12px;
	border-bottom: dashed 1px ${E_COLOR.NEW_LIGHT_GREY};
`;

import { E_FONT_WEIGHT } from "./../interfaces/dataTypes";
import styled from "styled-components";

export const Container = styled.div`
  overflow-y: scroll;
  position: relative;
  height: 100%;
`;

export const StoreName = styled.div`
  padding: 20px 37px;
  font-weight: ${E_FONT_WEIGHT.MEDIUM};
  font-size: 24px;
  line-height: 29.05px;
`;

import { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { E_COLOR, E_DELIVERY_OPTION, E_FONT_WEIGHT, E_VOUCHER_TYPE } from "../interfaces/dataTypes";
import { IVoucher } from "../interfaces";
import AvailableVoucher from "./AvailableVoucher";
import BottomSheetDetailVoucher from "./BottomSheetDetailVoucher";
import { Swipe, Position } from "mirsahib-react-swipe-component";
import InputText from "../components/InputText";
import { parseIDR } from "../utils";
import ButtonFull from "./ButtonFull";
import { WORDING } from "../constants/translate";
import CloseAsset from "../assets/close.png";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden" : "scroll")};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
	display: ${(props) => (props.isShow ? "block" : "none")};
	position: fixed;
	width: 100vh;
	max-width: 600px;
	background-color: rgba(26, 26, 26, 0.5);
	height: 100vh;
	bottom: 0;
	top: 0;
	z-index: 6;
`;

const Content = styled.div`
	width: calc(100% - 32px);
	max-width: 568px;
	max-height: 70vh;
	position: fixed;
	background-color: ${E_COLOR.WHITE};
	overflow: auto;
	bottom: 0;
	border-radius: 16px 16px 0 0;
	padding: 16px 16px 105px;
`;

const DragLine = styled.div`
	display: block;
	margin: 23px auto;
	border-radius: 100px;
	background-color: ${E_COLOR.NEW_LIGHT_GREY};
	width: 78px;
	height: 8px;
`;

const Title = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 25px;
`;

const SearchContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
`;

const SearchInput = styled.input`
	width: 60%;
	background-color: ${E_COLOR.NEW_GREY};
	padding: 16px 20px;
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	border-radius: 10px;
	border: none;
`;

const SearchButton = styled.button<{ color?: string }>`
	font-family: unset;
	border: none;
	margin-left: 13px;
	padding: 11px 20px;
	background-color: ${({ color }) => color ? color : E_COLOR.DARK_BLUE};
	color: ${E_COLOR.WHITE};
	border: 1px solid ${({ color }) => color ? color : E_COLOR.DARK_BLUE};
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 25px;
	border-radius: 10px;
	&:disabled {
		background-color: ${E_COLOR.NEW_LIGHT_GREY};
		color: ${E_COLOR.GREY_DISABLED};
		border-color: ${E_COLOR.LIGHT_GREY};
	}
`;

const VoucherCard = styled.div`
	padding: 12px 0 1px;
	margin-bottom: 12px;
	background-color: ${E_COLOR.WHITE};
	box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.113762);
	border-radius: 8px;
`;

const VoucherDescription = styled.div`
	margin: 0 16px;
	color: ${E_COLOR.DARK_GREY};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 19px;
	margin-bottom: 6px;
`;

const VoucherSeeDetailButton = styled.div<{ color?: string }>`
	margin: 0 16px;
	color: ${({ color }) => color ? color : E_COLOR.BLUE};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 10px;
	line-height: 15px;
`;

const VoucherNotFound = styled.div`
	margin: 20px 0;
	color: ${E_COLOR.RED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 15px;
`;

const VoucherCategory = styled.div`
	margin: 16px 0;
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
`;

const VoucherCondition = styled.div<{ isRed?: boolean }>`
	width: fit-content;
	margin: 0 0 8px 12px;
	padding: 0 7px;
	background-color: ${E_COLOR.NEW_GREY_BACKGROUND};
	border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
	border-radius: 30px;
	color: ${({isRed}) => isRed ? E_COLOR.RED : E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 8px;
	line-height: 21px;
`;

const VoucherConditionWrapper = styled.div`
	display: flex;
  flex-wrap: wrap;
`;

const ButtonChooseVoucherWrapper = styled.div`
	background-color: ${E_COLOR.WHITE};
	width: 100%;
	max-width: 600px;
	position: fixed;
	bottom: 0;
	margin-left: -16px;
	z-index: 3;
	border-top: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
`

const ImgClose = styled.img`
	float: right;
	width: 16px;
	height: 16px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	border-radius: 5px;
	margin: 16px 0 20px 0;
`;

interface IProps {
	isShow: boolean;
	setShow: (isShow: boolean) => void;
	eligibleVouchers: IVoucher[];
	notEligibleVouchers: IVoucher[];
	handleSearch: () => void;
	code: string;
	setCode: (code: string) => void;
	handleSelectVoucher: (voucher: IVoucher | null, isProductVoucher: boolean) => void;
	selectedProductVoucher: IVoucher | null;
	selectedShippingVoucher: IVoucher | null;
	fetchPromotionVoucher: (shopId: number, amount: number) => void;
	errorMessage: string;
	amountItemOnly: number;
	isSearchingVoucherByCode: boolean;
	numSelectedVoucher: number;
	language: "id" | "en";
	hexPrimary: string;
}

const BottomSheetVoucher = ({
	isShow,
	setShow,
	eligibleVouchers,
	notEligibleVouchers,
	handleSearch,
	code,
	setCode,
	handleSelectVoucher,
	selectedProductVoucher,
	selectedShippingVoucher,
	fetchPromotionVoucher,
	errorMessage,
	amountItemOnly,
	isSearchingVoucherByCode,
	numSelectedVoucher,
	language,
	hexPrimary,
}: IProps) => {
	const [isShowBottomSheetDetailVoucher, setIsShowBottomSheetDetailVoucher] =
		useState(false);
	const [voucherForDetail, setVoucherForDetail] = useState({} as IVoucher);

	const handleCloseBackground = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (e.target === e.currentTarget) {
			// setShow(false);
			if (
				eligibleVouchers.length === 0 ||
				(code === "" && !selectedProductVoucher && !selectedShippingVoucher)
			) {
				// fetchPromotionVoucher(null, amountItemOnly);
				// handleSelectVoucher(null);
				setCode("");
			}
		}
	};

	const handleClickSeeDetail = (voucher: IVoucher) => {
		setVoucherForDetail(voucher);
		setIsShowBottomSheetDetailVoucher(true);
	};
	const onSwipeDownListener = () => {
		setShow(false);
	};

	return (
		<Container isShow={isShow} onClick={(e) => handleCloseBackground(e)}>
			<GlobalStyle isShow={isShow} />
			<Content>
				<Header>
					<Title>{WORDING.review_page.SEARCH_VOUCHER_LABEL[language]}</Title>
					{/* <ImgClose onClick={() => setShow(false)} src={CloseAsset} /> */}
				</Header>
				{/* <Swipe
					nodeName="div"
					className="test"
					detectTouch
					onSwipedDown={onSwipeDownListener}
				>
					<DragLine onClick={(e) => handleCloseBackground(e)} />
				</Swipe> */}
				<SearchContainer>
					<InputText
						placeholder=""
						customMargin="0"
						name="code"
						type="text"
						onChange={(name, value) => setCode(value)}
						value={code}
					/>
					<SearchButton color={hexPrimary} onClick={handleSearch} disabled={!code && !isSearchingVoucherByCode}>
						{WORDING.general.APPLY[language]}
					</SearchButton>
				</SearchContainer>
				{eligibleVouchers.length > 0 && (
					<VoucherCategory>{WORDING.review_page.VALID_VOUCHER[language]}</VoucherCategory>
				)}
				{eligibleVouchers.length > 0 &&
					eligibleVouchers.map((voucher) => {
						const { description, id, shipping_type, payment_type, min_order } = voucher;

            let shippingTypeWording = "";
            if(shipping_type[0] === E_DELIVERY_OPTION.DINE_IN) {
              shippingTypeWording = WORDING.general.DINE_IN[language];
            } else if(shipping_type[0] === E_DELIVERY_OPTION.SELF_SHIPPING) {
              shippingTypeWording = WORDING.general.STORE_COURIER[language];
            } else if(shipping_type[0] === "pickup") {
              shippingTypeWording = WORDING.general.PICK_UP[language];
            } else if(shipping_type[0] === "shipper") {
              shippingTypeWording = WORDING.general.LOGISTIC_COURIER[language];
            }

            let paymentTypeWording = "";
            if(payment_type[0] === "manual-edc") {
              paymentTypeWording = WORDING.review_page.MANUAL_EDC[language];
            } else if(payment_type[0] === "cash") {
              paymentTypeWording = "Cash";
            } else if (payment_type[0].startsWith("custom/")) {
              paymentTypeWording = payment_type[0].replace("custom/", "");
            } else {
							paymentTypeWording = "E-Wallet/Transfer Bank"
						}

						let isDisabled = false;
						if(voucher.type === E_VOUCHER_TYPE.ORDER && (selectedProductVoucher || selectedShippingVoucher)) {
							if(selectedProductVoucher && selectedProductVoucher.id !== id) {
								isDisabled = true;
							}
							if(selectedShippingVoucher && (!selectedShippingVoucher.is_multiple_vouchers || !voucher.is_multiple_vouchers)) {
								isDisabled = true;
							}
						} else if (voucher.type === E_VOUCHER_TYPE.SHIPPING && (selectedProductVoucher || selectedShippingVoucher)) {
							if(selectedShippingVoucher && selectedShippingVoucher.id !== id) {
								isDisabled = true;
							}
							if(selectedProductVoucher && (!selectedProductVoucher.is_multiple_vouchers || !voucher.is_multiple_vouchers)) {
								isDisabled = true;
							}
						}

						return (
							<VoucherCard key={id}>
								<VoucherConditionWrapper>
									<VoucherCondition>
                    {
                      shipping_type.length === 1
                        ? shippingTypeWording
                        : WORDING.popup.VOUCHER_AND_OTHERS[language]
                          .replace('{type}', shippingTypeWording)
                          .replace('{num}', (shipping_type.length - 1) as any)
                    }
                  </VoucherCondition>
									<VoucherCondition>
                    {
                      payment_type.length === 1
                      ? paymentTypeWording
                      : WORDING.popup.VOUCHER_AND_OTHERS[language]
                        .replace('{type}', paymentTypeWording)
                        .replace('{num}', (payment_type.length-1) as any)
                    }
                  </VoucherCondition>
									<VoucherCondition>{WORDING.review_page.MIN_PURCHASE[language]} Rp{min_order === "-1" ? "0" : parseIDR(parseInt(min_order))}</VoucherCondition>
								</VoucherConditionWrapper>
								<VoucherDescription>{description}</VoucherDescription>
								<VoucherSeeDetailButton
									color={hexPrimary}
									onClick={() => handleClickSeeDetail(voucher)}
								>
									{WORDING.general.SEE_DETAIL[language]}
								</VoucherSeeDetailButton>
								<AvailableVoucher
									buttonLabel={selectedProductVoucher?.id === id || selectedShippingVoucher?.id === id ? WORDING.review_page.CANCEL[language] :  WORDING.review_page.USE[language]}
									isList={true}
									voucher={voucher}
									setSelectedVoucher={handleSelectVoucher}
									setShow={setShow}
									voucherLength={eligibleVouchers.length}
									isRedButton={selectedProductVoucher?.id === id || selectedShippingVoucher?.id === id}
									isNotSearchCode={code === ""}
									fetchPromotionVoucher={fetchPromotionVoucher}
									isDisabled={isDisabled}
									// isDisabled={(selectedProductVoucher && voucher.type === E_VOUCHER_TYPE.ORDER && selectedProductVoucher.id !== id ) || (selectedShippingVoucher && voucher.type === E_VOUCHER_TYPE.SHIPPING && selectedShippingVoucher.id !== id)}
									amountItemOnly={amountItemOnly}
									language={language}
								/>
							</VoucherCard>
						);
					})}

				{notEligibleVouchers.length > 0 && (
					<VoucherCategory>{WORDING.review_page.INVALID_VOUCHER[language]}</VoucherCategory>
				)}
				{notEligibleVouchers.length > 0 &&
					notEligibleVouchers.map((voucher) => {
						const { description, id, shipping_type, payment_type, min_order } = voucher;

            let shippingTypeWording = "";
            if(shipping_type[0] === E_DELIVERY_OPTION.DINE_IN) {
              shippingTypeWording = WORDING.general.DINE_IN[language];
            } else if(shipping_type[0] === E_DELIVERY_OPTION.SELF_SHIPPING) {
              shippingTypeWording = WORDING.general.STORE_COURIER[language];
            } else if(shipping_type[0] === "pickup") {
              shippingTypeWording = WORDING.general.PICK_UP[language];
            } else if(shipping_type[0] === "shipper") {
              shippingTypeWording = WORDING.general.LOGISTIC_COURIER[language];
            }

            let paymentTypeWording = "";
            if(payment_type[0] === "manual-edc") {
              paymentTypeWording = WORDING.review_page.MANUAL_EDC[language];
            } else if(payment_type[0] === "cash") {
              paymentTypeWording = "Cash";
            } else {
							paymentTypeWording = "E-Wallet/Transfer Bank"
						}
						return (
							<VoucherCard key={id}>
                <VoucherConditionWrapper>
									<VoucherCondition>
                    {
                      shipping_type.length === 1
                        ? shippingTypeWording
                        : WORDING.popup.VOUCHER_AND_OTHERS[language]
                          .replace('{type}', shippingTypeWording)
                          .replace('{num}', (shipping_type.length - 1) as any)
                    }
                  </VoucherCondition>
									<VoucherCondition>
                    {
                      payment_type.length === 1
                      ? paymentTypeWording
                      : WORDING.popup.VOUCHER_AND_OTHERS[language]
                        .replace('{type}', paymentTypeWording)
                        .replace('{num}', (payment_type.length-1) as any)
                    }
                  </VoucherCondition>
									<VoucherCondition>Minimum order Rp{min_order === "-1" ? "0" : parseIDR(parseInt(min_order))}</VoucherCondition>
								</VoucherConditionWrapper>
								<VoucherDescription>{description}</VoucherDescription>
								<VoucherSeeDetailButton
									color={hexPrimary}
									onClick={() => handleClickSeeDetail(voucher)}
								>
									{WORDING.general.SEE_DETAIL[language]}
								</VoucherSeeDetailButton>
								<AvailableVoucher
									buttonLabel={selectedProductVoucher?.id === id || selectedShippingVoucher?.id === id ?  WORDING.review_page.CANCEL[language]:  WORDING.review_page.USE[language]}
									isList={true}
									voucher={voucher}
									setSelectedVoucher={handleSelectVoucher}
									setShow={setShow}
									voucherLength={eligibleVouchers.length}
									isRedButton={selectedProductVoucher?.id === id || selectedShippingVoucher?.id === id}
									isNotSearchCode={code === ""}
									fetchPromotionVoucher={fetchPromotionVoucher}
									isDisabled={true}
									amountItemOnly={amountItemOnly}
									language={language}
								/>
							</VoucherCard>
						);
					})}
				{eligibleVouchers.length === 0 && notEligibleVouchers.length === 0 && (
					<VoucherNotFound>{errorMessage}</VoucherNotFound>
				)}
				<ButtonChooseVoucherWrapper>
					<ButtonFull hexPrimary={hexPrimary} isDisabled={false} isPrimary={true} onClick={() => setShow(false)}>{numSelectedVoucher > 0 ? `${WORDING.review_page.SELECT[language]} ${numSelectedVoucher} Voucher` : WORDING.general.CLOSE[language]}</ButtonFull>
				</ButtonChooseVoucherWrapper>
			</Content>
			<BottomSheetDetailVoucher
				voucher={voucherForDetail}
				isShow={isShowBottomSheetDetailVoucher}
				setShow={setIsShowBottomSheetDetailVoucher}
				language={language}
				hexPrimary={hexPrimary}
			/>
		</Container>
	);
};

export default BottomSheetVoucher;

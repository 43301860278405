import React from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { E_COLOR, E_FONT_WEIGHT, E_PAGE_PATHS } from "../interfaces/dataTypes";
import { IPathParams } from "../interfaces";
import { WORDING } from "../constants/translate";

const Container = styled.div<{ isFixed: boolean }>`
	position: ${({ isFixed }) => (isFixed ? "fixed" : "relative")};
	width: 100%;
	max-width: 600px;
	max-height: 150px;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);
	background-color: ${E_COLOR.WHITE};
	text-align: center;
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 25px;
	z-index: 5;
`;

const ButtonNext = styled.div<{
	isDisabled: boolean;
	isFinalButton: boolean;
	isCancelAble: boolean;
	color?: string;
}>`
	width: ${({ isCancelAble }) => (isCancelAble ? "150px" : "300px")};
	margin: 20px auto 16px;
	padding: 13px 0;
	background-color: ${({ isDisabled, isFinalButton, color }) =>
		isFinalButton
			? E_COLOR.GREEN
			: isDisabled
			? E_COLOR.GREY_DISABLED
			: color
			? color
			: E_COLOR.DARK_BLUE};
	border-radius: 10px;
	color: ${E_COLOR.WHITE};
`;

const ButtonCancel = styled.div<{ color?: string }>`
	width: 150px;
	margin: 20px auto 16px;
	padding: 13px 0;
	background-color: ${E_COLOR.WHITE};
	border: 1px solid ${({ color }) => (color ? color : E_COLOR.DARK_BLUE)};
	border-radius: 10px;
	color: ${({ color }) => (color ? color : E_COLOR.DARK_BLUE)};
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const PrivacyText = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 18px;
	margin: 0 16px 16px;
`;

const LinkText = styled.span`
	color: ${E_COLOR.DARK_BLUE};
  cursor: pointer;
`;

interface IProps {
	isCancelAble: boolean;
	isDisabled: boolean;
	isFinalButton: boolean;
	handleNext: () => void;
	pathNext?: E_PAGE_PATHS | string;
	handleBack?: () => void;
	pathPrev?: E_PAGE_PATHS;
	isFixed: boolean;
	language: "id" | "en";
	hexPrimary: string;
  setBuyer?: () => void;
	nextButtonLabel?: string;
	customPathNext?: string;
}

const Button = ({
	isCancelAble,
	isDisabled,
	isFinalButton,
	pathNext,
	pathPrev,
	handleNext,
	handleBack,
	isFixed,
	language,
	hexPrimary,
  setBuyer,
	nextButtonLabel,
	customPathNext,
}: IProps) => {
	const history = useHistory();
	const params = useParams();

	const handleNextButton = () => {
		const { shopLink } = params as IPathParams;
    setBuyer && setBuyer();

		if(!customPathNext) {
			if (!isFinalButton && pathNext) {
				history.push(`/${shopLink}/${pathNext}`);
			}
		}
		
		handleNext();
	};

	const handleBackButton = () => {
		if (handleBack) {
			handleBack();
		}

		if (pathPrev !== E_PAGE_PATHS.ITEMS) {
			history.push(pathPrev || "/");
		}
	};

	const handleClickTerm = () => {
		const url = "https://opaper.app/terms";

		// @ts-ignore
		window.open(url, "_blank");
	};

	const handleClickPrivacy = () => {
		const url = "https://opaper.app/privacy";

		// @ts-ignore
		window.open(url, "_blank");
	};

	return (
		<Container isFixed={isFixed}>
			<ButtonWrapper>
				{isCancelAble && (
					<ButtonCancel color={hexPrimary} onClick={handleBackButton}>
						{WORDING.general.BACK[language]}
					</ButtonCancel>
				)}
				<ButtonNext
					color={hexPrimary}
					isDisabled={isDisabled}
					isFinalButton={isFinalButton}
					onClick={isDisabled ? () => {} : handleNextButton}
					isCancelAble={isCancelAble}
				>
					{nextButtonLabel ? nextButtonLabel : isFinalButton
						? WORDING.general.CONFIRM[language]
						: WORDING.general.NEXT[language]}
				</ButtonNext>
			</ButtonWrapper>
			<PrivacyText>
        {language === 'id' && (
          <>
            Dengan melanjutkan proses kamu setuju dengan{" "}
            <LinkText onClick={handleClickTerm}>Syarat Penggunaan</LinkText> dan{" "}
            <LinkText onClick={handleClickPrivacy}>Kebijakan Privasi</LinkText> Opaper.
          </>
        )}
        {language === 'en' && (
          <>
            By clicking Next, you are agreeing with Opaper's{" "}
            <LinkText onClick={handleClickTerm}>Terms and Condition</LinkText> and{" "}
            <LinkText onClick={handleClickPrivacy}>Privacy Notice</LinkText>.
          </>
        )}
			</PrivacyText>
		</Container>
	);
};

export default Button;

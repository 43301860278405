/// <reference no-default-lib="true"/>
import React, { useState } from 'react';
import styled from 'styled-components';
import { E_COLOR, E_FONT_WEIGHT } from '../interfaces/dataTypes';

const Container = styled.input<{ isActive: boolean; customMargin: string, width: string, disabled?: boolean }>`
  box-sizing: border-box;
  width: ${({ width }) => width ? width : "calc(100% - 32px)"};
  margin: ${({ customMargin }) =>
    customMargin !== '' ? customMargin : '24px 16px 18px'};
  padding: 16px;
  border: 1px solid ${E_COLOR.NEW_GREY};
  border-radius: 8px;
  background-color: ${E_COLOR.NEW_GREY};
  color: ${({ disabled }) => disabled ? E_COLOR.GREY_DISABLED : E_COLOR.BLACK};
  font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
  font-size: 16px;
  line-height: 21px;
  outline-width: 0;
	font-family: "Plus Jakarta Sans";

  &:focus {
    border-color: ${E_COLOR.BLUE};
  }

  ::placeholder {
    color: ${E_COLOR.GREY_DISABLED};
    font-weight: ${E_FONT_WEIGHT.REGULAR};
    font-size: 16px;
    line-height: 24px;
    font-family: "Plus Jakarta Sans";
  }
`;

interface IProps {
  placeholder: string;
  name: string;
  type: string;
  onChange: (name: string, value: string) => void;
  value: string;
  customMargin?: string;
  maxLength?: string;
  width?: string;
  oninput?: string;
  disabled?: boolean;
  [key: string]: unknown;
}

export const InputText = ({
  placeholder,
  onChange,
  name,
  type,
  value,
  customMargin,
  maxLength,
  width,
  oninput,
  disabled,
  ...restProps
}: IProps) => {
  const [isActive, setIsActive] = useState(false);

  const handleChange = (name: string, value: string) => {
    if(maxLength) {
      onChange(name, value.slice(0,maxLength));
    } else {
      onChange(name, value);
    }
  }

  return (
    <Container
      isActive={isActive}
      placeholder={placeholder}
      onClick={() => setIsActive(!isActive)}
      onChange={(e) => handleChange(name, e.target.value)}
      type={type}
      value={value}
      customMargin={customMargin || ''}
      maxLength={maxLength}
      width={width}
      disabled={disabled}
      {...restProps}
    />
  );
};

export default InputText;

import { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";
import { IVoucher } from "../interfaces";
import CopyAsset from "../assets/copy.png";
import { getFullDateLabel, getTimeHourMinutes, getUnformattedDate, parseIDR } from "../utils";
import { WORDING } from "../constants/translate";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden" : "scroll")};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
	display: ${(props) => (props.isShow ? "block" : "none")};
	position: fixed;
	width: 100vh;
	max-width: 600px;
	background-color: rgba(26, 26, 26, 0.5);
	height: 100vh;
	bottom: 0;
	top: 0;
	z-index: 6;
`;

const Content = styled.div`
	width: calc(100% - 32px);
	padding-bottom: 20px;
	max-width: 568px;
	max-height: 70vh;
	position: fixed;
	background-color: ${E_COLOR.WHITE};
	overflow: auto;
	bottom: 0;
	border-radius: 16px 16px 0 0;
	padding: 16px;
`;

const DragLine = styled.div`
	display: block;
	margin: 23px auto;
	border-radius: 100px;
	background-color: ${E_COLOR.NEW_LIGHT_GREY};
	width: 78px;
	height: 8px;
`;

const Title = styled.div`
	margin-bottom: 10px;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 25px;
`;

const DateDetail = styled.div`
	margin-bottom: 24px;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 12px;
	line-height: 19px;
`;

const CodeProductWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 21px;
`;

const CodeProduct = styled.div`
	width: 50%;
`;

const CodeProductLabel = styled.div`
	margin-bottom: 8px;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 10px;
	line-height: 15px;
`;

const ValueWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const TextCode = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

const CopyIcon = styled.img`
	margin: 0 0 4px 12px;
	height: 14px;
`;

const TextItemLength = styled.div<{ color?: string }>`
	color: ${({ color }) => color ? color : E_COLOR.DARK_BLUE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

const TnCTitle = styled.div`
	width: 100%;
	margin-bottom: 25px;
	text-align: center;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
`;

const TnCPoint = styled.div`
	display: flex;
	margin-bottom: 28px;
`;

const GreyLine = styled.div`
	margin: 15px 0 23px -16px;
	width: 100vw;
	height: 8px;
	background-color: ${E_COLOR.NEW_GREY_LINE};
`;

const Dot = styled.div`
	background-color: ${E_COLOR.LIGHT_BLACK};
	border-radius: 50%;
	min-width: 4px;
	min-height: 4px;
	max-width: 4px;
	max-height: 4px;
	margin: 7px 8px 0 0;
`;

const TnCPointText = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
`;

const DimNotification = styled.div<{ isShow: boolean }>`
	bottom: 0;
	display: ${({ isShow }) => (isShow ? "block" : "none")};
	position: fixed;
	left: 50%;
	transform: translate(-50%, 0);
	margin: 0 auto 100px;
	padding: 7px 25px;
	background-color: ${E_COLOR.NEW_LIGHT_GREY};
	box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.113762);
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 14px;
	line-height: 21px;
	box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.12);
	border-radius: 24px;
	z-index: 7;
`;

const VoucherCondition = styled.div<{ isRed?: boolean }>`
	width: fit-content;
	margin: 0 12px 8px 0;
	padding: 0 7px;
	background-color: ${E_COLOR.NEW_GREY_BACKGROUND};
	border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
	border-radius: 30px;
	color: ${({ isRed }) => (isRed ? E_COLOR.RED : E_COLOR.LIGHT_BLACK)};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 8px;
	line-height: 21px;
`;

const VoucherConditionWrapper = styled.div`
	display: flex;
  flex-wrap: wrap;
`;

interface IProps {
	isShow: boolean;
	setShow: (isShow: boolean) => void;
	voucher: IVoucher;
	language: "id" | "en";
	hexPrimary: string;
}

const DAYS = [
  WORDING.general.SUNDAY,
  WORDING.general.MONDAY,
  WORDING.general.TUESDAY,
  WORDING.general.WEDNESDAY,
  WORDING.general.THURSDAY,
  WORDING.general.FRIDAY,
  WORDING.general.SATURDAY,
];

const BottomSheetDetailVoucher = ({ isShow, setShow, voucher, language, hexPrimary }: IProps) => {
	const { code, description, start_date, end_date, product_ids, end_time, start_time, is_recurring, recurring_config } = voucher;
	const formattedStartDate = getUnformattedDate(start_date);
	const formattedEndDate = getUnformattedDate(end_date);
	const [isShowDimNotification, setIsShowDimNotification] = useState(false);
	let timer: any;
  let recurringDay = '';

  if (is_recurring) {
    recurringDay += WORDING.review_page.EVERY[language] + ' ';
    if (recurring_config!.days.length === 1) {
      recurringDay += DAYS[recurring_config!.days[0]][language];
    } else if (recurring_config!.days.length < 7) {
      recurring_config!.days.forEach((day, index) => {
        if (index > 0 && index < recurring_config!.days.length - 1) {
          recurringDay += ', ';
        } else if (index === recurring_config!.days.length - 1) {
          recurringDay += ' ' + WORDING.general.AND[language] + ' ';
        }

        recurringDay += DAYS[day][language];
      })
    } else if (recurring_config!.days.length === 7) {
      recurringDay += WORDING.review_page.DAY[language];
    }

    recurringDay += ' ' + WORDING.review_page.ON_HOURS[language] + ` ${recurring_config!.start_time} - ${recurring_config!.end_time}`;
  }

  const getPaymentList = () => {
    const result: string[] = [];

    if (voucher?.payment_type?.toString().includes("xendit")) {
      result.push("E-wallet / Transfer Bank / QRIS / Kartu Kredit/Debit");
    }

    if (voucher?.payment_type?.toString().includes("cash")) {
      result.push(WORDING.general.CASH[language]);
    }
    
    if (voucher?.payment_type?.toString().includes("manual-edc")) {
      result.push(WORDING.review_page.MANUAL_EDC[language]);
    }

    voucher?.payment_type?.forEach((payment) => {
      if (payment.startsWith("custom/")) {
        result.push(payment.replace("custom/", ""));
      }
    });

    return result.join(', ');
  }

	const handleCloseBackground = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (e.target === e.currentTarget) {
			setShow(false);
		}
	};

	const handleCopy = () => {
		setIsShowDimNotification(true);
		timer = setTimeout(() => setIsShowDimNotification(false), 1000);

		// @ts-ignore
		const el = document.createElement("textarea");
		el.value = code;
		// @ts-ignore
		document.body.appendChild(el);
		el.select();
		// @ts-ignore
		document.execCommand("copy");
		// @ts-ignore
		document.body.removeChild(el);
	};

	useEffect(() => {
		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<Container isShow={isShow} onClick={(e) => handleCloseBackground(e)}>
			<GlobalStyle isShow={isShow} />
			<Content>
				<DragLine onClick={(e) => handleCloseBackground(e)} />
				<Title>{description}</Title>
				{is_recurring && (
          <DateDetail>
            {WORDING.review_page.VALID[language]}{" "}
            {`${getFullDateLabel(new Date(start_time), true)} - ${getFullDateLabel(new Date(end_time), true)}`}.{" "}
            {recurringDay}
          </DateDetail>
        )}
				{!is_recurring && (
          <DateDetail>
            {WORDING.review_page.VALID[language]}{" "}
            {`${getFullDateLabel(new Date(start_time), true)} ${getTimeHourMinutes(new Date(start_time))} - ${getFullDateLabel(
              new Date(end_time),
              true
            )} ${getTimeHourMinutes(new Date(end_time))}`}
          </DateDetail>
        )}
				<CodeProductWrapper>
					<CodeProduct>
						<CodeProductLabel>{WORDING.review_page.DISCOUNT_CODE[language]}</CodeProductLabel>
						<ValueWrapper>
							<TextCode id="textCopy">
								{code && code.length > 12
									? `${code.substring(0, 12)}...`
									: code}
							</TextCode>
							<CopyIcon src={CopyAsset} onClick={handleCopy} />
						</ValueWrapper>
					</CodeProduct>
					<CodeProduct>
						<CodeProductLabel>{WORDING.review_page.PRODUCT_LIST[language]}</CodeProductLabel>
						<ValueWrapper>
							<TextItemLength color={hexPrimary}>{product_ids?.length === 0 ? WORDING.review_page.ALL_PRODUCT[language] : `${product_ids?.length} Item`}</TextItemLength>
							<CopyIcon />
						</ValueWrapper>
					</CodeProduct>
				</CodeProductWrapper>
				<VoucherConditionWrapper>
					<VoucherCondition>
						{voucher?.shipping_type
							?.toString()
							.replace("pickup", ` ${WORDING.general.PICK_UP[language]}`)
							.replace("self_shipping", ` ${WORDING.general.STORE_COURIER[language]}`)
							.replace("dine_in", ` ${WORDING.general.DINE_IN[language]}`)
							.replace("shipper", ` ${WORDING.general.DELIVERY[language]}`)}
					</VoucherCondition>
					<VoucherCondition>
						{/* {voucher?.payment_type
							?.toString()
							.replace("cash", `${WORDING.general.CASH[language]}`)
							.replace("xendit/ewallet", "")
							.replace("xendit/e-wallet", "")
							.replace("xendit/va", "")
							.replace("xendit/card", "")
							.replace("xendit", " E-Wallet/Transfer Bank")
							.replace("xendit/qris", "")
							.replace("manual-edc", "")
							} */}
							{/* {voucher?.payment_type?.toString().includes("xendit") && "E-wallet / Transfer Bank / QRIS / Kartu Kredit/Debit"}
							{voucher?.payment_type?.length > 1 && ", "}
							{voucher?.payment_type?.toString().includes("cash") && `${WORDING.general.CASH[language]}`}
							{voucher?.payment_type?.length > 2 && ", "}
							{voucher?.payment_type?.toString().includes("manual-edc") && `${WORDING.review_page.MANUAL_EDC[language]}`} */}
              {getPaymentList()}
					</VoucherCondition>
					<VoucherCondition>
						{WORDING.review_page.MIN_PURCHASE[language]} Rp
						{voucher?.min_order === "-1"
							? "0"
							: parseIDR(parseInt(voucher?.min_order))}
					</VoucherCondition>
				</VoucherConditionWrapper>
				<GreyLine />
				<TnCTitle>{WORDING.review_page.TNC[language]}</TnCTitle>
				<TnCPoint>
					<Dot />
					<TnCPointText>
						{WORDING.review_page.PAID_WITH[language]}{" "}{getPaymentList()}
						{/* {voucher?.payment_type?.toString().includes("xendit") && "E-wallet / Transfer Bank / QRIS / Kartu Kredit/Debit"}
						{voucher?.payment_type?.length > 1 && ", "}
						{voucher?.payment_type?.toString().includes("cash") && `${WORDING.general.CASH[language]}`}
						{voucher?.payment_type?.length > 2 && ", "}
						{voucher?.payment_type?.toString().includes("manual-edc") && `${WORDING.review_page.MANUAL_EDC[language]}`} */}
					</TnCPointText>
				</TnCPoint>
        <TnCPoint>
					<Dot />
					<TnCPointText>
						{WORDING.review_page.SHIPPING_TYPE[language]} {voucher?.shipping_type
							?.toString()
							.replace("pickup", ` ${WORDING.general.PICK_UP[language]}`)
							.replace("self_shipping", ` ${WORDING.general.STORE_COURIER[language]}`)
							.replace("dine_in", ` ${WORDING.general.DINE_IN[language]}`)
							.replace("shipper", ` ${WORDING.general.LOGISTIC_COURIER[language]}`)}
					</TnCPointText>
				</TnCPoint>
        <TnCPoint>
					<Dot />
					<TnCPointText>
						{voucher?.min_order === "-1" ? WORDING.review_page.WITHOUT_MIN_PURCHASE[language] : `${WORDING.review_page.MIN_PURCHASE[language]} Rp${parseIDR(parseInt(voucher?.min_order))} ${WORDING.review_page.EXCLUDE_DELIVERY_FEE[language]}`}
					</TnCPointText>
				</TnCPoint>
				<TnCPoint>
					<Dot />
					<TnCPointText>
						{WORDING.message.PROMO_VALID_BY_DATE[language]}
					</TnCPointText>
				</TnCPoint>
				<TnCPoint>
					<Dot />
					<TnCPointText>
						{WORDING.message.OPAPER_RIGHTS[language]}
					</TnCPointText>
				</TnCPoint>
				<TnCPoint>
					<Dot />
					<TnCPointText>
						{WORDING.message.BUYER_AGREEMENT[language]}
					</TnCPointText>
				</TnCPoint>
			</Content>
			<DimNotification isShow={isShowDimNotification}>{WORDING.general.COPIED[language]}</DimNotification>
		</Container>
	);
};

export default BottomSheetDetailVoucher;

import styled from 'styled-components';
import { E_COLOR, E_FONT_WEIGHT } from '../interfaces/dataTypes';

import errorAsset from '../assets/error.png';
import successAsset from "../assets/success.png";
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { IPathParams } from '../interfaces';
import { WORDING } from '../constants/translate';

const Background = styled.div<{ isOpen: boolean }>`
  position: fixed;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  max-width: 600px;
  background-color: ${E_COLOR.GREY_BACKGROUND};
  z-index: 1;
`;

const Container = styled.div`
  width: calc(100% - 90px);
  padding: 20px 18px;
  background-color: ${E_COLOR.WHITE};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-align: center;
`;

const Title = styled.div`
  color: ${E_COLOR.BLACK};
  font-weight: ${E_FONT_WEIGHT.BOLD};
  font-size: 15px;
  line-height: 18.27px;
  margin: 15px 0 5px;
`;

const Description = styled.div`
  color: ${E_COLOR.BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 10px;
`;

const AgreementWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 10px;
`

const AgreementText = styled.div`
  color: ${E_COLOR.BLACK};
  font-weight: ${E_FONT_WEIGHT.REGULAR};
  font-size: 14px;
  line-height: 17px;
`;

const ButtonWrapper = styled.div`
`

const Button = styled.div<{ isClose: boolean, isDisabled: boolean }>`
  width: 200px;
  margin: 20px auto;
  padding: 13px 0;
  background-color: ${({ isClose, isDisabled }) =>
    isClose ? E_COLOR.WHITE : isDisabled ? E_COLOR.GREY_DISABLED : E_COLOR.DARK_BLUE};
  border-radius: 100px;
  border: 1px solid
    ${({ isClose, isDisabled }) => (isClose ? E_COLOR.GREY_BORDER : isDisabled ? E_COLOR.GREY_DISABLED : E_COLOR.BLUE)};
  text-align: center;
  color: ${({ isClose }) => (isClose ? E_COLOR.BLACK : E_COLOR.WHITE)};
  font-weight: ${E_FONT_WEIGHT.BOLD};
`;

const Checkbox = styled.input`
  width: 15px;
  height: 15px;
  border-radius: 5px;
  border: none;
  margin-right: 14px;
  border: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
  background: ${E_COLOR.WHITE};

  input:checked {
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    height: 4px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    width: 9px;
  }
`;

interface IProps {
  isShow: boolean;
  setIsShow: (isShow: boolean) => void;
  title?: string;
  desc?: string;
  handleSubmit: () => void;
  isSelectPickup: boolean;
  language: "id" | "en";
}

const PopupAgreement = ({
  isShow,
  desc,
  title,
  handleSubmit,
  isSelectPickup,
  setIsShow,
  language,
}: IProps) => {
  const [isAgree, setIsAgree] = useState(false);
  const history = useHistory();
  const params = useParams();

  const { shopLink } = params as IPathParams;

  const handleChangeShipping = () => {
    history.push(`/${shopLink}/contact`);
  }

  const handleNextButton = () => {
    handleSubmit();
    setIsShow(false);
  }

  return (
    <Background isOpen={isShow}>
      <Container>
        <Title>{title}</Title>
        <Description>
          {WORDING.message.FRAUD_AGREEMENT[language]} <b>{isSelectPickup ? WORDING.message.PICKUP_METHOD[language] : WORDING.message.COURIER_BY_SELLER[language]}</b> dan menyetujui bahwa seluruh tanggung jawab transaksi ini berada di pihak pembeli dan penjual. Jika Anda mencentang kotak di bawah ini berarti Anda sudah membaca informasi yang kami berikan dan menerima risiko bahwa Opaper tidak menggaransi apabila di kemudian hari terdapat kendala yang berhubungan dengan transaksi ini.
        </Description>
        <AgreementWrapper>
          <Checkbox
            type='checkbox'
            value='save'
            name='save-info'
            id='check-save'
            checked={isAgree}
            onChange={() => setIsAgree(!isAgree)}
          />
          <AgreementText>{WORDING.message.AGREE_OF_RISK[language]}</AgreementText>
        </AgreementWrapper>
        <ButtonWrapper>
          <Button isClose={true} onClick={handleChangeShipping} isDisabled={false}>
            {WORDING.review_page.CHANGE_SHIPPING[language]}
          </Button>
          <Button isClose={false} onClick={isAgree ? handleNextButton : () => {}} isDisabled={!isAgree}>
            {WORDING.general.NEXT[language]}
          </Button>
        </ButtonWrapper>
      </Container>
    </Background>
  );
};

export default PopupAgreement;

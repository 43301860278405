import React, {
	ChangeEvent,
	TextareaHTMLAttributes,
	useEffect,
	useState,
} from "react";
import styled, { createGlobalStyle } from "styled-components";
import {
	IItem,
	ISelectedItem,
	ISelectedItems,
	IVariant,
	IVariantDetail,
} from "../interfaces";
import { E_COLOR, E_FONT_WEIGHT } from "../interfaces/dataTypes";

import CloseAsset from "../assets/close.png";
import defaultAsset from "../assets/defaultItem.png";
import { calcDiscountPercentage, parseIDR } from "../utils";
import { GreyDashLine } from "../styles/SummaryOrder";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { WORDING } from "../constants/translate";

const GlobalStyle = createGlobalStyle<{ isShow: boolean }>`
  body {
    height: ${({ isShow }) => (isShow ? "100vh" : "100%")};
    overflow-y: ${({ isShow }) => (isShow ? "hidden" : "scroll")};
  }
`;

const Container = styled.div<{ isShow: boolean }>`
	z-index: 6;
	display: ${(props) => (props.isShow ? "block" : "none")};
	position: fixed;
	width: 100vh;
	max-width: 600px;
	background-color: rgba(26, 26, 26, 0.5);
	height: 100vh;
	bottom: 0;
	top: 0;
	overflow: hidden;
`;

const Content = styled.div`
	width: 100%;
	max-width: 600px;
	position: fixed;
	background-color: ${E_COLOR.WHITE};
	bottom: 0;
	border-radius: 16px 16px 0 0;
	margin-bottom: 70px;
`;

const ScrollAbleContent = styled.div`
	overflow-y: scroll;
	max-width: 600px;
	max-height: 65vh;
	padding-bottom: 20px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 16px;
`;

const Title = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 18px;
	line-height: 27px;
`;

const CloseIcon = styled.img`
	width: 16px;
	height: 16px;
`;

const ItemImage = styled.img`
	width: calc(100% - 36px);
	height: 145px;
	border-radius: 12px;
	object-fit: cover;
	margin: 20px 16px 12px;
`;

const ItemName = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 21px;
	margin: 0 16px 8px;
`;

const ItemPrice = styled.div`
	color: ${E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
`;

const ItemPriceWrapper = styled.div`
	display: flex;
	align-items: baseline;
	margin: 0 16px 8px;
`;

const ItemDiscount = styled.div`
	color: ${E_COLOR.GREY_DISABLED};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 10px;
	line-height: 14px;
	padding: 0 8px 0 4px;
	text-decoration: line-through;
`;

const ItemDiscountPercentage = styled.div`
	background-color: ${E_COLOR.RED};
	color: ${E_COLOR.WHITE};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 8px;
	line-height: 8px;
	padding: 2px;
	text-align: center;
	border-radius: 4px;
`;

const ItemDescription = styled.div`
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	margin: 0 16px 18px;
`;

const SeeMore = styled.div<{ color?: string }>`
	display: flex;
	justify-content: center;
	color: ${({ color }) => (color ? color : E_COLOR.DARK_BLUE)};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
	margin: 0 16px 18px;
`;

const GreyLine = styled.div`
	width: 100vw;
	max-width: 600px;
	height: 8px;
	background-color: ${E_COLOR.NEW_GREY_LINE};
`;

const VariantWrapper = styled.div`
	padding: 20px 16px 0;
`;

const VariantName = styled.div`
	color: ${E_COLOR.BLACK};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 18px;
	line-height: 27px;
	margin-bottom: 12px;
`;

const VariantDescription = styled.div`
	display: flex;
	color: ${E_COLOR.LIGHT_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	margin-bottom: 12px;
`;

const VariantOptionWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 14px;
`;

const VariantOptionName = styled.div<{ isDisabled: boolean }>`
	color: ${({ isDisabled }) =>
		isDisabled ? E_COLOR.GREY_DISABLED : E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	width: 60%;
`;

const VariantOptionPrice = styled.div<{ isDisabled: boolean }>`
	color: ${({ isDisabled }) =>
		isDisabled ? E_COLOR.GREY_DISABLED : E_COLOR.NEW_BLACK};
	font-weight: ${E_FONT_WEIGHT.REGULAR};
	font-size: 14px;
	line-height: 21px;
	text-align: right;
	width: 30%;
`;

const RadioButton = styled.input`
	-webkit-appearance: none;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	background: ${E_COLOR.WHITE};
	border: 1px solid ${E_COLOR.GREY_DISABLED};
	margin: 0 9px 2px 0;

	&:checked {
		background: ${E_COLOR.DARK_BLUE};
		border: solid 2px ${E_COLOR.DARK_BLUE};
	}
`;

// const Checkbox = styled.input.attrs({ type: 'checkbox' })`
// 	-webkit-appearance: none;
// 	width: 50px;
// 	height: 50px;
// 	border-radius: 6px;
// 	background: ${E_COLOR.WHITE};
// 	border: 1px solid ${E_COLOR.GREY_DISABLED};
// 	margin: 0 9px 2px 0;

// 	&:checked {
// 		background: ${E_COLOR.GREEN};
// 		border: solid 2px ${E_COLOR.RED};
//     width: 20px;
//     height: 20px;
//     border: solid white;
//     border-width: 0 3px 3px 0;
//     -webkit-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     transform: rotate(45deg);
//     right: 0;
// 	}

//   &:after {
//     left: 9px;
//   top: 5px;
//   width: 5px;
//   height: 10px;
//   border: solid white;
//   border-width: 0 3px 3px 0;
//   -webkit-transform: rotate(45deg);
//   -ms-transform: rotate(45deg);
//   transform: rotate(45deg);
//   }
// `;

// const Checkbox = styled.input.attrs({ type: 'checkbox' })`
// width: 24px;
// height: 24px;
// border-radius: 6px;

// &:checked {
//   background-color: #2196F3;
// }
// `

const TextSeparator = styled.div`
	margin: 8px 8px 0;
	width: 3px;
	height: 3px;
	border-radius: 50%;
	background-color: ${E_COLOR.LIGHT_BLACK};
`;

const ButtonNext = styled.div<{ isDisabled: boolean; color?: string }>`
	padding: 10px 0;
	background-color: ${({ isDisabled, color }) =>
		isDisabled ? E_COLOR.NEW_LIGHT_GREY : color ? color : E_COLOR.DARK_BLUE};
	border-radius: 10px;
	color: ${({ isDisabled }) =>
		isDisabled ? E_COLOR.GREY_DISABLED : E_COLOR.WHITE};
	border: 1px solid
		${({ isDisabled, color }) =>
			isDisabled ? E_COLOR.NEW_LIGHT_GREY : color ? color : E_COLOR.DARK_BLUE};
	text-align: center;
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
	margin: 12px;
	width: 100%;
`;

const ItemButtonStockWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: auto 8px 0;
`;

const ItemButtonWrapper = styled.div`
	width: 120px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	align-self: center;
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
	margin: 12px 8px 12px 12px;
`;

const ButtonAddMin = styled.div<{ isClickable: boolean; color?: string }>`
	display: flex;
	justify-content: center;
	width: 24px;
	height: 24px;
	color: ${E_COLOR.WHITE};
	background-color: ${({ isClickable, color }) =>
		isClickable ? (color ? color : E_COLOR.DARK_BLUE) : E_COLOR.NEW_LIGHT_GREY};
	border-radius: 50%;
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 16px;
	line-height: 20px;
`;

const CheckboxContainer = styled.div`
	display: inline-block;
	vertical-align: middle;
`;

const Icon = styled.svg`
	fill: none;
	stroke: white;
	stroke-width: 3px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const StyledCheckbox = styled.div<{
	checked: boolean;
	isCheckbox: boolean;
	color?: string;
}>`
	display: inline-block;
	width: ${({ isCheckbox }) => (isCheckbox ? "24px" : "23px")};
	height: ${({ isCheckbox }) => (isCheckbox ? "24px" : "23px")};
	background: ${({ checked, color }) =>
		checked ? (color ? color : E_COLOR.DARK_BLUE) : E_COLOR.WHITE};
	border-radius: ${({ isCheckbox }) => (isCheckbox ? "6px" : "50%")};
	margin: 0 9px 2px 0;
	transition: all 150ms;
	border: 1px solid
		${({ checked, color }) =>
			checked ? (color ? color : E_COLOR.DARK_BLUE) : E_COLOR.GREY_DISABLED};

	${Icon} {
		visibility: ${(props) => (props.checked ? "visible" : "hidden")};
	}
`;

const Notes = styled.div<{ color?: string }>`
	color: ${({ color }) => (color ? color : E_COLOR.DARK_BLUE)};
	font-weight: ${E_FONT_WEIGHT.EXTRA_BOLD};
	font-size: 16px;
	line-height: 24px;
	margin: 19px 16px 0;
`;

const InputNotes = styled.input.attrs({ type: "text" })`
	width: calc(100% - 66px);
	border: 1px solid ${E_COLOR.GREY_BORDER};
	outline: 0;
	border-radius: 6px;
	color: ${E_COLOR.NEW_BLACK};
	font-size: 16px;
	margin: 30px 16px 0;
	padding: 16px;
	font-family: "Plus Jakarta Sans";

	::placeholder,
	::-webkit-input-placeholder {
		color: ${E_COLOR.GREY_BORDER};
		font-family: "Plus Jakarta Sans";
	}
`;

const CarouselContainer = styled.div`
	width: 100%;
	.slick-dots {
		bottom: 25px;
		li {
			margin: 0;
			button:before {
				font-size: 10px;
			}
			.slick-active button:before {
				color: white;
			}
		}
	}
	.slick-dots li.slick-active button:before {
		color: white;
	}
	.slick-dots button:hover:before {
		color: white;
	}
`;

const Image = styled.img`
	width: calc(100% - 32px) !important;
	height: 258px !important;
	max-width: 568px;
	max-height: 378px;
	margin: 0 16px 17px;
	border-radius: 20px;
	object-fit: cover;
`;

const ButtonFixWrapper = styled.div`
	position: fixed;
	bottom: 0;
	display: flex;
	width: 100%;
	max-width: 600px;
	background-color: ${E_COLOR.WHITE};
	border-top: 1px solid ${E_COLOR.NEW_LIGHT_GREY};
`;

const TakeawayContainer = styled.div`
  padding: 16px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TakeawayLabel = styled.div`
	color: ${E_COLOR.NEW_BLACK};
  flex: 1;
	font-weight: ${E_FONT_WEIGHT.BOLD};
	font-size: 14px;
	line-height: 21px;
`;

const settings = {
	dots: true,
	arrows: false,
	draggable: true,
};

interface IProps {
	isShow: boolean;
	setShow: (isShow: boolean) => void;
	item: IItem;
	handleAddSelectedItem: (item: ISelectedItem) => void;
	setEditedItem: (selectedItem: ISelectedItem) => void;
	selectedItem?: ISelectedItem;
	countQuantityItemSameId: (id: string) => number;
	handleDeleteExistingItem: (id: string) => void;
	language: "id" | "en";
	hexPrimary: string;
  isPickUp?: boolean;
  isHideTakeaway?: boolean;
}

const BottomSheetItemDetail = ({
	isShow,
	setShow,
	item,
	selectedItem,
	handleAddSelectedItem,
	setEditedItem,
	countQuantityItemSameId,
	handleDeleteExistingItem,
	language,
	hexPrimary,
  isPickUp = false,
  isHideTakeaway = false,
}: IProps) => {
	const [isShowFullDescription, setIsShowFullDescription] = useState(false);
	const [variantInput, setVariantInput] = useState({} as any);
	const [notesInput, setNotesInput] = useState("");
	const [quantityInput, setQuantityInput] = useState(1);
	const [isEditNotes, setIsEditNotes] = useState(false);
  const [isTakeaway, setTakeaway] = useState(false);

  const showTakeaway = isHideTakeaway ? false : !isPickUp;

	const handleVariantInput = (
		isMultiple: boolean,
		variantId: string,
		variantOptionId: string,
		name: string,
		price: string,
		numOption: number,
		category: string
	) => {
		const updatedVariantInput = { ...variantInput };

		if (variantInput[variantId] && variantInput[variantId][variantOptionId]) {
			delete updatedVariantInput[variantId][variantOptionId];
			if (Object.keys(updatedVariantInput[variantId]).length === 0) {
				delete updatedVariantInput[variantId];
			}
		} else {
			if (isMultiple) {
				if (variantInput[variantId]) {
					if (
						Object.keys(variantInput[variantId]).length < numOption ||
						numOption === 0
					) {
						updatedVariantInput[variantId] = {
							...variantInput[variantId],
							[variantOptionId]: { name, price, category },
						};
					}
				} else {
					updatedVariantInput[variantId] = {
						...variantInput[variantId],
						[variantOptionId]: { name, price, category },
					};
				}
			} else {
				updatedVariantInput[variantId] = {
					[variantOptionId]: { name, price, category },
				};
			}
		}

		setVariantInput(updatedVariantInput);
	};

	const handleCloseBackground = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (e.target === e.currentTarget) {
			setShow(false);
			setNotesInput("");
			setVariantInput({});
			setQuantityInput(1);
      setTakeaway(false);
			setIsEditNotes(false);
		}
	};

	const handleChangeQuantity = (isAdding: boolean) => {
		if (isAdding) {
			if (quantityInput < item.stock) setQuantityInput(quantityInput + 1);
		} else {
			if (quantityInput !== 0) {
				setQuantityInput(quantityInput - 1);
			}
		}
	};

	const handleAddNewSelectedItem = () => {
		if (quantityInput === 0) {
			handleDeleteExistingItem(selectedItem?.data?.id as string);
		} else {
			handleAddSelectedItem({
				data: {
					...item,
					id: selectedItem?.data?.id ? selectedItem?.data.id : item.id,
				},
				notes: notesInput,
				quantity: quantityInput,
				variants: variantInput,
        is_takeaway: isPickUp ? true : isTakeaway
			});
		}
		setShow(false);
		setNotesInput("");
		setVariantInput({});
		setQuantityInput(1);
		setIsEditNotes(false);
    setTakeaway(false);
		setEditedItem(null);
	};

	const validationChangeSelectedItem = () => {
		let disabled = false;
		if (item.variant) {
			item.variant.forEach(({ is_mandatory, num_option, id }: IVariant) => {
				if (is_mandatory) {
					if (!variantInput[id]) {
						disabled = true;
					}
				}
				if (quantityInput === 0) {
					disabled = true;
				}
			});
		} else {
			if (quantityInput === 0 && !selectedItem?.quantity) {
				disabled = true;
			}
		}

		return disabled;
	};

	useEffect(() => {
		// @ts-ignore
		const scrollableDiv = document.getElementById("scrollable");
		scrollableDiv.scrollTop = 0;
		if (selectedItem?.quantity) {
			setNotesInput(selectedItem.notes);
			setQuantityInput(selectedItem.quantity);
			setVariantInput(selectedItem.variants);
      setTakeaway(selectedItem.is_takeaway);
		}
	}, [selectedItem, isShow]);

	const quantitySameId = selectedItem?.quantity
		? countQuantityItemSameId(`${parseInt(selectedItem?.data?.id as string)}`) -
		  selectedItem.quantity
		: countQuantityItemSameId(`${parseInt(item.id as string)}`);

	return (
		<Container isShow={isShow} onClick={(e) => handleCloseBackground(e)}>
			<GlobalStyle isShow={isShow} />
			<Content>
				<Header>
					<Title>{WORDING.item_page.CHOOSE_VARIANT[language]}</Title>
					<CloseIcon
						onClick={(e) => handleCloseBackground(e)}
						src={CloseAsset}
					/>
				</Header>
				<ScrollAbleContent id="scrollable">
					<CarouselContainer>
						{item.image_urls?.length > 1 ? (
							<Slider {...settings}>
								{item.image_urls.map((image: string) => (
									<Image src={image} alt="product" />
								))}
							</Slider>
						) : (
							<Image
								src={
									item.image_urls?.length ? item.image_urls[0] : defaultAsset
								}
							/>
						)}
					</CarouselContainer>
					{/* <ItemImage src={item.image_urls ? item.image_urls[0] : defaultAsset} /> */}
					<ItemName>{item.name}</ItemName>
					<ItemPriceWrapper>
						<ItemPrice>
							Rp
							{parseIDR(
								parseInt(item.discount_price ? item.discount_price : item.price)
							)}
						</ItemPrice>
						{item.discount_price && (
							<React.Fragment>
								<ItemDiscount>Rp{parseIDR(parseInt(item.price))}</ItemDiscount>
								<ItemDiscountPercentage>
									-
									{calcDiscountPercentage(
										parseInt(item.price),
										parseInt(item.discount_price)
									)}
									%
								</ItemDiscountPercentage>
							</React.Fragment>
						)}
					</ItemPriceWrapper>
					<ItemDescription>
						{isShowFullDescription
							? item?.description
							: item.description?.substring(0, 80)}
					</ItemDescription>
					{item?.description?.length > 80 && !isShowFullDescription && (
						<SeeMore
							onClick={() => setIsShowFullDescription(!isShowFullDescription)}
							color={hexPrimary}
						>
							{WORDING.general.SEE_DETAIL[language]}
						</SeeMore>
					)}
					{item?.description?.length > 80 && isShowFullDescription && (
						<SeeMore
							onClick={() => setIsShowFullDescription(!isShowFullDescription)}
							color={hexPrimary}
						>
							{WORDING.general.HIDE_DETAIL[language]}
						</SeeMore>
					)}
					{item.variant && <GreyLine />}
					{item.variant &&
						item.variant.length > 0 &&
						item.variant.map(
							({
								is_mandatory,
								name: categoryName,
								num_option,
								variant_details,
								id: variantId,
							}: IVariant) => {
								return (
									<React.Fragment>
										<VariantWrapper>
											<VariantName>{categoryName}</VariantName>
											<VariantDescription>
												{is_mandatory
													? WORDING.general.MANDATORY[language]
													: WORDING.general.OPTIONAL[language]}
												<TextSeparator />
												{num_option === 0
													? WORDING.item_page.NO_LIMIT[language]
													: `${WORDING.item_page.MAX[language]} ${num_option} ${WORDING.item_page.OPTION[language]}`}
											</VariantDescription>
											{variant_details.map(
												({
													name,
													price,
													id,
													is_out_of_stock,
													stock,
												}: IVariantDetail) => {
													return (
														<VariantOptionWrapper>
															{num_option === 0 || num_option > 1 ? (
																<CheckboxContainer
																	id={`${id}`}
																	onClick={
																		is_out_of_stock || stock === 0
																			? () => {}
																			: () =>
																					handleVariantInput(
																						true,
																						`${variantId}`,
																						`${id}`,
																						name,
																						price,
																						num_option,
																						categoryName
																					)
																	}
																>
																	<HiddenCheckbox
																		name={`${id}`}
																		checked={
																			variantInput[variantId] &&
																			variantInput[variantId][id]
																				? true
																				: false
																		}
																	/>
																	<StyledCheckbox
																		checked={
																			variantInput[variantId] &&
																			variantInput[variantId][id]
																				? true
																				: false
																		}
																		isCheckbox={true}
																		color={hexPrimary}
																	>
																		<Icon viewBox="0 0 24 24">
																			<polyline points="20 6 9 17 4 12" />
																		</Icon>
																	</StyledCheckbox>
																</CheckboxContainer>
															) : (
																<CheckboxContainer
																	id={`${id}`}
																	onClick={
																		is_out_of_stock || stock === 0
																			? () => {}
																			: () =>
																					handleVariantInput(
																						false,
																						`${variantId}`,
																						`${id}`,
																						name,
																						price,
																						num_option,
																						categoryName
																					)
																	}
																>
																	<HiddenCheckbox
																		name={`${id}`}
																		checked={
																			variantInput[variantId] &&
																			variantInput[variantId][id]
																				? true
																				: false
																		}
																	/>
																	<StyledCheckbox
																		checked={
																			variantInput[variantId] &&
																			variantInput[variantId][id]
																				? true
																				: false
																		}
																		isCheckbox={false}
																		color={hexPrimary}
																	>
																		<Icon viewBox="0 0 24 24">
																			<polyline points="20 6 9 17 3 12" />
																		</Icon>
																	</StyledCheckbox>
																</CheckboxContainer>
															)}
															<VariantOptionName
																isDisabled={is_out_of_stock || stock === 0}
															>
																{name}
															</VariantOptionName>
															<VariantOptionPrice
																isDisabled={is_out_of_stock || stock === 0}
															>
																{is_out_of_stock || stock === 0
																	? "Habis"
																	: `+ Rp${parseIDR(parseInt(price))}`}
															</VariantOptionPrice>
														</VariantOptionWrapper>
													);
												}
											)}
										</VariantWrapper>
										<GreyLine />
									</React.Fragment>
								);
							}
						)}
					{notesInput === "" && !isEditNotes ? (
						<Notes color={hexPrimary} onClick={() => setIsEditNotes(true)}>
							{WORDING.item_page.ADD_NOTES[language]}
						</Notes>
					) : (
						<InputNotes
							value={notesInput}
							// @ts-ignore
							onChange={(e) => setNotesInput(e.target.value)}
							placeholder={WORDING.item_page.NOTE_FOR_SELLER[language]}
							maxLength={35}
						/>
					)}
          {showTakeaway && (
              <TakeawayContainer>
                <CheckboxContainer
                  id="takeaway_checkbox"
                  onClick={() => setTakeaway((prevTakeaway) => !prevTakeaway)}
                >
                  <HiddenCheckbox
                    name="takeaway_checkbox"
                    checked={isTakeaway}
                  />
                  <StyledCheckbox
                    isCheckbox
                    checked={isTakeaway}
                    color={hexPrimary}
                  >
                    <Icon viewBox="0 0 24 24">
                      <polyline points="20 6 9 17 4 12" />
                    </Icon>
                  </StyledCheckbox>
                </CheckboxContainer>
                <TakeawayLabel>{WORDING.item_page.FOR_TAKE_AWAY[language]}</TakeawayLabel>
              </TakeawayContainer>
          )}
					<ButtonFixWrapper>
						<ItemButtonWrapper>
							<ButtonAddMin
								color={hexPrimary}
								isClickable={quantityInput !== 0}
								onClick={
									quantityInput !== 0
										? () => handleChangeQuantity(false)
										: () => {}
								}
							>
								-
							</ButtonAddMin>
							{quantityInput}
							<ButtonAddMin
								color={hexPrimary}
								isClickable={quantityInput + quantitySameId < item.stock}
								onClick={
									quantityInput + quantitySameId < item.stock
										? () => handleChangeQuantity(true)
										: () => {}
								}
							>
								+
							</ButtonAddMin>
						</ItemButtonWrapper>
						<ButtonNext
							color={hexPrimary}
							isDisabled={validationChangeSelectedItem()}
							onClick={
								validationChangeSelectedItem()
									? () => {}
									: handleAddNewSelectedItem
							}
						>
							{WORDING.general.NEXT2[language]}
						</ButtonNext>
					</ButtonFixWrapper>
				</ScrollAbleContent>
			</Content>
		</Container>
	);
};

export default BottomSheetItemDetail;
